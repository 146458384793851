import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import RouterWithSubRoutes from './RouterWithSubRoutes';
import { publicRouteList } from './PublicRoutes';
import { dashboardRouteList } from './DashboardRoutes';
import { signOut, setGeoCountry } from 'redux/auth';
import getCountry from 'lib/getCountry';

const publicRouteComponents = publicRouteList.map(
  ({ path, component, translationNameSpace }, key) => {
    const componentWithTranslations = withTranslation(translationNameSpace)(
      component
    );

    return (
      <Route
        exact
        path={path}
        component={componentWithTranslations}
        key={key}
      />
    );
  }
);

export default (_props) => {
  const { companyPermissions } = _props;
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);

  useEffect(() => {
    if (companyPermissions.length) return setIsRedirect(false);
    // signOutUser
    (async () => {
      dispatch(signOut());

      const response = await getCountry();
      const { data } = response;

      dispatch(
        setGeoCountry({
          countryName: data.countryname,
          countryCode: data.countrycode,
        })
      );
      setIsRedirect(true);
    })();
  }, [companyPermissions]);

  // For current logged in user,
  // they need to be forced log out to login again to get company accessible on views.
  if (isRedirect) return <Redirect to="/log-in" />;

  // handle user type url
  // eslint-disable-next-line max-len
  const allowedComponent = dashboardRouteList.filter(
    (details) => !details.view || companyPermissions.includes(details.view)
  );
  const dashboardRouteComponents = allowedComponent.map((route, key) => {
    return <RouterWithSubRoutes key={key} {...route} />;
  });

  return (
    <Switch>
      {publicRouteComponents}
      {dashboardRouteComponents}
      <Redirect to="/" />
    </Switch>
  );
};
