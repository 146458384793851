import { openSnackbar } from '../services/Notifier';

export const notify = (options) => {
  openSnackbar(options);
};

// error can be hash object or string
export const showErrorMessage = (error, autoHideDuration = 2000) => {
  const message =
    (error?.data || {})?.message ||
    ((error?.response || {})?.data || {})?.message ||
    error?.message ||
    error;

  notify({ message, variant: 'error', autoHideDuration });
};

// message is string
export const showSuccessMessage = (message, autoHideDuration = 2000) => {
  notify({ message, variant: 'success', autoHideDuration });
};

// message is string
export const showInfoMessage = (message, autoHideDuration = 2000) => {
  notify({ message, variant: 'info', autoHideDuration });
};

export const showSubscriptionMarmingMessage = ({
  message,
  autoHideDuration,
  closedCallback,
}) => {
  notify({ message, variant: 'info', autoHideDuration, closedCallback });
};
