import getCountry from 'lib/getCountry';

const { store } = require('services/store');
const { signOut, setGeoCountry } = require('redux/auth');

export async function getGeoCountry() {
  const response = await getCountry();
  const { data } = response;

  store.dispatch(
    setGeoCountry({
      countryName: data.countryname,
      countryCode: data.countrycode
    })
  );
}

export function signOutUser() {
  store.dispatch(signOut());
  getGeoCountry();
}
