import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography, Dialog, DialogTitle, DialogActions } from '@material-ui/core';

// Component styles
import styles from './styles';

// Common components
import { PopupMenu } from 'components/popups';

const TitleToolbar = props => {
  const {
    archiveDisabled,
    classes,
    className,
    clientDetails,
    detailPage = false,
    handleClickArchive,
    handleClickNew,
    handleCreateJob,
    handleShowJobSelectionToCreateInvoice,
    handleCreateProperty,
    listPage = false,
    translate,
    isShowingAccountingFeature
  } = props;

  const { displayName, firstName, lastName, title } = clientDetails;
  const personalName = [title, firstName, lastName].filter(Boolean).join(' ');

  const rootClassName = classNames(classes.root, className);
  const crumbsText = displayName ? `/ ${displayName}` : '';
  const crumbsUrl = displayName ? `/clients/${clientDetails.id}` : '';
  const menuData = [
    {
      title: translate('Common:createNew'),
      menuItems: [
        {
          label: translate('Common:job'),
          action: handleCreateJob,
        },
        {
          label: translate('Common:property'),
          action: handleCreateProperty,
        },
      ]
    }
  ];

  if (isShowingAccountingFeature) {
    menuData[0].menuItems.splice(1,0, {
      label: translate('Common:invoice'),
      action: handleShowJobSelectionToCreateInvoice,
    });
  }

  const [showArchiveConfirmation, setShowArchiveConfirmation] = React.useState(false);

  const confirmBeforeArchive = () => {
    setShowArchiveConfirmation(true);
  };

  const handleCancel = () => {
    setShowArchiveConfirmation(false);
  };

  const handleOk = () => {
    setShowArchiveConfirmation(false);
    handleClickArchive();
  };

  return (
    <div className={rootClassName}>
      {
        listPage ?
          <Grid container direction='row' justify='flex-end'>
            <Grid item>
              <Button
                color="primary"
                // size="large"
                variant="outlined"
                onClick={handleClickNew}
              >
                {translate('addNewClient')}
              </Button>
            </Grid>
          </Grid>
          :
          <Grid container direction='column' justify='space-between' spacing={2}>
            <Grid item container direction='row' alignItems='center' sm={12}>
              <Typography variant='h5' className={classes.backTitle}>
                {translate('Common:backTo')}
              </Typography>
              <Link to='/clients'>
                <Typography variant='h5' className={classes.backTo}>
                  {translate('Client:clients')}
                </Typography>
              </Link>
              {
                displayName &&
                  <Link to={crumbsUrl}>
                    <Typography variant='h5' className={`${classes.backTo} ${classes.capitalize}`}>
                      {crumbsText}
                    </Typography>
                  </Link>
              }
            </Grid>
            { detailPage &&
              <Grid item container direction='row' justify='space-between' sm={12}>
                <Grid item md={6} sm={12}>
                  <h1 className={classes.capitalize}>
                    { clientDetails.useCompanyName ? `${clientDetails.companyName} (${personalName})` : personalName }
                  </h1>
                </Grid>
                <Grid item container direction='row' justify='flex-end' spacing={1} md={6} sm={12}>
                  { !clientDetails.archived &&
                  <Grid item>
                    <Link to={`/clients/${clientDetails.id}/edit`}>
                      <Button variant="outlined">{translate('Common:edit')}</Button>
                    </Link>
                  </Grid>
                  }
                  <Grid item>
                    <Button variant="outlined" onClick={confirmBeforeArchive} disabled={archiveDisabled}>
                      {
                        clientDetails.archived ? translate('Common:unarchive') : translate('Common:archive')
                      }
                    </Button>
                  </Grid>
                  {
                    !clientDetails.archived &&
                    <Grid item>
                      <PopupMenu
                        menuData={menuData}
                        btnTitle={translate('Common:moreActions')}
                        btnIcon
                      />
                    </Grid>
                  }
                </Grid>
              </Grid>
            }
          </Grid>
      }

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        aria-labelledby="confirmation-dialog-title"
        open={showArchiveConfirmation}
      >
        <DialogTitle id="confirmation-dialog-title">
          { clientDetails.archived ?
            translate('Client:askConfirmationBeforeUnarchive')
            :
            translate('Client:askConfirmationBeforeArchive')
          }
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {translate('Common:cancel')}
          </Button>
          <Button onClick={handleOk} color="primary">
            {translate('Common:ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TitleToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

export default withStyles(styles)(TitleToolbar);