// import { setIsLoading } from '../redux/global';
import { showErrorMessage } from 'lib/notifier';
import { GET, POST, PUT, DELETE } from 'lib/api';

// Actions
const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';
const SET_CURRENT_SERVICE = 'SET_CURRENT_SERVICE';
const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_SERVICES = 'SET_SERVICES';
const SET_CLEAR_PRODUCT_SERVICE = 'SET_CLEAR_PRODUCT_SERVICE';

const initialState = {
  products: [],
  services: [],
  product: {},
  productId: null,
  service: {},
  serviceId: null,
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        productId: action.product.id,
        product: {
          ...action.product,
        }
      };

    case SET_CURRENT_SERVICE:
      return {
        ...state,
        serviceId: action.service.id,
        service: {
          ...action.service,
        }
      };

    case SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...action.products,
        }
      };


    case SET_SERVICES:
      return {
        ...state,
        services: {
          ...action.services,
        }
      };

    case SET_CLEAR_PRODUCT_SERVICE:
      return {
        ...state,
        product: {},
        productId: null,
        service: {},
        serviceId: null
      };

    default: return state;
  }
}

// Action Creators
export function setProducts(products) {
  return { type: SET_PRODUCTS, products };
}

export function setCurrentProduct(product) {
  return { type: SET_CURRENT_PRODUCT, product };
}

export function setServices(services) {
  return { type: SET_SERVICES, services };
}

export function setCurrentService(service) {
  return { type: SET_CURRENT_SERVICE, service };
}

export function clearProductsServices() {
  return { type: SET_CLEAR_PRODUCT_SERVICE };
}


// side effects, only as applicable
// e.g. thunks, epics, etc
export function getProducts(type) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const typeId = type === 'products' ? 'PRODUCT' : 'SERVICE';
    GET(
      `products?$limit=50&typeId=${typeId}&$sort[sortOrder]=1`,
      accessToken
    ).then((response) => {
      const { data } = response && response.data;

      if (data) {
        const products = data.reduce((results, product, index) => {
          results[index] = product;
          return results;
        }, {});

        if (type === 'products') {
          dispatch(setProducts(products));
        }
        if (type === 'services') {
          dispatch(setServices(products));
        }
        // dispatch(setIsLoading(false))
      } else {
        console.log(response);
      }
    }).catch(error => {
      showErrorMessage(error);
    });
  };
}

export function addProduct(form, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true))

    POST(
      '/products',
      accessToken,
      {
        data: form
      }
    ).then(result => {
      if (result.status === 200) {
        !!callback && callback();
        // dispatch(setIsLoading(false))
      }
    }).catch(error => {
      showErrorMessage(error);
    });
  };
}

export function updateProducts(id, form, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true))
    PUT(
      `products/${id}`,
      accessToken,
      {
        data: form
      }).then(result => {
      if (result.status === 200) {
        dispatch(getProducts('products'));
        dispatch(getProducts('services'));
        !!callback && callback();
        // dispatch(setIsLoading(false))
      }
    }).catch(error => {
      showErrorMessage(error);
    });
  };
}

export function deleteProduct(id, callback) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    // dispatch(setIsLoading(true));

    DELETE(`products/${id}`, accessToken).then(result => {
      const { status } = result;

      if (status === 200) {
        dispatch(getProducts('products'));
        dispatch(getProducts('services'));
        !!callback && callback();
        // dispatch(setIsLoading(false));
      };
    }).catch(error => {
      showErrorMessage(error);
    });
  };
};