export default {
  // common && crumbs
  staff:	'พนักงาน',
  manageTeam:	'จัดการทีมงาน',
  manageTeamDesc:	'เพิ่มหรือแก้ไข, มอบหมายงาน, หรือแก้ไขฟีเจอร์การเข้าถึงข้อมูลของพนักงานที่ใช้งาน WorkMagic',
  addStaff:	'+ เพิ่มพนักงาน',
  staffDetails:	'รายละเอียดพนักงาน',
  editStaff:	'แก้ไขพนักงาน',
  team:  'ทีมงาน',

  // staff table
  showDeactivated:	'แสดงข้อมูลพนักงานทั้งหมด',
  search:	'ค้นหา',
  avatar:	'ภาพ',
  firstName:	'ชื่อ',
  lastName:	'นามสกุล',
  email:	'อีเมล',
  phone:	'เบอร์โทรศัพท์',
  role:	'ตำแหน่ง',
  actions:	'รายการ',
  rows:	'rows',
  of:	'ของ',
  editUser:	'แก้ไขผู้ใช้งาน',
  deactivate:	'ปิดการใช้งาน',
  activate:	'เปิดการใช้งาน',
  delete:	'ลบ',

  // staff details
  personalInfo:	'ข้อมูลส่วนตัว',
  uploadPicture:	'อัพโหลดรูป',
  roles:	'ตำแหน่ง',
  permissionLevel:	'คุณอนุญาตให้ผู้ใช้งานนี้เข้าถึงข้อมูลได้มาก-น้อยเพียงใด',
  makeAdmin:	'ตั้งเป็นผู้จัดการบัญชี',
  adminPermissions: 'ผู้จัดการบัญชีสามารถเข้าถึงข้อมูลได้ทุกอย่าง เปลี่ยนตำแหน่งผู้ใช้งานนี้เพื่อแก้ไขระดับการเข้าถึง',
  ownerPermissions: 'เจ้าของบัญชีสามารถเข้าถึงข้อมูลได้ทุกอย่าง',
  select:	'เลือก',
  assignTo:	'(ไม่บังคับ) เลือกพนักงานที่ผู้ใช้งานคนนี้รายงานตรงต่อ',
  reporting: 'รายงานตรงต่อ',
  saveDetails:	'บันทึกรายละเอียด',
  addNewStaff:	'เพิ่มพนักงาน',
  askConfirmationBeforeDelete: 'คุณต้องการจะลบพนักงานคนนี้หรือไม่',
  updatedSuccess:	'{{staffName}} ได้ถูกอัปเดตเรียบร้อยแล้ว',

  clearFilter: 'Clear Filter'
};