/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

// redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  getVisits,
  updateVisit,
  deleteVisit,
  updateVisitByAction,
} from 'redux/visit';
import { clearJob, setCurrentJob } from 'redux/job';
import { reset } from 'redux-form';
import { setIsCalendarFiltering } from 'redux/global';
import {
  QUERY_STRING_DECODE,
  QUERY_STRING_ENCODE,
  SIDE_BAR_FILTER_FORM_NAME,
  STATUSES,
} from 'common/constant';
import queryString from 'query-string';

// Material helpers
import { withStyles } from '@material-ui/core/styles';

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';

// Custom components
import {
  VisitDetailsForm,
  JobDetailsForm,
  PopupToAddNewEvent,
  VisitDetailsModal,
} from '../components/modals';
import { showErrorMessage } from 'lib/notifier';

// Component styles
import styles from './style';
import './calendar.scss';
import {
  Portlet,
  PortletContent,
  FilterSidebar,
  BigCalendar,
} from 'pages/Dashboard/components';

import { CalendarListToolbar } from '../components/utils';
import { ArrowRight } from '@material-ui/icons';
import { BackdropCustom, withBackdropHook } from 'lib/loading';
import { checkItemsCheckbox, checkStatusIds } from 'pages/Dashboard/helpers/checkValue';
import { getTimeFormat } from 'lib/formatter';

const VISIT_STATUSES = [
  { value: 'ON_STANDBY', label: 'On Standby' },
  { value: 'ON_THE_WAY', label: 'On The Way' },
  { value: 'ARRIVED', label: 'Arrived' },
  { value: 'STARTED', label: 'Started' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'UNASSIGNED', label: 'Unassigned' },
  { value: 'LATE_VISIT', label: 'Late Visit' },
  { value: 'CANCELLED', label: 'Cancelled' },
];

class CalendarList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElDetailsPopup: null,
      eventsList: [],
      unscheduledVisits: [],
      unassignedVisits: [],
      showVisitDetailsForm: false,
      showJobDetailsForm: false,
      showNewEventPopup: false,
      selectedVisit: null,
      selectedDate: null,
      currentFilter: 'ALL',
      currentElPosition: null,
      currentStartDate: null,
      currentEndDate: null,
      currentCalendarView: 'month',
    };
    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.handleCloseVisitPopover = this.handleCloseVisitPopover.bind(this);
    this.handleCloseVisitForm = this.handleCloseVisitForm.bind(this);
    this.handleCloseNewEventPopup = this.handleCloseNewEventPopup.bind(this);
    this.handleSelectSlot = this.handleSelectSlot.bind(this);
    this.afterSaveVisitDetails = this.afterSaveVisitDetails.bind(this);
    this.openJobForm = this.openJobForm.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.handleClickVisitItem = this.handleClickVisitItem.bind(this);
    this.handleCalendarViewChange = this.handleCalendarViewChange.bind(this);
    this.handleCloseJobForm = this.handleCloseJobForm.bind(this);
    this.afterSaveJobDetails = this.afterSaveJobDetails.bind(this);
    this.fetchJobVisits = this.fetchJobVisits.bind(this);
    this.fetchUncheduledJobVisits = this.fetchUncheduledJobVisits.bind(this);
    this.startRefreshCalendarTimer = this.startRefreshCalendarTimer.bind(this);
    this.refreshCalendar = this.refreshCalendar.bind(this);
    this.setSelectedVisitDetails = this.setSelectedVisitDetails.bind(this);
  }

  startRefreshCalendarTimer() {
    // auto refresh every 5 minutes
    this.intervalId = setInterval(this.refreshCalendar, 5 * 60 * 1000);
  }

  refreshCalendar() {
    let fromDate;
    let toDate;
    const { currentCalendarView } = this.state;
    const getSearchParams = this.props.location.search;
    const objSearchParams = queryString.parse(getSearchParams, QUERY_STRING_DECODE);

    if (currentCalendarView === 'day') {
      fromDate = moment().startOf('day').format();
      toDate = moment().endOf('day').format();
    } else if (currentCalendarView === 'week') {
      fromDate = moment().startOf('week').format();
      toDate = moment().endOf('week').format();
    } else if (currentCalendarView === 'month') {
      fromDate = moment().startOf('month').startOf('week').format();
      toDate = moment().endOf('month').endOf('week').format();
    }
    this.setState({
      currentStartDate: fromDate,
      currentEndDate: toDate,
    });
    this.fetchJobVisitsService({
      ...objSearchParams,
      from: fromDate,
      to: toDate,
      statusIds: checkStatusIds(objSearchParams.statusIds),
      isCalendarPage : true
    });
    this.fetchUncheduledJobVisits();
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshCalendar();
    this.startRefreshCalendarTimer();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // reset filter form
    this.props.setIsCalendarFilteringDispatch(false);
    this.props.resetForm(SIDE_BAR_FILTER_FORM_NAME);
    clearInterval(this.intervalId);
  }

  handleCloseVisitPopover(needUpdate) {
    this.setState({
      anchorElDetailsPopup: null,
      // selectedVisit: null,
    });

    if (needUpdate) {
      this.fetchJobVisits({
        fromDate: this.state.currentStartDate,
        toDate: this.state.currentEndDate,
      });
    }
  }

  setSelectedVisitDetails(newData) {
    this.setState({ selectedVisit: newData });
  }

  handleCloseVisitForm() {
    this.setState({ showVisitDetailsForm: false });
  }

  handleCloseJobForm() {
    this.props.clearJob();
    this.setState({ showJobDetailsForm: false, selectedDate: null });
  }

  handleCloseNewEventPopup() {
    this.setState({ showNewEventPopup: false });
  }

  handleSelectSlot(e) {
    this.setState({
      showNewEventPopup: true,
      currentElPosition: { top: e.box.y, left: e.box.x },
      selectedDate: e.start,
    });
  }

  handleClickEditJob(visit) {
    this.setState({
      selectedVisit: visit,
      anchorElDetailsPopup: null,
      showVisitDetailsForm: true,
    });
  }

  handleClickVisitItem(e, visit) {
    // Close another popover if any
    this.setState({ anchorElDetailsPopup: null });
    this.setState({ showVisitDetailsForm: false });
    if (visit.virtual) {
      this.setState({ selectedVisit: visit });
      this.setState({ showVisitDetailsForm: true });
    } else {
      const thisEvent = e.currentTarget;
      thisEvent.setAttribute('aria-controls', 'rbc-selected');
      this.setState({
        anchorElDetailsPopup: e.currentTarget,
        selectedVisit: visit,
      });
    }
  }

  handleCalendarViewChange(view) {
    this.setState({ currentCalendarView: view });
  }

  onSaveFilters = (values) => {
    const staffList =
      values.staffs && values.staffs.map((staff) => staff.value);
    const hubList =
      values.hubs && values.hubs.map((hub) => hub.value);
    const jobStatusId =
      values.status && Array.isArray(values.status)
        ? values.status.map((item) => item.value)
        : values.status;
    const serviceReport =
      Array.isArray(values.serviceReport) &&
      values.serviceReport.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []);

    const lateVisit =
      Array.isArray(values.lateVisit) &&
      values.lateVisit.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []);

    const isItemsCollected =
      Array.isArray(values.isCollectedItems) &&
      values.isCollectedItems.reduce((prev, current) => {
        if (!current.checked) return prev;
        return [...prev, current.value];
      }, []);

    this.fetchJobVisits({
      fromDate: this.state.currentStartDate,
      toDate: this.state.currentEndDate,
      jobStatusId,
      staffIds: staffList,
      hubIds: hubList,
      jobCategoryIds: values.categoryIds,
      serviceReport,
      lateVisit,
      isItemsCollected,
      isVerified: values.isVerified
    });
    this.fetchUncheduledJobVisits({
      staffIds: staffList,
      hubIds: hubList,
    });
  };

  onSelectEvent(event, e) {
    this.setState({ anchorElDetailsPopup: null }); // Close another popover if any
    const thisEvent = e.currentTarget;
    thisEvent.setAttribute('aria-controls', 'rbc-selected');
    this.setState({
      anchorElDetailsPopup: e.currentTarget,
      selectedVisit: event,
    });
  }

  onRangeChange(rangeDate) {
    const { isFiltering, staffs, status, checkItemsCollected, jobCategoryIds: getJobCategoryIds,
      serviceReports, checkLateVisit, hubs } = this.props;
    let fromDate;
    let toDate;
    if (Array.isArray(rangeDate)) {
      fromDate = new Date(rangeDate[0]);
      toDate = new Date(
        moment(rangeDate[rangeDate.length - 1])
          .endOf('day')
          .toDate()
      );

      if (fromDate.getTime() === toDate.getTime()) {
        toDate = new Date(moment(toDate.getTime()).endOf('day').toDate());
      }
    } else {
      fromDate = new Date(rangeDate.start);
      toDate = new Date(rangeDate.end);
    }

    this.setState({
      currentStartDate: fromDate,
      currentEndDate: toDate,
    });

    let payload = { from: fromDate, to: toDate };
    if (isFiltering) {
      const staffList = staffs && staffs.map((staff) => staff.value);
      const hubList = hubs && hubs.map((hub) => hub.value);
      const jobStatusId =
        status && Array.isArray(status)
          ? status.map((item) => item.value)
          : status;
      const jobCategoryIds = getJobCategoryIds;
      const serviceReport = checkItemsCheckbox(serviceReports);
      const isItemsCollected = checkItemsCheckbox(checkItemsCollected);
      const lateVisit = checkItemsCheckbox(checkLateVisit);
      payload = { ...payload, jobStatusId, staffIds: staffList,
        jobCategoryIds, isItemsCollected, serviceReport, lateVisit, hubIds: hubList,
        sort: { field: 'createdAt', orderDirection: 'asc' },
      };
      const getSearchParams = this.props.location.search;
      const objSearchParams = queryString.parse(getSearchParams, QUERY_STRING_DECODE);
      payload = { ...payload, ...objSearchParams };
    }

    this.fetchJobVisitsService({
      ...payload,
      statusIds: checkStatusIds(payload.statusIds),
      isCalendarPage : true
    });
  }

  updateFilterStatus = (status) => {
    this.props.setIsCalendarFilteringDispatch(status);
  };

  openJobForm() {
    this.props.setCurrentJob({
      startDate: this.state.selectedDate,
      startTime:
        this.state.currentCalendarView !== 'month'
          ? this.state.selectedDate
          : null,
      endDate: null,
      endTime: null,
    });
    this.setState({ showJobDetailsForm: true });
  }

  afterSaveJobDetails(job) {
    this.setState({ showJobDetailsForm: false });
    if (job.statusId === STATUSES.UNSCHEDULED) {
      this.fetchUncheduledJobVisits();
    } else {
      const { isFiltering, staffs, status } = this.props;
      const { currentStartDate, currentEndDate } = this.state;

      let payload = {
        fromDate: currentStartDate,
        toDate: currentEndDate,
      };
      if (isFiltering) {
        const staffList = staffs && staffs.map((staff) => staff.value);
        const jobStatusId =
          status && Array.isArray(status)
            ? status.map((item) => item.value)
            : status;
        payload = { ...payload, jobStatusId, staffIds: staffList };
      }
      this.fetchJobVisits(payload);
    }
  }

  afterSaveVisitDetails(visit) {
    const { currentStartDate, currentEndDate } = this.state;
    const { isFiltering, staffs, status } = this.props;
    let payload = {
      fromDate: currentStartDate,
      toDate: currentEndDate,
    };
    if (isFiltering) {
      const staffList = staffs && staffs.map((staff) => staff.value);
      const jobStatusId =
        status && Array.isArray(status)
          ? status.map((item) => item.value)
          : status;
      payload = { ...payload, jobStatusId, staffIds: staffList };
    }
    this.fetchJobVisits(payload);
    this.fetchUncheduledJobVisits();

    const { eventsList } = this.state;
    const visitIndex = eventsList.findIndex((item) => item.id === visit.id);
    if (visitIndex !== -1) {
      // have to  make sure start, end of visit must be parse to date.
      // If not wil lead to error d[("get" + method)] is not a function
      eventsList[visitIndex] = {
        ...eventsList[visitIndex],
        ...visit,
        start: moment(visit.start).toDate(),
        end: moment(visit.end).toDate(),
      };
      return this.setState({
        selectedVisit: eventsList[visitIndex],
        eventsList,
      });
    }
    this.setState({
      selectedVisit: null,
      showVisitDetailsForm: false,
      selectedDate: null,
    });
  }

  fetchUncheduledJobVisits(options = {}) {
    const { getVisitsDispatch } = this.props;
    const params = {
      statusIds: [STATUSES.UNSCHEDULED],
      sort: { field: 'createdAt', orderDirection: 'asc' },
      isCalendarPage : true
    };
    if (options.staffIds && Array.isArray(options.staffIds)) {
      params['staffIds'] = options.staffIds;
    }

    if (options.hubIds && Array.isArray(options.hubIds)) {
      params['hubIds'] = options.hubIds;
    }

    return getVisitsDispatch(params).then(
      (response) => {
        if (response.status === 200) {
          const jobVisits = [];

          response.data.data.map((visit) => jobVisits.push(visit));

          this.setState({ unscheduledVisits: jobVisits });
        }
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  }

  async fetchJobVisitsService(params) {
    try {
      const { getVisitsDispatch } = this.props;
      const { sort, from, to, ...urlSearch } = params;
      const stringified = queryString.stringify(urlSearch, QUERY_STRING_ENCODE);
      this.props.history.replace({ ...this.props.location, search: stringified });
      const response = await this.props.withLoading(getVisitsDispatch(params));

      if (response.status === 200) {
        const jobVisits = [];
        response.data.data.forEach((visit) => {
          visit.start = moment(visit.start).toDate();
          visit.end = moment(visit.end).toDate();
          jobVisits.push(visit);
        });
        const unassignedVisits = response.data.data.filter(
          (visit) => visit.statusId === STATUSES.UNASSIGNED
        );
        this.setState({ eventsList: jobVisits, unassignedVisits });
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  async fetchJobVisits(options = {}) {
    const params = {
      sort: {
        field: options.sortBY || 'createdAt',
        orderDirection: options.orderDirection || 'asc',
      },
      isCalendarPage : true
    };

    if (options.fromDate) {
      params['from'] = options.fromDate;
    }
    if (options.toDate) {
      params['to'] = options.toDate;
    }

    if (options.jobStatusId && options.jobStatusId !== 'ALL') {
      params['statusIds'] = options.jobStatusId;
    }

    if (options.staffIds && Array.isArray(options.staffIds)) {
      params['staffIds'] = options.staffIds;
    }

    if (options.hubIds && Array.isArray(options.hubIds)) {
      params['hubIds'] = options.hubIds;
    }

    if (options.jobCategoryIds && Array.isArray(options.jobCategoryIds)) {
      params['jobCategoryIds'] = options.jobCategoryIds;
    }

    if (Array.isArray(options.serviceReport)) {
      params['serviceReport'] = options.serviceReport;
    }

    if (Array.isArray(options.lateVisit)) {
      params['lateVisit'] = options.lateVisit;
    }

    if (Array.isArray(options.isItemsCollected)) {
      params['isItemsCollected'] = options.isItemsCollected;
    }
    params['isVerified'] = options.isVerified;


    this.fetchJobVisitsService(params);

  }

  render() {
    const {
      classes,
      translate,
    } = this.props;

    const {
      eventsList,
      selectedVisit,
      anchorElDetailsPopup,
      showVisitDetailsForm,
      showNewEventPopup,
      currentCalendarView,
      showJobDetailsForm,
      fromDate,
      toDate
    } = this.state;

    const mapStatusIdToClassName = {
      COMPLETED: classes.completedEvent,
      LATE_VISIT: classes.lateEvent,
      UNASSIGNED: classes.unassignedEvent,
      ON_STANDBY: classes.onStandbyEvent,
      ON_THE_WAY: classes.onTheWayEvent,
      ARRIVED: classes.arrivedEvent,
      STARTED: classes.startedEvent,
      CANCELLED: classes.cancelledEvent
    };

    const Event = ({ event }) => {
      const { job: { client: { displayName } = {} } = {} } = event;
      const clientName = displayName;
      const itemClassName =
        mapStatusIdToClassName[event.statusId] || classes.onStandbyEvent;

      return (
        <div className={itemClassName}>
          <div className={classes.eventContent}>
            <div className={classes.eventTime}>
              {translate('job')}:{' '}
              {moment(event.start).format(getTimeFormat())}
            </div>
            <div className={classes.eventTitle}>
              {clientName} - {event.title}
            </div>
          </div>
        </div>
      );
    };

    const CustomToolbar = (props) => {
      // need pass onSaveFilters, if not button filter not show
      return (
        <CalendarListToolbar
          {...props}
          isFiltering={this.props.isFiltering}
          openJobForm={this.openJobForm}
          fetchJobVisits={this.fetchJobVisits}
          fetchUncheduledJobVisits={this.fetchUncheduledJobVisits}
        />
      );
    };

    const callbackAfterMarkJobComplete = (response) => {
      // job get from response not contain job details, so extract it
      const {
        data: { job, ...otherData },
        status,
      } = response;
      const { eventsList: eventsListCallBack } = this.state;
      if (status === 200) {
        const visitDetails = {
          ...otherData,
          start: moment(otherData.start).toDate(),
          end: moment(otherData.end).toDate(),
        };
        const visitIndex = eventsListCallBack.findIndex(
          (item) => item.id === otherData.id
        );
        if (visitIndex !== -1) {
          eventsListCallBack[visitIndex] = Object.assign(
            eventsListCallBack[visitIndex],
            visitDetails
          );
          this.setState({
            selectedVisit: eventsListCallBack[visitIndex],
            eventsList: eventsListCallBack,
          });
        } else {
          this.setState({
            selectedVisit: {
              ...selectedVisit,
              statusId: otherData.statusId,
            },
          });
        }
      }
    };

    const callbackAfterDeleteVisit = async (result) => {
      if (result.data && result.data.data) {
        const deletedId = result.data.data && result.data.data.id;
        const visitIndex = eventsList.findIndex(
          (item) => item.id === deletedId
        );
        eventsList.splice(visitIndex, 1);
        this.setState({
          eventsList,
          selectedVisit: null,
          showVisitDetailsForm: false,
          selectedDate: null,
          anchorElDetailsPopup: null,
        });
        this.fetchUncheduledJobVisits();
      }
    };

    const fetchJobs = () => {
      this.fetchJobVisits({
        fromDate,
        toDate,
      });;
      this.fetchUncheduledJobVisits();
    };

    return (
      <div className={classes.root}>
        <BackdropCustom open={this.props.backdrop} />
        <FilterSidebar
          classNames={classNames}
          statues={VISIT_STATUSES}
          updateFilterStatus={this.updateFilterStatus}
          onSubmit={this.onSaveFilters}
          showLateVisitFilter
          showFilterCollectedItems
        />
        <Grid container spacing={4}>
          <Grid style={{ paddingRight: 0 }} item xl={10} lg={10} md={10} xs={8}>
            <div className={classes.content}>
              <BigCalendar
                eventsList={eventsList}
                views={['day', 'week', 'month']}
                currentCalendarView={currentCalendarView}
                CustomToolbar={CustomToolbar}
                Event={Event}
                handleCalendarViewChange={this.handleCalendarViewChange}
                handleSelectSlot={this.handleSelectSlot}
                onRangeChange={this.onRangeChange}
                onSelectEvent={this.onSelectEvent}
              />
            </div>
          </Grid>
          <Grid
            style={{ paddingLeft: 0, marginTop: 76 }}
            item
            xl={2}
            lg={2}
            md={2}
            xs={4}
          >
            {/* <Portlet className={classes.reminderPortlet}>
              <PortletContent className={classNames(classes.portletContent)}>
                <Grid container alignItems="center">
                  <span
                    className={`${classes.headerText} ${classes.scheduleColor}`}
                  >
                    {translate('needSchedule')}
                  </span>
                </Grid>
                <List className={classes.listControl}>
                  {this.state.unscheduledVisits.map((item, index) => {
                    const {
                      publicId,
                      job: { client: { displayName: clientName } = {} } = {},
                    } = item;
                    return (
                      <ListItem
                        key={item.id}
                        className={`${classes.listItem} ${index !== 0 && classes.borderTop}`}
                        onClick={(e) => this.handleClickVisitItem(e, item)}
                      >
                        <Grid container>
                          <ListItemText
                            className={classes.itemText}
                            primary={`${
                              (item.job || {}).publicId
                            } - ${clientName}`}
                          />
                          <ListItemText className={classes.itemText}>
                            {translate('visit', { visitId: publicId || (item.job || {}).publicId })}
                          </ListItemText>
                        </Grid>
                        <ListItemIcon className={classes.listItemIcon}>
                          <ArrowRight />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
              </PortletContent>
              <PortletContent className={`${classes.portletContent}`}>
                <Grid container alignItems="center">
                  <span
                    className={`${classes.headerText} ${classes.assignedColor}`}
                  >
                    {translate('needAssigned')}
                  </span>
                </Grid>
                <List className={classes.listControl}>
                  {this.state.unassignedVisits.map((item, index) => {
                    const {
                      publicId,
                      job: { client: { displayName: clientName } = {} } = {},
                    } = item;
                    return (
                      <ListItem
                        key={item.id}
                        className={`${classes.listItem} ${index !== 0 && classes.borderTop}`}
                        onClick={(e) => this.handleClickVisitItem(e, item)}
                      >
                        <Grid container>
                          <ListItemText
                            className={classes.itemText}
                            primary={`${
                              (item.job || {}).publicId
                            } - ${clientName}`}
                          />
                          <ListItemText className={classes.itemText}>
                            {translate('visit', { visitId: publicId || (item.job || {}).publicId })}
                          </ListItemText>
                        </Grid>
                        <ListItemIcon className={classes.listItemIcon}>
                          <ArrowRight />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })}
                </List>
              </PortletContent>
            </Portlet> */}
          </Grid>
        </Grid>

        <VisitDetailsModal
          showJobDetails={Boolean(anchorElDetailsPopup)}
          handleCloseVisitDetails={this.handleCloseVisitPopover}
          callbackAfterMarkJobComplete={callbackAfterMarkJobComplete}
          callbackAfterDeleteVisit={callbackAfterDeleteVisit}
          afterSaveVisitDetails={this.afterSaveVisitDetails}
          translate={translate}
          visitId={selectedVisit && selectedVisit.id}
          setSelectedVisitDetails={this.setSelectedVisitDetails}
          fetchJobs={fetchJobs}
          isCalendarPage
        />

        {selectedVisit && (
          <VisitDetailsForm
            visitDetails={selectedVisit}
            showVisitDetailsForm={showVisitDetailsForm}
            handleCloseVisitForm={this.handleCloseVisitForm}
            afterSaveVisitDetails={this.afterSaveVisitDetails}
            translate={translate}
          />
        )}

        {showJobDetailsForm && (
          <JobDetailsForm
            showJobDetailsForm={showJobDetailsForm}
            handleCloseJobForm={this.handleCloseJobForm}
            afterSaveJobDetails={this.afterSaveJobDetails}
            translate={translate}
          />
        )}

        {showNewEventPopup && (
          <PopupToAddNewEvent
            showNewEventPopup={showNewEventPopup}
            handleCloseNewEventPopup={this.handleCloseNewEventPopup}
            anchorPosition={this.state.currentElPosition}
            selectedDate={this.state.selectedDate}
            openJobForm={this.openJobForm}
            translate={translate}
          />
        )}
      </div>
    );
  }
}

CalendarList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getVisitsDispatch: (options) => dispatch(getVisits(options)),
  setCurrentJob: (job) => dispatch(setCurrentJob(job)),
  clearJob: () => dispatch(clearJob()),
  updateVisit: (jobDetails) => dispatch(updateVisit(jobDetails)),
  deleteVisit: (id) => dispatch(deleteVisit(id)),
  setIsCalendarFilteringDispatch: (status) =>
    dispatch(setIsCalendarFiltering(status)),
  resetForm: (name) => dispatch(reset(name)),
  updateVisitByActionDispatch: (payload) =>
    dispatch(updateVisitByAction(payload)),
});

const mapStateToProps = (state) => {
  const filterParams = state.global.filterParams || {};
  return {
    isFiltering: state.global.isCalendarFiltering || false,
    staffs: filterParams.staffs || '',
    hubs: filterParams.hubs || '',
    status: filterParams.status || '',
    jobCategoryIds: filterParams.categoryIds|| [],
    serviceReports: filterParams.serviceReport,
    checkItemsCollected: filterParams.isCollectedItems,
    checkLateVisit: filterParams.lateVisit,
  };
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withBackdropHook
)(CalendarList);
