import React, { useEffect, useState } from 'react';

import {
  reduxForm,
  Field,
  change,
  Fields,
  formValueSelector,
} from 'redux-form';
import { useDispatch, connect } from 'react-redux';

import {
  Button,
  Typography,
  Box,
  Tooltip,
  Grid,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import styles from '../../components.module.scss';
import moment from 'moment';
import { StatusChip } from 'pages/Dashboard/components';
import InfoIcon from '@material-ui/icons/Info';
import { showErrorMessage } from 'lib/notifier';

import { getJob, jobActions } from 'redux/job';

import detailsIcon from 'assets/images/icons/details.svg';
import CalendarSrc from 'assets/images/enterprise/calendar.png';
import CopyButton from './copyBtn';
import { getDateTimeFormat } from 'lib/formatter';
import ReviewChip from 'pages/Dashboard/components/ReviewChip';

const FORM_NAME = 'GeneralInfoForm';

const RenderCurrentVisit = ({ input, translate }) => {
  if (!input.value) return null;
  return (
    <Box
      mt={3}
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={styles.current_visit_wrapper}
    >
      <Box display="flex">
        <img src={CalendarSrc} alt="calendarSrc" />
        <Box ml={2} display="flex" flexDirection="column">
          <Typography variant="h5">
            {translate('Dashboard:currentVisit')}
          </Typography>
          <Typography variant="subtitle1" className={`${styles.subtitle} mt_5`}>
            {moment(input.value.start).format(getDateTimeFormat())}
          </Typography>
          <Typography variant="subtitle1" className={styles.subtitle}>
            ID: {input.value.publicId}
          </Typography>
        </Box>
      </Box>
      <Box>
        <StatusChip job status={input.value.statusId} />

        <Tooltip className="ml_5" title={translate('currentVisitToolTip')}>
          <InfoIcon />
        </Tooltip>
      </Box>
    </Box>
  );
};

const RenderStatusChip = ({
  groupStatusFilter,
  verified,
  startVerifiedAt,
  label,
}) => {
  const getReviewStatus = () => {
    if (verified?.input.value) return 'REVIEWED';
    return startVerifiedAt?.input.value ? 'IN_REVIEW' : '';
  };

  if (!groupStatusFilter?.input.value) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>
        {label}
      </Typography>
      <Box className={styles.statusChip} display="flex" alignItems="center">
        <StatusChip
          job
          status={groupStatusFilter.input.value}
          reviewStatus={getReviewStatus()}
        />
        {getReviewStatus() === 'IN_REVIEW' && <ReviewChip />}
      </Box>
    </Box>
  );
};

const RenderField = ({
  input,
  label,
  isBoldText,
  isShowCopyBtn,
  translate,
}) => {
  if (!input.value) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography
          className={isBoldText ? styles.boldText : styles.normalText}
        >
          {input.value}
        </Typography>
        {isShowCopyBtn && (
          <CopyButton translate={translate} jobId={input.value} />
        )}
      </Box>
    </Box>
  );
};

const RenderFields = (props) => {
  const { label, translate } = props;
  const start = props[props.names[0]].input.value;
  const end = props[props.names[1]].input.value;

  if (!start || !end) return null;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>
        {label}
      </Typography>
      <Box display="flex" alignItems="center">
        <Typography className={styles.boldText}>{start}</Typography>
        <Box mx={1}>
          <Typography variant="subtitle1" className={styles.form_title}>
            {translate('to')}
          </Typography>
        </Box>
        <Typography className={styles.boldText}>{end}</Typography>
      </Box>
    </Box>
  );
};

const RenderCategoriesField = (props) => {
  const { label, input } = props;
  if (!input.value || !input.value.length) return null;
  const parentList = input.value?.filter((v) => v.parentCategoryId === null);
  const categoryList = parentList.map((item) => {
    // Get sub category items
    const subCategories = input.value.filter(
      (c) => c.parentCategoryId === item.id
    );
    // Get sub sub category items
    const subSubCategories = input.value.filter((c) => {
      return subCategories.filter((sc) => c.parentCategoryId === sc.id).length;
    });
    // Convert to display string
    item.subSubCategories = subSubCategories.map((ssc) => ssc.name).join(', ');
    item.subCategories = subCategories.map((sc) => sc.name).join(', ');
    return item;
  });

  return (
    <Box mt={2} display="flex" flexDirection="column">
      <Typography variant="subtitle1" className={styles.form_title}>
        {label}
      </Typography>
      {categoryList.map((c) => {
        return (
          <Box key={c.id} display="flex" alignItems="center">
            <Typography
              noWrap
              className={styles.boldText}
            >{`${c.name}${c.subCategories && ("/"+ c.subCategories)}${c.subSubCategories && ("/"+ c.subSubCategories)}`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
const renderCheckbox = ({ input, label, handleOpenItemCollectedModal }) => {
  return (
    <FormControlLabel
      classes={{ root: styles.form_label }}
      control={
        <Checkbox
          checked={input.value}
          onChange={handleOpenItemCollectedModal}
          classes={{
            checked: styles.checked_state,
          }}
        />
      }
      label={label}
    />
  );
};

const renderPropertyField = ({ input, label, translate }) => {
  if (!input.value || !Object.keys(input.value).length) return null;
  const { unitNumber, address } = input.value;
  const formattedAddress = unitNumber
    ? [unitNumber, address].join(', ')
    : address;
  return (
    <Box mt={2} display="flex" flexDirection="column">
      {label && (
        <Typography variant="subtitle1" className={styles.form_title}>
          {label}
        </Typography>
      )}
      <Box>
        <Typography className="font_bold">{input.value.name}</Typography>
        <Typography>{unitNumber}</Typography>
        <Typography>{address}</Typography>
        <CopyButton translate={translate} address={formattedAddress} />
      </Box>
    </Box>
  );
};

let GeneralInfo = (props) => {
  const { translate, isCollectedVal, visitDetails: { job } = {} } = props;

  const dispatch = useDispatch();

  const [
    isShowingItemCollectedModal,
    setIsShowingItemCollectedModal,
  ] = useState(false);

  useEffect(() => {
    if (!job || !job.publicId) return null;
    (async () => {
      const result = await dispatch(getJob(job.publicId)).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);

      const {
        publicId,
        statusId,
        title,
        createdAt,
        description,
        currentVisit,
        jobSchedule: { start, end } = {},
        property,
        isCollected,
        verified,
        startVerifiedAt,
        categories,
      } = result.data;

      dispatch(change(FORM_NAME, 'jobId', publicId));
      dispatch(change(FORM_NAME, 'groupStatusFilter', statusId));
      dispatch(change(FORM_NAME, 'jobTitle', title));
      dispatch(change(FORM_NAME, 'categories', categories));
      dispatch(change(FORM_NAME, 'jobDescription', description));
      dispatch(change(FORM_NAME, 'currentVisit', currentVisit));
      dispatch(change(FORM_NAME, 'isCollected', isCollected));
      dispatch(
        change(
          FORM_NAME,
          'dateCreated',
          moment(createdAt).format(getDateTimeFormat())
        )
      );
      dispatch(
        change(
          FORM_NAME,
          'scheduleStart',
          start && moment(start).format(getDateTimeFormat())
        )
      );
      dispatch(
        change(
          FORM_NAME,
          'scheduleEnd',
          end && moment(end).format(getDateTimeFormat())
        )
      );
      dispatch(change(FORM_NAME, 'property', property));
      dispatch(change(FORM_NAME, 'verified', verified));
      dispatch(change(FORM_NAME, 'startVerifiedAt', startVerifiedAt));
    })();
  }, [job, dispatch]);

  const handleClickManagement = () =>
    window.open(`/jobs/${job.publicId}`, '_blank');

  const handleOpenItemCollectedModal = () =>
    setIsShowingItemCollectedModal(true);

  const handleCloseItemCollectedModal = () =>
    setIsShowingItemCollectedModal(false);

  const handleConfirmChangeItemCollected = async () => {
    const action = isCollectedVal ? 'MARK_UNCOLLECTED' : 'MARK_COLLECTED';
    const result = await dispatch(jobActions(job.publicId, action)).catch(
      (e) => ({
        e,
      })
    );
    if (result.e) return showErrorMessage(result.e);
    handleCloseItemCollectedModal();

    dispatch(change(FORM_NAME, 'isCollected', result.isCollected));
    // fetchJobs && fetchJobs();
  };
  return (
    <>
      <Dialog
        open={isShowingItemCollectedModal}
        onClose={handleCloseItemCollectedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography variant="h4" className="mb_5">
            {translate(
              job.isCollected
                ? 'markItemUncollectedTitle'
                : 'markItemCollectedTitle'
            )}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseItemCollectedModal} variant="outlined">
            {translate('Common:close')}
          </Button>
          <Button
            onClick={handleConfirmChangeItemCollected}
            className={styles.confirm_change_collected}
            variant="contained"
            autoFocus
          >
            {translate('Common:confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3" className={styles.modal_title}>
            {translate('modalGeneralInfo')}
          </Typography>
          <Box display="flex" alignItems="center">
            <Field
              name="isCollected"
              component={renderCheckbox}
              label={translate('markItemsCollected')}
              handleOpenItemCollectedModal={handleOpenItemCollectedModal}
            />
            <Button
              onClick={handleClickManagement}
              variant="contained"
              className={styles.btn_management}
            >
              <img src={detailsIcon} alt="detailsIcon" className="mr_5" />
              {translate('fullDetails')}
            </Button>
          </Box>
        </Box>

        <Field
          name="currentVisit"
          component={RenderCurrentVisit}
          translate={translate}
        />

        <Box display="flex" flexDirection="column">
          <Grid container a>
            <Grid container item sm={6} md={6} alignItems="flex-end">
              <Field
                label={translate('jobId')}
                name="jobId"
                component={RenderField}
                translate={translate}
                isShowCopyBtn
              />
            </Grid>
            <Grid item sm={6} md={6}>
              <Fields
                label={translate('status')}
                names={['groupStatusFilter', 'startVerifiedAt', 'verified']}
                component={RenderStatusChip}
                translate={translate}
              />
            </Grid>
          </Grid>
          <Field
            label={translate('jobTitle')}
            name="jobTitle"
            component={RenderField}
            translate={translate}
            isBoldText
          />
          <Field
            label={translate('dateCreated')}
            name="dateCreated"
            component={RenderField}
            translate={translate}
            isBoldText
          />
          <Fields
            label={translate('schedule')}
            names={['scheduleStart', 'scheduleEnd']}
            component={RenderFields}
            translate={translate}
          />
          <Field
            label={translate('location')}
            name="property"
            component={renderPropertyField}
            translate={translate}
          />
          <Field
            label={translate('category')}
            name="categories"
            component={RenderCategoriesField}
            translate={translate}
          />
          <Field
            label={translate('jobDescription')}
            name="jobDescription"
            component={RenderField}
            translate={translate}
          />
        </Box>
      </Box>
    </>
  );
};

const formSelector = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => {
  return {
    isCollectedVal: formSelector(state, 'isCollected'),
  };
};

GeneralInfo = reduxForm({
  form: FORM_NAME,
  enableReinitialize: true,
})(GeneralInfo);

export default connect(mapStateToProps, null)(GeneralInfo);
