import React from 'react';

// Externals
import { Link } from 'react-router-dom';

// Material components
import { Button, Grid, Menu, MenuItem, Typography, withStyles } from '@material-ui/core';

// Component styles
import { JobClientNameHeaderStyles } from './styles';
import ConfirmArchiveJobModal from '../modals/ConfirmArchiveJobModal';

export default withStyles(JobClientNameHeaderStyles)((props) => {
  const {
    allowedStatuses,
    classes,
    handleClickArchive,
    handleClickStatus,
    job,
    jobStatuses,
    translate,
  } = props;
  const {
    publicId,
    statusId,
  } = job;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmArchiveJob, setConfirmArchiveJob] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Grid className={classes.headerContainer} item md={5} sm={12}>
        <Grid item container direction="column">
          <Grid itemScope>
            <Typography className={classes.jobTitle} variant="h3">{job.title}</Typography>
          </Grid>
          <Grid item container direction='row'>
            <Typography className={classes.textColor} style={{ marginRight: 10 }} variant="h4">ID: </Typography>
            <Typography className={classes.textColor} variant="h4">{job.publicId}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        justify="flex-end"
        spacing={1}
        md={7}
        sm={12}
      >
        <Grid item>
          {
            (job.permissions || {}).ableToEdit ?
              <Link to={`/jobs/${publicId}/edit`}>
                <Button variant="outlined" color="primary">
                  {translate('Common:edit')}
                </Button>
              </Link> :
              <Button variant="outlined" color="primary" disabled>
                {translate('Common:edit')}
              </Button>
          }
        </Grid>
        {
          !job.archived &&
            <Grid item>
              <Button
                aria-controls="status-menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                onClick={handleClick}
              >
                {translate('Common:markAs')}
              </Button>
              <Menu
                id="status-menu"
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.popoverWithArrow}
              >
                {jobStatuses &&
              jobStatuses.map((item) => {
                if (allowedStatuses.includes(item.id)) {
                  return (
                    <MenuItem
                      key={item.id}
                      disabled={statusId === item.id}
                      onClick={(e) => {
                        handleClickStatus(e, item.id);
                        setAnchorEl(null);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
                return null;
              })}
              </Menu>
            </Grid>
        }

        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (job.archived) return handleClickArchive();
              return setConfirmArchiveJob(true);
            }}
          >
            {job.archived
              ? translate('Common:unarchive')
              : translate('Common:archive')}
          </Button>

          {confirmArchiveJob && (
            <ConfirmArchiveJobModal
              open={confirmArchiveJob}
              handleClose={() => {
                setConfirmArchiveJob(false);
              }}
              handleConfirmArchive={handleClickArchive}
              translate={translate}
              jobId={job.publicId}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
});
