export default {
  // common && crumbs
  productsServices:	'สินค้า/บริการ',
  productsServicesDesc:	'เพิ่มและอัปเดตข้อมูลสินค้า/บริการ เพื่อการบริหารจัดการใบเสนอราคา งาน และใบแจ้งหนี้/ใบกำกับภาษีได้อย่างมีประสิทธิภาพ',
  addProduct:	'+ สินค้าใหม่',
  addService:	'+ บริการใหม่',


  // modalform
  services:	'บริการ',
  products:	'สินค้า',
  updateService:	'อัปเดตบริการ',
  updateProduct:	'อัปเดตสินค้า',
  addNewProduct:	'เพิ่มสินค้าใหม่',
  addNewService:	'เพิ่มบริการใหม่',
  addNewProductService:	'เพิ่มสินค้า/บริการใหม่',
  name:	'ชื่อ',
  description:	'รายละเอียด',
  unitCost:	'ราคาต่อหน่วย',
  taxRate:	'อัตราภาษี',
  create:	'สร้าง',
  update:	'อัปเดต',
  noService: 'ไม่มีบริการ เพิ่มบริการเพื่อให้แสดงในหน้านี้',
  noProduct: 'ไม่มีสินค้า เพิ่มสินค้าเพื่อให้แสดงในหน้านี้',
  permanentDelete: 'การลบ <0><0></0></0> จะเป็นการลบออกจากระบบ WorkMagic และไม่สามารถกู้คืนได้',
  noTax: 'ไม่มีภาษี',
  nameLimitCharacters: 'สูงสุด 100 ตัวอักษร',
  save: 'บันทึก',
};