import { white, black, COLORS, grey, gray } from '../colors';

export default {
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
    gray: '#F2F2F2',
    primary_yellow: '#F9C825',
  },
  primary: {
    contrastText: white,
    main: COLORS.pink,
    light: '#e63394',
    dark: '#9c0055',
    grey: grey[200],
  },
  secondary: {
    contrastText: white,
    main: COLORS.blue,
    light: '#3663a2',
    dark: '#022a61',
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#A6B1BB',
    grey700: grey[700],
    grey800: grey[800],
    blue: '#1070CA',
    gray: 'rgba(0, 0, 0, 0.5);',
    white,
    gray1: '#666666',
    black: '#1A1A1A',
    pink: '#EB008B',
  },
  background: {
    default: '#E2E2E2',
    dark: '#172B4D',
    paper: white,
    muted: grey[50],
    header: grey[200],
    muted2: grey[100],
    grey600: grey[600],
    grey620: grey[620],
    wm_clock_in_out: '#c72c6d',
    wm_visit: '#2E4F9B',
    wm_hover_color: 'rgba(0, 0, 0, 0.05)',
    wm_overtime: '#478FE0',
    wm_late: '#FF3B30',
    wm_sucess: '#30C17C',
    wm_decline: '#FF3B30',
    gray: '#F0F0F0',
  },
  border: '#DFE3E8',
  divider: '#DFE3E8',
  chip: {
    invoice: {
      light: '#FFE8DD',
      dark: '#FF5300',
    },
    lateVisit: {
      light: '#FFD4D4',
      dark: '#EF4570',
      wm_bg: '#EFE8FF',
      wm_color: '#8952FF',
    },
    unscheduled: {
      light: grey[50],
      dark: '#F37459',
      text: '#ED5A66',
      wm_bg: '#F6D7D9',
    },
    unassigned: {
      light: grey[50],
      dark: '#F9A34D',
      text: '#FF770B',
      wm_bg: '#FEEEE1',
    },
    onStandby: {
      light: grey[50],
      dark: '#FFD04F',
      wm_bg: '#F4E29D',
      wm_color: '#1A1A1A',
    },
    onTheWay: {
      light: grey[50],
      dark: '#D4C6D6',
      wm_bg: '#94BFFF',
      wm_color: '#1A1A1A',
    },
    arrived: {
      light: grey[50],
      dark: '#B087B5',
      wm_bg: '#E6A598',
      wm_color: '#1A1A1A',
    },
    started: {
      light: grey[50],
      dark: '#5CBAB4',
      wm_bg: '#E8FDF1',
      wm_color: '#3BB673',
    },
    completed: {
      light: grey[50],
      dark: '#77A9DB',
      wm_bg: '#EBF9F2',
      wm_color: '#30C17C',
    },
    abandoned: {
      light: grey[50],
      dark: '#BE1E2D',
      wm_bg: '#FFF3F2',
      wm_color: '#FF3B30',
    },
    archived: {
      light: grey[50],
      dark: '#58595B',
      wm_color: 'rgba(0, 0, 0, 0.5)',
      wm_bg: '#FFFFFF',
    },
    cancelled: {
      light: grey[50],
      dark: '#BE1E2D',
      wm_bg: '#E6E6E6',
      wm_color: '#797979',
    },
    inReview: {
      light: grey[50],
      dark: '#BE1E2D',
      wm_bg: '#F6D7D9',
      wm_color: '#ED5A66',
    },
    accepted: {
      light: grey[50],
      dark: '#009E2C',
    },
    awaitingPayment: {
      light: '#e63394',
    },
  },
  invoice: {
    lateVisit: {
      light: '#FFD4D4',
      dark: '#EF4570',
      wm_bg: '#EFE8FF',
      wm_color: '#8952FF',
    },
    unscheduled: {
      light: grey[50],
      dark: '#F37459',
      text: '#ED5A66',
      wm_bg: '#F6D7D9',
    },
    unassigned: {
      light: grey[50],
      dark: '#F9A34D',
      text: '#FF770B',
      wm_bg: '#FEEEE1',
    },
    onStandby: {
      light: grey[50],
      dark: '#FFD04F',
      wm_bg: '#F4E29D',
      wm_color: '#1A1A1A',
    },
    onTheWay: {
      light: grey[50],
      dark: '#D4C6D6',
      wm_bg: '#94BFFF',
      wm_color: '#1A1A1A',
    },
    arrived: {
      light: grey[50],
      dark: '#B087B5',
      wm_bg: '#E6A598',
      wm_color: '#1A1A1A',
    },
    started: {
      light: grey[50],
      dark: '#5CBAB4',
      wm_bg: '#E8FDF1',
      wm_color: '#3BB673',
    },
    completed: {
      light: grey[50],
      dark: '#77A9DB',
      wm_bg: '#EBF9F2',
      wm_color: '#30C17C',
    },
    abandoned: {
      light: grey[50],
      dark: '#BE1E2D',
      wm_bg: '#A7A7A7',
      wm_color: '#FF3B30',
    },
    archived: {
      light: grey[50],
      dark: '#58595B',
      wm_color: '#FFFFFF',
      wm_bg: '#A7A7A7',
    },
    accepted: {
      light: grey[50],
      dark: '#009E2C',
    },
    awaitingPayment: {
      wm_color: '#E76A43',
      wm_bg: '#FFE8D3',
    },
    awaitingApproval: {
      wm_color: '#205BCD',
      wm_bg: '#D3E5FF',
    },
    paid: {
      wm_color: '#ffffff',
      wm_bg: '#30C17C',
    },
    badDebt: {
      wm_color: '#ffffff',
      wm_bg: '#DF4D45',
    },
    converted: {
      wm_color: '#5C75C9',
      wm_bg: '#EBF0F5',
    },
    changesRequested: {
      wm_color: '#8952FF',
      wm_bg: '#EFE8FF',
    },
  },
  timesheet: {
    dark: '#5B6879',
    dateRow: '#6283B1',
    headerColor: '#D3C187',
    light: '#F2F2F5',
  },
  calendar: {
    green: '#8AC788',
    blue: '#043D8B',
    lateVisit: {
      color: grey[900],
      borderColor: '#8952FF',
      backgroundColor: '#EFE8FF',
    },
    unassigned: {
      color: grey[900],
      borderColor: '#FF770B',
      backgroundColor: '#FEEEE1',
    },
    onStandby: {
      color: grey[900],
      borderColor: '#F4E29D',
      backgroundColor: '#F3EED9',
    },
    onTheWay: {
      color: grey[900],
      borderColor: '#94BFFF',
      backgroundColor: '#D6E3F6',
    },
    arrived: {
      color: grey[900],
      borderColor: '#E6A598',
      backgroundColor: '#FBE6E2',
    },
    started: {
      color: grey[900],
      borderColor: '#5CBAB4',
      backgroundColor: '#C9E8E6',
    },
    completed: {
      color: grey[900],
      borderColor: '#3BB673',
      backgroundColor: '#CFEEE5',
    },
    cancelled: {
      color: grey[900],
      borderColor: gray[100],
      backgroundColor: gray[50],
    },
  },
};
