import { GET, POST, PUT, DELETE } from 'lib/api';

const initialState = {
  client: {},
};
// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    default:
      return state;
  }
}

export function fetchEnterpriseList(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/companies', accessToken, params).catch(
      (message) => ({
        message,
      })
    );
    if (result.message) return result;
    return result.data;
  };
}

export function getEnterpriseVisits(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/visits',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseServiceReport(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/service-reports',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseJobList(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/jobs',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function mediaActions(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/media-actions',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function serviceReportActions(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/service-report-actions',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getMedia(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/medias',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function downloadJobList(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST(
      '/enterprise_account/job_action',
      accessToken,
      data
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEnterpriseProperties(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      '/enterprise_account/properties',
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getMediaDetails(jobId, params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET(
      `/enterprise_account/medias/${jobId}`,
      accessToken,
      params
    ).catch((message) => ({
      e: message,
    }));
    if (result.e) return result;
    return result.data;
  };
}

export function getEntepriseProduct(params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await GET('/products', accessToken, params).catch(
      (message) => ({
        e: message,
      })
    );
    if (result.e) return result;
    return result.data;
  };
}

export function addEntepriseProduct(data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await POST('/products', accessToken, {
      data,
    }).catch((message) => ({
      e: message,
    }));

    if (result.e) return result;
    return result.data;
  };
}

export function updateEntepriseProduct(id, data) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await PUT(`products/${id}`, accessToken, {
      data,
    }).catch((message) => ({
      e: message,
    }));

    if (result.e) return result;
    return result.data;
  };
}

export function deleteEntepriseProduct(id, params) {
  return async (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const result = await DELETE(`products/${id}`, accessToken, { params }).catch(
      (message) => ({
        e: message,
      })
    );

    if (result.e) return result;
    return result.data;
  };
}
