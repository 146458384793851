import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer(props) {
  const { country, translate } = props;

  const getBusinessInfo = (countryCode) => {
    switch (countryCode.countryCode) {
      case 'MY':
        const MYbusiness = {
          name: translate('copyrightMY'),
          regNum: translate('regNumMY'),
        };
        return MYbusiness;
      case 'SG':
        const SGbusiness = {
          name: translate('copyrightSG'),
          regNum: translate('regNumSG'),
        };
        return SGbusiness;
      case 'TH':
        const THbusiness = {
          name: translate('copyrightMY'),
          regNum: translate('regNumMY'),
        };
        return THbusiness;
      default:
        const business = {
          name: translate('copyrightMY'),
          regNum: translate('regNumMY'),
        };
        return business;
    }
  };

  return (
    <>
      {/* <!--::footer_part start::--> */}
      <footer className="footer_part">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-12 col-md-12 col-lg-5 text-lg-left text-md-center text-sm-center">
              <div className="single_footer_part">
                <Link to="/" className="footer_logo_iner">
                  <img src="img/footer_logo.png" alt="WorkMagic" />
                </Link>
                <p className="tag_line">{translate('runBusinessLikeMagic')}</p>
                <p className="copyright">{getBusinessInfo(country).name}</p>
                <p className="copyright">{getBusinessInfo(country).regNum}</p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 pt-3 text-lg-left text-md-center text-sm-center">
              <div className="single_footer_part">
                <h4>{translate('aboutUs')}</h4>
                <ul className="list-unstyled">
                  <li>
                    <Link to="contact">{translate('contactUs')}</Link>
                  </li>
                  <li>
                    <a href="https://servishero.zendesk.com/hc/en-us/categories/360002523291-WorkMagic">
                      {translate('faq')}
                    </a>
                  </li>
                  <li>
                    <a href="term-of-service">
                      {translate('termsAndConditions')}
                    </a>
                  </li>
                  <li>
                    <a href="privacy-policy">{translate('privacyPolicy')}</a>
                  </li>
                </ul>
                {/* HIDE LANGUAGE SELECTION UNTIL FULLY TRANSLATED */}
                {/* <div className="change-language-btn">
                  <ChangeLanguageMenu selectedLanguage={i18n.language} />
                </div> */}
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 text-lg-center pt-3 justify-content-end text-md-center text-sm-center">
              <div className="single_footer_part d-flex flex-column justify-content-end">
                <div>
                  <Link to="sign-up" className="btn_2">
                    {translate('getStarted')}
                  </Link>
                </div>
                {/* HIDE SOCIAL MEDIA ICONS UNTIL SOCIAL MEDIA IS READY */}
                <div className="footer_icon social_icon text-lg-center text-md-center text-sm-center">
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/workmagicapp">
                        <img
                          src="img/icon/fb_icon.png"
                          className="social_icons"
                          alt=""
                        />
                      </a>
                    </li>
                    {/* <li><a href="https://www.instagram.com/workmagic"><img src="img/icon/ig_icon.png" className="social_icons" alt="" /></a></li>
                    <li><a href="https://lin.ee/fdelXsO"><img src="img/icon/line_icon.png" className="social_icons" alt="" /></a></li> */}
                  </ul>
                </div>
                <div className="google_play_banner">
                  <a href="https://play.google.com/store/apps/details?id=com.shgroup.workmagic&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                    <img
                      alt="Get it on Google Play"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      className="google_play_banner"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--::footer_part end::--> */}
    </>
  );
}
