import React, { useEffect } from "react";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { Field, submit } from "redux-form";
import { addStaff, getStaffs, clearStaffs } from "redux/staff";

// Material components
import { Button, Chip, Input, Typography, withStyles, Popper, Paper, List, ListItem } from "@material-ui/core";

// Shared components
import SelectTeamModal from "pages/Dashboard/components/SelectTeamModal";
import Portlet from "pages/Dashboard/components/Portlet";
import PortletHeader from "pages/Dashboard/components/PortletHeader";
import PortletLabel from "pages/Dashboard/components/PortletLabel";
import PortletContent from "pages/Dashboard/components/PortletContent";
import CreateStaffModal from "pages/Dashboard/components/CreateStaffModal";
// Component styles
import TeamCardStyle from "./styles";
import { withTeamStatusHook } from "pages/Dashboard/helpers/getTeamStatus";
import { TextInput } from "components/inputs";

const mapStateToProps = (state) => ({
  staffs: state.staff.staffs,
  staffDetails: (state.form.newStaff && state.form.newStaff.values) || {},
});

const mapDispatchToProps = (dispatch) => ({
  getStaffs: (params) => dispatch(getStaffs(params)),
  addStaff: (staffDetails) => dispatch(addStaff(staffDetails)),
  submitStaffForm: () => dispatch(submit("newStaff")),
  clearStaffs: () => dispatch(clearStaffs()),
});

class TeamCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openStaffList: false,
      openNewStaff: false,
      searchQuery: ""
    };
    this.handleAssignStaff = this.handleAssignStaff.bind(this);
    this.handleCreateNewStaff = this.handleCreateNewStaff.bind(this);
    this.handleCloseSelectTeamModal = this.handleCloseSelectTeamModal.bind(
      this
    );
    this.handleCreateStaff = this.handleCreateStaff.bind(this);
    this.handleCreateClose = this.handleCreateClose.bind(this);
    this.removeStaff = this.removeStaff.bind(this);
    this.handleClearTeam = this.handleClearTeam.bind(this);
    this.finishSelectionTeam = this.finishSelectionTeam.bind(this);
  }

  componentDidMount() {
    this.props.clearStaffs();
    this.props.getStaffs({ $scope: "JOB_MANAGEMENT|ASSIGN_JOB", $limit: -1 });
    document.addEventListener("click", this.handleDocumentClick);

  }

  componentWillUnmount() {
    // Remove the click event listener when the component unmounts
    document.removeEventListener("click", this.handleDocumentClick);
  }

  componentDidUpdate(prevProps) {
    // Check if the assignees prop has changed
    if (prevProps.assignees !== this.props.assignees) {
      // Reset the search input text when assignees change
      this.setState({ searchQuery: "" });
    }
  }

  handleAssignStaff() {
    this.setState({ openStaffList: !this.state.openStaffList });
  }

  async handleCreateNewStaff() {
    const formData = new FormData();
    formData.append("staffInfo", JSON.stringify(this.props.staffDetails));
    await this.props.addStaff(formData);

    await this.props.getStaffs({ $scope: "JOB_MANAGEMENT|ASSIGN_JOB" });
    this.setState({ openNewStaff: false });
    this.setState({ openStaffList: true });
  }

  handleCloseSelectTeamModal() {
    this.setState({ openStaffList: false });
  }

  handleCreateStaff() {
    this.setState({ openStaffList: false });
    this.setState({ openNewStaff: true });
  }

  handleCreateClose() {
    this.setState({ openNewStaff: false, openStaffList: true });
  }

  handleClearTeam() {
    this.props.clearTeam();
  }

  removeStaff(assignee) {
    const { assignees } = this.props;
    const newTeam = {};

    Object.keys(assignees).forEach((item) => {
      newTeam[item] = assignees[item];
    });
    delete newTeam[`${assignee}`];

    this.props.updateTeam(newTeam);
  }

  finishSelectionTeam() {
    this.setState({ openStaffList: false, });
    if (this.props.saveTeamAfterChosen) {
      this.props.handleConfirmTeam();
    }
  }

  handleSelectStaff(staffId) {
    // Get the selected staff member based on staffId
    const selectedStaff = this.props.staffs.find((staff) => staff.id === staffId);

    // Check if the staff member is not already in the assignees
    if (!this.props.assignees[staffId]) {
      // Add the selected staff member to the assignees
      const newAssignees = {
        ...this.props.assignees,
        [staffId]: selectedStaff,
      };

      // Update the assignees state
      this.props.updateTeam(newAssignees);

      // Reset the search input text
      this.setState({ searchQuery: "" });
    }
  }

  handleSearchInputChange(event) {
    const { value } = event.target;
    this.setState({ searchQuery: value });
  }

  // Handler for the document click event (outside click)
  handleDocumentClick = (event) => {
    if (
      this.searchResultsRef &&
      !this.searchResultsRef.contains(event.target)
    ) {

      this.setState({ searchQuery: "" });
    }
  };

  renderSearchResults() {
    const { staffs } = this.props;
    const { searchQuery } = this.state;

    // Filter staffs based on the search query
    const filteredStaffs = staffs.filter((staff) =>
      staff.fullName.includes(searchQuery)
    );

    if (searchQuery.length === 0) {
      return null; // Return nothing if there are no results or search results should not be shown
    }

    return (
      <Paper
        ref={(ref) => (this.searchResultsRef = ref)} // Reference to the search results container
        style={{
          position: "absolute",
          marginTop: "0px",
          width: "300px",
          maxWidth: "500px",
          maxHeight: "180px",
          overflowX: "auto",
          overflowY: "auto",
        }}
      >
        <List   >
          {filteredStaffs.length !== 0 ? filteredStaffs.map((staff, _index) => {
            const disableClicked = Object.keys(this.props.assignees).includes(staff.id)
            return (
              <ListItem key={staff.id}>
                <Button
                  defaultValue={staff.id}
                  fullWidth
                  size="small"
                  onClick={() => this.handleSelectStaff(staff.id)} // Add this onClick handler
                  disabled={disableClicked}
                >
                  {staff.fullName}
                </Button>
              </ListItem>
            )
          }) : <div style={{ textAlign: "center", padding: 10 }}>ไม่พบรายชื่อที่ค้นหา</div>}
        </List>
      </Paper>
    );
  }
  render() {
    const {
      assignees,
      classes,
      staffs,
      submitStaffForm,
      translate,
      handleOpenModalCategory,
      team,
    } = this.props;

    const { openStaffList, openNewStaff, searchQuery } = this.state;

    return (
      <Portlet className={classes.root}>
        <PortletHeader className={classes.cardHeader}>
          <PortletLabel title={translate("Job:team")} />
          <Field
            style={{ width: "50%" }}
            name="Team"
            label={"Team"}
            component={TextInput}
            variant="outlined"
            margin="dense"
            value={searchQuery}
            onChange={(event) => this.handleSearchInputChange(event)}
          />
          <Button
            className={classes.assignButton}
            size="small"
            onClick={this.handleAssignStaff}
          >
            {translate("Job:assign")}
          </Button>
        </PortletHeader>
        <PortletContent>
          <div style={{ position: "sticky", display: "flex", justifyContent: "center", }}>
            {this.renderSearchResults()}
          </div>
          {assignees && !!Object.keys(assignees).length ? (
            Object.keys(assignees).map((assignee) => {
              const selectedStaff =
                staffs.find((staff) => staff.id === assignee) ||
                team.find((staff) => staff.id === assignee);

              return assignees[assignee] && selectedStaff ? (
                <Chip
                  key={selectedStaff.id}
                  className={classes.teamChip}
                  label={
                    selectedStaff.fullName +
                    this.props.getStatus(
                      selectedStaff.deleted,
                      selectedStaff.archived
                    )
                  }
                  onDelete={() => {
                    this.removeStaff(selectedStaff.id);
                  }}
                />
              ) : null;
            })
          ) : (
            <Typography className={classes.noneAssignText}>
              {translate("Job:noUsersAssigned")}
            </Typography>
          )}
        </PortletContent>
        {openStaffList && (
          <SelectTeamModal
            staffs={staffs}
            open={openStaffList}
            handleCloseSelectTeamModal={this.handleCloseSelectTeamModal}
            finishTeamSelection={this.finishSelectionTeam}
            handleCreateUser={this.handleCreateStaff}
            handleClearTeam={this.handleClearTeam}
            translate={translate}
          />
        )}
        {openNewStaff && (
          <CreateStaffModal
            open={openNewStaff}
            onSubmit={this.handleCreateNewStaff}
            handleClick={submitStaffForm}
            handleClose={this.handleCreateClose}
            translate={translate}
            handleOpenModalCategory={handleOpenModalCategory}
            currentCategoriesStaff={this.props.currentCategoriesStaff}
          />
        )}
      </Portlet>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(TeamCardStyle),
  withTeamStatusHook
)(TeamCard);
