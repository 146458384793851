import React, { useState } from 'react';
import '../../index.scss';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

import {
  Grid,
  Typography,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { EMAIL_VALIDATION } from 'common/constant';

import checkSrc from 'assets/images/check.svg';
import addSrc from 'assets/images/round-add.svg';
import minusSrc from 'assets/images/round-minus.svg';

const CustomField = ({ name, label, numberType }) => (
  <Field
    name={name}
    component={TextInput}
    label={label}
    inputClass="customize_input"
    variant="outlined"
    type={numberType ? 'number' : 'text'}
    required
  />
);

const defaultCard = [
  'technicians',
  'handyman',
  'cleaners',
  'contractors',
  'electrician/plumber',
];

const SelectableCard = ({ input, t }) => {
  const handleClick = (itemName) => {
    const selectedValue = input.value;

    if (
      !selectedValue ||
      !Array.isArray(selectedValue) ||
      !selectedValue.length
    )
      return input.onChange([itemName]);

    const valueIndex = selectedValue.indexOf(itemName);
    if (valueIndex === -1) return input.onChange([...selectedValue, itemName]);
    const newSelectedValue = selectedValue.filter((v) => v !== itemName);
    return input.onChange(newSelectedValue);
  };

  return (
    <>
      {defaultCard.map((itemName, key) => {
        const isSelected = (input && input.value.includes(itemName)) || false;
        return (
          <Grid key={key} className="hover_grid">
            <Grid
              item
              onClick={() => handleClick(itemName)}
              className={`${
                isSelected ? 'card_selected' : 'card_normal'
              } selectable_card d-flex flex-row align-items-center mr_8 mt_10`}
            >
              <Typography>{t(itemName)}</Typography>
              {isSelected && (
                <img className="ml_10" src={checkSrc} alt="check_src" />
              )}
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const AdditionalLabour = (props) => {
  const { t } = props;
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
    props.input && props.input.onChange('');
  };
  return (
    <>
      <Button className="mb_10 pl_0" onClick={handleClick}>
        <img className="mr_10" src={isOpen ? minusSrc : addSrc} alt="icon" />
        <Typography>{t('typeOfLabour')}</Typography>
      </Button>
      {isOpen && (
        <TextInput
          {...props}
          label={t('whatOther')}
          inputClass="customize_input"
          variant="outlined"
        />
      )}
    </>
  );
};

const DropDownComponent = (props) => {
  const { t, input } = props;

  const handleChange = (e) => {
    input.onChange(e.target.value);
  };

  return (
    <FormControl variant="outlined" className="selectCountry">
      <InputLabel>{t('selectCountry')}</InputLabel>
      <Select
        classes={{ root: 'left_align' }}
        value={input && input.value}
        onChange={handleChange}
        label={t('selectCountry')}
      >
        <MenuItem value="MY">Malaysia</MenuItem>
        <MenuItem value="SG">Singapore</MenuItem>
        <MenuItem value="TH">Thailand</MenuItem>
      </Select>
    </FormControl>
  );
};

// eslint-disable-next-line import/no-mutable-exports
let FormSupport = ({ t, handleSubmit, submitting, pristine, invalid }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Grid className="row">
        <Grid className="col-12 col-md-6 p-0 pr-md-3 text_left">
          <Typography>{t('typeNeeded')}</Typography>
          <Grid container className="">
            <Field t={t} name="labours" component={SelectableCard} />
            <Grid container className="mt_20">
              <Field
                t={t}
                name="additionalLabours"
                component={AdditionalLabour}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className="col-12 col-md-6 p-0 mt-3 mt-md-0">
          <CustomField name="contactName" label={t('contactName')} />
          <Grid className="mt_10">
            <CustomField name="businessName" label={t('businessName')} />
          </Grid>
          <Grid className="mt_10">
            <CustomField name="email" label={t('email')} />
          </Grid>
          <Grid className="mt_10">
            <CustomField
              name="phoneNumber"
              label={t('phoneNumber')}
              numberType
            />
          </Grid>
          <Grid className="mt_10">
            <Field
              t={t}
              name="country"
              component={DropDownComponent}
              required
            />
          </Grid>
          <Grid className="mt_10">
            <CustomField name="city" label={t('city')} />
          </Grid>
          <Grid className="mt_20">
            <Button
              fullWidth
              disabled={submitting || pristine || invalid}
              variant="contained"
              className={
                submitting || pristine || invalid
                  ? 'btn_submit_disabled submit_interest_cta'
                  : 'btn_submit submit_interest_cta'
              }
              type="submit"
            >
              {t('submitRequest')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

const validate = (values) => {
  const errors = {};
  ['contactName', 'businessName', 'email', 'phoneNumber', 'city', 'country'].forEach(
    (item) => {
      if (!values[item]) errors[item] = 'required';
    }
  );

  if (
    values.email &&
    !EMAIL_VALIDATION.test(values.email)
  ) {
    errors.email = 'invalidEmail';
  }

  if (values.labours && !values.labours.length && !values.additionalLabours) {
    errors.labours = 'required';
  }

  return errors;
};

const mapStateToProps = () => ({
  initialValues: {
    contactName: '',
    businessName: '',
    email: '',
    phoneNumber: '',
    city: '',
    labours: [],
    additionalLabours: '',
    country: ''
  },
});

FormSupport = reduxForm({
  form: 'supportForm',
  validate,
})(FormSupport);
export default connect(mapStateToProps, null)(FormSupport);
