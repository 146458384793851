export default {
  // common && crumbs
  addJob: '+ New Job',
  jobs: 'Jobs',
  jobDetails: 'Job Details',
  editJob: 'Edit Job',
  newJob: 'New Job',
  startJob: 'Start Job',
  endJob: 'End Job',
  jobId: 'Job ID',
  jobIdAndOrderId: 'Job ID/Order ID',
  newReport: 'Job Details / New Report',
  reportDetails: 'Job Details / Report Details',
  editReportDetails: 'Job Details / Edit Report Details',

  job: 'Job',
  // jobs table
  status: 'Status',
  createdAt: 'Created at',
  createdAtAndBy: 'Created at/by',
  id: 'ID',
  title: 'Title',
  client: 'Client',
  address: 'Address',
  schedule: 'Schedule',
  price: 'Price',
  actions: 'Actions',
  search: 'Search',
  showArchived: 'Show Archived',
  rows: 'rows',
  of: 'of',
  viewJob: 'View Job',
  createdBy: 'Created By',

  // DETAILS
  contactDetails: 'Contact details',
  startAt: 'Start at',
  endAt: 'End at',
  generateInvoice: 'Generate Invoice',

  // line items
  lineItems: 'Line Items',
  productService: 'Product/Service',
  description: 'Description',
  quantity: 'Quantity',
  unitPrice: 'Unit Price',
  total: 'Total:',
  subtotal: 'Subtotal',

  // visits
  visits: 'Visits',
  newVisit: 'New Visit',
  number: 'Number',
  assignedTo: 'Assigned to',
  scheduleVisit: 'Schedule A Visit',
  'job#': 'Job #',
  visitTitle: 'Visit Title',
  incorrectPrevTime:
    'Cannot change status because the previous phase is not completed.',

  // billing
  billing: 'Billing',
  date: 'Date',
  subject: 'Subject',
  totals: 'Totals',

  // notes
  noteDetails: 'Note Details',
  comingSoon: 'Coming soon...',

  // assigned team
  assignedTeam: 'Assigned Team',

  // items
  items: 'Items',

  // Media/File
  mediaFile: 'Media/File',
  photo: 'Photo',
  video: 'Video',
  files: 'Files',
  visitOn: 'Visit on',
  filterByTag: 'Filter by tag',
  filterByType: 'Filter by type',
  inputTag: 'Input tag',
  evaluation: 'Evaluation',
  afterJob: 'After job',
  others: 'Others',

  // JOB FORM
  oneOffJob: 'One-Off Job',
  oneOffJobDescription: 'A one time job with one or more visits',
  recurringJob: 'Recurring Job (Coming Soon)',
  recurringJobDescription: 'A contract job with recurring visits',
  jobFor: 'Job for',
  jobForClient: '<0><0></0></0>',
  clientName: ' Client Name',
  propertyAddress: 'Property Address',
  email: 'Email',
  contactNo: 'Contact no',
  change: 'Change',
  jobType: 'Job Type',
  startsOn: 'Starts on',
  endsOn: 'Ends on',
  billingFrequency: 'Billing Frequency',

  // client modal
  selectCreateClient: 'Select or create a client',
  whichClient: 'Which client would you create this for?',
  createClient: 'Create new client',
  properties: 'Properties',
  noPropertyRecorded: 'No property recorded.',
  createNewClient: 'Create a new client',

  // property modal
  selectCreateProperty: 'Select or create a property',
  whichProperty: 'Which property would you like to use for this ?',
  property: 'Property',
  createProperty: 'Create Property',
  updateProperty: 'Update Property',
  createPropertyFor: 'Create new property for',
  updatePropertyFor: 'Update property for',
  createNewProperty: 'Create a new property',
  coordsRequired:
    'Property coordinates are required for GPS Tracking. Please enter the lat(latitude) and lng(longitude) for the property or search for the property again by re-entering the property address.',

  // schedule card
  time: 'Time',
  startDate: 'Start Date',
  endDate: 'End Date',
  startTime: 'Start Time',
  endTime: 'End Time',
  optional: 'Optional',

  // team card
  team: 'Team',
  assign: '+ Assign',
  noUsersAssigned: 'No users are currently assigned',
  selectTeam: 'Select team member(s)',
  noStaffAddUser:
    'There is no staff available. Please add new user to assign team.',
  createUser: '+ Create User',
  done: 'Done',
  addUser: 'Add new user',
  clearTeam: 'Clear Team',

  // line item card
  serviceProduct: 'Service / Product',
  qty: 'Qty.',
  addLineItem: '+ Add Line Item',
  item: 'Item',
  internalNotes: 'Internal notes',

  // unassigned job
  unAssignedJobs: 'UnAssigned Jobs',
  noUnassignedJobs: 'There is no unassigned job',
  deleteVisit: 'Are you sure to delete this visit?',
  completed: 'Completed',

  // archiveJobModal
  archiveJob: 'ARCHIVE JOB',
  archiveAndCloseJob: 'Archive and Close Job <0></0>',
  archiveJobAndVisit:
    'By closing this job, any incomplete visits will be cancelled',

  // media
  confirmDelete: 'DELETE MEDIA',
  confirmDeleteContent:
    'Deleting Media #<0><0></0></0> will permanently remove it from WorkMagic',
  uploadSuccess:
    'Your file(s) are processing, files will be shown after process completed',
  viewFile: 'View file',
  tag: 'Tag',
  submittedOn: 'submitted on',
  dropHere: 'Drop files here or use the “Upload” button',

  // serviceReport
  serviceReport: 'Service Report',
  btnTitle: 'Download template',
  btnDescription: 'for printing and general use',
  filters: 'Filters',
  type: 'Type',
  jobReport: 'Job Report',
  visitReport: 'Visit Report',
  reportNo: 'Report No.',
  issuedAt: 'Issued at',
  lastUpdated: 'Last updated',
  selectJob: 'Select Job’s Visits to create service report',
  generateFrom: 'Generate Service Report from...',
  visit: 'Visit',
  viewMode: 'View mode',
  editMode: 'Edit mode',
  view: 'View',
  edit: 'Edit',
  saveAsDraft: 'Save as Draft',
  saveAndSend: 'Save and Send',
  full: 'Full',
  pictorial: 'Pictorial',
  fullDescription: 'Report with full information',
  pictorialDescription: 'Mainly focus on pictures of before & after Job',
  companyPhone: 'Phone:  ({{countryPhoneCode}}) {{phoneNumber}}',
  companyEmail: 'Email:  {{email}}',
  reportNumber: 'Report No. <0><0></0></0>',
  reportDate: 'Date: <0><0></0></0>',
  customer: 'Customer',
  phoneNumber: 'Phone number',
  appointmentDate: 'Appointment date',
  requestDate: 'Request date',
  jobDescription: 'Job Description',
  actionTaken: 'Action taken / Remark',
  deviceInfo: 'Device Info',
  modelNumber: 'Model Number',
  serialNumber: 'Serial Number',
  serviceType: 'Service Type',
  jobImage: 'Job’s images',
  browseFromGallery: 'Browse from gallery',
  jobMedia: 'Job’s Media',
  jobMediaDescription: 'You can select multiple items.',
  itemSelected: '{{number}} items selected',
  select: 'Select',
  addCaption: 'Add Caption',
  serviceProvide: 'Service Provided',
  itemNo: 'Item No.',
  unitPriceServiceReport: 'Unit Price',
  certificate:
    'Certified that the above work has been satisfactorily carried out',
  timeArrival: 'Time of arrival',
  serviceBy: 'Serviced by',
  timeOfCompletion: 'Time of completion',
  scheduleDate: 'Schedule Date',
  certified:
    'Certified that the above work has been satisfactorily carried out',
  servicedBy: 'Service by',
  clientSignature: 'Client’s Signature',
  signatureDescription: '(if signed by representative)',
  dateFormat: 'MMM DD, YYYY',
  dateTimeFormat: 'MMM DD, YYYY - hh:mm A',
  timeFormat: 'hh:mm A',
  reportType: 'Report type: {{type}}',
  generalInfo: 'Job’s General Info',
  jobImages: 'Job’s images',
  sendReport: 'Send Service Report via...',
  reportMethod: 'Select how you want to send Service Report',
  deleteReport: 'Delete report?',
  deleteDescription:
    'Are you sure you want to delete this? It can not be undone.',
  addMore: 'Add more recipient',
  createSuccessfully: 'Service Report saved as draft',
  editSuccessfully: 'Service Report edited successfully',
  deleteSuccessfully: 'Service Report deleted successfully',
  sendSuccess: 'Service Report sent successfully',
  serviceTypeSiteRepair: 'Site Service/Repair/Calibration',
  serviceTypeInHouseRepair: 'In house services/Repair/Calibration',
  serviceTypeFollowUp: 'Follow up',
  serviceTypeMaintenance: 'Maintenance Contract',
  serviceTypeDiagnostic: 'Diagnostic Services',
  serviceTypeInstallation: 'Project Installation',
  downloadSuccessfully: 'Download Service Report Successfully',
  emailInputPlaceHolder: 'Press "Enter" to submit',
  JOB_FULL: 'Job Report - Full',
  JOB_PICTORIAL: 'Job Report - Pictorial',
  VISIT_FULL: 'Visit Report - Full',
  VISIT_PICTORIAL: 'Visit Report - Pictorial',
  totalPrice: 'Total Price',
  additionalSetting: 'Additional Settings',
  skipOnTheWayTracking: 'Skip \'On The Way\' tracking',
  skipArrivedTracking: 'Skip \'Arrived\' tracking',
  jobCategory: 'Category tags',
  category: 'Category Tags',
  addCategory: '+ Category',
  selectClient: 'Select client',
  id_category: 'Job ID/Order ID/Category',
  weAreGeneratingReport:
    'We are generating your report, this might take more than a minute.',

  viewAll: 'View all ({{total}})',
  dateCreated: 'Date created',
  location: 'Location',
  visitId: 'Visit ID',
  copy: 'Copy',
  visitDetails: 'Visit details',
  complete: 'Complete',
  cancel: 'Cancel',
  delete: 'Delete',
  editVisit: 'Edit visit',
  startOn: 'Start On',
  endOn: 'End On',
  discard: 'Discard',
  saveChanges: 'Save changes',
  selectTeamMember: 'Select team member(s)',
  clearSelection: 'Clear selections',
  progressTracker: 'PROGRESS TRACKER',
  clientDetails: 'Client Details',
  clientDetailsUpper: 'CLIENT DETAILS',
  allAddress: 'All address',
  details: 'Details',
  cancelled: 'Cancelled',
  markAs: 'Mark as...',
  createNewVisit: 'Create New Visit',
  generateSRFrom: 'Generate service report from...',
  jobTitle: 'Job title',
  confirmComplete: 'Are you sure you want to complete this visit?',
  completeVisit: 'Complete this visit?',
  cancelVisit: 'Cancel this visit?',
  confirmCancel: 'Are you sure you want to cancel this visit?',
  name: 'Name',
  phone: 'Phone',
  teamManagements: 'Team managements',
  visitManagement: 'Visit managements',
  fullClientDetails: 'Full client details',
  contactInfo: 'Contact Info',
  modalGeneralInfo: 'General Info',
  fullDetails: 'Full Details',
  jobContainVisit: 'JOB DETAILS RELATED TO THIS VISIT',
  currentVisitToolTip: 'Current Status',
  to: 'to',
  start: 'Start',
  remove: 'Remove',
  viewDetails: 'View details',
  changeProgressTimestamp: 'Change progress’s timestamp',
  addService: 'Add item',
  addAnotherService: 'Add another service',
  service: 'Service',
  itemName: 'Item Name',
  model: 'Model.',
  serialNo: 'Serial No.',
  viewAs: 'View as',
  serviceCard: 'Service cards',
  serviceOnly: 'Service only',
  itemOnly: 'Items only',
  serviceReportNumber: 'Service Report ({{numberCreatedSr}})',
  serviceReportFilter: 'Filters for jobs\' service report availability/presence',
  available: 'Available',
  none: 'None',
  hasLateVisit: 'Has late visit',
  hasNoVisit: 'No late visit',
  lateVisit: 'Late Visit',
  serviceReportAvailable: 'Service Report(s) available.',
  jobHasLate: 'This job has late visit(s)',
  scheduleLater:
    'Schedule later, any current schedule will be removed and the visit will be marked as Unscheduled.',
  orderId: 'Order ID',
  deliveryNote: 'DN Number (Delivery Note)',
  hubInfo: 'Hub Info',
  viewJobDetails: 'View job details',
  viewClientDetails: 'View client details',
  archivedThisJob: 'Archive this job?',
  unarchivedThisJob: 'Unarchive this job?',
  archivedThisJobDescription: 'Are you sure you want to archive this Job?',
  unarchivedThisJobDescription: 'Are you sure you want to unarchive this Job?',
  unarchive: 'Unarchive',
  archive: 'Archive',
  deleteThisJob: 'Delete this Job?',
  deleteThisJobDescription:
    'Are you sure you want to delete this Job? You can not undo this action.',
  deleteJob: 'Delete this Job',
  deleteJobSuccessfully: 'Delete job successfully',
  itemPicked: 'Job’s items had been collected',
  markItemsCollected: 'Mark job’s items Collected',
  markItemsUncollected: 'Mark job’s items Uncollected',
  markItemCollectedTitle: 'Mark items as Collected?',
  markItemUncollectedTitle: 'Mark items as Uncollected?',
  collectedItems: 'Collected Items?',
  notCollected: 'Not collected',
  collected: 'Collected',
  collectedStatus: 'Collection Status',
  fileSelected: '{{numberFiles}} files selected',
  assignToVisit: 'Assign to Visit',
  confirmVisitTitle: 'Confirm your visit updates?',
  currentVisit: 'Current visit',
  categoryTags: 'CATEGORY TAGS',
  deleted: ' (deleted)',
  deactivated: ' (deactivated)',

  // job verification
  endJobReview: 'End Job Review',
  openJob: 'Open Job',
  startJobReview: 'Start Job Review',
  confirmCompleteClose: 'Confirm Completion and Close',
  confirmCancelClose: 'Confirm Cancellation and Close',
  cancelClose: 'Cancel and Close Job',
  completeClose: 'Complete and Close Job',
  confirm: 'Confirm',
  completeCloseJob: 'Complete and close this visit & job',
  cancelCloseJob: 'Cancel and close this visit & job',
  close: 'Close',
  openCreateVisit: 'Open Job & Create New Visit',
  createVisitOnly: 'Create Visit Only',
  openJobOnly: 'Open Job Only',
  deleteAllVisit: 'Delete All Visits',
  completeCurrentVisit: 'Complete Current Visit',
  cancelCurrentVisit: 'Cancel Current Visit',
};
