/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useCallback } from 'react';

// Redux
import { useDispatch } from 'react-redux';
import { getMediaDetails } from 'redux/enterprise';
import { showErrorMessage } from 'lib/notifier';
// Material components
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';

// Material icons
import { Close as CloseIcon } from '@material-ui/icons';
import folderIcon from 'assets/images/folder.svg';
import tagsIcon from 'assets/images/tags.svg';
// Component styles
import styles from './styles.module.scss';
import { LoadingModal } from 'pages/Dashboard/components';
import { moment } from 'lib/app-moment';
import { MEDIA_TYPES, MEDIA_PHASES } from 'common/constant';

import mediaSrc from 'assets/icons/file.svg';
import viewFileSrc from 'assets/icons/viewFile.svg';

const Tag = ({ children }) => {
  return (
    <Grid className={styles.div_tag}>
      <span className={styles.tag}>{children}</span>
    </Grid>
  );
};

const MediaDetails = (props) => {
  const dispatch = useDispatch();
  const {
    open,
    changeModalState,
    currentMediaSelected,
    translate,
    jobPublicId,
    mediaList
  } = props;
  const [mediaDetails, setMediaDetails] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(0)

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await dispatch(
        getMediaDetails(currentMediaSelected.id, {
          params: {
            jobId: jobPublicId,
          },
        })
      ).catch((e) => ({
        e,
      }));
      if (response.e) return showErrorMessage(response.e);
      setMediaDetails(response);
      setIsLoading(false);
    })();
  }, [currentMediaSelected.id]);
  useEffect(() => {
    FindIndex();
  }, [mediaDetails, currentMediaSelected,])

  const FindIndex = useCallback(async () => {
    const mediaIndex = mediaList.findIndex((v, _) => v.fileName === mediaDetails.fileName)
    setCurrentMediaIndex(mediaIndex)
  }, [mediaDetails])

  const goToPreviousMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === 0 ? mediaList.length - 1 : prevIndex - 1
    );
  };

  // Function to navigate to the next media
  const goToNextMedia = () => {
    setCurrentMediaIndex((prevIndex) =>
      prevIndex === mediaList.length - 1 ? 0 : prevIndex + 1
    );
  };

  // const handleViewFile = () =>
  //   window.open(mediaDetails.mediaUrl, '_blank', 'noopener,noreferrer');

  if (isLoading) return <LoadingModal open={isLoading} />;
  const renderCurrentMedia = () => {
    const currentMediaDetails = mediaList[currentMediaIndex];

    if (!currentMediaDetails) {
      return <p>Loading...</p>
    }


    if (currentMediaDetails.mediaType === MEDIA_TYPES.PHOTO.toString()) {
      return (
        <img
          className={styles.img_notes}
          alt="note_image"
          src={currentMediaDetails.mediaUrl}
        />
      );
    } else if (currentMediaDetails.mediaType === MEDIA_TYPES.VIDEO.toString()) {
      return (
        <video
          className={styles.video}
          src={currentMediaDetails.mediaUrl}
          autoPlay
          loop
          controls
        />
      );
    } else {
      return (
        <Grid
          className={styles.fileStyles}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <img alt="media_file" src={require('assets/icons/file.svg')} />
          </Grid>
          <Grid item className={styles.paddingVertical}>
            <span>{currentMediaDetails.fileName}</span>
          </Grid>
          <Button
            onClick={() =>
              window.open(
                currentMediaDetails.mediaUrl,
                '_blank',
                'noopener,noreferrer'
              )}
            className={styles.btnViewFile}
          >
            <img alt="media_file" src={require('assets/icons/viewFile.svg')} />
            <span>{translate('viewFile')}</span>
          </Button>
        </Grid>
      );
    }
  };
  // let leftComponent = null;
  // if (mediaDetails && mediaDetails.mediaType) {
  //   if (mediaDetails.mediaType === MEDIA_TYPES.PHOTO.toString()) {
  //     leftComponent = (
  //       <img
  //         className={styles.img_notes}
  //         alt="note_image"
  //         src={mediaDetails.mediaUrl}
  //       />
  //     );
  //   } else if (mediaDetails.mediaType === MEDIA_TYPES.VIDEO.toString()) {
  //     leftComponent = (
  //       <video
  //         className={styles.video}
  //         src={mediaDetails.mediaUrl}
  //         autoPlay
  //         loop
  //         controls
  //       />
  //     );
  //   } else {
  //     leftComponent = (
  //       <Grid
  //         className={styles.fileStyles}
  //         container
  //         direction="column"
  //         justify="center"
  //         alignItems="center"
  //       >
  //         <Grid item>
  //           <img alt="media_file" src={mediaSrc} />
  //         </Grid>
  //         <Grid item className={styles.paddingVertical}>
  //           <span>{mediaDetails.fileName}</span>
  //         </Grid>
  //         <Button onClick={handleViewFile} className={styles.btnViewFile}>
  //           <img alt="media_file" src={viewFileSrc} />
  //           <span>{translate('Job:viewFile')}</span>
  //         </Button>
  //       </Grid>
  //     );
  //   }
  // }
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      classes={{ paper: styles.modalPaper }}
      onClose={() => changeModalState(false)}
    >
      <DialogContent className={styles.modalContent}>
        <Grid className={styles.container} container>
          <Grid
            xs={7}
            item
            container
            justify="center"
            alignItems="center"
            className={styles.fileContainer}
          >
            <Tooltip title="Previous" placement="left">
              <IconButton
                style={{ position: 'absolute', left: 0, }}
                className={styles.leftArrowButton}
                onClick={goToPreviousMedia}
              >
                <NavigateBefore />
              </IconButton>
            </Tooltip>
            {renderCurrentMedia()}
            <Tooltip title="Next" placement="right">
              <IconButton
                style={{ position: 'absolute', left: "55%", }}
                className={styles.rightArrowButton}
                onClick={goToNextMedia}
              >
                <NavigateNext />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid
            xs={5}
            item
            className={styles.container}
            container
            direction="column"
          >
            <Grid container item justify="flex-end" className={styles.header}>
              <Grid className={styles.closeIcon}>
                <IconButton
                  className={styles.iconBtn}
                  onClick={() => changeModalState(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item className={styles.content}>
              <Grid item className={styles.title}>
                {mediaDetails && mediaDetails.issuedBy && (
                  <Grid>
                    <Typography variant="h4">
                      {mediaDetails.issuedBy.fullName}
                    </Typography>
                  </Grid>
                )}
                <Grid>
                  <Typography className={styles.dateTime}>
                    {translate('Job:submittedOn')}{' '}
                    {moment(mediaDetails.createdAt).format(
                      'MMM DD, YYYY - hh:mm'
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item className={styles.evalSpacing}>
                <img src={folderIcon} alt="folder_icon" />
                <Typography variant="h5" className={styles.evaluation}>
                  {MEDIA_PHASES[mediaDetails.mediaPhase]}
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify="space-between"
                  className={styles.mb_15}
                >
                  <Grid item className={styles.tagContainer}>
                    <img src={tagsIcon} alt="tag_icon" />
                    <Typography variant="h5" className={styles.evaluation}>
                      {translate('Job:tag')}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  {mediaDetails &&
                    mediaDetails.tags &&
                    mediaDetails.tags.map((tag, index) => (
                      <Tag key={index}>{tag && tag.text}</Tag>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MediaDetails;
