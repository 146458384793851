export default {
  planBilling: 'Plan & Billing',
  accountOverview: 'Account Overview',
  activeSince: 'Active Since',
  accountStatus: 'Account Status',
  active: 'Active',
  inactive: 'Inactive',
  currentPlan: 'Current Plan',
  trial: 'Trial',
  pro: 'PRO',
  trialWillEnd: 'Your trial for {{plan}} plan will end in {{time}} days.',
  trialWasEnd:
    'Your trial for {{plan}} plan was end. Please complete the subscription to continue using Workmagic',
  nextBillingDate: 'Next billing date',
  changePlan: 'Change Plan',
  deactivate: 'Deactivate',
  activate: 'Activate',
  deactivateAccount: 'Deactivate Account',
  deactivateTrial:
    'Your trial will automatically expire so there’s no need to deactivate',
  userNum: 'users',
  onYourPlan: 'on your plan',

  // subscription plan
  manageSubscription: 'Manage Subscription',
  selectPlan: 'Select Subscription Plan',
  plan: 'Plan',
  manageAddOns: 'Manage Your Add-ons',
  billingCycle: 'Billing Cycle',
  addOns: 'Add-ons',
  additionalUsers: 'Additional Users',
  teamManagement: 'Team Management',
  timesheet: 'Timesheet',
  teamGPSTracking: 'Team GPS Tracking',
  marketing: 'Marketing',
  users: 'Users',
  total: 'Total',
  taxInclusive: 'Tax inclusive',
  proceedToCheckout: 'Proceed to checkout',
  addOnPricePerMonth: '{{currency}} {{price}} per user/month',
  planIncluded: 'Plan included',
  BASIC: 'WorkMagic Basic',
  PLUS: 'WorkMagic Plus',
  PRO: 'WorkMagic Pro',
  planFeature:
    'Feature is charged per user for all users in plan that does not have the feature',
  submitting: 'Submitting..',
  planIncludes: 'Plan includes',
  updateSubscription: 'Update subscription',
  pleaseInputCard: 'Please input your card',
  subscriptionPlanSaved: 'Subscription plan saved',
  paymentMethodSaved: 'Payment method saved',
  trialExpiredMessage:
    'Your trial has expired, subscribe to a plan to continue using WorkMagic',
  checkoutAgreementMessage:
    'I agree to the <0>Subscription Agreement</0> and <1>Privacy Policy</1> and' +
    ' understand that my subscription will start from <2></2>',
  subscriptionAgreement: 'Subscription Agreement',
  privacyPolicy: 'Privacy Policy',
  needMoreTime: 'Need more time? or have questions',
  contactCustomer: 'Contact customer support',
  FAQ: 'FAQ',
  learnMore: 'Learn More',
  billingDetails: 'Billing details',
  cardNumber: 'Card Number',
  expiresOn: 'Expires On',
  edit: 'Edit',
  addCard: 'Add Card',
  subtotal: 'Subtotal',
  creditCardRate: 'Credit Card Rate',
  logout: 'Logout',
  month: 'month',
  year: 'year',
  today: 'today',
  summary: 'Summary',
  monthly: 'Monthly',
  annual: 'Annual',
  now: 'now',
  yourCompanyWasDeactivated:
    'Your company account was deactivated. Please contact your admin or account owner to activate it',
};
