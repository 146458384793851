import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { mediaActions } from "redux/enterprise";

import VisitOnFilter from "../visitOn";
import FilterByTag from "../tag";
import FilterByType from "../type";

import PhraseBtn from "./phraseBtn";
import DownloadMenu from "./downloadMenu";
import RenderMedia from "./renderMedia";
import styles from "./media.module.scss";
import { showErrorMessage } from "lib/notifier";

import selectedSrc from "assets/images/icons/selected-icon.svg";

const phrases = [
  {
    label: "evaluation",
    key: 0,
  },
  {
    label: "afterJob",
    key: 1,
  },
  {
    label: "others",
    key: 2,
  },
];

const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
const DOWNLOAD_ACTION = "DOWNLOAD";

const Media = (props) => {
  const { t, jobPublicId, setIsOpenDownloadModal } = props;
  const dispatch = useDispatch();

  const [selectedPhrase, setSelectedPhrase] = useState(0);
  const [isSelectFile, setIsSelectFile] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([0, 1, 2]);
  const [visitOn, setVisitOn] = useState([]);
  const [selectedVisitIds, setSelectedVisitIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isSelectAllMedia, setIsSelectAllMedia] = useState(false);

  const handleClickBtn = (key) => setSelectedPhrase(key);

  const handleCancelSelectFile = () => {
    setIsSelectFile(false);
    setSelectedFiles([]);
  };

  const handleDownload = async () => {
    setIsOpenDownloadModal(true);
    const data = {
      jobId: jobPublicId,
      mediaIds: selectedFiles,
      action: DOWNLOAD_ACTION,
    };
    setIsLoading(true);
    const result = await dispatch(mediaActions({ data })).catch((e) => ({
      e,
    }));
    setIsLoading(false);
    if (result.e) return showErrorMessage(result.e);
    if (result.url) window.open(result.url, "_blank");
    handleCancelSelectFile();
  };
  const handleEnableSelectFile = () => setIsSelectFile(true);

  useEffect(() => {
    (async () => {
      const data = {
        jobId: jobPublicId,
        action: GET_FILTER_OPTIONS,
      };
      const result = await dispatch(mediaActions({ data })).catch((e) => ({
        e,
      }));
      if (result.e) return showErrorMessage(result.e);
      const { tags: resultTags, visitOn: resultVisitOn } = result;
      const respondTags = (Array.isArray(resultTags) && resultTags) || [];

      setVisitOn(resultVisitOn);
      setSelectedVisitIds(resultVisitOn.map((visit) => visit.visitId));
      setTags(respondTags);
    })();
  }, [dispatch, jobPublicId]);

  const handleSelectFile = (media) => {
    if (!isSelectFile) return false;

    if (!selectedFiles.includes(media.id))
      return setSelectedFiles([...selectedFiles, media.id]);

    const deepCloneFiles = [...selectedFiles];
    deepCloneFiles.splice(deepCloneFiles.indexOf(media.id), 1);
    setSelectedFiles(deepCloneFiles);
  };

  const handleSelectAll = () => setIsSelectAllMedia(true);
  const handleDeselect = () => setSelectedFiles([]);

  return (
    <Grid container>
      <Grid
        item
        sm={3}
        md={2}
        className={`${styles.filter_border} ${
          isSelectFile && styles.selected_file_bg
        }`}
      >
        {isSelectFile ? (
          <Box
            height="100%"
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Box width="25%" mb={1}>
              <img src={selectedSrc} alt="selectedSrc" />
            </Box>
            <Typography className="font_bold mb_8">
              {t("numberSelected", { number: selectedFiles.length })}
            </Typography>
            <Typography variant="subtitle1">
              {t("selectedFileFromGallery")}
            </Typography>
            <Box width="50%" mt={2}>
              <Button
                variant="contained"
                disabled={!selectedFiles.length}
                onClick={handleDownload}
                className={selectedFiles.length ? styles.download_btn : ""}
              >
                {t("download")}
              </Button>
            </Box>
            <Box width="50%" mt={1}>
              <Button onClick={handleCancelSelectFile} variant="outlined">
                {t("Common:cancel")}
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Box p={2} className={styles.border_bottom}>
              <VisitOnFilter
                {...props}
                visitOn={visitOn}
                selectedVisitIds={selectedVisitIds}
                setSelectedVisitIds={setSelectedVisitIds}
              />
            </Box>
            <Box p={2} className={styles.border_bottom}>
              <FilterByTag
                {...props}
                tags={tags}
                setSelectedTags={setSelectedTags}
                selectedTags={selectedTags}
              />
            </Box>
            <Box p={2}>
              <FilterByType
                {...props}
                mediaTypes={mediaTypes}
                setMediaTypes={setMediaTypes}
              />
            </Box>
          </>
        )}
      </Grid>
      <Grid item sm={9} md={10}>
        {/* Header */}
        <Box p={2} display="flex" justifyContent="space-between">
          <Box display="flex">
            {phrases.map((item) => (
              <Grid key={item.key} className="mr_8">
                <PhraseBtn
                  isChosen={selectedPhrase === item.key}
                  label={t(`Job:${item.label}`)}
                  onClick={() => handleClickBtn(item.key)}
                  selectedPhrase={selectedPhrase}
                />
              </Grid>
            ))}
          </Box>

          {isSelectFile ? (
            <Box>
              <Button onClick={handleSelectAll} className={styles.select_btn}>
                {t("selectAll")}
              </Button>
              <Button
                onClick={handleDeselect}
                disabled={!selectedFiles.length}
                className={selectedFiles.length ? styles.select_btn : ""}
              >
                {t("deselectAll")}
              </Button>
            </Box>
          ) : (
            <DownloadMenu
              {...props}
              isLoading={isLoading}
              handleDownloadAll={handleDownload}
              handleEnableSelectFile={handleEnableSelectFile}
            />
          )}
        </Box>

        <Box p={2}>
          <RenderMedia
            {...props}
            isSelectFile={isSelectFile}
            selectedFiles={selectedFiles}
            handleSelectFile={handleSelectFile}
            mediaTypes={mediaTypes}
            selectedPhrase={selectedPhrase}
            selectedTags={selectedTags}
            selectedVisitIds={selectedVisitIds}
            setIsSelectAllMedia={setIsSelectAllMedia}
            isSelectAllMedia={isSelectAllMedia}
            setSelectedFiles={setSelectedFiles}
            translate={t}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Media;
