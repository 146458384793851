import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel';

import { Header, Footer } from 'components/publicComponents';


const PricingPage = props => {
  const { t, geoCountry } = props;

  return (
    <div>
      <Header translate={t} secondaryPage />

      {/* <!-- breadcrumb start--> */}
      <section className="breadcrumb breadcrumb_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner text-center">
                <div className="breadcrumb_iner_item">
                  <h2>{t('pricingPlan')}</h2>
                  {/* <h5>{translate('productFeatures')} <span></span> {translate('productFeatures')}</h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb start--> */}

      {/* <!--::use sasu part end::--> */}
      <section className="use_sasu padding_top">
        <div className="container">
          <div className="row justify-content-center">
            {/* <img src="img/price_banner.png" alt="" /> */}
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_1.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::use sasu part end::--> */}

      {/* <PricingPlan translate={t} country={geoCountry} secondaryPage /> */}

      {/* <!--::testimonials part start::--> */}
      <section className="review_part carousel_section">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-5">
              <h2>Testimonials</h2>
              <OwlCarousel
                className="owl-theme review_slider"
                items={1}
                loop
                margin={10}
                dots
              >
                <div className="item carousel_body_text review_part_text">
                  <p>“I used to waste time using paper schedules and Whatsapp to coordinate my staff
                      schedules - now with Workmagic, jobs are instantly assigned to my workers and
                      I’ve more time to work on more important matters!”
                    <br /><br /><span>- Chong Sai Kin, Director, Cool Connect Aircon Pte Ltd -</span></p>
                </div>
                <div className="item carousel_body_text review_part_text">
                  <p>“I can impress my customers with modern and professional service just like big
                      service companies”
                    <br /><br /><span>- Huan Lin - Trust Clean - Owner -</span></p>
                </div>
              </OwlCarousel>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="learning_img">
                <img src="img/testimonials_bg.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <img src="img/animate_icon/Ellipse_4.png" alt="" className="feature_icon_1 custom-animation1" />
      </section>
      {/* <!--::testimonials part end::--> */}

      <Footer translate={t} country={geoCountry} />
    </div>
  );
};

const mapStateToProps = state => ({
  geoCountry: state.auth.geoCountry,
});

export default connect(mapStateToProps, null)(withRouter(PricingPage));