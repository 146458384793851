import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchServiceReportList, setSelectedServiceReport } from "redux/job";
// component
import ServiceReportTable from "./Table";
import DownloadPopup from "./Menu";

// other
import styles from "./styles.module.scss";
import { showErrorMessage } from "lib/notifier";
import LabelVisitOn from "./LabelVisitOn";

const CheckBoxCustom = ({ label, value, onChange, checked }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          name={value}
          classes={{ root: styles.checkbox, checked: styles.checkedState }}
        />
      }
      label={label}
      classes={{
        root: styles.checkboxRoot,
        label: styles.checkboxLabel,
      }}
    />
  );
};

const ServiceReport = ({ translate, jobId }) => {
  const dispatch = useDispatch();
  const serviceReportFilterList = useSelector(
    (state) => state.job.serviceReportFilterList || []
  );
  const [serviceReports, setServiceReports] = useState({
    list: [],
    page: 0,
    total: 0,
    limit: 10,
  });
  const [filter, setFilter] = useState({
    JOB: true,
    VISIT: true,
  });
  const [query, setQuery] = useState({
    jobId,
    reportFors: ["JOB", "VISIT"],
    limit: 10,
    skip: 0,
    visitIds: [],
  });

  useEffect(() => {
    // clear selected report
    dispatch(setSelectedServiceReport({}));
  }, [dispatch]);

  useEffect(() => {
    const fetchServiceReports = async () => {
      const response = await dispatch(
        fetchServiceReportList(query)
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);
      const { data, skip, total, limit } = response.data;
      setServiceReports({
        list: data,
        page: skip,
        total,
        limit,
      });
    };

    fetchServiceReports();
  }, [query, dispatch]);

  const handleChangeFilterCheckBox = (value) => {
    const checkboxState = { ...filter, [value]: !filter[value] };
    const checkedOptions = Object.keys(checkboxState).filter(
      (item) => checkboxState[item]
    );
    setFilter(checkboxState);
    setQuery({ ...query, reportFors: checkedOptions, skip: 0 });
  };

  const handleClickDate = (id) => {
    let { visitIds } = query;
    if (visitIds.includes(id)) {
      visitIds.splice(visitIds.indexOf(id), 1);
    } else {
      visitIds = [...visitIds, id];
    }
    setQuery({ ...query, visitIds, skip: 0 });
  };

  return (
    <Grid container wrap="nowrap">
      <Grid xs={3} item className={styles.leftContainer}>
        {/* download button */}
        <DownloadPopup
          dispatch={dispatch}
          styles={styles}
          translate={translate}
          jobId={jobId}
        />

        {/* filter */}

        <Grid item className="p_15">
          <Typography className={styles.subHeader}>
            {translate("type").toUpperCase()}
          </Typography>
          <CheckBoxCustom
            checked={filter["JOB"]}
            onChange={() => handleChangeFilterCheckBox("JOB")}
            label={translate("jobReport")}
            value="jobReport"
          />
          <CheckBoxCustom
            checked={filter["VISIT"]}
            onChange={() => handleChangeFilterCheckBox("VISIT")}
            label={translate("visitReport")}
            value="visitReport"
          />
        </Grid>

        {serviceReportFilterList?.length > 0 && (
          <Grid item className={styles.visitContainer}>
            <Typography>{translate("visitOn").toUpperCase()}</Typography>
            {serviceReportFilterList.map((visit, index) => (
              <CheckBoxCustom
                onChange={() => handleClickDate(visit.id)}
                label={
                  <LabelVisitOn
                    publicId={visit.publicId}
                    visitOn={visit.start}
                  />
                }
                value={visit.id}
                key={index}
              />
            ))}
          </Grid>
        )}
      </Grid>
      <Grid id="rightContainer" item className={styles.rightContainer}>
        <ServiceReportTable
          translate={translate}
          serviceReports={serviceReports}
          setQuery={setQuery}
          query={query}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(ServiceReport);
