import React, { useState, useEffect } from "react";

// Redux
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { change, reduxForm } from "redux-form";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

// Material icons
import { Close as CloseIcon } from "@material-ui/icons";

// Customised components
import { StaffForm } from "pages/Dashboard/views/Staffs/components/forms";
import { validate } from "pages/Dashboard/views/Staffs/components/utils";

// Component styles
import CreateStaffModalStyle from "./styles";
import ImageCroppingModal from "../imageCroppingModal";
import { readFile } from "lib/file";

const mapStateToProps = (state) => ({
  initialValues: {
    roleId: "WORKER",
  },
});

const FORM_NAME = "newStaff";

function CreateStaffModal(props) {
  const {
    classes,
    open,
    handleClick,
    handleClose,
    handleSubmit,
    pristine,
    invalid,
    submitting,
    handleOpenModalCategory,
    currentCategoriesStaff,
  } = props;
  const { t } = useTranslation("Staff");
  const dispatch = useDispatch();
  const [croppingImageSrc, setCroppingImageSrc] = useState({});
  const [croppedImage, setCroppedImage] = useState(null);

  const croppedImageCallback = (resultCroppingImage) => {
    setCroppedImage(resultCroppingImage);
    // If we are creating staff, so just add croppedImage to avatar to submit form
    dispatch(change(FORM_NAME, "avatar", resultCroppingImage));
    setCroppingImageSrc({});
  };
  const openCroppingImgModal = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const imageDataUrl = await readFile(file);
      setCroppingImageSrc({ file: imageDataUrl, open: true });
    }
  };
  const onCloseImageCroppingModal = () => {
    setCroppingImageSrc({});
  };

  useEffect(() => {
    if (currentCategoriesStaff.current) {
      currentCategoriesStaff.current = "";
    }
  }, [open, currentCategoriesStaff]);

  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
    >
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle disableTypography className={classes.teamDialogTitle}>
          <Typography variant="h6" className={classes.teamDialogTitleText}>
            Add new user
          </Typography>
          {open ? (
            <IconButton
              aria-label="Close"
              className={classes.closeButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent dividers>
          <StaffForm
            translate={t}
            staffProfile={croppedImage}
            formName={FORM_NAME}
            handleClickOnCategory={handleOpenModalCategory}
            openCroppingImgModal={openCroppingImgModal}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            type="button"
            disabled={pristine || invalid || submitting}
            onClick={handleClick}
          >
            Create user
          </Button>
        </DialogActions>
      </form>
      {croppingImageSrc.open && (
        <ImageCroppingModal
          open
          cropBtnText={t("crop")}
          translate={t}
          image={croppingImageSrc.file}
          onClose={onCloseImageCroppingModal}
          croppedImageCallback={croppedImageCallback}
        />
      )}
    </Dialog>
  );
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(CreateStaffModalStyle)
)(reduxForm({ form: FORM_NAME, validate })(CreateStaffModal));
