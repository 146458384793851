export default {
  // LANDING PAGE
  marketingMsg1: 'ซอฟท์แวร์ที่ดีที่สุดในการช่วยบริหารธุรกิจของคุณ',
  marketingMsg2:
    'เทคโนโลยีของเราจะช่วยให้คุณบริหารจัดการทีม, ลูกค้า, และองค์กรของคุณได้เหมือนมีเวทมนตร์',
  greatForBusiness: 'เหมาะสำหรับธุรกิจเหล่านี้:',
  businessOptions:
    'บริการทำความสะอาด | บริการงานแอร์ | บริการงานประปา | บริการงานไฟฟ้า | บริการงานรับเหมาก่อสร้าง | บริการช่างซ่อมบำรุง | และอื่นๆอีกมากมาย',
  howWMHelp: 'ให้ <0><0></0></0> ช่วยเหลืออย่างไรคะ',
  beforeJob: 'ก่อนเริ่มงาน:',
  onlineBooking: 'จองงานออนไลน์',
  staffScheduling: 'วางแผนตารางงานของทีม',
  estimates: 'ใบเสนอราคา',
  onTheJob: 'ระหว่างการทำงาน:',
  employerDispatch: 'การจัดแจงงาน',
  onMyWay: 'SMS แจ้งสถานะ "กำลังเดินทาง"',
  gpsTracking: 'GPS ติดตามตัวสำหรับลูกค้าและผู้จัดการ',
  afterJob: 'หลังงานจบ:',
  eInvoicing: 'ใบเสร็จออนไลน์',
  digitalPayments: 'ชำระเงินออนไลน์',
  followUpMarketing: 'การตลาดและโปรโมชั่นหลังการขาย',
  signUpNow: 'ลงทะเบียนเลย ฟรี!',
  signUpReason:
    'We can help modernise your business, increase sales, and reduce your operating costs. Start your free trial today!',
  getStarted: 'เริ่มต้นลงทะเบียน',
  talkToUs: 'ติดต่อเราเพื่อสอบถามเพิ่มเติม',
  termsOfService: 'เงื่อนไขการให้บริการ',
  privacyPolicy: 'นโยบายความเป็นส่วนตัว',
  copyright: 'COPYRIGHT © 2019 WorkMagic. ALL RIGHTS RESERVED',

  // ADVANTAGE SLIDER
  whyWM: 'ทำไมต้องเลือก <0><0></0></0>',
  moreJobs: 'รับงานได้มากกว่า',
  moreJobsReason:
    'เว็บเพจจองงานออนไลน์ของเวิร์คเมจิกทำให้ลูกค้าสามารถจองบริการสะดวกใช้งานง่าย และเป็นประโยชน์ ช่วยนำธุรกิจมาอยู่บนโลกออนไลน์ ได้อย่างรวดเร็วและคุ้มราคาที่สุด',
  organiseTeam: 'บริหารพนักงานอย่างมีประสิทธิภาพ',
  organiseTeamReason:
    'เวิร์คเมจิกทำให้การจัดตารางงานและติดตามพนักงานมีประสิทธิภาพกว่าเดิมดูข้อมูลและกระจายงานไปให้พนักงานได้แบบเรียลไทม์ และยังรับฟีดแบ็คจากลูกค้าหลังให้บริการได้ทันที',
  impressCustomers: 'สร้างความประทับใจ',
  impressCustomersReason:
    'ปัจจุบันนี้ทุกคนชอบข้อมูลที่รวดเร็ว และการบริการที่ดีเยี่ยม ใช้เวิร์คเมจิกเพื่อสร้างความประทับใจให้กับลูกค้า ไม่ว่าจะเป็นการจองบริการด้วยตนเอง SMSแจ้งสถานะงาน หรือการติดตามพนักงานแบบเรียลไทม์',
  receivePayments: 'จัดการเรื่องเงินได้สะดวก',
  receivePaymentsReason:
    'เกาะกระแสสังคมไร้เงินสด เวิร์คเมจิกติดอาวุธให้ธุรกิจให้พร้อมที่จะรับการชำระเงินออนไลน์ไม่ว่าจะเป็นบัตรเครดิต การโอนเงิน QR Code หรือช่องทางอื่นๆ ลดปัญหาลูกค้าจ่ายช้าด้วยการมีช่องทางชำระเงินที่หลากหลาย',
  marketingTools: 'เครื่องมือทางการตลาดช่วยเพิ่มยอดขาย',
  marketingToolsReason:
    'รักษาลูกค้าไว้ด้วยช่องทางการตลาดที่ทันสมัย เวิร์คเมจิกมีเครื่องมือที่ช่วยทำการตลาดไม่ว่าจะเป็นการแจ้งเตือนการรับบริการครั้งถัดไปหรือการแจ้งโปรโมชั่นประจำเดือน กระตุ้นยอดขายได้ง่ายๆด้วยเวิร์คเมจิก',

  // new landing page
  // NAVBAR
  home: 'Home',
  features: 'Features',
  pricing: 'Pricing',
  blog: 'Blog',
  pages: 'Pages',
  contact: 'Contact',

  // PRICE LIST
  pricingPlan: 'แพลนและราคา',
  perMonth: '/เดือน',
  basic: 'Basic',
  basicDesc:
    'For the ‘One-Man-Show’ with flexibility to add on features when needed',
  plus: 'Plus',
  plusDesc:
    'For SMEs who want to go Digital and where Team tracking is important',
  pro: 'Pro',
  proDesc:
    'For larger teams who want to grow their business with an all-inclusive price',
  enterprise: 'Enterprise',
  enterpriseDesc:
    'For large companies who wish to highlight their brand more prominently in the market',
  oneUser: '1 User',
  threeUsers: 'Up to 3 Users',
  sixUsers: 'Up to 6 Users',
  enterpriseUsers: 'More than 10 Users',
  personalisedBranding: 'Personalised Branding',
  advancedCustomisations: 'Advanced Customisations',
  jobManagement: 'Job Management',
  workScheduling: 'Work Scheduling',
  jobStatusNotification: 'Job Status Notification',
  customerManagement: 'Customer Management',
  customerDatabase: 'Customer Database',
  smsNotification: 'SMS Notification*',
  emailNotification: 'Email Notification',
  digitalAccountingTools: 'Digital Accounting Tools',
  paperlessQuotation: 'Paperless Quotation',
  paperlessInvoicing: 'Paperless Invoicing',
  paymentRecording: 'Payment Recording',
  teamManagement: 'Team Management',
  employeeTimesheets: 'Employee Timesheets',
  realTimeTracking: 'GPS Tracking for Customer',
  teamTracking: 'GPS Tracking for Team',
  smsMarketing: 'SMS Marketing*',
  emailMarketing: 'Email Marketing*',
  advancedSupport: 'Advanced Support',
  dedicatedManager: 'Dedicated Account Manager',
  extras: 'Extras',
  extrasDescBasic: 'GPS Tracking for Team and Employee Timesheets',
  extrasDescPlus: 'Employee Timesheets',
  availableAtExtraCharge: '(available at extra charge)',
  allFeaturesUnderPlus: 'All features under Plus Plan',
  allFeaturesUnderBasic: 'All features under Basic Plan',
  chargesApply: '*usage charges apply',

  // FOOTER
  aboutUs: 'เกี่ยวกับเรา',
  faq: 'คำถามพบบ่อย',
  termsAndConditions: 'รายละเอียดและเงื่อนไข',

  // FEATURE PAGE
  productFeatures: 'ฟีเจอร์ต่างๆ',

  // CONTACT PAGE
  sendMessage: 'ส่งข้อความ',

  caseStudies: 'กรณีศึกษา',
  trustedBy: 'ได้รับความไว้วางใจจาก',
  whyChooseWM: 'ทำไมต้องเวิร์คเมจิค?',
  letSimplify: 'เปลี่ยนการบริหารจัดการทีมช่างให้เป็นเรื่องง่าย',
  outsource: 'ลดการจัดการ ด้วยการ Outsource ทีมช่าง',
  outsourceDescription:
    'เรามีเครือข่ายทีมช่างที่ผ่านการตรวจสอบจากทั่วประเทศ ทีมงานเราจะช่วยคุณบริหารจัดการตั้งแต่ต้นจนจบ ลดความวุ่นวาย เพื่อที่คุณจะได้มีเวลากับงานอื่นที่สำคัญกว่า',
  completeService: 'เก็บข้อมูลครบถ้วนด้วยรีพอร์ทงาน',
  completeServiceDescription:
    'ระบบการจัดการงานของเวิร์คเมจิคช่วยเก็บข้อมูลทั้งหมดไว้ในที่เดียว คุณสามารถวัดประสิทธิภาพและดูสถานะของงานแบบเรียลไทม์ได้ทาง Dashboard ของเรา',
  flexibleTeam: 'ทีมเล็ก ทีมใหญ่ ออกแบบได้ตามใจคุณ',
  flexibleTeamDescription:
    'เราเข้าใจในสถานการณ์ธุรกิจที่อาจเปลี่ยนแปลง ด้วยระบบการจัดการแบบ "Workforce On Cloud" คุณจะไม่ต้องกังวลเรื่องการบริหารจัดการเมื่อต้องการเพิ่มหรือลดจำนวนช่างอีกต่อไป',
  labourManagement: 'จัดหาช่างที่มีฝีมือได้ตรงกับความต้องการ',
  labourManagementDescription:
    'ลดภาระฝ่าย HR ลดความยุ่งยากในการเปลี่ยนคน ไม่ต้องค้นหาหรือนัดสัมภาษณ์ด้วยตัวเอง เพียงแจ้งขอบเขตงานและสกิลที่ต้องการ เราจะจัดทีมช่างที่เหมาะสมให้กับธุรกิจคุณ',
  letUsSupport: 'ให้เราดูแลธุรกิจคุณ',
  copyRight: 'ลิขสิทธิ์ © 2019 บจก. เซอร์วิส ฮีโร่ (ประเทศไทย)',
  registration: 'สงวนสิทธิ์',
  caseStudy: 'กรณีศึกษา',
  mqdcTitle: 'MQDC - ทีมช่างดูแลบริการงานซ่อมหลังการขาย',
  mqdcDesciption:
    'Magnolia Quality Development Corporation Limited (MQDC) เป็นผู้พัฒนาอสังหาริมทรัพย์ชั้นนำของประเทศไทย ที่ก่อนหน้านี้ได้ใช้ทีมช่างขนาดเล็กเพื่อเข้าไปดูแลบริการหลังการขายให้แก่ลูกค้า แต่ก็ต้องพบกับปัญหาด้านการจัดการ และด้านเอกสารมากมาย ปัจจุบันเวิร์คเมจิคได้ดูแลแบบครบวงจร โดยส่งทีมช่างฝีมือเข้าให้บริการงานซ่อมแซม บริหารจัดการด้วยเทคโนโลยีที่ช่วยให้เข้าถึงข้อมูลได้ทุกทีทุกเวลา ทำให้การทำงานง่าย สะดวก และมีประสิทธิภาพมากขึ้น',
  kimberlyTitle:
    'Kimberly-Clark Professionals - ทีมช่าง PM สินค้าของบริษัท',
  kimberlyDescription:
    'Kimberly-Clark Professionals มองหาวิธีที่ง่ายที่สุดในการบำรุงรักษาเครื่องจ่ายกระดาษทิชชู่และกระดาษเช็ดมือให้กับลูกค้า เพื่อเพิ่มมูลค่าให้กับสินค้า และเพิ่มความพึงพอใจให้กับลูกค้า โดยต้องการทีมช่างคุณภาพ ที่สามารถซ่อมบำรุงอุปกรณ์ได้ตามมาตรฐานที่กำหนด เวิร์คเมจิคเข้ามาดูแลบริการตั้งแต่ต้นจนจบ โดยจัดส่งทีมช่างเพื่อเข้าดูแลงาน Preventive Maintenance (PM) ตู้จ่ายหลายร้อยแห่งทั่วกรุงเทพฯและปริมณฑล ทำให้ Kimberly-Clark Professionals นั้นสามารถโฟกัสในธุรกิจหลัก ขายสินค้า และเปิดลูกค้าใหม่ได้มากขึ้น โดยมีเวิร์คเมจิคดูแลซัพพอร์ตในฝั่งการซ่อมบำรุงทั้งหมด',
  samsungTitle: 'Samsung.com - ทีมช่างจัดส่งและติดตั้งเครื่องใช้ไฟฟ้า',
  samsungDescription:
    'Samsung ต้องการทีมช่างเพื่อที่จะจัดส่งและติดตั้งเครื่องใช้ไฟฟ้าขนาดใหญ่เช่น ทีวี เครื่องปรับอากาศ และเครื่องซักผ้า ที่ขายผ่านช่องทาง E-Commerce ของตนเอง (samsung.com) เวิร์คเมจิคได้รับความไว้วางใจให้เป็น Exclusive Partner ในการจัดทีมช่างเพื่อขนส่งและติดตั้งสินค้าเหล่านี้ทั่วประเทศ (มาเลเซีย) ส่งผลให้ Samsung สามารถเพิ่มยอดขายออนไลน์ได้อย่างก้าวกระโดด และลดความยุ่งยากในการบริหารจัดการได้มากกว่า 50%',
  typeOfLabour: 'ช่างประเภทอื่นๆ',
  typeNeeded: 'ประเภทช่างที่ต้องการ',
  submitRequest: 'ส่งรีเควส',
  contactName: 'ชื่อผู้ติดต่อ',
  businessName: 'ชื่อบริษัท',
  email: 'อีเมล',
  phoneNumber: 'เบอร์มือถือ',
  city: 'จังหวัด',
  technicians: 'ช่างแอร์',
  handyman: 'ช่างซ่อมบำรุง',
  cleaners: 'พนักงานทำความสะอาด',
  'electrician/plumber': 'ช่างไฟฟ้า/ประปา',
  contractors: 'ผู้รับเหมา',
  flexibleSkill: 'ทีมช่างคุณภาพ',
  blueCollar: 'สำหรับทุกความต้องการ <br>ของธุรกิจคุณ <br/>',
  integratable:
    'เวิร์คเมจิคคือแพลตฟอร์มที่คุณสามารถหาช่างคุณภาพให้กับธุรกิจของคุณ เรามีช่างคุณภาพ สกิลหลากหลาย พร้อมสำหรับทุกการใช้งาน คัดกรองช่างด้วยระบบการตรวจสอบหลายขั้นตอน ผสานด้วยแอปพลิเคชันเพื่อใช้ในการจัดการงาน ให้คุณได้ต่อยอดและดำเนินธุรกิจของคุณได้อย่างมีประสิทธิภาพมากกว่าที่เคยเป็นมา',
  demand: 'ทีมช่างคุณภาพ สำหรับธุรกิจของคุณ',
  powerBy: 'โดย',
  legal: 'ข้อมูลทางกฎหมาย',
  privacy: 'นโยบายความเป็นส่วนตัว',
  tof: 'เงื่อนไขในการบริการ',
  reachUs: 'ติดต่อเรา',
  flexibleWorkforce: 'ทีมช่างคุณภาพ ไม่ต้องจ้างเอง',
  flexibleWorkforceDescription:
    'ดูแลส่งช่างให้กับคุณ ด้วยระบบ "Workforce On Cloud" ของเรา จ่ายเท่าที่ใช้ ลดภาระค่าใช้จ่ายพนักงานประจำของบริษัท',
  workerManagement: 'บริหารงานด้วยเทคโนโลยี',
  workerManagementDescription:
    'อัปเดตสถานะงาน ติดตามทีมงาน และวัดประสิทธิภาพด้วยแพลตฟอร์มของเรา ทั้งบนเว็บไซต์และแอปพลิเคชันมือถือ',
  outsourceOperation: 'ดูแลการจัดการให้ตั้งแต่ต้นจนจบ',
  outsourceOperationDescription:
    'หมดห่วงเรื่องการจัดการทีม Operations ภายใน เราจะดูแลประสานงานให้ตั้งแต่ต้นจนจบ ตั้งแต่จัดหาช่าง นัดตารางงาน ไปจนถึงส่งรีพอร์ทสรุปงาน',
  easyBilling: 'สรุปยอด วางบิลรายเดือน',
  easyBillingDescription:
    'บัญชีไม่วุ่นวาย เราวางบิลรายเดือน พร้อมเอกสารที่จำเป็น ตามรอบการสั่งจ่ายของบริษัทคุณ ลดภาระเอกสารมากถึง 60%',
  getStartedNow: 'ส่งรีเควส',
  whatOther: 'กรุณาอธิบาย',
  ourTeam: 'ทีมช่างคุณภาพ พร้อมให้บริการ',
  selectCountry: 'เลือกประเทศ',

  aboutUsContent:
    '<0>WorkMagic by ServisHero</0> คือธุรกิจของเซอร์วิสฮีโร่ที่แตกไลน์ออกมาจากธุรกิจหลัก โดยมุ่งเน้นที่การให้บริการงานช่างกับลูกค้าองค์กร ไม่ว่าจะเป็นช่างแอร์ ช่างไฟฟ้า ช่างประปา ช่างซ่อมบำรุง หรือผู้รับเหมา เราเน้นการใช้เทคโนโลยีในการบริหารจัดการงาน ทำให้การทำงานมีประสิทธิภาพ ลดความยุ่งยากของการทำงาน และเพิ่มโอกาสในการสร้างรายได้ให้กับธุรกิจของคุณ เวิร์คเมจิคเปิดให้บริการแล้วใน 3 ประเทศ ได้แก่ สิงคโปร์ มาเลเซีย และประเทศไทย โดย ได้รับความไว้วางใจจากองค์กรชั้นนำมากมาย เช่น แมกโนเลีย ควอลิตี้ เดเวลอปเมนท์, พฤกษา, อนันดา เดเวลอปเมนท์, คิมเบอร์ลี่-คล๊าค, และอื่นๆอีกมากมาย',
  learnMore: 'เรียนรู้เพิ่มเติม',
  shortedAboutUsContent:
    '<0>WorkMagic by ServisHero</0> คือธุรกิจของเซอร์วิสฮีโร่ที่แตกไลน์ออกมาจากธุรกิจหลัก โดยมุ่งเน้นที่การให้บริการงานช่างกับลูกค้าองค์กร ไม่ว่าจะเป็นช่างแอร์ ช่างไฟฟ้า ช่างประปา ช่างซ่อมบำรุง หรือผู้รับเหมา',
  seeLess: 'ดูเพิ่มเติม',
  seeMore: 'ดูน้อยลง',
};
