export default values => {
  const errors = { message: {} };

  const requiredFields = [
    'address',
    // 'city',
    'zipCode',
    'countryCode'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'required';
      errors.message[field] = `${field}Required`;
    }
  });

  return errors;
};