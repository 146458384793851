import React, { useEffect, useRef, useState } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";

// Redux
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { reduxForm, formValueSelector, change, arraySplice } from "redux-form";
import { setIsSaving } from "redux/job";

// Material components
import {
  Grid,
  Typography,
  Button,
  Box,
  withStyles,
  ListItem,
  ListItemText,
  List,
} from "@material-ui/core";

// Material icons
import {
  EditOutlined as EditOutlinedIcon,
  Add as AddIcon,
} from "@material-ui/icons";

// Customised components
import {
  DateTimeScheduleCard,
  ServiceProductCard,
  InternalNotesCard,
} from "../cards";
import JobClientDetailsForm from "./jobClientDetailsForm";
import AdditionalSetting from "./additionalSetting";
// Shared components
import {
  Portlet,
  PortletContent,
  TeamCard,
  CategoryModal,
} from "pages/Dashboard/components";

// Component styles
import { JobFormStyle } from "./styles";
import { OnlyDecimal } from "components/converters";

const jobForm = formValueSelector("jobForm");
let timer;

const mapStateToProps = (state) => {
  const { job } = state;
  const { selectedClient, selectedProperty } = job || {};
  const { jobSetting } = state.config.configs;
  let jobTitle = (jobSetting && jobSetting.defaultTitle) || "";
  if (selectedClient)
    jobTitle = jobTitle.replace(/{clientName}/gi, selectedClient.name);
  // check whether selectedClient existed
  const disabledBtn = !jobForm(state, "selectedClient");
  const currentCompany = state.auth.currentUser.companies[0] || {};
  return {
    currencyCode: state.auth.currentUser.companies[0].currency,
    jobType: state.job.jobType,
    disabledBtn,
    initialValues: {
      publicId: null,
      startDate: job.job.startDate,
      // get from calendar create job, use startDate because it just have start date
      endDate: job.job.startDate,
      startTime: null,
      endTime: null,
      team: {},
      categories: [],
      selectedClient,
      selectedProperty,
      internalNotes: null,
      title: jobTitle,
      description: null,
      products: [
        {
          name: "",
          description: "",
          quantity: 1,
          unitPrice: "0.00",
          total: "0.00",
          inventories: [
            {
              name: "",
              serialNumber: "",
              model: "",
              quantity: 1,
              total: "0.00",
              unitPrice: "0.00",
            },
          ],
        },
      ],
      additionalSettings: [
        {
          // Default, we skip ontheway and arrived for job on TH
          skipOnTheWayTracking:
            currentCompany.countryCode === "TH" ||
            currentCompany.tag === "servishero",
          skipArrivedTracking:
            currentCompany.countryCode === "TH" ||
            currentCompany.tag === "servishero",
        },
      ],
    },
    assignees: jobForm(state, "team") || {},
    currentCategories: jobForm(state, "categories") || [],
    discountType: jobForm(state, "discountType"),
    discountValue: jobForm(state, "discountValue"),
    jobItems: jobForm(state, "products"),
    team: jobForm(state, "teamDetails") || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  setIsSaving: () => dispatch(setIsSaving()),
  updateJobForm: (attribute, value) => {
    dispatch(change("jobForm", attribute, value));
  },
  updateJobItem: (index, newItem) => {
    dispatch(arraySplice("jobForm", "products", index, 1, newItem));
  },
  clearTeam: () => {
    dispatch(change("jobForm", "team", null));
  },
  updateTeam: (team) => {
    dispatch(change("jobForm", "team", team));
  },
});

let JobDetails = (props) => {
  const {
    classes,
    className,
    handleCancelJobForm,
    handleSubmit,
    jobType,
    translate,
    quoteId,
    isEditForm,
    disabledBtn,
    jobItems,
    discountValue,
    discountType,
    updateJobItem,
    updateJobForm,
    assignees,
    clearTeam,
    updateTeam,
    currentCategories,
    team,
  } = props;
  const dispatch = useDispatch();

  const rootClassName = classNames(classes.root, className);

  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openCategoryModalStaff, setOpenCategoryModalStaff] = useState(false);
  const currentCategoriesStaff = useRef();

  const assignCardWidth = isEditForm ? 12 : 5;

  function updateJobWhenItemChange() {
    let quoteTaxAmount = 0;
    let discountAmount = 0;
    let totalAmount = 0;
    let discountPercentage = 0;

    const sustotalBeforeDiscount = (jobItems || []).reduce(
      (sum, currentItem) => {
        return (
          sum +
          parseFloat(currentItem.unitPrice) * parseFloat(currentItem.quantity)
        );
      },
      0
    );

    if (!discountValue || parseFloat(discountValue) <= 0) {
      discountAmount = 0;
      discountPercentage = 0;
    } else if (discountType !== "%") {
      discountAmount = parseFloat(discountValue);
      discountPercentage = parseFloat(discountValue) / sustotalBeforeDiscount;
    } else {
      discountPercentage = parseFloat(discountValue);
      discountAmount =
        (parseFloat(discountValue) * sustotalBeforeDiscount) / 100;
    }
    const sustotalAfterDiscount =
      parseFloat(sustotalBeforeDiscount) - parseFloat(discountAmount);

    (jobItems || []).forEach((item, index) => {
      let sumTaxPercentage = 0;
      if (item.taxRate) {
        sumTaxPercentage = parseFloat(item.taxRate.percentage || 0);
      }

      if (item.taxComponent) {
        sumTaxPercentage = (item.taxComponent.taxRates || []).reduce(
          (sum, taxRate) => {
            return sum + parseFloat(taxRate.percentage || 0);
          },
          0
        );
      }
      const itemInitalTotal =
        parseFloat(item.quantity) * parseFloat(item.unitPrice);
      const itemDiscount =
        (parseFloat(itemInitalTotal) * discountPercentage) / 100;
      const itemTotalAfterDiscount = parseFloat(itemInitalTotal) - itemDiscount;
      const itemTaxAmount =
        (itemTotalAfterDiscount * parseFloat(sumTaxPercentage)) / 100;

      const totalIncludeTax = OnlyDecimal(
        itemTotalAfterDiscount + itemTaxAmount
      );

      const inventories = (item.inventories || []).map((inventory) => {
        if (!inventory.total || !inventory.unitPrice)
          return {
            ...inventory,
            total: "0.00",
          };
        return {
          ...inventory,
          total: OnlyDecimal(
            inventory.quantity * parseFloat(inventory.unitPrice)
          ),
        };
      });

      const calculatedSubtotal = OnlyDecimal(
        parseFloat(
          inventories.reduce((prev, current) => {
            return prev + parseFloat(current.total);
          }, 0)
        ) + parseFloat(totalIncludeTax)
      );

      const newItem = {
        ...item,
        inventories,
        total: OnlyDecimal(itemTotalAfterDiscount),
        tax: itemTaxAmount,
        totalIncludeTax,
        calculatedSubtotal,
      };

      updateJobItem(index, newItem);
      quoteTaxAmount += itemTaxAmount;
    });

    totalAmount =
      parseFloat(sustotalAfterDiscount) + parseFloat(quoteTaxAmount);
    updateJobForm("subtotal", OnlyDecimal(sustotalAfterDiscount));
    updateJobForm("discount", OnlyDecimal(discountAmount));
    updateJobForm("tax", OnlyDecimal(quoteTaxAmount));
    updateJobForm("total", OnlyDecimal(totalAmount));
  }

  const handleCloseSelectCategoryModal = (_e) => {
    setOpenCategoryModal(false);
  };

  const handleCloseSelectCategoryModalStaff = (_e) => {
    setOpenCategoryModalStaff(false);
  };

  const handleCategorySelection = (data) => {
    dispatch(change("jobForm", "categories", data));
    setOpenCategoryModal(false);
  };

  const handleCategorySelectionStaff = (data) => {
    dispatch(change("newStaff", "categories", data));
    currentCategoriesStaff.current = data;
    setOpenCategoryModalStaff(false);
    if (Array.isArray(data) && data.length) {
      const categoryIds = data.map((category) => category.id);
      dispatch(change("newStaff", "categoryIds", categoryIds));
    }
  };

  useEffect(() => {
    updateJobWhenItemChange();
  }, [discountValue, jobItems, discountType, updateJobWhenItemChange]);

  const handleOpenModalCategory = () => setOpenCategoryModalStaff(true);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          clearTimeout(timer);
          timer = setTimeout(() => handleSubmit(), 500);
        }}
      >
        <Box
          mb={3}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container>
            <Typography variant="h5">{translate("Common:backTo")}</Typography>
            <Link to="/jobs">
              <Typography variant="h5" className="ml_5">
                {translate("jobs")}
              </Typography>
            </Link>
          </Grid>

          <Grid container justify="flex-end">
            <Button onClick={handleCancelJobForm}>
              {translate("Common:cancel")}
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={disabledBtn}
              className={classes.save_btn}
            >
              {translate("Common:save")}
            </Button>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            <Portlet className={rootClassName} jobLabel>
              <PortletContent
                noPadding
                style={{ overflow: "unset", zIndex: 2 }}
              >
                <Grid container>
                  <Grid item xs={6} className={classes.jobClientDetail}>
                    <JobClientDetailsForm
                      fixedClient={!!quoteId}
                      jobType={jobType}
                      translate={translate}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={6}
                    alignItems="flex-start"
                    className={classes.jobAdditionalSettings}
                  >
                    <Grid
                      item
                      container
                      direction="row"
                      style={{ height: "100%" }}
                    >
                      {/* Job setting here */}
                      <Grid
                        item
                        md={6}
                        xs={12}
                        className={classes.trackingSetting}
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          style={{ height: 40 }}
                        >
                          <Typography variant="body1">
                            {translate("Job:additionalSetting")}
                          </Typography>
                        </Grid>
                        <AdditionalSetting translate={translate} />
                      </Grid>
                      <Grid
                        item
                        container
                        md={6}
                        xs={12}
                        alignItems="flex-start"
                        alignContent="flex-start"
                      >
                        <Grid
                          item
                          container
                          alignItems="center"
                          justify="space-between"
                          style={{ height: 40 }}
                        >
                          <Typography variant="body1">
                            {translate("Job:jobCategory")}
                          </Typography>
                          <Button
                            size="small"
                            onClick={(_e) => setOpenCategoryModal(true)}
                            className={classes.save_btn}
                          >
                            {currentCategories.length === 0 && (
                              <>
                                {translate("Common:add")}
                                <AddIcon fontSize="small" />
                              </>
                            )}
                            {currentCategories.length > 0 && (
                              <>
                                {translate("Common:edit")}
                                <EditOutlinedIcon fontSize="small" />
                              </>
                            )}
                          </Button>
                        </Grid>

                        {/* Category list */}
                        {currentCategories.length > 0 && (
                          <Grid item container className={classes.categoryList}>
                            <List dense style={{ width: "100%" }}>
                              {currentCategories.map((category) => {
                                return (
                                  <ListItem
                                    key={category.id}
                                    className={classes.catItem}
                                  >
                                    <ListItemText primary={category.name} />
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        )}

                        {/* No category added */}
                        {currentCategories.length === 0 && (
                          <Grid item container className={classes.noCategory}>
                            <Typography variant="body1">
                              {translate("Category:noCategoryAdded")}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>
          </Grid>
          <Grid item md={12} sm={12} style={{ zIndex: 1 }}>
            <Portlet className={rootClassName}>
              <PortletContent noPadding>
                <Grid
                  container
                  className={classes.jobType}
                  direction="row"
                  spacing={2}
                >
                  {/* Job Schedule and Team Assignment */}
                  <Grid item md={12} sm={12}>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="stretch"
                    >
                      {!isEditForm && (
                        <Grid item md={7} sm={12}>
                          {/* Schedule Card */}
                          <DateTimeScheduleCard translate={translate} />
                        </Grid>
                      )}
                      <Grid item md={assignCardWidth} sm={12}>
                        {/* Assign Team Card */}
                        <TeamCard
                          assignees={assignees}
                          clearTeam={clearTeam}
                          translate={translate}
                          updateTeam={updateTeam}
                          handleOpenModalCategory={handleOpenModalCategory}
                          currentCategoriesStaff={currentCategoriesStaff}
                          team={team}
                        />
                      </Grid>

                      {/* Internal Notes */}
                      <Grid item md={12} sm={12}>
                        <InternalNotesCard translate={translate} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>

            {/* Line/Sales Items */}
            <Box mt={3}>
              <ServiceProductCard translate={translate} />
            </Box>
          </Grid>
        </Grid>
      </form>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={translate}
          categories={currentCategories}
          handleCategorySelection={handleCategorySelection}
        />
      )}
      {openCategoryModalStaff && (
        <CategoryModal
          open={openCategoryModalStaff}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModalStaff}
          translate={translate}
          categories={currentCategoriesStaff.current}
          handleCategorySelection={handleCategorySelectionStaff}
        />
      )}
    </>
  );
};

JobDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

JobDetails = reduxForm({ form: "jobForm", destroyOnUnmount: true })(JobDetails);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(JobFormStyle)
)(JobDetails);
