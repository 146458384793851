import { POST, GET, PATCH, DELETE } from 'lib/api';

// url
const JOB_ACTIONS = '/v2/jobs/actions';

// Actions
const SET_CURRENT_JOB = 'SET_CURRENT_JOB';
const SET_CLEAR_JOB = 'SET_CLEAR_JOB';
const SET_JOB_TYPE = 'SET_JOB_TYPE';
const SET_ASSIGNEES = 'SET_ASSIGNEES';
const SET_IS_CUSTOM_PRODUCT = 'SET_IS_CUSTOM_PRODUCT';
const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';
const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
const SET_IS_SAVING = 'SET_IS_SAVING';
const SET_JOB_ITEMS = 'SET_JOB_ITEMS';
const SET_SERVICE_REPORT_DETAILS = 'SET_SERVICE_REPORT_DETAILS';
const SET_IS_VIEW_MODE = 'SET_IS_VIEW_MODE';
const SET_SERVICE_REPORT_FILTER_LIST = 'SET_SERVICE_REPORT_FILTER_LIST';
const SET_VISIT_ID_REPORT_GENERATE_FROM = 'SET_VISIT_ID_REPORT_GENERATE_FROM';

const initialState = {
  job: {},
  jobType: 'oneOff',
  assignees: null,
  isCustomProduct: [],
  productIndex: null,
  selectedClient: null,
  selectedProperty: null,
  modalVisibility: false,
  modalType: null,
  isSaving: false,
  jobItems: [],
  reportDetails: {},
  isViewServiceReportMode: true,
  serviceReportFilterList: [],
  visitReportGenerateFrom: '',
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_JOB:
      return {
        ...state,
        job: {
          ...action.job,
        },
      };

    case SET_JOB_TYPE:
      return {
        ...state,
        jobType: action.jobType,
      };

    case SET_ASSIGNEES:
      return {
        ...state,
        assignees: action.assignees,
      };

    case SET_IS_CUSTOM_PRODUCT:
      return {
        ...state,
        isCustomProduct: action.isCustomProduct,
      };

    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.client,
      };

    case SET_SELECTED_PROPERTY:
      return {
        ...state,
        selectedProperty: action.property,
      };

    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        modalVisibility: action.visibility,
        modalType: action.modalType,
      };

    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case SET_JOB_ITEMS:
      return {
        ...state,
        jobItems: action.jobItems,
      };

    case SET_CLEAR_JOB:
      return {
        ...state,
        job: {},
        assignees: null,
        isCustomProduct: [],
        productIndex: [],
        selectedClient: null,
        client: null,
        selectedProperty: null,
        modalVisibility: false,
        modalType: null,
        isSaving: false,
        jobItems: [],
      };

    case SET_SERVICE_REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.details,
      };

    case SET_IS_VIEW_MODE:
      return {
        ...state,
        isViewServiceReportMode: action.mode,
      };

    case SET_SERVICE_REPORT_FILTER_LIST:
      return {
        ...state,
        serviceReportFilterList: action.list,
      };

    case SET_VISIT_ID_REPORT_GENERATE_FROM:
      return {
        ...state,
        visitReportGenerateFrom: action.visitId,
      };

    default:
      return state;
  }
}

// Action Creators
export function setCurrentJob(job) {
  return { type: SET_CURRENT_JOB, job };
}

export function clearJob() {
  return { type: SET_CLEAR_JOB };
}

export function setJobType(jobType) {
  return { type: SET_JOB_TYPE, jobType };
}

export function setAssignees(assignees) {
  return { type: SET_ASSIGNEES, assignees };
}

export function setIsCustomProduct(isCustomProduct) {
  return { type: SET_IS_CUSTOM_PRODUCT, isCustomProduct };
}

export function setSelectedClient(client) {
  return { type: SET_SELECTED_CLIENT, client };
}

export function setModalVisibility(visibility, modalType) {
  return { type: SET_MODAL_VISIBILITY, visibility, modalType };
}

export function setIsSaving(isSaving) {
  return { type: SET_IS_SAVING, isSaving };
}

export function setJobItems(jobItems) {
  return { type: SET_JOB_ITEMS, jobItems };
}

export function setSelectedProperty(property) {
  return { type: SET_SELECTED_PROPERTY, property };
}

export function setServiceReportFilterList(list) {
  return { type: SET_SERVICE_REPORT_FILTER_LIST, list };
}

export function setVisitIdToGenerateReport(id) {
  return { type: SET_VISIT_ID_REPORT_GENERATE_FROM, visitId: id };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
export function getJob(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/v2/jobs/${id}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function addJob(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/v2/jobs', accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

// ****** getJobs params
// options = {
//   clientId: xxxyyyzzz
//   limit: limit,
//   skip: skip,
//   from: from,
//   to: to,
//   searchString: null,
//   sort: {
//     field: 'fieldNameHere',
//     orderDirection: 'asc'
//   }
// }
const mapOptionsToPayload = ['includingArchived', 'clientId', 'statusIds', 'staffIds', 'scheduledOnFrom',
  'scheduledOnTo', 'createdOnFrom', 'createdOnTo', 'jobCategoryIds', 'clientId', 'serviceReport', 'lateVisit',
  'exportXLSX', 'completionTimeFrom', 'completionTimeTo', 'hubIds', 'isItemsCollected', 'isVerified'];

export function getJobs(options = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    const queryParams = {};

    mapOptionsToPayload.forEach(name => {
      if (options[name]) queryParams[name] = options[name];
    });

    if (options.limit) {
      queryParams['$limit'] = options.limit;
    }

    if (options.skip) {
      queryParams['$skip'] = options.skip;
    }

    if (options.searchString) {
      queryParams['$q'] = options.searchString;
    }

    if (options.sort && options.sort.field) {
      queryParams[`$sort[${options.sort.field}]`] =
        options.sort.orderDirection === 'asc' ? 1 : -1;
    }
    if (options.from) {
      queryParams['$from'] = options.from;
    }
    if (options.to) {
      queryParams['$to'] = options.to;
    }

    const params = { params: queryParams };
    return GET('/v2/jobs', accessToken, params)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function updateJobDetails(jobDetails) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/v2/jobs/${jobDetails.publicId || jobDetails.id}`, accessToken, { data: jobDetails })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function getJobItems(jobIds, params = {}) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const searchParams = { ...params, jobIds };

    return GET('/job-items', accessToken, { params: searchParams })
      .then(
        (response) => {
          dispatch(setJobItems(response.data.data));
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}

export function addServiceReport(data) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/service-reports', accessToken, { data })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function updateServiceReportDetails(id, payload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return PATCH(`/service-reports/${id}`, accessToken, {
      data: payload,
    })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function setSelectedServiceReport(details) {
  return { type: SET_SERVICE_REPORT_DETAILS, details };
}

export function fetchServiceReportDetails(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/service-reports/${id}`, accessToken)
      .then(
        (response) => {
          dispatch(setSelectedServiceReport({ ...response.data, id }));
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function fetchServiceReportList(query) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/service-reports', accessToken, { params: query })
      .then(
        (response) => {
          dispatch(
            setServiceReportFilterList(response.data.visitDataForFilter)
          );
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function setCurrentView(mode) {
  return { type: SET_IS_VIEW_MODE, mode };
}

export function downloadPdf(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET(`/service-reports/${id}?pdf=true`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function sendToClientVia(payload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('service-report-actions', accessToken, { data: payload })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function deleteServiceReport(id) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return DELETE(`/service-reports/${id}`, accessToken)
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function downloadTemplateServiceReport(payload) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/service-report-actions', accessToken, { data: payload })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function jobActions(jobId, action) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const { job } = getState().job;
    const payload = {
      action,
      jobId
    };

    return POST(JOB_ACTIONS, accessToken , { data: payload })
      .then(
        (response) => {
          const updatedJob = { ...job, ...response.data };
          return Promise.resolve(updatedJob);
        },
        (error) => {
          return Promise.reject(error.response);
        }
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function getAssignedTeam(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return GET('/company-staff', accessToken, { params })
      .then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      )
      .catch((err) => {
        return Promise.reject(err);
      });
  };
}
