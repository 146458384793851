import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useSelector } from 'react-redux';

const localizer = momentLocalizer(moment);
const minTime = moment().startOf('day').toDate();
const maxTime = moment().endOf('day').toDate();

const CustomCalendarHeader = ({ label }) => (
  <div
    style={{ paddingTop: 10, textTransform: 'uppercase', fontWeight: 900 }}
  >
    {label}
  </div>
);

const BigCalendar = (props) => {
  const {
    views,
    currentCalendarView,
    Event,
    CustomToolbar,
    eventsList = [],
    handleCalendarViewChange,
    handleSelectSlot,
    onRangeChange,
    onSelectEvent,
  } = props;
  const calendar = useSelector((state) => state.calendar); // Access the 'calendar' slice of the state

  // note: onView have to provide if pass view in props

  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    function filterEventsForToday(events) {
      // Get the current date
      const currentDate = new Date();

      // If todayOnly is true, filter events that occur today
      if (calendar.todayOnly) {
        const filteredEvents = events.filter((event) => {
          // Convert the event's start date to a Date object
          const eventStartDate = new Date(event.start);

          // Check if the event's start date matches the current date (year, month, and day)
          return (
            eventStartDate.getDate() === currentDate.getDate() &&
            eventStartDate.getMonth() === currentDate.getMonth() &&
            eventStartDate.getFullYear() === currentDate.getFullYear()
          );
        });

        // Update the filteredEvents state with the new filtered events
        setFilteredEvents(filteredEvents);
      } else {
        // If todayOnly is false, set filteredEvents to the original eventsList
        setFilteredEvents(events);
      }
    }

    filterEventsForToday(eventsList);
  }, [eventsList, calendar]);

  return (
    <Calendar
      popup
      selectable
      localizer={localizer}
      events={filteredEvents}
      startAccessor="start"
      endAccessor="end"
      defaultDate={new Date()}
      views={views}
      view={currentCalendarView}
      showMultiDayTimes
      scrollToTime={moment().toDate()}
      step={30}
      timeslots={2}
      min={minTime}
      max={maxTime}
      components={{
        toolbar: CustomToolbar,
        event: Event,
        day: { header: CustomCalendarHeader },
        week: { header: CustomCalendarHeader },
        month: { header: CustomCalendarHeader },
      }}
      onSelectEvent={onSelectEvent}
      onSelectSlot={handleSelectSlot}
      onView={handleCalendarViewChange}
      onRangeChange={onRangeChange}
    />
  );
};

export default BigCalendar;
