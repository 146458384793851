import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { getHubDetails, updateHub } from "redux/hub";
import { submit } from "redux-form";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";

import { Form } from "../components";

import styles from "../styles.module.scss";

const FORM_NAME = "warehouseManagement";

const EditWarehouse = (props) => {
  const {
    history,
    match: {
      params: { hubId },
    },
    location: {
      state: {
        data: { enterpriseId },
      },
    },
  } = props;
  const dispatch = useDispatch();
  const { t: translate } = useTranslation("Hub");
  const [warehouseDetails, setWarehouseDetails] = useState({});

  const handleBack = () => history.goBack();

  const handleSubmitEditForm = () => dispatch(submit(FORM_NAME));

  const handleSubmit = async (values) => {
    const newValues = { ...values, enterpriseId };
    const result = await dispatch(updateHub(hubId, newValues)).catch((e) => ({
      e,
    }));

    if (result.e) return showErrorMessage(result.e);

    showSuccessMessage(translate("updateSuccessfully"));
    history.replace(`/hub/${hubId}`, { data: { enterpriseId } });
  };

  useEffect(() => {
    (async () => {
      const params = { enterpriseId };
      const result = await dispatch(getHubDetails(hubId, params)).catch(
        (e) => ({
          e,
        })
      );

      if (result.e) return showErrorMessage(result.e);
      setWarehouseDetails(result);
    })();
  }, [dispatch, hubId, enterpriseId]);

  return (
    <Box p={2}>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handleBack}>
          <ArrowBackIcon className="mr_5" />
          {translate("Common:back")}
        </Button>

        <Box display="flex">
          <Button variant="outlined" className="mr_5" onClick={handleBack}>
            {translate("Common:discard")}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmitEditForm}
            className={styles.btn_save}
          >
            {translate("Common:save")}
          </Button>
        </Box>
      </Box>

      <Form
        {...props}
        translate={translate}
        onSubmit={handleSubmit}
        warehouseDetails={warehouseDetails}
      />
    </Box>
  );
};

export default EditWarehouse;
