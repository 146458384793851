import React, { useMemo } from "react";
import { Box, Button, Menu } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import "../styles.scss";
import { useHandleReviewJob, useReviewJobApi } from "../hook";
import { ALL_VISIT_STATUS } from "common/constant";
import {
  REVIEW_BUTTON_STATUS,
  STATUS_CONFIRM_BUTTON,
  VISIT_STATUS,
} from "../constants";
import { useHandleMenu } from "hook/modal";
import { useTranslation } from "react-i18next";
import ListMenuItem from "./ListMenuItem";
import { useDispatch } from "react-redux";
import { setConfirmReviewButton, setContentModal } from "redux/jobVerification";

const ReviewButton = ({
  openModal,
  status,
  jobId,
  currentVisitStatus,
  refetchJobDetail,
  hasPendingVisit,
  afterSaveVisitDetails,
  withLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("Job");
  const { open, anchorEl, openMenu, closeMenu } = useHandleMenu();
  const { startReviewJob } = useReviewJobApi();
  const { cancelReviewConfirm, completedReviewConfirm } = useHandleReviewJob({
    openModal,
    closeMenu,
    refetchJobDetail,
    afterSaveVisitDetails,
    withLoading,
  });

  const startReview = async () => {
    if (
      !currentVisitStatus ||
      currentVisitStatus === ALL_VISIT_STATUS.UNSCHEDULED
    ) {
      dispatch(setContentModal(VISIT_STATUS.UNSCHEDULED));
      dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.UNSCHEDULE));
      openModal();
    } else {
      const data = { jobId };
      await startReviewJob(data);
      refetchJobDetail();
    }
  };

  const openJobClosed = async () => {
    dispatch(setContentModal(VISIT_STATUS.OPEN));
    dispatch(setConfirmReviewButton(STATUS_CONFIRM_BUTTON.OPEN_CREATE_VISIT));
    openModal();
  };

  const ButtonStatus = useMemo(() => {
    switch (status) {
      case REVIEW_BUTTON_STATUS.IN_REVIEW:
        return (
          <Button
            id="end-review-button"
            className="review-button end-review-button"
            aria-controls={open ? "end-review-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={openMenu}
            fullWidth
          >
            <Box mx="auto">{t("endJobReview")}</Box>
            <Box style={{ borderLeft: "solid #A2A2A2 1px" }} ml={1} pl={1}>
              <KeyboardArrowDown color="#101010" />
            </Box>
          </Button>
        );
      case REVIEW_BUTTON_STATUS.REVIEWED:
        return (
          <Button
            fullWidth
            onClick={openJobClosed}
            className="review-button open-review-button"
          >
            {t("openJob")}
          </Button>
        );
      default:
        return (
          <Button
            fullWidth
            onClick={startReview}
            className="review-button start-review-button"
          >
            {t("startJobReview")}
          </Button>
        );
    }
  }, [
    status,
    currentVisitStatus,
    open,
    openJobClosed,
    openMenu,
    startReview,
    t,
  ]);

  return (
    <>
      {ButtonStatus}
      <Menu
        id="end-review-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "end-review-button",
        }}
        className="review-menu"
      >
        <ListMenuItem
          t={t}
          currentVisitStatus={currentVisitStatus}
          completedReviewConfirm={() =>
            completedReviewConfirm({
              hasPendingVisit,
              currentVisitStatus,
              jobId,
            })
          }
          cancelReviewConfirm={() =>
            cancelReviewConfirm({ hasPendingVisit, currentVisitStatus, jobId })
          }
        />
      </Menu>
    </>
  );
};

export default ReviewButton;
