import React from 'react';

// Externals
import PropTypes from 'prop-types';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, InputAdornment, Typography } from '@material-ui/core';

// Customised components
import { TextInput } from 'components/inputs';
import { OnlyDecimal } from 'components/converters';

// Component styles
import styles from './styles';

const mapDispatchToProps = dispatch => ({
  updateProduct: (name, newValue) => dispatch(change('productDetails', name, newValue)),
});


function ProductServiceForm(props) {
  const { classes, currency, custom, taxRates, taxComponents, updateProduct,
    serviceModal, isInDoD2UFranchisee, translate } = props;

  const serviceType = [
    {
      name: translate('Product:service'),
      value: 'SERVICE'
    },
    {
      name: translate('Product:product'),
      value: 'PRODUCT'
    },
  ];

  const handleFocus = value => {
    value.currentTarget.select();
  };

  const handleBlur = (event, newValue, previousValue, name) => {
    event.preventDefault();
    updateProduct(name, OnlyDecimal(newValue));
  };

  return (
    <div
      className={classes.formField}
    >
      <Grid container spacing={2}>
        {
          custom &&
            <Grid item xs={12} sm={12}>
              <Field
                name='type'
                component={TextInput}
                label={translate('Product:itemType')}
                variant='outlined'
                select
                SelectProps={{ native: true }}
              >
                <option value='' />
                {
                  serviceType.map(option => (
                    <option
                      key={option.name}
                      value={option.value}
                    >
                      {option.name}
                    </option>
                  ))
                }
              </Field>
            </Grid>
        }
        <Typography className="compulsory-title">{translate('CompanySetting:compulsoryField')}</Typography>
        <Grid item xs={12} sm={12}>
          <Field
            name='name'
            component={TextInput}
            label={translate('Common:name')}
            variant='outlined'
            inputProps={{
              maxLength: 100,
            }}
            required
            helperText={translate('Product:nameLimitCharacters')}
          />
        </Grid>
        {
          !serviceModal &&
          <Grid item xs={12} sm={12}>
            <Field
              name='model'
              component={TextInput}
              label={translate('Common:model')}
              variant='outlined'
              inputProps={{
                maxLength: 100,
              }}
              required
            />
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <Field
            name='description'
            component={TextInput}
            label={translate('Common:description')}
            variant='outlined'
            multiline
            rows='5'
            rowsMax='5'
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Field
            name='unitPrice'
            component={TextInput}
            variant="outlined"
            label={translate('Product:unitCost')}
            InputProps={{
              startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Field
            name='taxRateId'
            component={TextInput}
            label={translate('Product:taxRate')}
            variant='outlined'
            select
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          >
            <option key='' value=''>{translate('noTax')}</option>
            {
              taxRates && Object.keys(taxRates).length > 0 &&
              taxRates.map(option => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </option>
              ))
            }
          </Field>
        </Grid>

        { isInDoD2UFranchisee &&
          <Grid item xs={12} sm={12}>
            <Field
              name='taxComponentFk'
              component={TextInput}
              label={translate('Product:taxComponent')}
              variant='outlined'
              select
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            >
              <option key='' value=''>{translate('Product:noTaxComponent')}</option>
              {
                taxComponents && Object.keys(taxComponents).length > 0 &&
                taxComponents.map(option => (
                  <option
                    key={option.pk}
                    value={option.pk}
                  >
                    {option.name}
                  </option>
                ))
              }
            </Field>
          </Grid> }
      </Grid>
    </div>
  );
};

ProductServiceForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(ProductServiceForm);