import React, { useState, useEffect, useRef } from 'react';
import LazyLoad from 'react-lazyload';

import { Typography, Grid } from '@material-ui/core';

import samsungCardSrc from 'assets/images/logo/samsung-logo.svg';
import kimberlyCardSrc from 'assets/images/logo/kimberly-clark-logo.svg';
import mqdcCardSrc from 'assets/images/logo/mqdc-logo.svg';

const defaultScale = {
  cardOne: 1,
  cardTwo: 1,
};

const StackCard = ({ t }) => {
  const [scale, setScale] = useState(defaultScale);
  const cardOneRef = useRef();
  const cardTwoRef = useRef();
  const cardThreeRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      // it seem unmount not work, so temp use if to check
      if (
        !containerRef.current ||
        !cardThreeRef.current ||
        !cardTwoRef.current ||
        !cardOneRef.current
      )
        return;
      const container = containerRef.current.getBoundingClientRect();
      const lastItem = cardThreeRef.current.getBoundingClientRect();
      const cardTwo = cardTwoRef.current.getBoundingClientRect();
      const cardOne = cardOneRef.current.getBoundingClientRect();
      if (container.top >= 0) return setScale(defaultScale);
      if (lastItem.top <= 0) return;
      const scaleRate = 1 - (container.height / lastItem.top) * 0.015;
      if (scaleRate <= 0.9) return;
      let newScale = {
        ...scale,
        cardOne: scaleRate,
      };
      if (cardTwo.top <= cardOne.height) {
        newScale = {
          ...newScale,
          cardTwo: 1 - (container.height / lastItem.top) * 0.01,
        };
      }
      setScale(newScale);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  return (
    <Grid ref={containerRef} className="container">
      <Grid
        style={{
          transform: `scale(${scale.cardOne})`,
          transition: 'transform 1s',
        }}
        ref={cardOneRef}
        className="card card_border text-left"
      >
        <Grid className="card-body d-flex flex-column-reverse flex-md-row">
          <Grid className="col-12 col-md-8">
            <Typography
              variant="subtitle2"
              className="card_title font_bold mb_5"
            >
              {t('caseStudy')}
            </Typography>
            <Typography variant="h3" className="font_bold black_text">
              {t('mqdcTitle')}
            </Typography>
            <Typography variant="body1" className="mt_20 black_text font_16">
              {t('mqdcDesciption')}
            </Typography>
          </Grid>
          <Grid className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <LazyLoad>
              <img src={mqdcCardSrc} alt="mqdc_alt" />
            </LazyLoad>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        ref={cardTwoRef}
        className="card card_border text-left"
        style={{
          transform: `scale(${scale.cardTwo})`,
          transition: 'transform 1s',
        }}
      >
        <Grid className="card-body d-flex flex-column-reverse flex-md-row">
          <Grid className="col-12 col-md-8">
            <Typography
              variant="subtitle2"
              className="card_title font_bold mb_5"
            >
              {t('caseStudy')}
            </Typography>
            <Typography variant="h3" className="font_bold black_text">
              {t('kimberlyTitle')}
            </Typography>
            <Typography variant="body1" className="mt_20 black_text font_16">
              {t('kimberlyDescription')}
            </Typography>
          </Grid>
          <Grid className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <LazyLoad>
              <img src={kimberlyCardSrc} alt="kimberly_src" />
            </LazyLoad>
          </Grid>
        </Grid>
      </Grid>

      <Grid ref={cardThreeRef} className="card card_border text-left">
        <Grid className="card-body d-flex flex-column-reverse flex-md-row">
          <Grid className="col-12 col-md-8">
            <Typography
              variant="subtitle2"
              className="card_title font_bold mb_5"
            >
              {t('caseStudy')}
            </Typography>
            <Typography variant="h3" className="font_bold black_text">
              {t('samsungTitle')}
            </Typography>
            <Typography variant="body1" className="mt_20 black_text font_16">
              {t('samsungDescription')}
            </Typography>
          </Grid>
          <Grid className="col-12 col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <LazyLoad>
              <img src={samsungCardSrc} alt="samsung_alt" />
            </LazyLoad>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StackCard;
