import React, { useEffect } from "react";

// redux
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

// components
import { Grid, Typography, Checkbox, Box } from "@material-ui/core";
import { TextInput } from "components/inputs";
import { SwitchButton } from "../../../components";
import Category from "../../../components/category";
import Countries from "lib/countryList";
import { EMAIL_VALIDATION } from "common/constant";

const countryList = new Countries();
const countryCodeOptions = countryList.countryPhoneCodes();

const FORM_NAME = "EnterpriseUser";

const renderContactPerson = ({
  input,
  title,
  description,
  setIsChangingOwner,
}) => {
  const handleOnChange = (e) => {
    const value = e.target.checked;
    if (setIsChangingOwner) setIsChangingOwner(value);
    input.onChange(value);
  };
  return (
    <Box display="flex" alignItems="flex-start">
      <Checkbox
        checked={input.value}
        style={{ marginTop: "-10px", color: "#F9C825" }}
        onChange={handleOnChange}
      />
      <Box display="flex" flexDirection="column">
        <Typography variant="h5">{title}</Typography>
        {description.map((text, index) => (
          <Box display="flex" alignItems="center">
            <Grid
              style={{
                width: 4,
                height: 4,
                background: "#4C4C4C",
                borderRadius: 4,
                marginRight: 8,
              }}
            />
            <Typography style={{ color: "#4C4C4C" }} key={index}>
              {text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const EnterpriseForm = (props) => {
  const {
    t,
    pristine,
    invalid,
    submitting,
    setIsReadyToSubmit,
    setIsChangingOwner,
    enterpriseAccountId,
  } = props;

  useEffect(() => {
    if (pristine || invalid || submitting) return setIsReadyToSubmit(false);
    setIsReadyToSubmit(true);
  }, [pristine, invalid, submitting, setIsReadyToSubmit]);

  return (
    <Grid container>
      <Field
        name="isOwner"
        component={renderContactPerson}
        title={t("makeUserOwner")}
        description={[
          t("replaceCurrentOwner"),
          t("bothEmailPhoneRequired"),
          t("cantBeDeleted"),
        ]}
        setIsChangingOwner={setIsChangingOwner}
      />
      <Grid item container className="mt_5">
        <Field
          name="isContactPerson"
          component={renderContactPerson}
          title={t("makeContactPerson")}
          description={[t("replacePerson"), t("bothEmailAndPhoneNumber")]}
        />
      </Grid>
      <Grid item container className="mt_20">
        <Grid item md={6} sm={6}>
          <Field
            name="firstName"
            component={TextInput}
            margin="dense"
            label={t("firstName")}
            type="text"
            variant="outlined"
          />
          <Field
            name="lastName"
            component={TextInput}
            margin="dense"
            label={t("lastName")}
            type="text"
            variant="outlined"
          />
          <Field
            name="email"
            component={TextInput}
            margin="dense"
            label={t("email")}
            type="text"
            variant="outlined"
          />
          <Field
            name="countryPhoneCode"
            component={TextInput}
            label={t("countryPhoneCode")}
            select
            SelectProps={{ native: true }}
            margin="dense"
            variant="outlined"
          >
            <option key="" value="">
              {" "}
            </option>
            {countryCodeOptions &&
              countryCodeOptions.length &&
              countryCodeOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </Field>
          <Field
            name="phoneNumber"
            component={TextInput}
            margin="dense"
            label={t("phoneNumber")}
            type="text"
            variant="outlined"
          />
          <Field
            name="title"
            component={TextInput}
            margin="dense"
            label={t("title")}
            placeholder={t("titlePlaceholder")}
            type="text"
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} sm={6} style={{ paddingLeft: 12 }}>
          <Field
            name="categories"
            t={t}
            component={Category}
            isShorterContainer
            enterpriseAccountId={enterpriseAccountId}
          />
          <Box mt={2}>
            <Field
              name="accountStatus"
              component={SwitchButton}
              label={t("accountStatus")}
              options={[
                { key: true, name: t("active") },
                { key: false, name: t("deactivated") },
              ]}
            />
          </Box>
          <Field
            name="notes"
            component={TextInput}
            margin="normal"
            label={t("notes")}
            type="text"
            variant="outlined"
            minRows="4"
            placeholder={t("notesPlaceholder")}
            multiline
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  const userDetails = ownProps.userDetails || {};
  const companyDetails = ownProps.companyDetails || {};

  // get staff categories first then company categories
  const categories =
    (Array.isArray(userDetails.categories) &&
      userDetails.categories.length &&
      userDetails.categories) ||
    (Array.isArray(companyDetails.categories) &&
      companyDetails.categories.length &&
      companyDetails.categories);
  return {
    initialValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phoneNumber: userDetails.phoneNumber,
      title: userDetails.title,
      accountStatus: !userDetails.archived,
      notes: userDetails.notes,
      isContactPerson: userDetails.isContactPerson || false,
      isOwner: userDetails.roleId === "OWNER" || false,
      countryPhoneCode:
        userDetails.countryPhoneCode ||
        countryList.getCountryPhoneCodeByCountryCode(
          (ownProps.companyDetails || {}).countryCode
        ),
      categories: categories || [],
    },
    enterpriseAccountId: companyDetails.id,
  };
};

const REQUIRED_FIELDS = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "countryPhoneCode",
];

const validate = (values) => {
  const errors = {};
  REQUIRED_FIELDS.forEach((field) => {
    if (!values[field]) {
      errors[field] = "required";
    }
  });

  if (![true, false].includes(values.accountStatus)) {
    errors.accountStatus = "required";
  }

  if (values.email && !EMAIL_VALIDATION.test(values.email))
    errors.email = "invalidEmail";
  return errors;
};

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate,
  })
)(EnterpriseForm);
