import React, { forwardRef, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Redux
import { compose } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getIsUpdateSideBar,
  getUpdateSideBar,
  setIsUpdateSideBar,
  setMenuStatus,
} from "redux/sidebar";
import { VIEWS_NAME } from "common/constant";

// Material helpers
// Material components
import {
  withStyles,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Icon,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";

// Material icons
import { ExpandLess, ExpandMore, Menu as MenuIcon } from "@material-ui/icons";

// Component styles
import styles from "./styles";
import { withTranslation } from "react-i18next";
import {
  getIsEnterpriseUserSelector,
  setCurrentCompanyLogo,
  setCurrentCompanyPermissions,
} from "redux/auth";

const d2uLogo = require("assets/images/Disinfection-logo.svg");
const wmLogo = require("assets/images/logo/wm-logo-horizontal.svg");
const calendarIcon = require("assets/images/sidebar/Calendar.svg");
const gpsIcon = require("assets/images/sidebar/GPS.svg");
const invoiceIcon = require("assets/images/sidebar/Invoice.svg");
const businessSettingsIcon = require("assets/images/sidebar/BusinessSettings.svg");
const workSettingsIcon = require("assets/images/sidebar/WorkSettings.svg");
const jobsIcon = require("assets/images/sidebar/Jobs.svg");
const peopleIcon = require("assets/images/sidebar/People.svg");
const announcementIcon = require("assets/images/announcement/index.svg");
const servicesIcon = require("assets/images/sidebar/ProductService.svg");
const quoteIcon = require("assets/images/sidebar/Quote.svg");
const teamIcon = require("assets/images/sidebar/Team.svg");
const timesheetIcon = require("assets/images/sidebar/Timesheet.svg");
const planBillingIcon = require("assets/images/sidebar/Plan&Billing.svg");
const accountSettingIcon = require("assets/images/sidebar/AccountSettings.svg");
const categoryTagIcon = require("assets/images/sidebar/Tag.svg");
const newWMLogo = require("assets/images/sidebar/NewWmLogo.svg");
const newAccountSettingIcon = require("assets/images/sidebar/NewAccountSettings.svg");
const WarehouseIcon = require("assets/images/sidebar/Warehouse.svg");
const DoubleArrowIcon = require("assets/images/sidebar/double-arrow.svg");

const mapStateToProps = (state) => ({
  companyTag:
    state.auth.currentUser &&
    state.auth.currentUser.companies &&
    state.auth.currentUser.companies[0].tag,
  calendarStatus: state.sidebar.calendar,
  clientStatus: state.sidebar.client,
  teamStatus: state.sidebar.team,
  workStatus: state.sidebar.work,
  businessManagementStatus: state.sidebar.businessManagement,
  accountStatus: state.sidebar.account,
  companyPermissions:
    (state.auth && state.auth.currentCompanyPermissions) || [],
  companyLogo: (state.auth && state.auth.currentCompanyLogo) || wmLogo,
});

const mapDispatchToProps = {
  setMenuStatus,
};

const NavLinkWithRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

// SidebarItem Component
const SidebarItem = (props) => {
  const {
    classes,
    linkTo,
    label,
    icon,
    expand,
    submenu,
    statusName,
    setMenuStatus: setMenuStatusDispatch,
    isFullSideBar,
    companyPermissions,
  } = props;
  const [showRootMenu, setShowRootMenu] = useState(false);
  const hasSubmenu = !linkTo && !!submenu && submenu.length > 0;
  const childlessProps = {
    activeClassName: classes.activeListItem,
    component: NavLinkWithRef,
    to: linkTo,
  };

  useEffect(() => {
    if (!hasSubmenu) return;

    // this method verify, whether any subMenu are allow to show
    // if all subMenu not allow to show, not show rootMenu, validSubmenu return true false
    // d2u dont show subcription so have to check here
    const validSubmenu = submenu.some((value) => {
      return companyPermissions.includes(value.view);
    });
    setShowRootMenu(validSubmenu);
  }, [companyPermissions, hasSubmenu, submenu]);

  const propsWithChild = {
    onClick: () => setMenuStatusDispatch(statusName),
  };

  const selectedProps = hasSubmenu ? propsWithChild : childlessProps;
  if (!showRootMenu && hasSubmenu) return null;

  return (
    <div
      className={classNames(
        !isFullSideBar ? classes.noPadding : null,
        hasSubmenu ? classes.pt_16 : null,
        !isFullSideBar && hasSubmenu ? classes.borderTop : null
      )}
    >
      <ListItem
        button
        className={classNames(
          classes.listItem,
          !isFullSideBar && !icon ? classes.displayNone : null
        )}
        {...selectedProps}
      >
        {icon && (
          <Tooltip
            placement="right"
            title={<Grid style={{ fontSize: 14 }}>{label}</Grid>}
          >
            <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
          </Tooltip>
        )}
        <ListItemText
          primary={label}
          classes={{ primary: classes.listItemTitleText }}
          className={classNames(
            classes.fs20,
            isFullSideBar ? classes.fullSideBar : classes.miniSideBar
          )}
        />
        {(() => {
          if (!hasSubmenu || !isFullSideBar) return null;
          if (expand) return <ExpandLess />;
          return <ExpandMore />;
        })()}
      </ListItem>
      {!linkTo && hasSubmenu > 0 && (
        <Collapse in={expand}>
          <List component="div" disablePadding>
            {submenu.map((menu) => {
              if (!companyPermissions.includes(menu.view)) return null;

              const {
                icon: subMenuIcon,
                label: subMenuLabel,
                linkTo: subMenuLinkTo,
              } = menu;
              return (
                <ListItem
                  key={subMenuLabel}
                  activeClassName={classNames(
                    classes.nested,
                    classes.activeListItem
                  )}
                  button
                  className={classNames(classes.nested, classes.listItem)}
                  component={NavLinkWithRef}
                  to={subMenuLinkTo}
                >
                  <Tooltip
                    placement="right"
                    title={<Grid style={{ fontSize: 14 }}>{menu.label}</Grid>}
                  >
                    <ListItemIcon
                      fontSize="small"
                      className={classes.listItemIcon}
                    >
                      {subMenuIcon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    classes={{ primary: classes.listItemText }}
                    primary={subMenuLabel}
                  />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
};

// Sidebar Main Component
const Sidebar = (props) => {
  const {
    businessManagementStatus,
    classes,
    className,
    clientStatus,
    setMenuStatus: setMenuStatusDispatch,
    t,
    workStatus,
    accountStatus,
    onToggleSidebar,
    isFullSideBar,
    companyPermissions,
    companyLogo,
  } = props;

  const companyTag = (props.companyTag || "")
    .replace(/(\r\n|\n|\r)/gm, "")
    .trim();

  const dispatch = useDispatch();
  const isUpdateSideBar = useSelector(getIsUpdateSideBar);
  const isEnterpriseUser = useSelector(getIsEnterpriseUserSelector);
  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    if (isEnterpriseUser) {
      dispatch(getUpdateSideBar()).then((result) => {
        dispatch(setCurrentCompanyLogo(result.data?.companyLogo));
      });
    }
    dispatch(getUpdateSideBar()).then((result) => {
      dispatch(setCurrentCompanyLogo(result.data?.companyLogo));
      if (!isUpdateSideBar) {
        dispatch(setCurrentCompanyPermissions(result.data?.companyWmViews));
        dispatch(setIsUpdateSideBar(true));
      }
    });
  }, [dispatch, isEnterpriseUser, isUpdateSideBar]);
  const SvgIcon = (imgSource) => (
    <Icon style={{ display: "flex" }}>
      <img src={imgSource.src} height={24} width={24} alt="logo_img" />
    </Icon>
  );
  const SidebarMenu = [
    {
      label: t("dashboard"),
      icon: <SvgIcon src={newWMLogo} />,
      linkTo: "/dashboard",
      expand: null,
      submenu: [],
      statusName: "dashboard",
    },
    {
      label: t("calendar"),
      icon: <SvgIcon src={calendarIcon} />,
      linkTo: "/enterprise-calendar",
      expand: null,
      submenu: [],
      statusName: "calendar",
      view: VIEWS_NAME.enterpriseCalendar,
    },
    {
      label: t("calendar"),
      icon: <SvgIcon src={calendarIcon} />,
      linkTo: "/calendar",
      expand: null,
      submenu: [],
      statusName: "calendar",
      view: VIEWS_NAME.calendar,
    },
    {
      label: t("customers"),
      icon: <SvgIcon src={peopleIcon} />,
      linkTo: "/clients",
      expand: null,
      submenu: [],
      statusName: "customers",
      view: VIEWS_NAME.calendar,
    },
    {
      label: "Announcements",
      icon: <SvgIcon src={announcementIcon} />,
      linkTo: "/announcements",
      expand: null,
      submenu: [],
      statusName: "announcements",
      view: VIEWS_NAME.calendar,
    },
    // {
    //   label: t("people"),
    //   linkTo: null,
    //   expand: clientStatus,
    //   statusName: "client",
    //   submenu: [
    //     {
    //       label: t("customers"),
    //       icon: <SvgIcon src={peopleIcon} />,
    //       linkTo: "/clients",
    //       view: VIEWS_NAME.clients,
    //     },
    //   ],
    // },
    {
      label: t("work"),
      linkTo: null,
      expand: workStatus,
      statusName: "work",
      submenu: [
        {
          label: t("jobs"),
          icon: <SvgIcon src={jobsIcon} />,
          linkTo: "/jobs",
          view: VIEWS_NAME.jobs,
        },
        {
          label: t("jobs"),
          icon: <SvgIcon src={jobsIcon} />,
          linkTo: "/enterprise-jobs",
          view: VIEWS_NAME.enterpriseJobs,
        },
        {
          label: t("tracking"),
          icon: <SvgIcon src={gpsIcon} />,
          linkTo: "/gps",
          expand: null,
          submenu: [],
          statusName: "gps",
          view: VIEWS_NAME.gps,
        },
        // {
        //   label: t("invoices"),
        //   icon: <SvgIcon src={invoiceIcon} />,
        //   linkTo: "/invoices",
        //   view: VIEWS_NAME.invoices,
        // },
        // {
        //   label: t("quotes"),
        //   icon: <SvgIcon src={quoteIcon} />,
        //   linkTo: "/quotes",
        //   view: VIEWS_NAME.quote,
        // },
      ],
    },
    {
      label: t("businessManagement"),
      linkTo: null,
      expand: businessManagementStatus,
      statusName: "businessManagement",
      submenu: [
        {
          label: t("team"),
          icon: <SvgIcon src={teamIcon} />,
          linkTo: "/teams",
          view: VIEWS_NAME.staff,
        },
        // {
        //   label: t("timesheet"),
        //   icon: <SvgIcon src={timesheetIcon} />,
        //   linkTo: "/timesheet",
        //   expand: null,
        //   submenu: [],
        //   statusName: "timesheet",
        //   view: VIEWS_NAME.timesheet,
        // },
        {
          label: t("productsService"),
          icon: <SvgIcon src={servicesIcon} />,
          linkTo: "/products",
          view: VIEWS_NAME.productsServices,
        },
        {
          label: t("hubs"),
          icon: <SvgIcon src={WarehouseIcon} />,
          linkTo: "/hub",
          expand: null,
          submenu: [],
          statusName: "hub",
          view: VIEWS_NAME.hubs,
        },
        {
          label: t("categories"),
          icon: <SvgIcon src={categoryTagIcon} />,
          linkTo: "/category",
          view: VIEWS_NAME.category,
        },
        {
          label: t("enterpriseAccount"),
          icon: <SvgIcon src={accountSettingIcon} />,
          linkTo: "/enterprise",
          view: VIEWS_NAME.enterpriseAccountManagement,
        },
        {
          label: t("businessSettings"),
          icon: <SvgIcon src={businessSettingsIcon} />,
          linkTo: "/business_management/business-settings",
          view: VIEWS_NAME.businessManagement,
        },
        {
          label: t("workSettings"),
          icon: <SvgIcon src={workSettingsIcon} />,
          linkTo: "/business_management/work-settings",
          view: VIEWS_NAME.workSettings,
        },
      ],
    },
    {
      label: t("accountManagement"),
      linkTo: null,
      expand: accountStatus,
      statusName: "account",
      submenu: [
        {
          label: t("accountSettings"),
          icon: <SvgIcon src={newAccountSettingIcon} />,
          linkTo: "/account-setting",
          view: VIEWS_NAME.accountSetting,
        },
        {
          label: t("planBilling"),
          icon: <SvgIcon src={planBillingIcon} />,
          linkTo: "/subscription",
          view: VIEWS_NAME.planBilling,
        },
      ],
    },
  ];

  return (
    <>
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          {!isFullSideBar && (
            <Tooltip title={t("Sidebar:showSidebar")}>
              <IconButton
                className={classes.menuButton}
                onClick={onToggleSidebar}
                variant="text"
              >
                <MenuIcon />
              </IconButton>
            </Tooltip>
          )}
          {isFullSideBar && (
            <>
              <Link
                className={classes.logoLink}
                to={isEnterpriseUser ? "/dashboard" : "/calendar"}
              >
                <img
                  alt="WM logo"
                  className={classes.logoImage}
                  src={
                    companyTag && companyTag === "disinfection2u"
                      ? d2uLogo
                      : companyLogo
                  }
                />
              </Link>
              <Tooltip title={t("Sidebar:closeSidebar")}>
                <IconButton
                  className={classes.menuButton}
                  onClick={onToggleSidebar}
                  variant="text"
                >
                  {isFullSideBar ? (
                    <img src={DoubleArrowIcon} alt="wm-logo" />
                  ) : (
                    <MenuIcon />
                  )}
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
        <Divider className={classes.logoDivider} />
        <List className={classes.list} component="div" disablePadding>
          {SidebarMenu.map((menu, index) => {
            const {
              label,
              icon,
              linkTo,
              expand,
              submenu,
              statusName,
              view,
            } = menu;

            /*
            for case not have submenu, handle in this not need to check inside anymore
          */
            if (view && !companyPermissions.includes(view)) return null;

            return (
              <SidebarItem
                classes={classes}
                expand={isFullSideBar ? expand : true}
                icon={icon}
                key={label}
                label={label}
                linkTo={linkTo}
                setMenuStatus={(value) => setMenuStatusDispatch(value)}
                statusName={statusName}
                submenu={submenu}
                companyPermissions={companyPermissions}
                companyTag={companyTag}
                isFullSideBar={isFullSideBar}
              />
            );
          })}
        </List>
        {companyTag && companyTag === "disinfection2u" && isFullSideBar && (
          <Grid className={classes.logoContainer} container justify="center">
            <Typography variant="h6" className={classes.powerBy}>
              {t("powerBy")}
            </Typography>
            <div className={classes.poweredByWmLogo}>
              <img src={wmLogo} alt="wm-logo" className={classes.wmLogo} />
            </div>
          </Grid>
        )}
      </nav>
      {companyTag && companyTag === "enterprise_partner" && isFullSideBar && (
        <Grid className={classes.footerLogoContainer} container>
          <Typography variant="h6" className={classes.footerPowerBy}>
            {t("powerBy")}
          </Typography>
          <div className={classes.footerLogo}>
            <img src={wmLogo} alt="wm-logo" className={classes.footerWmLogo} />
          </div>
        </Grid>
      )}
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default compose(
  withTranslation("Sidebar"),
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Sidebar);
