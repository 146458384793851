import React, { Component } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
// Material components
import { withStyles, Button, IconButton } from '@material-ui/core';

// Material icons
import {
  Delete as DeleteIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';
import DropZoneUploadFile from '../DropzoneUploadFile';

const AllowedRolesForImportingClients = ['OWNER', 'ADMIN', 'MANAGER'];
const AllowedRolesForExportingClients = ['OWNER', 'ADMIN'];
class ClientToolbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openFileSelector: false,
      hightlight: false
    };
    this.fileInputRef = React.createRef();
    this.showFileUploader = this.showFileUploader.bind(this);
    this.handleCloseFileUploader = this.handleCloseFileUploader.bind(this);
  }

  handleCloseFileUploader() {
    this.setState({ openFileSelector: false });
  };

  showFileUploader() {
    this.setState({ openFileSelector: true });
  }


  render() {
    const { classes, translate, className, selectedUsers,
      processClientImport, handleExportClients, currentUser } = this.props;
    const { openFileSelector } = this.state;
    const userRole = currentUser.companies[0].roleId;
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          {selectedUsers.length > 0 && (
            <IconButton
              className={classes.deleteButton}
              onClick={this.handleDeleteUsers}
            >
              <DeleteIcon />
            </IconButton>
          )}

          <Link to='/clients/new'>
            <Button
              color="primary"
              variant="outlined"
            >
              {translate('Client:addClient')}
            </Button>
          </Link>

          <Button
            color="primary"
            variant="outlined"
            className={classes.importBtn}
            disabled={!AllowedRolesForImportingClients.includes(userRole)}
            onClick={this.showFileUploader}
          >
            { translate('Client:importClients') }
          </Button>

          <Button
            color="primary"
            variant="outlined"
            className={classes.importBtn}
            onClick={handleExportClients}
            disabled={!AllowedRolesForExportingClients.includes(userRole)}
          >
            { translate('Client:exportClients') }
          </Button>
        </div>

        <DropZoneUploadFile
          openFileSelector={openFileSelector}
          handleCloseFileUploader={this.handleCloseFileUploader}
          processClientImport={processClientImport}
          translate={translate}
        />
      </div>
    );
  }
}

ClientToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

ClientToolbar.defaultProps = {
  selectedUsers: []
};

export default withStyles(styles)(ClientToolbar);
