import React, { useState } from "react";
import { Button, Menu } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "./date.css";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "./customeRangeCalendar";

const CustomizeDateRangePicker = (props) => {
  const { input = {} } = props;
  const { t } = useTranslation("Common");

  const startDateInput = input.value ? input.value[0] : null;
  const endDateInput = input.value ? input.value[1] : null;
  const getEndDateCurrentMonth = () => {
    const startOfMonth = moment().toDate();
    const endOfMonth = moment().toDate();
    return {
      startDate: startDateInput ? startDateInput : startOfMonth,
      endDate: endDateInput ? endDateInput : endOfMonth,
    };
  };
  const defaultRange = {
    ...getEndDateCurrentMonth(),
    key: "selection",
  };
  const [dateRange, setDateValue] = useState(defaultRange);

  const getDisplayValueFromDateRange = (tempDateRange) => {
    const { startDate: tempStartDate, endDate: tempEndDate } = tempDateRange;
    const momentStartDate = moment(tempStartDate);
    const momentEndDate = moment(tempEndDate);
    return {
      startDate: new Date(
        momentStartDate.year(),
        momentStartDate.month(),
        momentStartDate.date()
      ),
      endDate: new Date(
        new Date(
          momentEndDate.year(),
          momentEndDate.month(),
          momentEndDate.date() + 1
        ) - 1
      ),
      key: "selection",
    };
  };
  const [
    dateRangePickerDisplayValue,
    setDateRangePickerDisplayValue,
  ] = useState(getDisplayValueFromDateRange(dateRange));
  const [anchorEl, setAnchorEl] = useState(null);

  // useEffect(() => {
  //   setDateRangePickerDisplayValue(getDisplayValueFromDateRange(dateRange));
  // }, [tabValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (payload) => {
    const { startDate: start, endDate: end } = payload.selection;
    setDateValue({
      ...payload.selection,
      startDate: moment()
        .startOf("days")
        .year(start.getFullYear())
        .month(start.getMonth())
        .date(start.getDate())
        .toDate(),

      endDate: moment()
        .endOf("days")
        .year(end.getFullYear())
        .month(end.getMonth())
        .date(end.getDate())
        .toDate(),
    });

    setDateRangePickerDisplayValue({
      startDate: start,
      endDate: end,
      key: "selection",
    });

    input.onChange([start, end]);
  };
  const { startDate, endDate } = dateRangePickerDisplayValue;
  return (
    <>
      <Button
        style={
          startDateInput &&
          endDateInput && { padding: "5px", paddingRight: "0px" }
        }
        variant="outlined"
        onClick={handleClick}
      >
        {startDateInput && moment(startDate).format("MMM DD, YYYY")}
        {endDateInput && " - "}
        {endDateInput && moment(endDate).format("MMM DD, YYYY")}
        {!startDateInput && !endDateInput && t("allDates")}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <DateRangePicker
          showPreview={false}
          onChange={handleOnChange}
          staticRanges={defaultStaticRanges}
          ranges={[dateRangePickerDisplayValue]}
        />
      </Menu>
    </>
  );
};

export default CustomizeDateRangePicker;
