import React, { useState } from 'react';
import { getVisit } from 'redux/visit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Box, Typography, Button, IconButton, Dialog } from '@material-ui/core';
import CalendarSrc from 'assets/images/enterprise/calendar.png';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import styles from '../card.module.scss';
import { STATUSES } from 'common/constant';
import { showErrorMessage } from 'lib/notifier';

import Check from 'assets/images/icons/check.svg';
import Close from 'assets/images/icons/red-close.svg';
import { getDateTimeFormat } from 'lib/formatter';

const STATUS_HIDE_CURRENT_VISIT = ['COMPLETED', 'CANCELLED', 'ARCHIVED'];

const CurrentVisitCard = (props) => {
  const {
    updateJobStatus,
    translate,
    handleSelectVisit,
    job: { statusId, currentVisit = null } = {},
  } = props;

  const dispatch = useDispatch();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(
    false
  );

  if (!currentVisit || STATUS_HIDE_CURRENT_VISIT.includes(statusId))
    return null;

  const handleShowVisit = async () => {
    const result = await dispatch(getVisit(currentVisit?.id)).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    handleSelectVisit(result.data);
  };

  const handleClose = () => setIsOpenConfirmModal(false);
  const handleOpenConfirm = () => setIsOpenConfirmModal(true);
  const handleOpenConfirmCancel = () => setIsOpenConfirmCancelModal(true);
  const handleCloseConfirmCancel = () => setIsOpenConfirmCancelModal(false);

  const handleClickUpdateStatus = (status) => {
    handleCloseConfirmCancel();
    handleClose();
    updateJobStatus && updateJobStatus(status);
  };

  return (
    <>
      <Dialog
        maxWidth="lg"
        open={isOpenConfirmCancelModal}
        classes={{ paper: styles.modalDefaultWidth }}
      >
        <Box p={3}>
          <Typography variant="h3">{translate('Job:cancelVisit')}</Typography>
          <Box mt={2}>
            <Typography>{translate('confirmCancel')}</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              onClick={handleCloseConfirmCancel}
              variant="outlined"
              className="mr_5"
            >
              {translate('Common:close')}
            </Button>
            <Button
              onClick={() => handleClickUpdateStatus(STATUSES.CANCELLED)}
              variant="contained"
              className={styles.cancel_btn}
            >
              {translate('Common:cancel')}
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={isOpenConfirmModal}
        classes={{ paper: styles.modalDefaultWidth }}
      >
        <Box p={3}>
          <Typography variant="h3">{translate('Job:completeVisit')}</Typography>
          <Box mt={2}>
            <Typography>{translate('confirmComplete')}</Typography>
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose} variant="outlined" className="mr_5">
              {translate('Common:close')}
            </Button>
            <Button
              onClick={() => handleClickUpdateStatus(STATUSES.COMPLETED)}
              variant="contained"
              className={styles.complete_btn}
            >
              {translate('Common:complete')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      {currentVisit &&
      <Box
        display="flex"
        justifyContent="space-between"
        className={styles.current_visit_wrapper}
        p={2}
        mb={1}
      >
        <Box display="flex">
          <img src={CalendarSrc} alt="calendarSrc" />
          <Box ml={2} display="flex" flexDirection="column">
            <Typography variant="h5">
              {translate('Dashboard:currentVisit')}
            </Typography>
            {currentVisit.start && (
              <Typography
                variant="subtitle1"
                className={`${styles.subtitle} mt_5`}
              >
                {moment(currentVisit.start).format(
                  getDateTimeFormat()
                )}
              </Typography>
            )}
            <Typography variant="subtitle1" className={styles.subtitle}>
              ID: {currentVisit.publicId}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Button
            variant="contained"
            className={styles.check_btn}
            onClick={handleOpenConfirm}
          >
            <img src={Check} alt="Check" className="mr_5" />
            {translate('complete')}
          </Button>
          <Box mx={1}>
            <Button
              variant="contained"
              className={styles.close_btn}
              onClick={handleOpenConfirmCancel}
            >
              <img src={Close} alt="Close" className="mr_5" />
              {translate('cancel')}
            </Button>
          </Box>
          <IconButton onClick={handleShowVisit}>
            <InfoOutlinedIcon />
          </IconButton>
        </Box>
      </Box>}
    </>
  );
};

export default CurrentVisitCard;
