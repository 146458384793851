import React from 'react';
import '../../index.scss';

import { Grid, Typography } from '@material-ui/core';

import newLogoSrc from 'assets/images/logo/new_logo.svg';
import SHSrc from 'assets/images/logo/sh-logo.svg';
import fbSrc from 'assets/images/logo/facebook.svg';
import WMFooterSrc from 'assets/images/logo/letter_footer.svg';

const LandingPageFooter = ({ t }) => {
  return (
    <Grid className="footer relative_container">
      <img className="img_floating" src={WMFooterSrc} alt="wm_src" />
      <Grid className="container d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center align-items-md-start">
        <Grid className="d-flex flex-column col-12 col-md-4 align-items-center align-items-md-start">
          <img style={{ width: '50%' }} src={newLogoSrc} alt="footer_img" />
          <Grid className="text_wrapper text-left">
            <Typography className="white_text subtitle">
              {t('demand')}
            </Typography>
          </Grid>
          <Grid className="d-flex mt-3 mt-md-0">
            <Typography className="white_text mr_5">{t('powerBy')}</Typography>
            <img src={SHSrc} alt="sh_logo" />
          </Grid>
        </Grid>

        {/* contact */}
        <Grid className="row contact_spacing col-12 col-md-6">
          <Grid className="col-6 col-md-6 d-block text-left">
            <Typography className="white_text font_bold">
              {t('legal')}
            </Typography>
            <a href="privacy-policy">
              <Typography className="white_text mt_15 mb_10">
                {t('privacy')}
              </Typography>
            </a>
            <a href="term-of-service">
              <Typography className="white_text">{t('tof')}</Typography>
            </a>
          </Grid>
          <Grid className="col-6 col-md-3 d-block text-left">
            <Typography className="white_text font_bold">
              {t('reachUs')}
            </Typography>
            <a href="https://www.facebook.com/workmagicapp">
              <img
                src={fbSrc}
                className="white_text mt_15 mb_10"
                alt="fb_icon"
              />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LandingPageFooter;
