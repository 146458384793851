import React from 'react';
import { withStyles, Typography, Grid } from '@material-ui/core';
import styles from './styles';

import newLogoSrc from 'assets/images/logo/new_logo_black_text.svg';

const HeaderIcon = () => (
  <Grid item className="header-logo">
    <a href="/" className="logo-cover">
      <img src={newLogoSrc} className="wm-logo" alt="WorkMagic" />
    </a>
  </Grid>
);

const PrivacyPolicy = props => {
  const {
    classes
  } = props;

  return (
    <div className={classes.root}>
      <HeaderIcon />
      <div className={classes.pageTitle}>
        <Typography variant='h1'>Privacy Policy</Typography>
      </div>
      <Grid
        container
        spacing={4}
      >
        <div className={classes.pageContent}>
          <ol type="1">
            <li className={classes.termItem}>
              <strong>
                ACCEPTANCE OF TERMS
              </strong>
              <div className={classes.itemContent}>
                <p>PLEASE READ THE TERMS OF SERVICE THOROUGHLY AND CAREFULLY.
                  The terms and conditions set forth below (“Terms of Use”)
                and the Privacy Policy (as defined below) constitute a legally-binding agreement,</p>

                <ul className={classes.listChild}>
                  <li>If you are accessing our service in Malaysia:
                    between Business Pixel Sdn Bhd, a company incorporated in Malaysia; or</li>
                  <li>If you are accessing our service from Singapore:
                    between ServisHero Pte Ltd, a company incorporated in Singapore; or</li>
                  <li>If you are accessing our service from Thailand:
                    between ServisHero (Thailand) Limited, and you</li>
                </ul>

                <p>"ServisHero" is defined as the ServisHero entity described above,
                   as relevant according to your country of access.</p>

                <p> These Terms of Use contain provisions that define your limits, legal rights and obligations with
                respect to your use of and participation in (i) the ServisHero Product,
                including the classified advertisements, forums, various email functions and Internet links,
                and all content and ServisHero services available through the domain and sub-domains of
                ServisHero located at www.ServisHero.com as well as the mobile application associated with
                ServisHero (collectively referred to herein as the “Product”), and (ii)
                the online transactions between those
                users of the Product who are offering services (each, a “Service Professional”)
                and those users of the Product who are obtaining services (each, a “Service User”) through
                the Product (such services, collectively, the “Services”).
                The Terms of Use described below incorporate the Privacy Policy and apply to all users of the Product,
                including users who are also contributors of video content, information, private and public messages,
                advertisements, and other materials or Services on the Product.</p>

                <p>You acknowledge that the Product serves as a venue for the online distribution and
                publication of user submitted information between Service Professionals and Service Users,
                and, by using, visiting, registering for, and/or otherwise participating in this Product,
                including the Services presented, promoted,
                and displayed on the Product, and by clicking on “I have read and agree to the terms of use,”
                you hereby certify that: (1) you are either a Service Professional or a prospective Service User,
                (2) you have the authority to enter into these Terms of Use,
                (3) you authorize the transfer of payment for Services requested through the use of the Product,
                and (4) you agree to be bound by all terms and conditions of these Terms of Use and any other
                documents incorporated by reference herein.
                If you do not so agree to the foregoing, you should not click to affirm your acceptance thereof,
                in which case you are prohibited from accessing or using the Product.
                If you do not agree to any of the provisions set forth in the Terms of Use,
                kindly discontinue viewing or participating in this Product immediately.</p>

                <p>All references to “you” or “your,” as applicable, mean the person that accesses, uses,
                and/or participates in the Product in any manner.
                If you use the Product or open an Account (as defined below) on behalf of a business,
                you represent and warrant that you have the authority to bind that business and your acceptance
                of the Terms of Use will be deemed an acceptance by that business
                and “you” and “your” herein shall refer to that business.</p>
              </div>
            </li>
            <li className={classes.termItem}>
              <strong>MODIFICATIONS TO TERMS OF USE AND/OR PRIVACY POLICY</strong>
              <div className={classes.itemContent}>
                ServisHero reserves the right, in its sole discretion, to change, modify,
                or otherwise amend the Terms of Use,
                and any other documents incorporated by reference herein, at any time,
                and ServisHero will post notice of the changes and the amended Terms of Use at the
                domain of http://www.ServisHero.com , and/or may communicate the amendments
                through any method of written contact that ServisHero has established with you.
                It is your responsibility to review the Terms of Use for any changes.
                Your use of the Product following any amendment of the Terms of Use will signify your
                assent to and acceptance of any revised Terms of Use.
                If you do not agree to abide by these or any future Terms of Use,
                please do not use or access the Product.
              </div>
            </li>
            <li className={classes.termItem}>
              <strong>PRIVACY POLICY</strong>
              <div className={classes.itemContent}>
                <p>ServisHero has established a Privacy Policy that
                  explains to users how their information is collected and used. </p>
                <p>The Privacy Policy is referenced above and hereby incorporated
                  into the Terms of Use set forth herein. </p>
                <p>Your use of this Product is governed by the Privacy Policy. </p>
                <p>The Privacy Policy is located at:
                  <a href="https://workmagic.app/privacy-policy">www.workmagic.app/privacy-policy.</a></p>
              </div>
            </li>
          </ol>
        </div>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(PrivacyPolicy);