export const QUOTE_ALLOW_STATUSES = Object.freeze({
  DRAFT: 'DRAFT',
  AWAITING_RESPONSE: 'AWAITING_RESPONSE',
  CHANGES_REQUESTED: 'CHANGES_REQUESTED',
  APPROVED: 'APPROVED',
  ACCEPTED: 'ACCEPTED',
  CONVERTED: 'CONVERTED',
  ARCHIVED: 'ARCHIVED',
  COMPLETED: 'COMPLETED',
  LATE_VISIT: 'LATE_VISIT'
});

export const STATUSES = Object.freeze({
  COMPLETED: 'COMPLETED',
  LATE_VISIT: 'LATE_VISIT',
  UNASSIGNED: 'UNASSIGNED',
  UNSCHEDULED: 'UNSCHEDULED',
  CANCELLED: 'CANCELLED'
});

export const MEDIA_TYPES = Object.freeze({
  PHOTO: 0,
  VIDEO: 1,
  FILE: 2
});
export const MEDIA_PHASES = Object.freeze({
  EVALUATION: '0',
  AFTER_JOB: '1',
  OTHERS: '2',
  0: 'Evaluation',
  1: 'After Job',
  2: 'Others'
});

export const JOB_TYPES = Object.freeze({
  HOURLY_JOB: '0',
  ONCE_OFF: '1',
  OTHERS: '2',
  0: 'HOURLY_JOB',
  1: 'ONCE_OFF',
  2: 'OTHERS'
});

export const VIEWS_NAME = Object.freeze({
  dashboard: 'DASHBOARD',
  calendar: 'CALENDAR',
  gps: 'TRACKING',
  clients: 'CLIENT',
  staff: 'STAFF',
  work: 'WORK',
  requests: 'REQUESTS',
  quote: 'QUOTE',
  jobs: 'JOB',
  invoices: 'INVOICE',
  businessManagement: 'BUSINESS_SETTING',
  workSettings: 'WORK_SETTING',
  productsServices: 'PRODUCT_SERVICE',
  timesheet: 'TIMESHEET',
  products: 'PRODUCTS',
  accountSetting: 'ACCOUNT_SETTING',
  planBilling: 'PLAN_BILLING',
  category: 'CATEGORY_MANAGEMENT',
  hubs: 'HUB',
  enterpriseAccountManagement: 'ENTERPRISE_ACCOUNT_MANAGEMENT',
  enterpriseCalendar: 'ENTERPRISE_CALENDAR',
  enterpriseJobs: 'ENTERPRISE_JOBS'
});

export const DEFAULT_PLAN = 'PRO';
export const HEADER_PENDING_PAYMENT = 'x-payment-pending';

export const SERVICE_REPORT_FORM_NAME = 'serviceReportForm';
export const VIEW_SERVICE_REPORT_FORM_NAME = 'viewServiceReportForm';
export const SIDE_BAR_FILTER_FORM_NAME = 'filterForm';
export const SEND_TO_CLIENT = 'SEND_TO_CLIENT';

export const EMAIL_VALIDATION = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const STATUS_TO_ACTION = Object.freeze({
  ON_THE_WAY: 'MARK_OTW',
  ARRIVED: 'MARK_ARRIVED',
  STARTED: 'MARK_STARTED',
  COMPLETED: 'MARK_COMPLETED',
  CANCELLED: 'MARK_CANCELLED',
  ON_STANDBY: 'MARK_ON_STANDBY'
});

export const REASSIGN_TEAM = 'ASSIGN_VISIT';

export const PROGRESS_TIMESTAMP_ADJUSTMENT = 'PROGRESS_TIMESTAMP_ADJUSTMENT';
export const WAREHOUSE_ACTIONS = {
  UNARCHIVE_WAREHOUSE: 'UNARCHIVE_WAREHOUSE',
  ARCHIVE_WAREHOUSE: 'ARCHIVE_WAREHOUSE'
};

export const TIME_FORMAT_12 = 'hh:mm A';
export const TIME_FORMAT_24 = 'HH:mm';

export const THAI = 'th-TH';
export const ENGLISH = 'en-US';

export const TIME_FORMAT_12H_API = '12h';
export const TIME_FORMAT_24H_API = '24h';

export const DATE_TIME_FORMAT_12H = 'MMM DD, YYYY - hh:mm A';
export const DATE_TIME_FORMAT_24H = 'MMM DD, YYYY - HH:mm';

export const DATE_FORMAT = 'DD/MM/YYYY';

export const ALL_VISIT_STATUS = {
  LATE_VISIT: 'LATE_VISIT',
  UNSCHEDULED: 'UNSCHEDULED',
  UNASSIGNED: 'UNASSIGNED',
  ON_STANDBY: 'ON_STANDBY',
  ON_THE_WAY: 'ON_THE_WAY',
  ARRIVED: 'ARRIVED',
  STARTED: 'STARTED',
  COMPLETED: 'COMPLETED',
  ARCHIVED: 'ARCHIVED',
  CANCELLED: 'CANCELLED',
  DELETED: 'DELETED'
};

export const QUERY_STRING_DECODE = {
  arrayFormat: 'bracket',
  decode: false,
  skipNull: true,
  skipEmptyString: true
};
export const QUERY_STRING_ENCODE = {
  arrayFormat: 'bracket',
  encode: false,
  skipNull: true,
  skipEmptyString: true
};

export const JOB_STATUS_ENTERPRISE_DASHBOARD = {
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  PENDING: 'pending',
  CANCELLED_AND_CLOSED: 'cancelledAndClosed',
  COMPLETED_AND_CLOSED: 'completedAndClosed',
  ARCHIVED: 'archived'
};

export const JOB_STATUS_ENTERPRISE = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  CANCELLED_AND_CLOSED: 'CANCELLED_AND_CLOSED',
  COMPLETED_AND_CLOSED: 'COMPLETED_AND_CLOSED',
  ARCHIVED: 'ARCHIVED'
};
