import React, { useState, useEffect } from "react";
import { Trans } from "react-i18next";

// Redux
import { connect } from "react-redux";

// Material components
import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
} from "@material-ui/core";
import { change, formValueSelector } from "redux-form";
import { getMediaFiles } from "redux/media";
import { uploadJobFiles } from "redux/uploadFile";
import { Close as CloseIcon } from "@material-ui/icons";
import styles from "./styles/jobMedia.module.scss";
import uploadIcon from "assets/images/service-report/upload.svg";
import uploadingGif from "assets/images/service-report/uploading.gif";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";
import { SERVICE_REPORT_FORM_NAME } from "common/constant";

const ALLOW_FILE_TYPES = ["jpg", "jpeg", "png", "svg", "heic"];
const serviceReportFormValues = formValueSelector(SERVICE_REPORT_FORM_NAME);

const mapStateToProps = (state) => {
  const { evaluation, afterJob } = serviceReportFormValues(
    state,
    "evaluation",
    "afterJob"
  );
  return {
    selectedImageEvaluation: evaluation,
    selectedImageAfterJob: afterJob,
    jobId: (state.job.job && state.job.job.publicId) || null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMediaFilesAPI: (data) => dispatch(getMediaFiles(data)),
  changeField: (field, value) =>
    dispatch(change(SERVICE_REPORT_FORM_NAME, field, value)),
  uploadJobFilesAPI: (formData) => dispatch(uploadJobFiles(formData)),
});

const mapPhaseNumberToName = {
  0: "evaluation",
  1: "afterJob",
};

const JobMediaModal = ({
  translate,
  getMediaFilesAPI,
  uploadJobFilesAPI,
  phase = 0,
  jobId,
  visitId,
  changeField,
  stateOpen,
  setStateOpen,
  selectedImageEvaluation,
  selectedImageAfterJob,
}) => {
  const [mediaList, setMedia] = useState("");
  const [selectedImageIds, setSelectedImageId] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const fetchMedia = async () => {
    let requestQuery = {
      // 0 is image
      mediaTypes: [0],
      mediaPhase: phase,
      jobId,
    };
    if (visitId) requestQuery = { ...requestQuery, visits: [visitId] };
    const {
      data: { data },
    } = await getMediaFilesAPI(requestQuery);
    setMedia(data);
  };
  useEffect(() => {
    const updateSelectedImageId = () => {
      const isEvaluation = phase === 0;
      const idList = [];
      if (isEvaluation) {
        selectedImageEvaluation.forEach((item) => idList.push(item.id));
      } else {
        selectedImageAfterJob.forEach((item) => idList.push(item.id));
      }
      setSelectedImageId(idList);
    };
    fetchMedia();
    updateSelectedImageId();
  }, [phase, selectedImageAfterJob, selectedImageEvaluation]);

  const handleAddMediaToForm = () => {
    const selectedList = selectedImageIds;
    const defaultMedias =
      phase === 0 ? selectedImageEvaluation : selectedImageAfterJob;
    // get list from selected list, to keep caption
    const remainMedias = defaultMedias.filter((media) => {
      const index = selectedList.indexOf(media.id);
      if (index >= 0) {
        // remove from local selected list
        selectedList.splice(index, 1);
        return true;
      }
      return false;
    });
    // get new list
    const selectedMedia = mediaList.filter((item) =>
      selectedList.includes(item.id)
    );
    changeField(mapPhaseNumberToName[phase], [
      ...remainMedias,
      ...selectedMedia,
    ]);
    setStateOpen(false);
  };

  const validateFileType = (file = {}) => {
    const { name, type } = file;
    const fileExtArray = name.split(".");
    const fileExt =
      fileExtArray[fileExtArray.length - 1] &&
      fileExtArray[fileExtArray.length - 1].toLowerCase();
    const error = type || fileExt;
    return ALLOW_FILE_TYPES.includes(fileExt) ? null : error;
  };

  const onSubmit = async (files) => {
    const filesLength = files.length;
    if (!filesLength) return null;
    let error = null;
    for (let i = 0; i < filesLength; i += 1) {
      error = validateFileType(files[i]);
      if (error) break;
    }
    if (error) return showErrorMessage(`Invalid file format: ${error}`);
    setIsUploading(true);
    for (let i = 0; i < filesLength; i += 1) {
      const formData = new FormData();
      formData.append("file", files[i]);
      formData.append("jobId", jobId);
      formData.append("phase", phase);
      // eslint-disable-next-line no-await-in-loop
      const result = await uploadJobFilesAPI(formData).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
    }
    showSuccessMessage(translate("uploadSuccess"));
    setIsUploading(false);
    fetchMedia();
  };

  return (
    <Dialog
      open={stateOpen}
      maxWidth="md"
      classes={{ paper: styles.mediaModal }}
    >
      <Grid className={styles.modalHeader}>
        <Typography variant="h3">{translate("jobMedia")}</Typography>
        <IconButton
          onClick={() => setStateOpen(false)}
          className={styles.iconClose}
          aria-label="Close"
        >
          <CloseIcon />
        </IconButton>
        <Typography className="mt_5" variant="body1">
          {translate("jobMediaDescription")}
        </Typography>
      </Grid>

      <DialogContent className={styles.modalContent}>
        {isUploading ? (
          <Grid className={styles.uploadContainer}>
            <img src={uploadingGif} alt="upload_gif" />
            <Typography className="mt_10" align="center" variant="body1">
              {translate("Uploading")}
            </Typography>
          </Grid>
        ) : (
          <label className={styles.uploadContainer} htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={(e) => onSubmit(e.target.files)}
              multiple
            />
            <Grid container justify="center" alignItems="center">
              <img src={uploadIcon} alt="upload_icon" />
              {/* <Typography className="mt_10" align="center" variant="body1">
                {translate("Upload from your computer")}
              </Typography> */}
            </Grid>
          </label>
        )}

        {mediaList &&
          mediaList.map((detail, index) => (
            <ImageComponent
              key={index}
              detail={detail}
              setSelectedImageId={setSelectedImageId}
              selectedImageIds={selectedImageIds}
            />
          ))}
      </DialogContent>
      <DialogActions disableSpacing className={styles.modalFooter}>
        <Typography variant="body1">
          <Trans
            i18nKey="Job:itemSelected"
            values={{ number: selectedImageIds.length }}
          />
        </Typography>
        <Button
          color="secondary"
          disabled={selectedImageIds.length === 0}
          variant="contained"
          onClick={handleAddMediaToForm}
        >
          {translate("Common:done")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ImageComponent = ({ detail, setSelectedImageId, selectedImageIds }) => {
  const handleImageClick = () => {
    if (!selectedImageIds.includes(detail.id)) {
      return setSelectedImageId([...selectedImageIds, detail.id]);
    }
    const reduceSelectedList = selectedImageIds.filter(
      (imageId) => imageId !== detail.id
    );
    setSelectedImageId(reduceSelectedList);
  };

  return (
    <Grid onClick={handleImageClick} className={styles.imageContainer}>
      <img src={detail.mediaUrl} alt="media_img" />
      {selectedImageIds.includes(detail.id) && (
        <Checkbox
          classes={{ checked: styles.checkStyle }}
          className={styles.checkbox}
          checked
        />
      )}
    </Grid>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(JobMediaModal);
