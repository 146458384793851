const SET_SCREEN_IS_LOADING = 'SET_SCREEN_IS_LOADING';
const UNSET_SCREEN_IS_LOADING = 'UNSET_SCREEN_IS_LOADING';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_MODAL_BOX_VISIBILITY = 'SET_MODAL_BOX_VISIBILITY';
const SET_IS_LOADING = 'SET_IS_LOADING';
const TOGGLE_FILTER_SIDEBAR = 'TOGGLE_FILTER_SIDEBAR';
const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS';
const SET_IS_JOB_FILTERING = 'SET_IS_JOB_FILTERING';
const SET_IS_CALENDAR_FILTERING = 'SET_IS_CALENDAR_FILTERING';
const SET_CURRENT_STAFF_FILTER = 'SET_CURRENT_STAFF_FILTER';
const SET_IS_FULL_SIDEBAR = 'SET_IS_FULL_SIDEBAR';

const initialState = {
  screenIsLoading: false,
  errorMessage: null,
  modalBoxVisibility: false,
  isLoading: false,
  isOpenFilterSidebar: false,
  isFiltering: false,
  isCalendarFiltering: false,
  isJobFiltering: false,
  filterParams: {},
  currentStaffFilter: [],
  isFullSidebar: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SCREEN_IS_LOADING:
      return {
        ...state,
        screenIsLoading: true,
      };

    case UNSET_SCREEN_IS_LOADING:
      return {
        ...state,
        screenIsLoading: false,
      };

    case SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };

    case SET_MODAL_BOX_VISIBILITY:
      return {
        ...state,
        modalBoxVisibility: action.modalBoxVisibility,
        isLoading: false,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case TOGGLE_FILTER_SIDEBAR:
      return {
        ...state,
        isOpenFilterSidebar: action.state,
      };

    case SET_IS_JOB_FILTERING:
      return {
        ...state,
        isJobFiltering: action.state,
      };
    case SET_IS_CALENDAR_FILTERING:
      return {
        ...state,
        isCalendarFiltering: action.state,
      };

    case SET_FILTER_PARAMS:
      return {
        ...state,
        filterParams: action.params,
      };

    case SET_CURRENT_STAFF_FILTER:
      return {
        ...state,
        currentStaffFilter: action.data
      };

    case SET_IS_FULL_SIDEBAR:
      return {
        ...state,
        isFullSidebar: action.state
      };
    default:
      return state;
  }
}

export function setIsFullSidebar(state) {
  return { type: SET_IS_FULL_SIDEBAR, state };
}

export function setScreenIsLoading() {
  return { type: SET_SCREEN_IS_LOADING };
}

export function unsetScreenIsLoading() {
  return { type: UNSET_SCREEN_IS_LOADING };
}

export function setErrorMessage(errorMessage) {
  return { type: SET_ERROR_MESSAGE, errorMessage };
}

export function setModalBoxVisibility(modalBoxVisibility) {
  return { type: SET_MODAL_BOX_VISIBILITY, modalBoxVisibility };
}

export function setIsLoading(isLoading) {
  return { type: SET_IS_LOADING, isLoading };
}

export function toggleFilterSidebar(state) {
  return { type: TOGGLE_FILTER_SIDEBAR, state };
}

export function setIsCalendarFiltering(state) {
  return { type: SET_IS_CALENDAR_FILTERING, state };
}

export function setIsJobsFiltering(state) {
  return { type: SET_IS_JOB_FILTERING, state };
}

export function setFilterParams(params) {
  return { type: SET_FILTER_PARAMS, params };
}
export function recordFormToGGSheet(url, params = {}) {
  return async () => {
    // header name must match with GG sheet header name, event a space
    const query = Object.keys(params)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
    const link = url + query;
    const result = await fetch(link).catch((e) => ({ e }));

    if (result.e) return result.e;
    return false;
  };
}

export function setStaffFilter(data) {
  return { type: SET_CURRENT_STAFF_FILTER, data };
}