import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography } from '@material-ui/core';

// Component styles
import { QuoteToolbarStyles } from './styles';

// Common components
import { PopupMenu } from 'components/popups';
import { QUOTE_ALLOW_STATUSES } from 'common/constant';

const QuotesHeader = props => {
  const {
    classes,
    className,
    clientName,
    detailPage = false,
    handleClickNew,
    handleClickStatus,
    handleDownloadPdf,
    handlePrint,
    handleEmailQuote,
    handleSmsQuote,
    // handleCopyQuote,
    handleConvertToJob,
    listPage = false,
    match,
    quote,
    translate
  } = props;
  const rootClassName = classNames(classes.root, className);
  const crumbsText = clientName ? `/ Quote for ${clientName}` : '';
  const crumbsUrl = clientName ? `/quotes/${match.params.quoteId}` : '';

  const menuData = [];
  if (quote &&
    [QUOTE_ALLOW_STATUSES.APPROVED,
      QUOTE_ALLOW_STATUSES.AWAITING_RESPONSE,
      QUOTE_ALLOW_STATUSES.ACCEPTED,
      QUOTE_ALLOW_STATUSES.CONVERTED].includes(quote.status)) {
    menuData.push(
      {
        title: translate('sendAs'),
        menuItems: [
          {
            label: translate('Common:email'),
            action: handleEmailQuote,
            disabled: true
          },
          {
            label: translate('Common:sms'),
            action: handleSmsQuote,
          },
        ]
      },
    );
  }
  menuData.push(
    {
      menuItems: [
        {
          label: translate('downloadPdf'),
          action: handleDownloadPdf,
        },
        {
          label: translate('print'),
          action: handlePrint,
        },
        // {
        //   label: translate('copyQuoteTo'),
        //   action: handleCopyQuote,
        // },
        ...(quote &&
          quote.nextValidStatus &&
          quote.status !== QUOTE_ALLOW_STATUSES.ARCHIVED &&
          quote.nextValidStatus.indexOf(QUOTE_ALLOW_STATUSES.CONVERTED) >= 0 ?
          [{
            label: translate('convertToJob'),
            action: handleConvertToJob,
          }] : []),
      ]
    }
  );

  return (
    <div className={rootClassName}>
      {
        listPage ?
          <Grid container direction='row' justify='flex-end'>
            <Grid item>
              <Button
                color="primary"
                // size="large"
                variant="outlined"
                onClick={handleClickNew}
              >
                {translate('addQuote')}
              </Button>
            </Grid>
          </Grid>
          : <Grid container direction='row' justify='space-between'>
            <Grid item container direction='row' alignItems='center' md={4} sm={12}>
              <Typography variant='h5' className={classes.backTitle}>
                {translate('Common:backTo')}
              </Typography>
              <Link to='/quotes'>
                <Typography variant='h5' className={classes.backTo}>
                  {translate('quotes')}
                </Typography>
              </Link>
              {
                clientName &&
                <Link to={crumbsUrl}>
                  <Typography variant='h5' className={classes.backTo}>
                    {crumbsText}
                  </Typography>
                </Link>
              }
            </Grid>
            {
              detailPage &&
              <Grid item container direction='row' justify='flex-end' spacing={1} md={8} sm={12}>
                <Grid item>
                  <PopupMenu
                    menuData={menuData}
                    status={quote.statusId}
                    handleClickStatus={handleClickStatus}
                    btnTitle={translate('Common:moreActions')}
                    btnIcon
                  />
                </Grid>
              </Grid>
            }
          </Grid>
      }
    </div>
  );
};

QuotesHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

export default withStyles(QuoteToolbarStyles)(QuotesHeader);