import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { useReviewJobApi } from '../hook';
import CurrentVisit from '../../CurrentVisit';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from './ConfirmButtonDialog';
import { useDispatch, useSelector } from 'react-redux';
import { getButtonConfirmStatus,
  getConfirmReviewModalStatus, getContentModal, setOpenConfirmReviewModal, setOpenJob } from 'redux/jobVerification';
import { CREATE_VISIT_ONLY_STATUS } from '../constants';

export const ConfirmReviewModal = ({
  currentVisit,
  jobId,
  openNewVisitModal,
  refetchJobDetail,
  withLoading,
  afterSaveVisitDetails
}) => {
  const dispatch = useDispatch();
  const openModal = useSelector(getConfirmReviewModalStatus);
  const contentModal = useSelector(getContentModal);
  const buttonConfirmStatus = useSelector(getButtonConfirmStatus);
  const closeReviewModal = () => {
    dispatch(setOpenConfirmReviewModal(false));
  };
  const {
    completeReviewJob,
    cancelReviewJob,
    completeCurrentVisit,
    cancelCurrentVisit,
    completeAndDeleteAllVisits,
    cancelAndDeleteAllVisits,
    openJobOnly
  } = useReviewJobApi();
  const { t } = useTranslation('Job');
  const data = { jobId, currentVisitId: currentVisit?.id };
  const reloadAfterConfirm = () => Promise.all([
    closeReviewModal(),
    refetchJobDetail && refetchJobDetail(),
    afterSaveVisitDetails && afterSaveVisitDetails()
  ]);
  const confirmCompleted = async () => {
    return withLoading(
      completeReviewJob(data).then(() =>
        reloadAfterConfirm()
      ));
  };
  const confirmCanceled = async () => {
    return withLoading(
      cancelReviewJob(data).then(() =>
        reloadAfterConfirm()
      ));
  };
  const confirmCompletedCurrentVisit = async () => {
    return withLoading(
      completeCurrentVisit({ jobId }).then(() =>
        reloadAfterConfirm()
      )
    );
  };
  const confirmCancelCurrentVisit = async () => {
    return withLoading(
      cancelCurrentVisit({ jobId }).then(() =>
        reloadAfterConfirm()
      )
    );
  };
  const confirmOpenJobOnly = () => {
    return withLoading(
      openJobOnly({ jobId }).then(() =>
        reloadAfterConfirm()
      )
    );
  };
  const confirmCompleteAndDeleteAllVisits = () => {
    return withLoading(
      completeAndDeleteAllVisits({ jobId }).then(() =>
        reloadAfterConfirm()
      )
    );
  };
  const confirmCancelAndDeleteAllVisits = () => {
    return withLoading(
      cancelAndDeleteAllVisits({ jobId }).then(() =>
        reloadAfterConfirm()
      )
    );
  };
  const createVisitOnly = () => {
    dispatch(setOpenJob(CREATE_VISIT_ONLY_STATUS.CREATE_VISIT_ONLY));
    openNewVisitModal();
    closeReviewModal();
  };
  const openAndCreateVisit = () => {
    dispatch(setOpenJob(CREATE_VISIT_ONLY_STATUS.OPEN_AND_CREATE_VISIT));
    openNewVisitModal();
  };
  return (
    <Dialog open={openModal} onClose={closeReviewModal}>
      <DialogTitle
        className='title'
        style={{
          padding: '32px',
        }}
      >{contentModal.title}
      </DialogTitle>
      <DialogContent
        className='message'
        style={{
          paddingLeft: '32px',
          paddingRight: '32px',
        }}
      >
        { contentModal.isShowCurrentVisit && <CurrentVisit visit={currentVisit} /> }
        {contentModal.message}
      </DialogContent>
      <DialogActions
        className='modal-footer'
        style={{
          padding: '32px',
        }}
      >
        <Button onClick={closeReviewModal} variant="outlined">
          {t('close')}
        </Button>
        { contentModal.isShowCompleteDeleteAllVisits &&
        <Button onClick={confirmCompleteAndDeleteAllVisits} variant="outlined">
          {t('deleteAllVisit')}
        </Button>}
        { contentModal.isShowCancelDeleteAllVisits &&
        <Button onClick={confirmCancelAndDeleteAllVisits} variant="outlined">
          {t('deleteAllVisit')}
        </Button>}
        { contentModal.isShowOpenJobOnly &&
        <Button onClick={confirmOpenJobOnly} variant="outlined">
          {t('openJobOnly')}
        </Button>}
        { contentModal.isShowCreateVisitOnly &&
        <Button onClick={createVisitOnly} variant="outlined">
          {t('createVisitOnly')}
        </Button>}
        <ConfirmButton
          buttonConfirmStatus={buttonConfirmStatus}
          completeReviewJob={confirmCompleted}
          cancelReviewJob={confirmCanceled}
          completeCurrentVisit={confirmCompletedCurrentVisit}
          cancelCurrentVisit={confirmCancelCurrentVisit}
          openAndCreateVisit={openAndCreateVisit}
          confirmOpenJobOnly={confirmOpenJobOnly}
        />
      </DialogActions>
    </Dialog>
  );
};