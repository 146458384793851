import React from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Component styles
const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  headerLogo: {
    textAlign: 'center',
  },
  wmLogo: {
    maxWidth: 116,
  },
  content: {
    // marginTop: '150px',
    textAlign: 'center'
  },
  image: {
    // display: 'inline-block',
    marginTop: '50px',
    maxWidth: '100%',
    width: '554px'
  },
  oops: {
    color: theme.palette.text.secondary,
    fontSize: 30,
  },
  pageNotFound: {
    color: theme.palette.text.secondary,
    fontSize: 20,
    marginTop: 25,
    marginBottom: 25,
  },
});

const notFoundImage = require('assets/images/not_found.png');
const wmLogo = require('assets/images/logo/logo.png');


const NotFound = props => {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Grid item className={classes.headerLogo} container direction="row" justify="space-between">
        <Grid item>
          <Link to="/" className={classes.logoCover}>
            <img src={wmLogo} className={classes.wmLogo} alt="WorkMagic" />
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <img
            alt="Under development"
            className={classes.image}
            src={notFoundImage}
          />
        </Grid>
        <Grid item container direction="column" justify="space-between" alignItems="center">
          <div className={classes.content}>
            <Typography className={classes.oops}>
              OOPS!
            </Typography>
            <Typography className={classes.pageNotFound}>
              The page you requested could not be found!
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

NotFound.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
