import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography } from '@material-ui/core';

// Shared components
import { PopupMenu } from 'components/popups';

// Component styles
import { JobsToolbarStyles } from './styles';

function JobsToolbar(props) {
  const {
    allowedStatuses,
    classes,
    className,
    clientName,
    detailPage = false,
    handleClickNew,
    handleClickStatus,
    handleGenerateInvoice,
    handleGenerateServiceReport,
    job,
    jobStatuses,
    listPage = false,
    match,
    translate,
    isShowingAccountingFeature
  } = props;

  const rootClassName = classNames(classes.root, className);

  const crumbsText = clientName ? `/ Job for ${clientName}` : '';
  const crumbsUrl = clientName ? `/jobs/${match.params.jobId}` : '';

  const menuData = [
    {
      title: translate('Common:markAs'),
      status: jobStatuses,
      allowedStatuses,
    },
    {
      title: translate('generateFrom'),
      menuItems: [
        // {
        //   label: translate('job'),
        //   action: () => handleGenerateServiceReport('job'),
        //   padding: true
        // },
        {
          label: translate('visit'),
          action: () => handleGenerateServiceReport('visit'),
          padding: true
        },
      ],
    },
  ];

  if (isShowingAccountingFeature) {
    menuData.splice(1, 0, {
      menuItems: [
        {
          label: translate('generateInvoice'),
          action: handleGenerateInvoice,
          disabled: !((job || {}).permissions || {}).ableToCreateInvoice,
        },
      ],
    });
  }

  return (
    <div className={rootClassName}>
      {listPage ? (
        <Grid container direction="row" justify="flex-end">
          <Grid item>
            <Button
              color="primary"
              // size="large"
              variant="outlined"
              onClick={handleClickNew}
            >
              {translate('addInvoice')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="row" justify="space-between">
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            md={4}
            sm={12}
          >
            <Typography variant="h5" className={classes.backTitle}>
              {translate('Common:backTo')}
            </Typography>
            <Link to="/jobs">
              <Typography variant="h5" className={classes.backTo}>
                {translate('jobs')}
              </Typography>
            </Link>
            {clientName && (
              <Link to={crumbsUrl}>
                <Typography variant="h5" className={classes.backTo}>
                  {crumbsText}
                </Typography>
              </Link>
            )}
          </Grid>
          {detailPage && (
            <Grid
              item
              container
              direction="row"
              justify="flex-end"
              spacing={1}
              md={8}
              sm={12}
            >
              <Grid item>
                <PopupMenu
                  menuData={menuData}
                  status={job.statusId}
                  handleClickStatus={handleClickStatus}
                  btnTitle={translate('Common:moreActions')}
                  btnIcon
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
}

JobsToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
};

export default withStyles(JobsToolbarStyles)(JobsToolbar);
