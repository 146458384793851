import React from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import { getDateTimeFormat } from "lib/formatter";

const mapStateToProps = (state, ownProps) => {
  const { jobDetails = {} } = ownProps;
  const {
    client = {},
    jobSchedule = {},
    property = {},
    statusDetail = {},
  } = jobDetails;

  const currentVisit =
    jobDetails.visits &&
    jobDetails.visits.find((visit) => visit.id === statusDetail.currentVisitId);
  return {
    initialValues: {
      name: client.displayName,
      contactPhone: client.phoneNumber,
      contactEmail: client.email,
      address: property.address,
      jobId: jobDetails.publicId,
      jobTitle: jobDetails.title,
      dateCreated:
        jobDetails.createdAt &&
        moment(jobDetails.createdAt).format(getDateTimeFormat()),
      scheduleStart:
        jobSchedule.start &&
        moment(jobSchedule.start).format(getDateTimeFormat()),
      scheduleEnd:
        jobSchedule.end && moment(jobSchedule.end).format(getDateTimeFormat()),
      location: property.address,
      jobDescription: jobDetails.description,
      groupStatusFilter: jobDetails.groupStatusFilter,
      currentVisit,
    },
    jobId: jobDetails.publicId,
  };
};

const JobDetailsWrapper = ({ children }) => <>{children}</>;

export default compose(
  connect(mapStateToProps, null),
  reduxForm({
    form: "GeneralInfoForm",
    enableReinitialize: true,
  })
)(JobDetailsWrapper);
