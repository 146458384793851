import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

import { locales } from 'locales';
import { connect, useDispatch } from 'react-redux';
import { recordFormToGGSheet } from 'redux/global';
import { reset } from 'redux-form';
import { Trans } from 'react-i18next';

// components
import { Grid, Typography, Button, useMediaQuery } from '@material-ui/core';
import {
  Header,
  Footer,
  FormSupport,
  Accordion,
  StackCard,
  AboutUs,
} from './components';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';
// style
import './index.scss';
import bgSrc from 'assets/images/landing-page/layer_bg.png';
import capitalSrc from 'assets/images/landing-page/capitaland.svg';
import echoSrc from 'assets/images/landing-page/echo_world.svg';
import mqdcSrc from 'assets/images/landing-page/mqdc.svg';
import kimberlySrc from 'assets/images/landing-page/kimberly_clark.svg';
import samsungSrc from 'assets/images/landing-page/samsung.svg';
import senhenSrc from 'assets/images/landing-page/senheng.svg';

import whyWM1 from 'assets/images/landing-page/outsource_operation.svg';
import whyWM2 from 'assets/images/landing-page/complete_service.svg';
import whyWM3 from 'assets/images/landing-page/fully_flexible.svg';
import whyWM4 from 'assets/images/landing-page/labour_management.svg';

import leftSide from 'assets/images/landing-page/left-workside.png';
import lowerCenter from 'assets/images/landing-page/lower-center-workside.png';
import lowerRight from 'assets/images/landing-page/lower-right-workside.png';
import upperCenter from 'assets/images/landing-page/upper-center-workside.png';
import upperRight from 'assets/images/landing-page/upper-right-workside.png';

const sheetUrl =
  'https://script.google.com/macros/s/AKfycbzRu842ce78VGln-e9aEoDU2wvmUX0kpYW77jVAbn_vE0-nG3OOaqmVlav2pCObQ05sKw/exec?';

const NewLandingPage = ({ t, isSignIn }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const dispatch = useDispatch();
  const caseStudyRef = useRef();
  const formRef = useRef();

  const scrollToForm = () =>
    formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const scrollToCaseStudy = () =>
    caseStudyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  useEffect(() => {
    locales.setLanguage('en');
    const hash = window.location.hash.substring(1);
    if (hash && hash.length && hash === 'register') {
      setTimeout(() => scrollToForm(), 500);
    }
  }, []);

  const handleSubmitForm = async (values) => {
    let params = {
      'Contact Name': values.contactName,
      'Business Name': values.businessName,
      Email: values.email,
      'Phone Number': values.phoneNumber,
      Country: values.country,
      City: values.city,
    };

    let { labours, additionalLabours } = values;
    if (additionalLabours) labours = [...labours, additionalLabours];
    params = { ...params, 'Type of Labour Needed': labours };

    const errorMessage = await dispatch(recordFormToGGSheet(sheetUrl, params));
    if (errorMessage) return showErrorMessage(errorMessage);
    showSuccessMessage('success');
    dispatch(reset('supportForm'));
  };

  return (
    <Grid className="landing_page">
      <Grid className="header_wrapper">
        <Header translate={t} isSignIn={isSignIn} />
      </Grid>

      {/* body */}
      <Grid className="relative_container layer_background">
        <Grid className="container">
          <Grid className="title_wrapper col-12 col-md-7 p-0">
            <Typography variant="h2" className="white_text font_bold">
              {t('flexibleSkill')} <br />
              <Trans
                i18nKey="LandingPage:blueCollar"
                components={{ br: <br /> }}
              />
            </Typography>
            <Typography variant="body2" className="header_content">
              {t('integratable')}
            </Typography>
          </Grid>

          <Grid className="group_btn d-flex">
            <Button
              onClick={scrollToForm}
              className="btn yellow_bg font_bold mr_15 get_started_cta"
            >
              {t('getStartedNow')}
            </Button>
            <Button
              onClick={scrollToCaseStudy}
              className="btn outline_btn font_bold white_text font_bold case_studies_cta"
            >
              {t('caseStudies')}
            </Button>
          </Grid>

          <Grid className="d-none d-md-block trust_by_wrapper mt_30 pb_70">
            <Typography className="title_text" variant="subtitle2">
              {t('trustedBy')}
            </Typography>
            <Grid className="mt_15 d-flex flex-wrap">
              <img className="mr_10vw" src={samsungSrc} alt="img_capital" />
              <img className="col-4" src={kimberlySrc} alt="img_kimberly" />
              <img className="col-4" src={echoSrc} alt="img_echo" />
              <img className="mr_10vw mt_10" src={mqdcSrc} alt="img_mqdc" />
              <img className="col-4 mt_10" src={senhenSrc} alt="img_senheng" />
              <img className="col-4 mt_10" src={capitalSrc} alt="img_sam" />
            </Grid>
          </Grid>
        </Grid>
        {/* side background */}
        <Grid className="floating_container">
          <LazyLoad>
            <img className="floating_img" src={bgSrc} alt="img" />
          </LazyLoad>
        </Grid>

        <Grid className="d-block d-md-none trust_by_wrapper container mt_30">
          <Typography className="title_text font_bold" variant="subtitle1">
            {t('trustedBy')}
          </Typography>
          <Grid className="mt_15 d-flex justify-content-between flex-wrap">
            <img className="pl_0 col-6" src={samsungSrc} alt="img_capital" />
            <img className="col-6 pr_0" src={kimberlySrc} alt="img_kimberly" />
            <img className="pl_0 col-6" src={echoSrc} alt="img_echo" />
            <img className="col-6 mt_10 pr_0" src={mqdcSrc} alt="img_gamuda" />
            <img
              className="pl_0 col-6 mt_10"
              src={senhenSrc}
              alt="img_senheng"
            />
            <img className="col-6 mt_10 pr_0" src={capitalSrc} alt="img_sam" />
          </Grid>
        </Grid>
      </Grid>

      {/* flexible workforce */}
      <Grid className="workforce_wrapper">
        <Grid className="container">
          <Grid className="row">
            <Accordion t={t} />
          </Grid>
        </Grid>
      </Grid>

      {/* why workmagic */}
      <Grid className="why_wm text_left">
        <Grid className="container">
          <Typography variant="body1" className="title_why_wm font_bold">
            {t('whyChooseWM')}
          </Typography>
          <Typography variant="h1" className="title_why_wm font_bold mt_10">
            {t('letSimplify')}
          </Typography>

          <Grid className="mt_60 row">
            <Grid className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start">
              <img src={whyWM1} alt="why_wm1" />
              <Grid className="ml_10 mt-2 mt-md-0">
                <Typography variant="body1" className="font_bold mb-2">
                  {t('outsource')}
                </Typography>
                <Typography variant="body1" className="black_text">
                  {t('outsourceDescription')}
                </Typography>
              </Grid>
            </Grid>
            <Grid className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start mt-5 mt-md-0">
              <img src={whyWM2} alt="why_wm2" />
              <Grid className="ml_10 mt-2 mt-md-0">
                <Typography variant="body1" className="font_bold mb-2">
                  {t('completeService')}
                </Typography>
                <Typography variant="body1" className="black_text">
                  {t('completeServiceDescription')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="mt_40 row">
            <Grid className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start mt-5 mt-md-0">
              <img src={whyWM3} alt="why_wm3" />
              <Grid className="ml_10 mt-2 mt-md-0">
                <Typography variant="body1" className="font_bold mb-2">
                  {t('flexibleTeam')}
                </Typography>
                <Typography variant="body1" className="black_text">
                  {t('flexibleTeamDescription')}
                </Typography>
              </Grid>
            </Grid>
            <Grid className="col-12 col-md-6 d-flex flex-column flex-md-row align-items-start mt-5 mt-md-0">
              <img src={whyWM4} alt="why_wm4" />
              <Grid className="ml_10 mt-2 mt-md-0">
                <Typography variant="body1" className="font_bold mb-2">
                  {t('labourManagement')}
                </Typography>
                <Typography variant="body1" className="black_text">
                  {t('labourManagementDescription')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* case_study */}
      <Grid ref={caseStudyRef} className="case_study">
        <Grid className="mb-5 container">
          <Typography
            variant="h2"
            className="white_text text-left text-md-center"
          >
            {t('ourTeam')}
          </Typography>

          <Grid className="d-block d-md-none mb-3 mt-4">
            <LazyLoad>
              <img src={leftSide} alt="left_side_worker_img" />
            </LazyLoad>
          </Grid>

          <Grid className="mt-0 mt-md-5 d-flex justify-content-between">
            <Grid className="d-none d-md-block">
              <LazyLoad>
                <img src={leftSide} alt="left_side_worker_img" />
              </LazyLoad>
            </Grid>
            <Grid className="d-flex flex-column marginHorizontal">
              <Grid style={{ flex: 1 }}>
                <LazyLoad>
                  <img src={upperCenter} alt="center-upper" />
                </LazyLoad>
              </Grid>
              <Grid
                style={{ flex: 1 }}
                className="d-flex align-items-end mt-3 mt-md-0"
              >
                <LazyLoad>
                  <img src={lowerCenter} alt="center-lower" />
                </LazyLoad>
              </Grid>
            </Grid>
            <Grid className="d-flex flex-column">
              <Grid style={{ flex: 1 }}>
                <LazyLoad>
                  <img src={upperRight} alt="right-upper" />
                </LazyLoad>
              </Grid>
              <Grid
                style={{ flex: 1 }}
                className="d-flex align-items-end mt-3 mt-md-0"
              >
                <LazyLoad>
                  <img src={lowerRight} alt="right-lower" />
                </LazyLoad>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <StackCard t={t} />

        {/* form */}
        <Grid
          ref={formRef}
          className="container mt_40 d-flex flex-column flex-md-row"
        >
          <Grid className="col-12 col-md-3 pl_0 text-center text-md-left justify-content-center d-flex my-4 my-md-0">
            <Typography
              variant={isMobile ? 'h1' : 'h3'}
              className="white_text col-10 col-md-12"
            >
              {t('letUsSupport')}
            </Typography>
          </Grid>

          <Grid className="col-12 col-md-9 p_0">
            <Grid className="card form_wrapper">
              <FormSupport t={t} onSubmit={handleSubmitForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* about us */}
      <AboutUs t={t} />
      {/* footer */}
      <LazyLoad>
        <Footer t={t} />
      </LazyLoad>

      <Grid className="copy_right">
        <Typography className="text-center">
          <span className="mr_30 d-block d-md-inline">{t('copyRight')}</span>
          <span>{t('registration')}</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  isSignIn: state.auth.isSignIn,
});

export default connect(mapStateToProps, null)(withRouter(NewLandingPage));
