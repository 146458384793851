import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { signOutUser } from 'api/authenticationApi';

// Material helpers and components
import {
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  ClickAwayListener,
  Paper,
  MenuItem,
  MenuList,
  Avatar,
  withStyles
} from '@material-ui/core';


// Material icons
import {
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons';
// Component styles
import styles from './styles';
import './index.scss';

import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';

// asset images
const wmLogo = require('assets/images/logo/wm-logo-horizontal.svg');
const backIcon = require('assets/images/icons/chevron-left.svg');

class Topbar extends Component {
  signal = true;

  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      notificationsLimit: 4,
      notificationsCount: 0,
      notificationsEl: null,
      openDropdownMenu: false,
    };
  }

  componentDidMount() {
    this.signal = true;
  }

  componentWillUnmount() {
    this.signal = false;
  }

  handleSignOut = () => {
    const { history, currentUser } = this.props;

    signOutUser();
    let companyTag = (currentUser &&
      currentUser.companies &&
      currentUser.companies[0] &&
      currentUser.companies[0].tag) || '';
    companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();

    if (companyTag === 'disinfection2u') {
      return history.push('/disinfection2u-log-in');
    }
    history.push('/log-in');
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  handleClick = () => {
    this.setState(state => ({
      openDropdownMenu: !state.openDropdownMenu,
    }));
  };

  handleClickAway = () => {
    this.setState({
      openDropdownMenu: false,
    });
  };

  handleClose = () => {
    this.setState({
      openDropdownMenu: false,
    });
  }

  render() {
    const {
      classes,
      className,
      currentUser,
      translate,
      backUrl
    } = this.props;

    const { openDropdownMenu } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <>
        <Grid className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <Grid container direction="row" justify="space-between">
              <Grid item container direction="row" alignItems="center" md={6} sm={6}>
                <Grid item>
                  <Grid className={classes.logoWrapper}>
                    <Link
                      to={backUrl}
                    >
                      <img
                        alt="Back icon"
                        src={backIcon}
                      />
                    </Link>
                    <Link
                      className={classes.logoLink}
                      to="/calendar"
                    >
                      <img
                        alt="WM logo"
                        className={classes.image}
                        src={wmLogo}
                      />
                    </Link>
                  </Grid>
                </Grid>


              </Grid>



              <Grid item container direction="row" justify="flex-end" alignItems="center" md={6} sm={6}>

                <Grid item>
                  <ClickAwayListener onClickAway={this.handleClickAway}>
                    <Grid className={classNames(classes.downdownMenu)}>
                      <Tooltip title={translate('Common:settings')}>
                        <IconButton
                          className={classes.signOutButton}
                          onClick={this.handleClick}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </Tooltip>
                      {openDropdownMenu ? (
                        <Paper className={classes.paper}>
                          <Grid className="dropdown-header">
                            <Grid className={classes.menuForUserInfo}>
                              <Grid className={classes.userAvatar}>
                                <Avatar className={classes.avatar} src={(currentUser || {}).avatar || StaffDefaultAvatar} />
                              </Grid>
                              <Grid className={classes.userInfo}>
                                <span className="username">
                                  { currentUser.fullName }
                                </span>
                                <span className="email">{currentUser.email}</span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <MenuList className={classes.menuList}>
                            <MenuItem onClick={this.handleSignOut}>Logout</MenuItem>
                          </MenuList>
                        </Paper>
                      ) : null}
                    </Grid>
                  </ClickAwayListener>
                </Grid>
              </Grid>
            </Grid>

          </Toolbar>
        </Grid>

      </>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser
});

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withStyles(styles)
)(Topbar);
