import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import {
  Grid,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import TextInput from 'components/inputs/TextInput';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import styles from './D2uLoginForm.module.scss';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import { EMAIL_VALIDATION } from 'common/constant';

const imageBg = require('assets/images/d2u_worker.svg');
const imageBgMb = require('assets/images/d2u_worker_mb.svg');
const d2uLogo = require('assets/images/Disinfection-logo.svg');
const wmLogo = require('assets/images/logo/wm-logo-horizontal.svg');

const mapStateToProps = () => ({
  initialValues: {
    email: null,
    password: null,
  },
});

const validate = (values) => {
  const errors = { message: {} };
  const requiredFields = ['email', 'password'];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'required';
      errors.message[field] = `${field}Required`;
    }
  });

  if (
    values.email &&
    !EMAIL_VALIDATION.test(values.email)
  ) {
    errors.email = 'invalidEmail';
    errors.message.email = 'invalidEmail';
  }

  return errors;
};

let LogInPage = (props) => {
  const { pristine, invalid, submitting, handleSubmit, translate } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  return (
    // <Container className={styles.appContainer}>
    <Grid
      container
      item
      wrap="wrap"
      justify="center"
      spacing={0}
      className={styles.logInContentContainer}
    >
      <Grid item xs={11} md={6} className={styles.leftPane}>
        <Grid
          className={styles.formContainer}
          container
          alignItems="center"
          direction="column"
          justify="space-around"
        >
          <Grid className={styles.w_50}>
            <MediaQuery query="(min-device-width: 768px)">
              <Grid
                item
                className={classNames('header-logo', styles.headerLogo)}
                container
                direction="row"
              >
                <Grid item>
                  <img
                    style={{ minHeight: 100, minWidth: 240 }}
                    src={d2uLogo}
                    alt="WorkMagic"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography className={styles.signInTitle}>
                  {translate('Common:signin')}
                </Typography>
              </Grid>
            </MediaQuery>
            <MediaQuery query="(max-device-width: 768px)">
              <Grid
                item
                className={classNames('header-logo', styles.headerLogo)}
                container
                justify='center'
                direction="row"
              >
                <Grid item>
                  <img
                    src={wmLogo}
                    alt="WorkMagic"
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Typography className={styles.signInTitle}>
                  {translate('Common:signin')}
                </Typography>
              </Grid>
            </MediaQuery>
            <Grid item className={styles.signInForm}>
              <form onSubmit={handleSubmit}>
                <div>
                  <Field
                    name="email"
                    component={TextInput}
                    label={translate('Common:email')}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
                <div>
                  <Field
                    name="password"
                    component={TextInput}
                    label={translate('Common:password')}
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            aria-label="Toggle password visibility"
                            onClick={handleClickShowPassword}
                            tabIndex={-1}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      inputProps: { minLength: 8 },
                    }}
                  />
                  <div className={styles.forgotPwWraper}>
                    <Link className={styles.forgotPwLink} to="/forgot">
                      {translate('forgotPassword')}
                    </Link>
                  </div>
                </div>
                <div className={styles.signInButton}>
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    className={styles.btnStyle}
                    disabled={pristine || submitting || invalid}
                  >
                    <span className={styles.btnLabel}>
                      {translate('Common:login')}
                    </span>
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
          <MediaQuery query="(min-device-width: 768px)">
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classNames(styles.w_50, styles.poweredBy)}
            >
              <Typography variant="h6" className={styles.teamDialogTitleText}>
                {translate('Common:poweredBy')}
              </Typography>
              <img src={wmLogo} alt={translate('Common:WM')} />
            </Grid>
          </MediaQuery>
        </Grid>
      </Grid>
      <MediaQuery query="(min-device-width: 768px)">
        <Grid item md={6} className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBg}
              className={styles.cleanerImage}
              alt={translate('Common:WM')}
            />
          </div>
        </Grid>
      </MediaQuery>
      <MediaQuery query="(max-device-width: 768px)">
        <Grid item className={styles.rightPane}>
          <div className={styles.imageContainer}>
            <img
              src={imageBgMb}
              className={styles.cleanerImage}
              alt={translate('Common:WM')}
            />
          </div>
        </Grid>
      </MediaQuery>
    </Grid>
    // </Container>
  );
};

LogInPage = reduxForm({
  form: 'authForm',
  validate,
})(LogInPage);

export default connect(mapStateToProps, null)(LogInPage);
