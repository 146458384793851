import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Grid,
  IconButton,
  Divider,
} from '@material-ui/core';
import styles from './styles/ServiceReportToolbar.module.scss';
import { Edit, Visibility } from '@material-ui/icons';
import { useTranslation, Trans } from 'react-i18next';
import { PopupMenu } from 'components/popups';
import { submit, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCurrentView, downloadPdf } from 'redux/job';
import { SendReportModal, ConfirmArchiveReportModal } from '../modals';
import { SERVICE_REPORT_FORM_NAME } from 'common/constant';

import CustomizedPopupMenu from './CustomizedPopupMenu';
import printIcon from 'assets/images/service-report/print.svg';
import deleteIcon from 'assets/images/service-report/delete.svg';
import downloadIcon from 'assets/images/service-report/download.svg';
import draftIcon from 'assets/images/service-report/draft.svg';
import triangleIcon from 'assets/images/service-report/triangle.svg';
import sendIcon from 'assets/images/service-report/send.svg';
import backIcon from 'assets/images/service-report/arrowBack.svg';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';

const ServiceReportToolbar = (props) => {
  const {
    isFromNewServiceReport,
    history,
    fromEditPage,
    handlePrint,
    match: {
      params
    }
  } = props;
  const { t: translate } = useTranslation('Job');
  const dispatch = useDispatch();
  const isViewServiceReportMode = useSelector(
    (state) => state.job.isViewServiceReportMode
  );
  const reportDetails = useSelector((state) => state.job.reportDetails) || {};
  const [currentReportType, setCurrentReportType] = useState('FULL');
  const [isOpenSendToClientModal, setIsOpenModal] = useState(false);
  const [isSaveAndSendAction, setIsSaveAndSendAction] = useState(false);
  const [isOpenArchiveReportModal, setIsOpenArchiveReportModal] = useState(
    false
  );
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setCurrentReportType(reportDetails.reportType || 'FULL');
  }, [reportDetails.reportType]);

  const submitRemoteForm = () => {
    dispatch(submit(SERVICE_REPORT_FORM_NAME));
  };

  const handleSaveAndSend = () => {
    setIsSaveAndSendAction(true);
    setIsOpenModal(true);
  };

  const handleSaveAsDraft = () => {
    setIsSaveAndSendAction(false);
    submitRemoteForm();
  };

  const menuData = [
    {
      menuItems: [
        {
          label: fromEditPage
            ? translate('Common:save')
            : translate('saveAsDraft'),
          action: () => handleSaveAsDraft(),
          iconSrc: draftIcon,
        },
      ],
    },
    {
      menuItems: [
        {
          label: translate('saveAndSend'),
          action: () => handleSaveAndSend(),
          iconSrc: triangleIcon,
        },
      ],
    },
  ];

  const changeReportType = async (value) => {
    await dispatch(change(SERVICE_REPORT_FORM_NAME, 'reportType', value));
    setCurrentReportType(value);
  };

  const reportTypes = [
    {
      menuItems: [
        {
          label: translate('full'),
          description: translate('fullDescription'),
          action: () => changeReportType('FULL'),
        },
      ],
    },
    {
      menuItems: [
        {
          label: translate('pictorial'),
          description: translate('pictorialDescription'),
          action: () => changeReportType('PICTORIAL'),
        },
      ],
    },
  ];

  const handleChangeMode = () => {
    dispatch(setCurrentView(!isViewServiceReportMode));
    history.replace(isViewServiceReportMode ? 'edit' : 'view');
  };

  const handleDownloadPdf = async () => {
    showSuccessMessage(translate('Job:weAreGeneratingReport'));
    setIsDownloading(true);
    const result = await dispatch(downloadPdf(reportDetails.id)).catch((e) => ({
      e,
    }));
    setIsDownloading(false);
    if (result.e) return showErrorMessage(result.e);
    window.open((result.data.fileUrl || result.data.pdfFile), '_blank');
  };

  return (
    <AppBar position="static" classes={{ root: styles.appBar }}>
      <SendReportModal
        isSaveAndSendAction={isSaveAndSendAction}
        reportDetails={reportDetails}
        isOpenSendToClientModal={isOpenSendToClientModal}
        setIsOpenModal={setIsOpenModal}
        submitRemoteForm={submitRemoteForm}
      />
      <ConfirmArchiveReportModal
        reportDetails={reportDetails}
        isOpenArchiveReportModal={isOpenArchiveReportModal}
        setIsOpenArchiveReportModal={setIsOpenArchiveReportModal}
      />
      <Toolbar
        className={
          !isFromNewServiceReport ? styles.space_between : styles.flex_end
        }
      >
        {!isFromNewServiceReport && (
          <>
            <Grid className={styles.dp_flex}>
              {isViewServiceReportMode && (
                <>
                  <Button
                    onClick={() => params.jobId ? history.push(`/jobs/${params.jobId}`) : history.goBack()}
                    className={styles.backBtn}
                  >
                    <img src={backIcon} alt="back_icon" />
                    <Typography color="inherit" variant="h5">
                      {translate('Common:back')}
                    </Typography>
                  </Button>
                  <Divider orientation="vertical" className={styles.divider} />
                </>
              )}

              <Grid className={styles.dp_flex}>
                {isViewServiceReportMode ? (
                  <Visibility className={`${styles.defaultIconColor} mr_5`} />
                ) : (
                  <Edit className={`${styles.defaultIconColor} mr_5`} />
                )}

                <Typography variant="body1">
                  {isViewServiceReportMode
                    ? translate('viewMode')
                    : translate('editMode')}
                </Typography>
              </Grid>
              <Button
                onClick={handleChangeMode}
                className="ml_10"
                variant="outlined"
                color="primary"
              >
                {isViewServiceReportMode ? (
                  <Edit className={`${styles.actionIcon} mr_5`} />
                ) : (
                  <Visibility className={`${styles.actionIcon} mr_5`} />
                )}

                <Typography color="inherit" variant="h6">
                  {isViewServiceReportMode
                    ? translate('edit')
                    : translate('view')}
                </Typography>
              </Button>
            </Grid>
          </>
        )}
        <Grid className={styles.dp_flex}>
          <Grid className="mr_15">
            {isViewServiceReportMode && !isFromNewServiceReport ? (
              <Typography>
                <Trans
                  i18nKey="Job:reportType"
                  values={{ type: reportDetails.reportType }}
                />
              </Typography>
            ) : (
              <CustomizedPopupMenu
                menuData={reportTypes}
                btnTitle={translate('Common:save')}
                reportType={currentReportType}
              />
            )}
          </Grid>
          {!isFromNewServiceReport && isViewServiceReportMode && (
            <>
              <Grid
                className={`${styles.dp_flex} ${styles.borderLeft} pl_15 pr_15`}
              >
                <IconButton onClick={() => setIsOpenArchiveReportModal(true)}>
                  <img src={deleteIcon} alt="delete_image" />
                </IconButton>
                <IconButton disabled={isDownloading} onClick={handleDownloadPdf}>
                  <img src={downloadIcon} alt="download_image" />
                </IconButton>
                <IconButton onClick={handlePrint}>
                  <img src={printIcon} alt="print_image" />
                </IconButton>
              </Grid>
              {isViewServiceReportMode && (
                <Button
                  onClick={() => setIsOpenModal(true)}
                  className={styles.sendBtn}
                >
                  <img src={sendIcon} alt="send_icon" className="mr_5" />
                  {translate('Common:send')}
                </Button>
              )}
            </>
          )}

          {(!isViewServiceReportMode || isFromNewServiceReport) && (
            <Grid className={`${styles.dp_flex} ${styles.borderLeft}`}>
              <Button
                onClick={() => history.goBack()}
                className={`${styles.actionIcon} ml_10 mr_10`}
              >
                {translate('Common:discard')}
              </Button>
              <PopupMenu
                btnCustomizeStyle={styles.btnSave}
                menuData={menuData}
                btnTitle={translate('Common:save')}
                btnIcon
              />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(ServiceReportToolbar);
