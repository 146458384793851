import React, { useState, useEffect } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useMediaQuery,
} from '@material-ui/core';
import flexibleSrc from 'assets/images/landing-page/flexible.png';
import managementSrc from 'assets/images/landing-page/management.png';
import outsourceSrc from 'assets/images/landing-page/outsource.png';
import billingSrc from 'assets/images/landing-page/billing.png';
import LazyLoad from 'react-lazyload';

const srcList = [flexibleSrc, managementSrc, outsourceSrc, billingSrc];

const ControlAccordion = ({ t }) => {
  const [selectedValue, setSelectedValue] = useState(1);
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleChange = (number) => {
    setSelectedValue(number);
  };

  useEffect(() => {
    if (isMobile) return setSelectedValue(-1);
    const timer = setInterval(() => {
      return setSelectedValue(selectedValue === 4 ? 1 : selectedValue + 1);
    }, 4000);
    return () => clearInterval(timer);
  }, [isMobile, selectedValue]);

  return (
    <>
      <Grid className="col-12 col-md-5 text-left d-block d-md-flex justify-content-center flex-column">
        <img
          className="d-block d-md-none mt_20"
          src={flexibleSrc}
          alt="workforce"
        />
        <Accordion
          className="accordion_wrapper"
          expanded={selectedValue === 1 || isMobile}
          onChange={() => handleChange(1)}
        >
          <AccordionSummary>
            {selectedValue === 1 && !isMobile && (
              <Grid className="active_style" />
            )}
            <Typography
              className={selectedValue === 1 ? 'white_text' : 'gray_text'}
              variant="h3"
            >
              {t('flexibleWorkforce')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="gray_text" variant="body1">
              {t('flexibleWorkforceDescription')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <img
          className="d-block d-md-none mt_20"
          src={managementSrc}
          alt="workforce"
        />
        <Accordion
          className="accordion_wrapper"
          expanded={selectedValue === 2 || isMobile}
          onChange={() => handleChange(2)}
        >
          <AccordionSummary>
            {selectedValue === 2 && !isMobile && (
              <Grid className="active_style" />
            )}
            <Typography
              className={selectedValue === 2 ? 'white_text' : 'gray_text'}
              variant="h3"
            >
              {t('workerManagement')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="white_text" variant="body1">
              {t('workerManagementDescription')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <img
          className="d-block d-md-none mt_20"
          src={outsourceSrc}
          alt="workforce"
        />
        <Accordion
          className="accordion_wrapper"
          expanded={selectedValue === 3 || isMobile}
          onChange={() => handleChange(3)}
        >
          <AccordionSummary>
            {selectedValue === 3 && !isMobile && (
              <Grid className="active_style" />
            )}
            <Typography
              className={selectedValue === 3 ? 'white_text' : 'gray_text'}
              variant="h3"
            >
              {t('outsourceOperation')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="white_text" variant="body1">
              {t('outsourceOperationDescription')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <img
          className="d-block d-md-none mt_20"
          src={billingSrc}
          alt="workforce"
        />
        <Accordion
          className="accordion_wrapper"
          expanded={selectedValue === 4 || isMobile}
          onChange={() => handleChange(4)}
        >
          <AccordionSummary>
            {selectedValue === 4 && !isMobile && (
              <Grid className="active_style" />
            )}
            <Typography
              className={selectedValue === 4 ? 'white_text' : 'gray_text'}
              variant="h3"
            >
              {t('easyBilling')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="white_text" variant="body1">
              {t('easyBillingDescription')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid className="d-none d-md-block col-7">
        <LazyLoad>
          <img src={srcList[selectedValue - 1] || flexibleSrc} alt="temp_img" />
        </LazyLoad>
      </Grid>
    </>
  );
};

export default ControlAccordion;
