import React from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { reset } from 'redux-form';

import { Header, Footer } from 'components/publicComponents';
import ContactForm from './components/ContactForm';
import { POST } from 'lib/api';
import { showErrorMessage, showSuccessMessage } from 'lib/notifier';


const ContactPage = props => {
  const { geoCountry, resetContactForm, t } = props;

  function submitContactUs(values) {
    POST('/enquiries', null, { data: values })
      .then(response => {
        if (response.status === 200) {
          showSuccessMessage(response.data.message);
          resetContactForm();
        }
      }, error => {
        showErrorMessage(error);
      });
  }
  return (
    <div>
      <Header translate={t} secondaryPage />

      {/* <!-- breadcrumb start--> */}
      <section className="breadcrumb breadcrumb_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb_iner text-center">
                <div className="breadcrumb_iner_item">
                  <h2>{t('Common:contactUs')}</h2>
                  {/* <h5>{translate('productFeatures')} <span></span> {translate('productFeatures')}</h5> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- breadcrumb start--> */}

      <section className="contact-section section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <h2>
                Request a Free Consultation!
              </h2>
              <ContactForm onSubmit={submitContactUs} translate={t} />
            </div>
            <div className="col-lg-5 col-md-6 pl-lg-5">
              <div className="media contact-info">
                <span className="contact-info__icon"><img src="img/icon/whatsapp_icon.png" alt="" /></span>
                <div className="media-body">
                  <h3>+65 9221 9077 (Singapore)</h3>
                  <h3>+60 12 722 4376 (Malaysia)</h3>
                  <h3>+62 225 9600 (Thailand)</h3>
                </div>
              </div>
              <div className="media contact-info">
                <span className="contact-info__icon email"><img src="img/icon/envelope_icon.png" alt="" /></span>
                <div className="media-body">
                  <h3>info@workmagic.app</h3>
                </div>
              </div>
              {/* HIDE SOCIAL MEDIA ICONS UNTIL SOCIAL MEDIA IS READY */}
              <div className="media contact-info">
                <a href="https://www.facebook.com/workmagicapp">
                  <span className="contact-info__icon"><img src="img/icon/fb_icon.png" alt="" /></span>
                </a>
                <div className="media-body">
                  <a href="https://www.facebook.com/workmagicapp"><h3>/workmagic</h3></a>
                </div>
              </div>
              {/* <div className="media contact-info">
                <span className="contact-info__icon"><img src="img/icon/ig_icon.png" alt="" /></span>
                <div className="media-body">
                  <h3>/workmagic</h3>
                </div>
              </div> */}
              {/* <div className="media contact-info">
                <span className="contact-info__icon"><img src="img/icon/line_icon.png" alt="" /></span>
                <div className="media-body">
                  <h3>/workmagic</h3>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer translate={t} country={geoCountry} />
    </div>
  );
};

const mapStateToProps = state => ({
  geoCountry: state.auth.geoCountry,
});

const mapDispatchToProps = dispatch => ({
  resetContactForm: () => dispatch(reset('contactUsForm')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ContactPage));