import React, { Fragment } from 'react';
import styles from './styles.module.scss';
import { Box, Card, Typography } from '@material-ui/core';


const CategoryTags = (props) => {
  const { input, translate } = props;
  if (!input.value?.length) return null;

  return (
    <Box>
      <Card>
        <Box p={2}>
          <Box pb={1}>
            <Typography className={`${styles.title} font_bold`}>
              {translate('categoryTags')}
            </Typography>
          </Box>
          { input.value.map((item) => {
            const subCategories = item.childrenCategories;
            return (
              <Box key={item.id} mb={2}>
                <Typography>
                  {item.name}
                </Typography>
                <ul className={styles.categoryList}>
                  {
                    subCategories?.map(subCategory => {
                      const subSubCategories = subCategory.childrenCategories;
                      return (
                        <Fragment key={subCategory.id}>
                          {
                            subSubCategories?.length ?
                              subSubCategories.map((subSubCategory) => {
                                return (
                                  <Fragment key={subSubCategory.id}>
                                    <li className={styles.categoryItem}>
                                      <Box className={styles.subCategoryItem}>
                                        <Typography>
                                          {subCategory.name}
                                        </Typography>
                                        <Typography>
                                          / {subSubCategory.name}
                                        </Typography>
                                      </Box>
                                    </li>
                                  </Fragment>
                                );
                              })
                              :
                              <li className={styles.categoryItem}>
                                <Box className={styles.subCategoryItem}>
                                  <Typography>
                                    {subCategory.name}
                                  </Typography>
                                </Box>
                              </li>
                          }
                        </Fragment>
                      );
                    }
                    )
                  }
                </ul>
              </Box>
            );
          })}
        </Box>
      </Card>
    </Box>
  );
};

export default CategoryTags;
