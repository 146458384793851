import React, { useState } from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { searchPlaces, getPlaceDetails } from 'redux/location';

// Material components
import { withStyles, Grid, ListItem, List, ListItemText,
  ClickAwayListener, CircularProgress } from '@material-ui/core';

// Customized components
import { TextInput } from 'components/inputs';
import Countries from 'lib/countryList';
import { showErrorMessage } from 'lib/notifier';
import styles from './styles';

const countryList = new Countries();
const countryCodeOptions = new Countries().countryCodes();

const SEARCH_TIMEOUT = 800;
let timer = 0;



function defineFieldName(name, nameOfField) {
  if (nameOfField && name) {
    return `${nameOfField}.${name}`;
  }
  return name;

};

function parsePlaceDetails(place) {
  let placeAddress = place.formatted_address;
  if (place.name && !place.formatted_address.includes(place.name)) {
    placeAddress = `${place.name}, ${place.formatted_address}`;
  }
  const location = {
    address: placeAddress,
    name: place.name,
  };
  // eslint-disable-next-line camelcase
  const { address_components } = place;
  const floor = address_components.find(item => item.types.includes('floor'));
  const room = address_components.find(item => item.types.includes('room'));
  const city = address_components.find(
    item => item.types.includes('locality') || item.types.includes('administrative_area_level_1'));
  const postalCode = address_components.find(item => item.types.includes('postal_code'));
  const state = address_components.find(item => item.types.includes('administrative_area_level_1'));
  const country = address_components.find(item => item.types.includes('country'));
  location.unitNumber = `${floor ? floor.long_name : ''} ${room ? room.long_name : ''}`.trim();
  location.city = city ? city.long_name : '';
  location.postalCode = location.zipCode = postalCode ? postalCode.long_name : '';
  location.state = state ? state.long_name : '';
  location.countryCode = country ? country.short_name : '';
  return location;
}


function BillingAddressForm(props) {
  const { nameField, translate, classes, handleSelectBillingAddress, placeSearchOptions } = props;
  console.log("BillingAddressForm -> placeSearchOptions", placeSearchOptions)

  const [searchResults, setSearchResults] = useState(null);
  const [isSearchingPlace, setIsSearchingPlace] = useState(false);

  const billingAddressField = {
    property: {
      address: {
        label: translate('Common:address'),
        type: 'text'
      },
      unitNumber: {
        label: translate('Common:unitNumber'),
        type: 'text'
      },
      city: {
        label: translate('Common:city'),
        type: 'text'
      },
      zipCode: {
        label: translate('Common:postalCode'),
        type: 'number'
      },
      state: {
        label: translate('Common:state'),
        type: 'text'
      },
      country: {
        label: translate('Common:country'),
        type: 'select'
      },

      additionalDetails: {
        label: translate('Common:additionalDetails'),
        type: 'text'
      }
    },
  };

  function addressSearching(event) {
    const textSearch = event.target.value;
    if (textSearch) {
      setIsSearchingPlace(true);
    } else {
      setIsSearchingPlace(false);
      setSearchResults(null);
    }

    clearTimeout(timer);

    if (textSearch) {
      timer = setTimeout(function() {
        setIsSearchingPlace(true);
        props.searchPlaces(textSearch, placeSearchOptions).then((response) => {
          setIsSearchingPlace(false);

          if (response.status === 200) {
            const { predictions } = response.data;
            setSearchResults(predictions);
          } else {
            showErrorMessage(response.data.message);
          }
        }, error => {
          setIsSearchingPlace(false);
          showErrorMessage(error);
        });

      }, SEARCH_TIMEOUT);
    }

  }

  function chooseLocation(event, location) {
    event.preventDefault();
    props.getPlaceDetails(location.place_id).then(response => {
      if (response.status === 200) {
        const { result } = response.data;
        const locationDetails = parsePlaceDetails(result);
        handleSelectBillingAddress(locationDetails);
        setSearchResults(null);
      }
    }, error => {
      showErrorMessage(error);
    });
  }

  function handleClickAway() {
    setSearchResults(null);
  }

  return (
    <>
      <Grid item container direction='row' spacing={1} className={classes.formContainer}>

        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Field
            name={defineFieldName('address', nameField)}
            component={TextInput}
            label={billingAddressField.property.address.label}
            type={billingAddressField.property.address.type}
            margin="dense"
            required
            variant="outlined"
            onChange={(e) => addressSearching(e)}
            InputProps={{
              endAdornment: isSearchingPlace && <CircularProgress size={25} position="end" color="secondary" />
            }}
          />

          {
            searchResults && !!searchResults.length > 0 &&
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className={classes.locationSuggestion}>
                <List className={classes.searchResults}>
                  {
                    searchResults.map(item => {
                      return (
                        <ListItem
                          key={item.place_id}
                          className={classes.itemAddress}
                          onClick={(e) => chooseLocation(e, item)}
                        >
                          <ListItemText
                            primary={item.structured_formatting && item.structured_formatting.main_text}
                            secondary={item.structured_formatting && item.structured_formatting.secondary_text}
                          />
                        </ListItem>
                      );
                    })
                  }
                </List>
              </div>
            </ClickAwayListener>
          }
          {
            searchResults && searchResults.length === 0 &&
            <div className={classes.locationSuggestion}>
              <List className={classes.searchResults}>
                <ListItem>
                  {translate('Location:noResultFound')}
                </ListItem>
              </List>
            </div>
          }
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Field
            name={defineFieldName('unitNumber', nameField)}
            component={TextInput}
            label={billingAddressField.property.unitNumber.label}
            type={billingAddressField.property.unitNumber.type}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('state', nameField)}
            component={TextInput}
            label={billingAddressField.property.city.label}
            type={billingAddressField.property.city.type}
            margin="dense"
            required
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('zipCode', nameField)}
            component={TextInput}
            label={billingAddressField.property.zipCode.label}
            type={billingAddressField.property.zipCode.type}
            margin="dense"
            variant="outlined"
          // required
          />
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('city', nameField)}
            component={TextInput}
            label={billingAddressField.property.state.label}
            type={billingAddressField.property.state.type}
            margin="dense"
            variant="outlined"
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6}>
          <Field
            name={defineFieldName('countryCode', nameField)}
            component={TextInput}
            label={billingAddressField.property.country.label}
            select
            SelectProps={{ native: true }}
            margin="dense"
            required
            variant="outlined"
          >
            <option key='' value=''> </option>
            {countryCodeOptions && countryCodeOptions.length &&
              countryCodeOptions.map(option => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </Field>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12}>
          <Field
            name={defineFieldName('additionalDetails', nameField)}
            component={TextInput}
            label={billingAddressField.property.additionalDetails.label}
            type={billingAddressField.property.additionalDetails.type}
            margin="dense"
            variant="outlined"
            multiline
            rows="4"
          />
        </Grid>

      </Grid>

    </>
  );
};


const mapDispatchToProps = dispatch => ({
  searchPlaces: (textSearch, option) => dispatch(searchPlaces(textSearch, option)),
  getPlaceDetails: (placeId) => dispatch(getPlaceDetails(placeId))
});

const getSearchOptions = ({ auth = {} }) => {
  const { currentUser } = auth;
  let language = 'en';
  let placeSearchOptions = {
    language,
  };
  // pick first company in list for country code
  const companies = (currentUser && currentUser.companies) || [];
  if (
    Array.isArray(companies) &&
    companies.length > 0 &&
    companies[0].countryCode
  ) {
    const { countryCode } = companies[0];
    // get language from user first, if not have get from countrycode
    language =
      currentUser && currentUser.language
        ? currentUser.language
        : countryList.getLanguageCodeByCountryCode(countryCode);
    placeSearchOptions = {
      ...placeSearchOptions,
      language,
      components: `country:${countryCode}`,
    };
  }
  return placeSearchOptions;
};

const mapStateToProps = (state, ownProps) => {
  return {
    placeSearchOptions: getSearchOptions(state),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(BillingAddressForm);