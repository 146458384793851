import React from 'react';
import { FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () => ({
  labelNoMargin: {
    margin: 0,
  },
  checkboxState: {
    color: '#F9C825 !important',
  },
});

// this component is using for return item object instead of object with true false
export default withStyles(styles)((props) => {
  const {
    input: { onChange, value },
    classes,
    label,
    handleClick,
    labelClassName,
    labelPlacement = 'end',
    item,
    key,
    isDisabled = false,
    ...rest
  } = props;

  const handleChange = () => onChange({ ...item, checked: !value.checked });
  return (
    <FormControlLabel
      control={
        isDisabled ? (
          <></>
        ) : (
          <Checkbox
            classes={{ checked: classNames(classes.checkboxState) }}
            checked={!!value.checked}
            onChange={handleChange}
            onClick={handleClick}
            disabled={isDisabled}
            {...rest}
          />
        )
      }
      label={label}
      labelPlacement={labelPlacement}
      classes={{
        labelPlacementStart: classNames(classes.labelNoMargin, labelClassName),
      }}
    />
  );
});
