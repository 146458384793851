import React from 'react';

// Externals
import classNames from 'classnames';

// Material components
// Material helpers
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles
} from '@material-ui/core';

import styles from './styles/quoteDetailsTable.module.css';

// Shared components
import {
  Portlet,
  PortletContent,
} from 'pages/Dashboard/components';

// Component styles
import { QuoteDetailsTableStyles } from './styles';
import { formatPrice } from 'lib/formatter';

const round = num => Math.round(parseFloat(num) * 100) / 100;

const QuoteDetailsTable = props => {
  const {
    currency,
    classes,
    // clientMessage,
    discount,
    quoteItems,
    translate,
    deposit,
    subtotal,
    tax,
    total,
    subTotalIncludeTax,
    isInDoD2UFranchisee,
    withholdingTax23
  } = props;

  const discountRows = discount && Object.keys(discount).length > 0 && parseInt(discount.discount) !== 0 ? 1 : 0;
  return (
    <Portlet className={classes.portlet}>
      <PortletContent noPadding>
        <Grid container direction='row' alignItems="center">
          <Table>
            <TableHead>
              { isInDoD2UFranchisee &&
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classNames(classes.rootCell)} align="left">
                    {translate('productService')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">{translate('quantity')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">{translate('unitPrice')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">
                    {translate('totalIncludeTax')}</TableCell>
                </TableRow>}

              { !isInDoD2UFranchisee &&
                <TableRow className={classes.tableHeader}>
                  <TableCell className={classNames(classes.rootCell)} align="left">
                    {translate('productService')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">{translate('quantity')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">{translate('unitPrice')}</TableCell>
                  <TableCell className={classNames(classes.rootCell)} align="right">
                    {translate('taxRate')}
                  </TableCell>

                  <TableCell className={classNames(classes.rootCell)} align="right">
                    {translate('totalBeforeTax')}</TableCell>
                </TableRow>}
            </TableHead>
            <TableBody>
              {quoteItems && quoteItems.map((row, index) => {
                if (isInDoD2UFranchisee) {
                  return <TableRow key={index}>
                    <TableCell align="left">
                      <Grid container direction='column'>
                        <Typography className={classes.itemTitle}>{row.name}</Typography>
                        <Typography className={classes.itemDescription}>{row.description}</Typography>
                      </Grid>
                    </TableCell>
                    <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                      {row.quantity}</TableCell>
                    <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                      {formatPrice(row.unitPrice, currency)}</TableCell>
                    <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                      {formatPrice(row.totalIncludeTax, currency)}</TableCell>
                  </TableRow>;
                }

                return <TableRow key={index}>
                  <TableCell align="left">
                    <Grid container direction='column'>
                      <Typography className={classes.itemTitle}>{row.name}</Typography>
                      <Typography className={classes.itemDescription}>{row.description}</Typography>
                    </Grid>
                  </TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                    {row.quantity}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                    {formatPrice(row.unitPrice, currency)}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                    {(row.taxRate && row.taxRate.percentage) || '0.00'}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.itemCell)} align="right">
                    {formatPrice(row.totalBeforeTax, currency)}</TableCell>
                </TableRow>;
              })}
            </TableBody>
          </Table>
          <div className={styles.emptyRow} />
        </Grid>

        <Grid container direction='row'>

          <Grid item sm={12} xl={8} lg={6} />
          <Grid item sm={12} xl={4} lg={6}>
            { isInDoD2UFranchisee && <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.rootCell} align="left">{translate('subtotal')}</TableCell>
                  <TableCell className={classes.rootCell} align="right">
                    {formatPrice(round(subTotalIncludeTax), currency)}</TableCell>
                </TableRow>
                <TableRow className={classes.taxRows}>
                  <TableCell className={classes.rootCell} align="left">{translate('taxArtical23')}</TableCell>
                  <TableCell className={classes.rootCell} align="right">
                    {formatPrice(round(withholdingTax23), currency)}</TableCell>
                </TableRow>
                {
                  !!discountRows &&
                    <TableRow className={classes.taxRows}>
                      <TableCell className={classes.rootCell} align="left">{translate('discount')}</TableCell>
                      <TableCell className={classes.rootCell} align="right">
                        {formatPrice(round(discount.discountFinal), currency)}</TableCell>
                    </TableRow>
                }
                <TableRow>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.totalRows)} align="left">
                    {translate('total')}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.totalRows)} align="right">
                    {formatPrice(total, currency)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.rowNoBorder)} align="left">
                    {translate('deposit')}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.rowNoBorder)} align="right">
                    {formatPrice(deposit, currency)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>}

            { !isInDoD2UFranchisee && <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.rootCell} align="left">{translate('subtotal')}</TableCell>
                  <TableCell className={classes.rootCell} align="right">{formatPrice(round(subtotal), currency)}</TableCell>
                </TableRow>
                <TableRow className={classes.taxRows}>
                  <TableCell className={classes.rootCell} align="left">{translate('tax')}</TableCell>
                  <TableCell className={classes.rootCell} align="right">{formatPrice(tax, currency)}</TableCell>
                </TableRow>
                {
                  !!discountRows &&
                    <TableRow className={classes.taxRows}>
                      <TableCell className={classes.rootCell} align="left">{translate('discount')}</TableCell>
                      <TableCell className={classes.rootCell} align="right">
                        {formatPrice(discount.discountFinal, currency)}</TableCell>
                    </TableRow>
                }
                <TableRow>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.totalRows)} align="left">
                    {translate('totalDueAfterTax')}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.totalRows)} align="right">
                    {formatPrice(total, currency)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.rowNoBorder)} align="left">
                    {translate('deposit')}</TableCell>
                  <TableCell className={classNames(classes.rootCell, classes.totalCell, classes.rowNoBorder)} align="right">
                    {formatPrice(deposit, currency)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>}
          </Grid>
        </Grid>
      </PortletContent>
    </Portlet>
  );
};

export default withStyles(QuoteDetailsTableStyles)(QuoteDetailsTable);
