import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography } from '@material-ui/core';

// Component styles

import QuoteToolbarStyles from './QuoteToolbarStyles';

const QuoteHeader = props => {
  const {
    classes,
    className,
    handleClickNew,
    listPage = false,
    translate
  } = props;


  const rootClassName = classNames(classes.root, className);


  return (
    <div className={rootClassName}>
      {
        listPage ?
          <Grid item container direction='row' justify='flex-end' spacing={1}>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleClickNew}
              >
                {translate('newQuote')}
              </Button>
            </Grid>
          </Grid>
          : <Grid container direction='row' justify='space-between'>
            <Grid item container direction='row' alignItems='center' md={4} sm={12}>
              <Typography variant='h5' className={classes.backTitle}>
                {translate('Common:backTo')}
              </Typography>
            </Grid>
          </Grid>
      }
    </div>
  );
};

QuoteHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

export default withStyles(QuoteToolbarStyles)(QuoteHeader);