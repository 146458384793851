import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ChangeLanguageMenu } from 'components/popups/';

import newLogoSrc from 'assets/images/logo/new_logo.svg';
import i18n from 'i18next';

const LandingPageHeader = (props) => {
  // eslint-disable-next-line react/prop-types
  const { isSignIn = false, secondaryPage = false, translate } = props;

  const headerType = classNames({
    single_page_menu: secondaryPage,
  });

  return (
    <header className={headerType}>
      <div className="container" style={{ position: 'unset' }}>
        <nav className="d-flex justify-content-between">
          <div className="justify-content-center">
            <Link to="/">
              <img src={newLogoSrc} alt="logo" />
            </Link>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <div className="language_wrapper mr-2 mr-md-5">
              <ChangeLanguageMenu
                fromLandingPage
                selectedLanguage={i18n.language}
              />
            </div>

            {isSignIn ? (
              <Link to="dashboard" className="small-btn btn_customize">
                {translate('Common:dashboard')}
              </Link>
            ) : (
              <Link to="log-in" className="small-btn btn_customize">
                {translate('Common:login')}
              </Link>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default LandingPageHeader;
