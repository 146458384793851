export default theme => ({
  root: {},
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },

  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    fontWeight: 500,
    cursor: 'pointer'
  },
  chip: {
    fontWeight: 600,
  },
  lateVisit: {
    backgroundColor: theme.palette.chip.lateVisit.light,
    color: theme.palette.chip.lateVisit.dark,
  },
  unscheduled: {
    backgroundColor: theme.palette.chip.unscheduled.light,
    color: theme.palette.chip.unscheduled.dark,
  },
  unassigned: {
    backgroundColor: theme.palette.chip.unassigned.light,
    color: theme.palette.chip.unassigned.dark,
  },
  onStandby: {
    backgroundColor: theme.palette.chip.onStandby.light,
    color: theme.palette.chip.onStandby.dark,
  },
  onTheWay: {
    backgroundColor: theme.palette.chip.onTheWay.light,
    color: theme.palette.chip.onTheWay.dark,
  },
  arrived: {
    backgroundColor: theme.palette.chip.arrived.light,
    color: theme.palette.chip.arrived.dark,
  },
  started: {
    backgroundColor: theme.palette.chip.started.light,
    color: theme.palette.chip.started.dark,
  },
  completed: {
    backgroundColor: theme.palette.chip.completed.light,
    color: theme.palette.chip.completed.dark,
  },
  abandoned: {
    backgroundColor: theme.palette.chip.abandoned.light,
    color: theme.palette.chip.abandoned.dark,
  },
  archived: {
    backgroundColor: theme.palette.chip.archived.light,
    color: theme.palette.chip.archived.dark,
  },
  includeArchiveCheckbox: {
    position: 'absolute',
    left: 20,
    top: 15
  },
  categoryChip: {
    margin: '2px 2px 2px 5px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
  },

  categoryName: {
    marginLeft: 5
  },

  created_by: {
    color: '#A2A2A2',
    marginTop: 4
  },
  confirm_archived: {
    background: '#F90042',
    color: '#ffffff',
    boxShadow: 'unset'
  },
  delete_btn: {
    color: '#F90042'
  },
  border_top: {
    borderTop: '1px solid #D9D9D9'
  },
  confirm_change_collected: {
    background: '#FFCC09',
    color: '#3A4457',
    boxShadow: 'unset'
  },
});
