import React, { useState } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Button, Grid, Typography } from '@material-ui/core';

// Component styles
import { InvoiceToolbarStyles } from './styles';

// Common components
import { SignatureModal } from '../modals';
import { PopupMenu } from 'components/popups';

const UNAPPROVAL_STATUS = ['DRAFT', 'AWAITING_APPROVAL'];
const InvoicesHeader = props => {
  const {
    classes,
    className,
    clientName,
    detailPage = false,
    handleClickNew,
    handleClickStatus,
    handleDownloadPdf,
    handlePrint,
    handleEmailInvoice,
    handleSmsInvoice,
    listPage = false,
    match,
    invoice = {},
    invoiceStatuses,
    translate,
    handleExportInvoices,
    fetchInvoiceDetails
  } = props;
  const [signature, setSignature] = useState(false);
  const rootClassName = classNames(classes.root, className);
  const crumbsText = clientName ? `/ Invoice for ${clientName}` : '';
  const crumbsUrl = clientName ? `/invoices/${match.params.invoiceId}` : '';
  const allowedStatuses = ['UNPAID', 'PAID', 'BAD_DEBT', 'AWAITING_APPROVAL', 'AWAITING_PAYMENT', 'APPROVED'];
  const handleCollectSignature = () => setSignature(true);
  const handleCloseSignature = () => setSignature(false);
  const afterUploadSignature = () => {
    fetchInvoiceDetails();
    setSignature(false);
  };
  const sendMailAndSms = {
    title: translate('sendAs'),
    menuItems: [
      {
        label: translate('Common:email'),
        action: handleEmailInvoice,
      },
      {
        label: translate('Common:sms'),
        action: handleSmsInvoice,
      },
    ]
  };
  let menuData = [
    {
      title: translate('Common:markAs'),
      status: invoiceStatuses,
      allowedStatuses
    },
    {
      menuItems: [
        {
          label: translate('collectSignature'),
          action: handleCollectSignature,
        },
        {
          label: translate('downloadPdf'),
          action: handleDownloadPdf,
        },
        {
          label: translate('print'),
          action: handlePrint,
        },
      ]
    }
  ];
  if (detailPage && !UNAPPROVAL_STATUS.includes(invoice.statusId)) {
    menuData = [sendMailAndSms, ...menuData];
  }

  return (
    <div className={rootClassName}>
      {
        listPage ?
          <Grid container direction='row' justify='flex-end' spacing={1}>
            <Grid item>
              <Button
                color="primary"
                // size="large"
                variant="outlined"
                onClick={handleClickNew}
              >
                {translate('addInvoice')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                className={classes.importBtn}
                onClick={handleExportInvoices}
              >
                { translate('exportInvoices') }
              </Button>
            </Grid>
          </Grid>
          : <Grid container direction='row' justify='space-between'>
            <Grid item container direction='row' alignItems='center' md={4} sm={12}>
              <Typography variant='h5' className={classes.backTitle}>
                {translate('backTo')}
              </Typography>
              <Link to='/invoices'>
                <Typography variant='h5' className={classes.backTo}>
                  {translate('invoices')}
                </Typography>
              </Link>
              {
                clientName &&
                <Link to={crumbsUrl}>
                  <Typography variant='h5' className={classes.backTo}>
                    {crumbsText}
                  </Typography>
                </Link>
              }
            </Grid>
            {
              detailPage &&
              <Grid item container direction='row' justify='flex-end' spacing={1} md={8} sm={12}>
                <Grid item>
                  <PopupMenu
                    menuData={menuData}
                    status={invoice.statusId}
                    handleClickStatus={handleClickStatus}
                    btnTitle={translate('Common:moreActions')}
                    btnIcon
                  />
                </Grid>
              </Grid>
            }
            {
              signature &&
              <SignatureModal
                open={signature}
                handleClose={handleCloseSignature}
                afterUploadSignature={afterUploadSignature}
              />
            }
          </Grid>
      }
    </div>
  );
};

InvoicesHeader.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array
};

export default withStyles(InvoiceToolbarStyles)(InvoicesHeader);