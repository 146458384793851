import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { signOutUser } from 'api/authenticationApi';
import { useTranslation } from 'react-i18next';

const wmLogo = require('assets/images/logo/wm-logo-horizontal.svg');

function AccountDeactivated(_props) {
  const { t: translate } = useTranslation('Account');
  return (
    <div className={styles.container}>
      <Grid item container direction="row" justify="space-between">
        <Grid item>
          <Link to="/">
            <img src={wmLogo} alt="WorkMagic" className={styles.logo} />
          </Link>
        </Grid>
      </Grid>
      <div className={styles.logout}>
        <div>{translate('yourCompanyWasDeactivated')}</div>
        <Button variant="contained" style={{background: '#F9C825' }} onClick={() => signOutUser()}>
          {translate('logout')}
        </Button>
      </div>
    </div>
  );
}


export default AccountDeactivated;