import React, { useState } from 'react';
import '../../index.scss';
import LazyLoad from 'react-lazyload';

import { Grid, Typography, Button, useMediaQuery } from '@material-ui/core';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

import WMSrc from 'assets/images/landing-page/wm_img.png';
import LearnMoreIcon from 'assets/images/learn-more-icon.svg';

const AboutUs = ({ t }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isShowFull, setShowFull] = useState(false);

  const handleLearnMore = () => {
    let url = 'https://servishero.com/sg/en/about';
    if (i18n.language === 'th-TH') url = 'https://servishero.com/th/th/about';

    window.open(url, '_blank');
  };

  let word = 'LandingPage:aboutUsContent';
  if (isMobile && !isShowFull) word = 'LandingPage:shortedAboutUsContent';

  return (
    <Grid className="about_us_wrapper">
      <Grid className="container py-5 d-block d-md-flex">
        <Grid className="col-12 col-md-6 px-0 mr-0 mr-md-3">
          <LazyLoad>
            <img src={WMSrc} alt="wm_src" />
          </LazyLoad>
        </Grid>
        <Grid className="col-12 col-md-6 text-left px-0 mt-3 mt-md-0 ml-0 ml-md-3">
          <Typography variant="h2">{t('aboutUs')}</Typography>
          <Grid className="my-3">
            <Typography variant="body2" className="about_content">
              <Trans i18nKey={word} components={[<strong />]} />
              {isMobile && (
                <Typography
                  variant="span"
                  onClick={() => setShowFull(!isShowFull)}
                  className="p-0"
                >
                  <strong> {t(isShowFull ? 'seeLess' : 'seeMore')}</strong>
                </Typography>
              )}
            </Typography>
          </Grid>
          <Button
            onClick={handleLearnMore}
            variant="contained"
            className="p-2 px-3 btn_learn_more"
          >
            <Typography className="font_bold">{t('learnMore')}</Typography>
            <img className="ml-2" src={LearnMoreIcon} alt="learn_more" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
