import React, { useEffect } from "react";

// Externals
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Redux
import { compose } from "redux";
import { useDispatch } from "react-redux";
import { initialize, SubmissionError } from "redux-form";
import { addJob, clearJob } from "redux/job";
import { getQuote } from "redux/quote";
import { showErrorMessage } from "lib/notifier";
// Material helpers
// Material components
import { Grid, withStyles } from "@material-ui/core";

// Custom components
import { JobForm } from "../components/forms";
import {
  JobValidation,
  modifyJobDataBeforeSubmission,
  initializeJobData,
} from "../components/utils";
// Component styles
import styles from "./style";

const NewJob = (props) => {
  const { classes, history, translate, quoteId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (quoteId) {
      dispatch(getQuote(quoteId)).then((rs) =>
        dispatch(initialize("jobForm", initializeJobData(rs.data)))
      );
    }
    // when unmount, clear job
    return () => dispatch(clearJob());
  }, [dispatch, quoteId]);

  function handleCancelJobForm() {
    dispatch(clearJob());
    history.push("/jobs");
  }

  function submitJobForm(values) {
    const errors = JobValidation(values);
    if (errors && Object.keys(errors).length > 1) {
      const messages = [];
      if (typeof errors === "object") {
        const message = Object.values(errors.message)
          .map((msg) => translate(`Error:${msg}`))
          .join("<br />");
        messages.push(message);
      } else {
        const message = Object.values(errors)
          .map((msg) => translate(`Error:${msg}`))
          .join("<br />");
        messages.push(message);
      }

      showErrorMessage(messages.join("<br />"));
      throw new SubmissionError(errors);
    } else {
      values = modifyJobDataBeforeSubmission(values);
      if (quoteId) {
        values.quoteId = quoteId;
      }
      dispatch(addJob(values)).then(
        (response) => {
          if (response.status === 200) {
            history.push(`/jobs/${response.data.publicId}`);
          }
        },
        (error) => {
          showErrorMessage(error);
        }
      );
    }
  }

  return (
    <div className={classes.pageRoot}>
      <Grid container spacing={4} justify="center">
        {/* <Grid item xs={12}>
          <JobsToolbar translate={translate} />
        </Grid> */}
        <Grid item xs={12}>
          <JobForm
            quoteId={quoteId}
            handleCancelJobForm={handleCancelJobForm}
            onSubmit={(values) => submitJobForm(values)}
            translate={translate}
          />
        </Grid>
      </Grid>
    </div>
  );
};

NewJob.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles))(NewJob);
