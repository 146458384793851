import { EMAIL_VALIDATION } from 'common/constant';

export const validateClientDetails = values => {

  const errors = { message: {} };

  const requiredFields = [
    'firstName',
  ];

  const requiredAddressFields = [
    'address',
    //  'city', 
     'countryCode','zipCode','lat','lng'
  ];

  const emailErrors = [];
  const phoneErrors = [];
  const propertyAddressErrors = [];
  let billingAddressErrors = {};
  if (values) {

    requiredFields.forEach(field => {
      if (!(values[field]?.trim())) {
        errors[field] = 'required';
        errors.message[field] = `${field}Required`;
      }
    });

    // === Validate for emails
    if (values.emails && values.emails.length) {
      values.emails.forEach((item, emailIndex) => {
        // if (!item || !item.email) {
        //   emailErrors[emailIndex] = {
        //     email:  'required',
        //     message: { email: 'emailRequired' }
        //   }
        // }
        // else
        if ( item && item.email && !EMAIL_VALIDATION.test(item.email)) {
          emailErrors[emailIndex] = {
            email:  'invalidEmail',
            message: { email:  'invalidEmail' }
          };
        }
      });
    }

    if( emailErrors.length > 0) {
      errors.emails = emailErrors;
    }

    // === Validate for phones

    if (values.phoneNumbers && values.phoneNumbers.length) {
      values.phoneNumbers.forEach((phone, phoneIndex) => {

        if (!phone || (!phone.countryPhoneCode && !phone.phoneNumber)) {
          phoneErrors[phoneIndex] = {
            countryPhoneCode: 'required',
            phoneNumber: 'required',
            message: {
              countryPhoneCode: 'countryPhoneCodeRequired',
              phoneNumber: 'mobileNumberRequired',
            }
          };
        } else if (!phone.phoneNumber) {
          phoneErrors[phoneIndex] = {
            phoneNumber: 'required',
            message: {
              phoneNumber: 'mobileNumberRequired',
            }
          };
        } else if (!phone.countryPhoneCode) {
          phoneErrors[phoneIndex] = {
            countryPhoneCode: 'required',
            message: {
              countryPhoneCode: 'countryPhoneCodeRequired',
            }
          };
        }
      });
    }

    if( phoneErrors.length > 0) {
      errors.phoneNumbers = phoneErrors;
    }

    // === Validate for property address
    if (values.properties && values.properties.length) {
      values.properties.forEach((property, propertyIndex) => {
        requiredAddressFields.forEach(field => {
          if (!property[field] || (property[field]?.length > 0 && property[field]?.trim()?.length === 0)) {
            propertyAddressErrors[propertyIndex] = propertyAddressErrors[propertyIndex] || {};
            propertyAddressErrors[propertyIndex][field] = 'required';
            propertyAddressErrors[propertyIndex].message = propertyAddressErrors[propertyIndex].message || {};
            propertyAddressErrors[propertyIndex].message[field] = `${field}Required`;
          }
        });

      });
    }

    if( propertyAddressErrors.length > 0) {
      errors.properties = propertyAddressErrors;
    }

    // === Validate for billing address
    if (values.billingAddress) {

      requiredAddressFields.forEach(field => {
        if (!values.billingAddress[field]) {
          billingAddressErrors = billingAddressErrors || {};
          billingAddressErrors[field] = 'required';
          billingAddressErrors.message = billingAddressErrors.message || {};
          billingAddressErrors.message[field] = `${field}Required`;
        }
      });

    }

    errors.billingAddress = billingAddressErrors;
  }

  return errors;
};
