import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Material components
import { KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';

// Material helpers
import {
  withStyles,
  Grid,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';
import { TIME_FORMAT_12, TIME_FORMAT_24 } from 'common/constant';
import { getTimeFormat } from 'lib/formatter';

const workingHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const loopNumber = 4;

// Component styles
const styles = {
  root: {
    width: '100%',
  },
  iconButton: {
    padding: 0,
  },
  inputAdornment: {
    display: 'none',
  },
  time_wrapper: {
    position: 'relative',
  },
  suggestion: {
    position: 'absolute',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.15)',
    width: '100%',
    background: '#FFFFFF',
    display: 'none',
    zIndex: 10,
    borderRadius: 8
  },
  suggestion_wrapper: {
    borderRadius: 12,
    maxHeight: 250,
    overflowY: 'auto',
    padding: 16
  },
  show_dropdown: {
    display: 'block',
  },
};

export default withStyles(styles)((props) => {
  const {
    classes,
    input,
    label,
    handleAccept,
    placeholder = '08:30',
    defaultValue,
    check24hFormat,
    helperText,
    mask = '__:__ _M',
    meta: { error },
    checkOnBlur = true,
    formatDatetime,
    ...rest
  } = props;
  const { t } = useTranslation('Error');
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [ampm, setAmpm] = useState(false);

  const handleClickAway = () => setIsFocus(false);

  const handleClick = (time) => {
    input.onChange(time);
    handleClickAway();
  };

  useEffect(() => {
    setAmpm(getTimeFormat() === TIME_FORMAT_12);
  }, []);

  useEffect(() => {
    if (input.value) {
      const check = moment(input.value, TIME_FORMAT_24, true);
      if (check.isValid()) {
        const time = moment(check.format(getTimeFormat()), getTimeFormat());
        setInputValue(time);
      }
      else {
        const getTime = moment(input.value).format(getTimeFormat());
        const time = moment(getTime, getTimeFormat());
        setInputValue(time);
      }
    }
  }, [input.value]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid className={classes.time_wrapper}>
        <KeyboardTimePicker
          ampm={ampm}
          className={classes.root}
          error={error}
          InputAdornmentProps={{
            className: classes.inputAdornment,
          }}
          helperText={error ? t(error) || t('timeWrongFormat') : helperText}
          inputVariant="outlined"
          label={label}
          margin="dense"
          mask={mask}
          onAccept={handleAccept}
          onChange={(value) => {
            input.onChange(value);
          }}
          onBlur={(event) => {
            if (!checkOnBlur) return false;
            const { value } = event.currentTarget;
            const inputDate = moment(value, TIME_FORMAT_12, true);
            if (!inputDate.isValid()) {
              const time = moment(value, TIME_FORMAT_12);
              input.onChange(
                moment(`${time.hours()}:${time.minutes()}`, TIME_FORMAT_12)
              );
            }
          }}
          onFocus={() => {
            setIsFocus(true);
          }}
          placeholder={placeholder}
          value={inputValue}
          {...rest}
        />

        <Grid
          className={`${classes.suggestion} ${isFocus && classes.show_dropdown
            }`}
        >
          <Grid className={classes.suggestion_wrapper}>
            {workingHours.map((hour) => {
              const comps = [];
              for (let i = 0; i < loopNumber; i += 1) {
                const time = moment()
                  .hours(hour)
                  .minutes(0)
                  .add(15 * (i + 1), 'minutes');
                comps.push(
                  <MenuItem
                    onClick={() => handleClick(time)}
                    key={`${hour}_${i}`}
                  >
                    {time.format(getTimeFormat())}
                  </MenuItem>
                );
              }
              return comps;
            })}
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
});
