import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  Tab, Tabs, AppBar,Typography, Grid, Divider,
  Chip, withStyles, Button, Popover, List, ListItem,
  ListItemIcon, ListItemText, Dialog, DialogActions,
  DialogTitle
} from '@material-ui/core';
import { ChevronRight, Close, CalendarToday, Edit, Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import './index.scss';
import Countries from 'lib/countryList';
import moment from 'moment';
import { showErrorMessage } from 'lib/notifier';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'pages/Dashboard/components';
import { STATUS_TO_ACTION } from 'common/constant';


import { deleteVisit, updateVisitByAction } from 'redux/visit';
import { getTimeFormat } from 'lib/formatter';

const styles = theme => ({
  root: {},

  assignButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  typography: {
    padding: theme.spacing(0),
    minWidth: 200
  },
  actions: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: 15,
    '& .item': {
      padding: '5px 20px',
      margin: '0 5px',
      borderRadius: 0,
      '& .MuiButton-label': {
        textTransform: 'capitalize'
      }
    }
  }
});

const STATUSES = [
  { id: 'ON_STANDBY', name: 'On Standby' },
  { id: 'ON_THE_WAY', name: 'On The Way' },
  { id: 'ARRIVED', name: 'Arrived' },
  { id: 'STARTED', name: 'Started' },
  { id: 'COMPLETED', name: 'Completed' },
  { id: 'CANCELLED', name: 'Cancelled' }
];

class VisitDetails extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tabIndex: 0,
      anchorActionEl: null,
      anchorStatusMenuEl: null,
      isSubmitting: false,
      visitDetails: props.visitDetails,
      openConfirmationDeletion: false
    };
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleOpenActionMenu = this.handleOpenActionMenu.bind(this);
    this.handleCloseActionMenu = this.handleCloseActionMenu.bind(this);
    this.handleOpenStatusMenu = this.handleOpenStatusMenu.bind(this);
    this.handleCloseStatusMenu = this.handleCloseStatusMenu.bind(this);
    this.updateVisitStatus = this.updateVisitStatus.bind(this);
    this.handleDeleteVisit = this.handleDeleteVisit.bind(this);
    this.handleCloseConfirmationDeletion = this.handleCloseConfirmationDeletion.bind(this);
    this.handleOpenConfirmationDeletion = this.handleOpenConfirmationDeletion.bind(this);
  }

  handleChangeTab(event, newValue) {
    this.setState({ tabIndex: newValue });
  }

  handleOpenActionMenu(event) {
    this.setState({ anchorActionEl: event.currentTarget });
  }

  handleCloseActionMenu() {
    this.setState({ anchorActionEl: null });
  }

  handleOpenStatusMenu(event) {
    this.setState({ anchorStatusMenuEl: event.currentTarget });
  }

  handleCloseStatusMenu() {
    this.setState({ anchorStatusMenuEl: null });
  }

  handleOpenConfirmationDeletion() {
    this.setState({ openConfirmationDeletion: true, anchorActionEl: null });
  }

  handleCloseConfirmationDeletion() {
    this.setState({ openConfirmationDeletion: false });
  }

  handleDeleteVisit() {
    const { deleteVisit, updateVisitList, closeVisitDetails } = this.props;
    const { visitDetails } = this.state;
    this.setState({ isSubmitting: true });
    deleteVisit(visitDetails.id).then(response => {
      this.setState({ isSubmitting: false, anchorActionMenuEl: null });
      closeVisitDetails();
      updateVisitList(visitDetails, true);
    }, error => {
      this.setState({ isSubmitting: false, anchorActionMenuEl: null });
      showErrorMessage(error);
    });
  }

  updateVisitStatus(statusId) {
    const { updateVisitList, closeVisitDetails, updateVisitByActionDispatch } = this.props;
    const { visitDetails } = this.state;

    const action = STATUS_TO_ACTION[statusId];
    this.setState({ isSubmitting: true });
    updateVisitByActionDispatch({ visitId: visitDetails.id, action }).then(() => {
      this.setState({ isSubmitting: false,
        anchorStatusMenuEl: null,
        visitDetails: { ...visitDetails, statusId } });
      closeVisitDetails();
      updateVisitList();
    }, error => {
      showErrorMessage(error);
      this.setState({ isSubmitting: false, anchorStatusMenuEl: null });
    });
  }



  render() {

    const { closeVisitDetails, classes, jobStatuses, handleOpenVisitForm, translate } = this.props;
    const { visitDetails } = this.state;

    function getStatus(id) {
      const visitItem = jobStatuses.find((item) => item.id === id);
      return visitItem ? visitItem.name : id;
    }
    return(
      <div className="visit-details-body">
        <Portlet>
          <PortletHeader className="card-header">
            <PortletLabel title={visitDetails.title} />
            <div className="close-modal" onClick={closeVisitDetails}>
              <Close className="close-icon" />
            </div>
          </PortletHeader>
          <PortletContent className="modal-content">
            <Grid container spacing={0} className="property-wrap">
              <Grid item xl={7} lg={7} md={7} xs={12} className="property-left-details-wrap">
                <div className="property-details">
                  <div className="property-address">
                    {visitDetails.property && visitDetails.property.address}
                  </div>
                  <div className="property-city-state">
                    { visitDetails.property &&
                      [ visitDetails.property.city,
                        visitDetails.property.state,
                        new Countries().countryName(visitDetails.property.countryCode),
                        visitDetails.property.zipCode
                      ].filter(e => e)
                        .join(', ')
                    }
                  </div>
                </div>
              </Grid>

              <Grid item xl={5} lg={5} md={5} xs={12} className="flex-right-wrap">
                <div className="icon"><CalendarToday /></div>
                <div className="visit-time">
                  {
                    visitDetails.start && visitDetails.end && (
                      moment(visitDetails.start).format('MMM DD, YYYY') === moment(visitDetails.end).format('MMM DD, YYYY') ?
                        `${moment(visitDetails.start).format('MMM DD, YYYY')}
                    ${moment(visitDetails.start).format(getTimeFormat())} - ${moment(visitDetails.end).format(getTimeFormat())}`
                        :
                        `${moment(visitDetails.start).format('MMM DD, YYYY')} ${moment(visitDetails.start).format(getTimeFormat())} -
                    ${moment(visitDetails.end).format('MMM DD, YYYY')} ${moment(visitDetails.end).format(getTimeFormat())}`
                    )}
                </div>
              </Grid>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} xs={12}>
              <div className={classes.actions}>
                {this.props.canEdit &&
                <Button
                  variant="outlined"
                  className={`item ${visitDetails.statusId.toLowerCase()}`}
                  disabled={this.state.isSubmitting}
                  onClick={this.handleOpenStatusMenu}
                >
                  {getStatus(visitDetails.statusId)}
                </Button>
                }

                <Popover
                  anchorEl={this.state.anchorStatusMenuEl}
                  open={Boolean(this.state.anchorStatusMenuEl)}
                  onClose={this.handleCloseStatusMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography} component="div">
                    <List component="nav" aria-label="visit actions">
                      {
                        STATUSES.map((item, index) => {
                          return (
                            <ListItem button key={index} onClick={event => this.updateVisitStatus(item.id)}>
                              <ListItemText primary={item.name} />
                            </ListItem>
                          );
                        })
                      }

                    </List>
                  </Typography>
                </Popover>
                {this.props.canEdit &&
                <Button
                  variant="outlined"
                  className="item"
                  onClick={this.handleOpenActionMenu}
                >
                  {translate('Common:moreActions')}
                </Button>
                }

                <Popover
                  anchorEl={this.state.anchorActionEl}
                  open={Boolean(this.state.anchorActionEl)}
                  onClose={this.handleCloseActionMenu}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className={classes.typography} component="div">
                    <List component="nav" aria-label="visit actions">
                      <ListItem button onClick={event => handleOpenVisitForm(visitDetails)}>
                        <ListItemIcon>
                          <Edit />
                        </ListItemIcon>
                        <ListItemText primary={translate('Common:edit')} />
                      </ListItem>
                      <ListItem button onClick={this.handleOpenConfirmationDeletion}>
                        <ListItemIcon>
                          <Delete />
                        </ListItemIcon>
                        <ListItemText primary={translate('Common:delete')} />
                      </ListItem>
                    </List>
                  </Typography>
                </Popover>
              </div>
            </Grid>
            <Grid item container xl={12} lg={12} md={12} xs={12} className="tab-list">
              <AppBar position="static" className="app-bar">
                <Tabs className="tabs" variant="fullWidth" value={this.state.tabIndex} onChange={this.handleChangeTab} indicatorColor="primary">
                  <Tab className="tab-item" label={translate('Common:info')} />
                  <Tab className="tab-item" label={translate('Common:client')} />
                  <Tab className="tab-item" label={translate('Common:notes')} />
                </Tabs>
              </AppBar>
              {this.state.tabIndex === 0 && <Typography component="div" className="visit-info">
                <div className="description"> {visitDetails.description}</div>
                <Divider />
                <div className="navigation">
                  <div className="nav-item">
                    <h4 className="title">{translate('Common:job')}</h4>
                    <div className="nav-content job-nav">
                      <div className="job-number">
                        {
                          visitDetails.job.publicId && `ID: ${visitDetails.job.publicId}`
                        }
                      </div>
                    </div>
                  </div>
                  <div className="nav-item">
                    <h4 className="title">{translate('Job:team')}</h4>
                    <div className="nav-content">
                      { visitDetails.team &&
                        visitDetails.team.length > 0 ?
                        visitDetails.team.map((item, index) => {
                          return <Chip
                            key={index}
                            label={item.fullName}
                            className="team-item"
                            component="span"
                          />;
                        })
                        : translate('Job:noUsersAssigned')
                      }
                    </div>
                  </div>

                </div>
              </Typography>}
              {this.state.tabIndex === 1 &&
                <Typography component="div" className="client-info">
                  <h4 className="title">{translate('Client:clientDetails')}</h4>
                  <Link to={`/clients/${visitDetails.job.client.id}`} target="_blank">
                    <div className="client-contact">
                      <div className="type">{translate('Common:name')}</div>
                      <div className="value">
                        { visitDetails.job.client.displayName }
                      </div>
                      <div className="nav-icon"><ChevronRight /></div>
                    </div>
                  </Link>
                </Typography>
              }
              { this.state.tabIndex === 2 &&
                <Typography component="div" className="visit-note">
                  <div className="note">{visitDetails.notes}</div>
                </Typography>
              }
            </Grid>
          </PortletContent>
        </Portlet>

        {/* Confirmation to delete visit */}

        <Dialog
          open={this.state.openConfirmationDeletion}
          onClose={this.handleCloseConfirmationDeletion}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{translate('deleteVisit')}</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleCloseConfirmationDeletion} color="primary">
              {translate('Common:cancel')}
            </Button>
            <Button onClick={this.handleDeleteVisit} color="primary" autoFocus>
              {translate('Common:ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteVisit: (id) => dispatch(deleteVisit(id)),
  updateVisitByActionDispatch: (payload) => dispatch(updateVisitByAction(payload))
});


// export default withStyles(styles)(VisitDetails);
export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(VisitDetails);
