import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';

const ReduxSwitch = (props) => {
  const { label, options, input } = props;

  const handleClick = (value) => input.onChange(value.key);

  return (
    <Grid container direction="column">
      {label && (
        <Typography style={{ color: '#A2A2A2' }} variant="body2">
          {label}
        </Typography>
      )}
      <Grid item container className="mt_10">
        {options.map((option, key) => {
          const isSelected = input.value === option.key;
          const style = isSelected ? { background: '#F9C825' } : {};
          return (
            <Button
              onClick={() => handleClick(option)}
              disabled={isSelected}
              style={{
                width: '50%',
                boxShadow: 'none',
                border: '1px solid #A2A2A2',
                borderRadius: 2,
                color: 'black',
                ...style,
              }}
              variant="contained"
              key={key}
            >
              {option.name}
            </Button>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ReduxSwitch;
