import React, { useState, useEffect, useCallback, useRef } from "react";
import styles from "./components.module.scss";

import { submit } from "redux-form";
import { useDispatch } from "react-redux";
import { getStaffs, addStaff } from "redux/staff";
import { showErrorMessage } from "lib/notifier";
import debounce from "lodash.debounce";

// Material helpers
import {
  Dialog,
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputBase,
  Paper,
} from "@material-ui/core";
import { CreateStaffModal } from "pages/Dashboard/components";

import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

import addUserIcon from "assets/images/icons/add-user.svg";

const TeamSelectionModal = (props) => {
  const {
    translate,
    input,
    isOpenSelectTeamModal,
    setIsOpenSelectTeamModal,
    selectedMemberList,
    handleOpenModalCategory,
    currentCategoriesStaff,
  } = props;
  const dispatch = useDispatch();
  const [teamMember, setTeamMember] = useState([]);
  const [selectedMemberIds, setSelectedMemberIds] = useState([]);
  const [selectedMember, setSelectedMember] = useState([]);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const componentRef = useRef(null);

  useEffect(() => {
    const list = selectedMemberList || [];
    setSelectedMemberIds(list.map((item) => item.id));
    setSelectedMember(list);
  }, [selectedMemberList]);

  const addUserCreatedToStaffList = (data) => {
    setTeamMember([data, ...teamMember]);
    setSelectedMemberIds([...selectedMemberIds, data.id]);
    setSelectedMember([...selectedMember, data]);
  };

  const handleCreateNewStaff = async (values) => {
    const formData = new FormData();
    if (values.sourceFile) formData.append("file", values.avatar[0]);

    formData.append("staffInfo", JSON.stringify(values));
    const response = await dispatch(addStaff(formData)).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    setIsOpenCreateModal(false);
    addUserCreatedToStaffList(response.data);
  };

  const handleCreateForm = () => setIsOpenCreateModal(true);
  const handleClose = () => setIsOpenCreateModal(false);

  const submitStaffForm = () => dispatch(submit("newStaff"));

  const fetchStaffs = async (values = {}) => {
    setIsFetching(true);
    const { search, skip } = values;
    const params = {
      $limit: 100,
      ...(search ? { $q: search } : {}),
      ...(skip ? { $skip: skip } : {}),
    };
    const response = await dispatch(getStaffs(params)).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    setTeamMember(
      skip ? [...teamMember, ...response.data.data] : response.data.data
    );
    setIsFetching(false);
  };

  const handler = useCallback(debounce(fetchStaffs, 500), []);

  const handleOnChange = (value) => {
    handler({ search: value });
  };

  useEffect(() => {
    fetchStaffs();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (!isFetching) {
        const componentNode = componentRef.current;
        if (!componentNode) return;

        const scrollTop = componentNode.scrollTop;
        const scrollHeight = componentNode.scrollHeight;
        const clientHeight = componentNode.clientHeight;

        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;

        if (isAtBottom && !isEndOfScroll) {
          fetchStaffs({ skip: teamMember.length });
          setIsEndOfScroll(true);
        } else {
          setIsEndOfScroll(false);
        }
      }
    };

    const componentNode = componentRef.current;
    if (componentNode) {
      componentNode.addEventListener("scroll", handleScroll);
      return () => {
        componentNode.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isEndOfScroll, teamMember, isFetching]);

  const handleUserSelect = (member) => {
    const memberIndex = selectedMemberIds.indexOf(member.id);
    if (memberIndex === -1) {
      setSelectedMemberIds([...selectedMemberIds, member.id]);
      return setSelectedMember([...selectedMember, member]);
    }
    const newList = [...selectedMemberIds];
    newList.splice(memberIndex, 1);
    setSelectedMemberIds(newList);

    const cloneMember = [...selectedMember];
    cloneMember.splice(memberIndex, 1);
    setSelectedMember(cloneMember);
  };

  const handleCloseModal = () => {
    setIsOpenSelectTeamModal(false);
  };

  const handleConfirmChangeTeam = () => {
    input.onChange(selectedMember);
    handleCloseModal();
  };

  const handleClearSelection = () => {
    setSelectedMember([]);
    setSelectedMemberIds([]);
  };

  return (
    <>
      {isOpenCreateModal && (
        <CreateStaffModal
          open={isOpenCreateModal}
          onSubmit={handleCreateNewStaff}
          handleClick={submitStaffForm}
          handleClose={handleClose}
          translate={translate}
          handleOpenModalCategory={handleOpenModalCategory}
          currentCategoriesStaff={currentCategoriesStaff}
        />
      )}
      <Dialog
        classes={{ paper: styles.team_modal }}
        open={isOpenSelectTeamModal}
        onClose={handleCloseModal}
      >
        <Box
          className={styles.border_bottom}
          pl={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className={styles.modal_title}>
            {translate("selectTeamMember")}
          </Typography>
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p={1}>
          <Paper className={styles.search_box}>
            <IconButton aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              autoFocus
              className={styles.inputSearch}
              placeholder={translate("Common:search")}
              inputProps={{ "aria-label": "search categories" }}
              onChange={(e) => handleOnChange(e.target.value)}
            />
          </Paper>
        </Box>

        <Box p={1} className={styles.content} ref={componentRef}>
          {teamMember.map((member, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectedMemberIds.includes(member.id)}
                  onChange={() => handleUserSelect(member)}
                  name="checkedB"
                  color="primary"
                  classes={{
                    root: styles.checkbox,
                    checked: styles.checked_state,
                  }}
                />
              }
              label={member.fullName}
              classes={{
                root: styles.checkboxRoot,
                label: styles.checkboxLabel,
              }}
            />
          ))}
        </Box>

        <Box
          className={styles.border_top}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={1}
        >
          <Grid>
            <IconButton onClick={handleCreateForm} className={styles.icon_btn}>
              <img src={addUserIcon} alt="addUserIcon" />
            </IconButton>
            <Button
              onClick={handleClearSelection}
              className={styles.btn_cancel}
              variant="contained"
            >
              {translate("clearSelection")}
            </Button>
          </Grid>
          <Button
            onClick={handleConfirmChangeTeam}
            className={styles.save_btn}
            variant="contained"
          >
            {translate("done")}
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default TeamSelectionModal;
