import React, { forwardRef } from 'react';

// Externals
import classNames from 'classnames';
import { Button, Menu, MenuItem, MenuList, Typography, Divider, withStyles } from '@material-ui/core';

import {
  MoreHorizRounded as MoreIcon
} from '@material-ui/icons';

// Component styles
import styles from './styles';

const MenuSection = withStyles(styles)(forwardRef((props, ref) => {
  const { classes, handleClickStatus, handleClose, lastIndex, itemStatus, sectionItem } = props;
  return (
    <MenuList innerRef={ref}>
      {
        sectionItem && !!sectionItem.title &&
          <Typography disabled className={classes.sectionTitle}>{sectionItem.title}</Typography>
      }
      {
        sectionItem && !!sectionItem.menuItems &&
          sectionItem.menuItems.map(item => (
            <MenuItem
              key={item.label}
              onClick={() => {
                item.action();
                handleClose();
              }}
            >
              {item.label}
            </MenuItem>
          ))
      }
      {
        sectionItem && !!sectionItem.status &&
          sectionItem.status.map(status => {
            if (sectionItem.allowedStatuses.includes(status.id)) {
              return (
                <MenuItem
                  key={status.id}
                  disabled={itemStatus === status.id}
                  onClick={e => {
                    handleClickStatus(e, status.id);
                    handleClose();
                  }}
                >
                  {status.name}
                </MenuItem>
              );
            }
            return null;
          })
      }
      {
        !lastIndex && <Divider variant='fullWidth' />
      }
    </MenuList>
  );
}));

const MoreActionsMenu = props => {
  const { classes, status, handleClickStatus, menuData, btnTitle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => setAnchorEl(anchorEl ? null : event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Button
        aria-controls="more-actions-menu"
        aria-haspopup="true"
        color='primary'
        onClick={handleClick}
        size={btnTitle? 'small' : 'medium'}
        variant="outlined"
        style={{ minWidth: 120, paddingRight: 0 }}
      >
        {
          btnTitle || <>
            More actions
            <MoreIcon className={classes.moreIcon} />
          </>
        }
      </Button>
      <Menu
        id="more-actions-menu"
        elevation={1}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        className={classNames(classes.arrow, classes.popoverWithArrow)}
      >
        {
          menuData && menuData.length > 0 &&
            menuData.map((section, index) => (
              <MenuSection
                handleClickStatus={handleClickStatus}
                handleClose={handleClose}
                lastIndex={index+1 === menuData.length}
                itemStatus={status}
                key={index}
                sectionItem={section}
              />
            ))
        }
      </Menu>
    </>
  );
};

export default withStyles(styles)(MoreActionsMenu);