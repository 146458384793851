export default {
  businessInformation: 'Business Information',
  businessDetails: 'Business Details',
  businessType: 'Business Type',
  fullLegalName: 'Full Legal Name',
  registrationNumber: 'Registration Number (If available)',
  compulsoryField: '* these fields are required',
  emailHelper: 'Your clients will reply to this email address',
  tradingName: 'Trading Name (If available)',
  website: 'Website',
  taxSettings: 'Tax Settings',
  ifAvailable: 'If available',
  taxID: 'Taxt ID Name',
  taxNumber: 'Tax ID Number',
  taxNumbers: 'Business Tax ID',
  addTaxID: '+ Add Tax ID',
  taxName: 'Tax Name',
  percentage: 'Percentage',
  addTaxNumber: '+ Add Tax Number',
  businessHours: 'Business Hours',
  startTime: 'Start Time',
  endTime: 'End Time',
  registeredAddress: 'Registered Address',
  fileUploadHelper: 'Maximum file size 99KB. PNG or JPEG format only. Maximum height of 180 pixels.',
  profilePicture: 'Business Logo',
  saveBusinessForm: 'Save Business Information',
  companySettingTitle: 'Great, let\'s get you set up',
  companySettingDescription: 'The more we know, the better we can help you customise your new WorkMagic system.',
  startingYear: 'What year did you start your business?',
  userRoleInCompany: 'Your role in the company?',
  leadSourceId: 'How did you find out about WorkMagic?',
  companySizeId: 'Please include yourself',
  selectIndustry: 'Select industries',
  selectionIndustryHelperText: 'Please select one industry',
  otherIndustry: 'Other Industry',
  otherIndustryHelperText: 'Please input industry if it is not listed',
  saveAndFinish: 'Save & Finish',
  companyUpdated: 'Company updated successfully',
  saveChange: 'Save Change',
  taxComponents: 'Tax Components',
  addTaxComponent: '+ Add Tax Component',
  taxComponentTitle: 'Tax Component Title',
  includedTaxRates: 'Included Tax Rates',
  taxApplicable: 'Tax Applicable',
  categories: 'Categories'
};