import React from 'react';

// Externals
import classNames from 'classnames';

// Material helpers
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from './loginForm.module.scss';

const wmLogo = require('assets/images/logo/wm-logo-horizontal.svg');

// Component styles


const HeaderIcon = () => (
  <Grid
    item
    className={classNames('header-logo', styles.headerLogo)}
    container
    direction="row"
  >
    <Grid item>
      <Link to="/" className="logo-cover">
        <img src={wmLogo} alt="WorkMagic" />
      </Link>
    </Grid>
  </Grid>
);

export default HeaderIcon;
