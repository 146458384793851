import { GET, POST, PATCH } from 'lib/api';
import { getConfigs } from './config';
import getCountry from 'lib/getCountry';


// Actions
const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
const SET_CURRENT_USER = 'SET_CURRENT_USER';
const SET_IS_ENTERPRISE_USER = 'SET_IS_ENTERPRISE_USER';
const SET_IS_SIGN_IN = 'SET_IS_SIGN_IN';
const SET_COMPANY_SET_UP_COMPLETED = 'SET_COMPANY_SET_UP_COMPLETED';
const SET_GEO_COUNTRY = 'SET_GEO_COUNTRY';
const SET_CURRENT_COMPANY_PERMISSIONS = 'SET_CURRENT_COMPANY_PERMISSIONS';
const SET_CURRENT_COMPANY_LOGO ='SET_CURRENT_COMPANY_LOGO';
const initialState = {
  accessToken: null,
  currentUser: {},
  isSignIn: false,
  isEnterpriseUser: null,
  companySetupCompleted: false,
  geoCountry: {},
  currentCompanyPermissions: [],
  currentCompanyLogo: '',
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AUTH_SIGN_IN:
      return {
        ...state,
        accessToken: action.accessToken,
        isSignIn: true
      };
    case AUTH_SIGN_OUT:
      return {
        ...state,
        accessToken: null,
        currentUser: {},
        isSignIn: false,
        isEnterpriseUser: null,
        companySetupCompleted: false
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.user
        }
      };
    case SET_IS_SIGN_IN:
      return {
        ...state,
        isSignIn: action.isSignIn
      };
    case SET_IS_ENTERPRISE_USER:
      return {
        ...state,
        isEnterpriseUser: action.isEnterpriseAccount
      };
    case SET_COMPANY_SET_UP_COMPLETED:
      return {
        ...state,
        companySetupCompleted: action.value
      };

    case SET_GEO_COUNTRY:
      return {
        ...state,
        geoCountry: { ...action.geoCountry }
      };

    case SET_CURRENT_COMPANY_PERMISSIONS:
      return {
        ...state,
        currentCompanyPermissions: action.permissions
      };

    case SET_CURRENT_COMPANY_LOGO:
      return {
        ...state,
        currentCompanyLogo: action.companyLogo
      };

    default: return state;
  }
}

// Action Creators
export function signIn(accessToken) {
  return { type: AUTH_SIGN_IN, accessToken };
}

export function signOut() {
  return { type: AUTH_SIGN_OUT };
}

export function setCurrentUser(user) {
  return { type: SET_CURRENT_USER, user };
}

export function setIsSignIn(isSignIn) {
  return { type: SET_IS_SIGN_IN, isSignIn };
}

export function setIsEnterpriseUser(isEnterpriseAccount) {
  return { type: SET_IS_ENTERPRISE_USER, isEnterpriseAccount };
}

export function setCompanySetupCompleted(value) {
  return { type: SET_COMPANY_SET_UP_COMPLETED, value };
}

export function setGeoCountry(geoCountry) {
  return { type: SET_GEO_COUNTRY, geoCountry };
}

export function setCurrentCompanyPermissions(permissions = []) {
  // Adding permission for new submenu
  const showScreens = permissions.map(item => item.view);
  return { type: SET_CURRENT_COMPANY_PERMISSIONS, permissions: showScreens };
}

export function setCurrentCompanyLogo(logo) {
  return { type: SET_CURRENT_COMPANY_LOGO, companyLogo: logo };
}

export function setCurrentCompanyDetails(details = {}) {
  return (dispatch, _getState) => {
    const company = _getState().auth.currentUser.companies[0];
    const newDetails = { ...company, ...details };
    const { currentUser } = _getState().auth;
    currentUser.companies = [newDetails];
    return { type: SET_CURRENT_USER, user: currentUser };
  };
}

// side effects, only as applicable
// e.g. thunks, epics, etc

export function getGeoCountry() {
  return (dispatch, _getState) => {
    getCountry().then(response => {
      const { data } = response;

      dispatch(setGeoCountry({
        countryName: data.countryname,
        countryCode: data.countrycode,
      }));
    });
  };
}

export function signInWithCredentials(email, password) {
  return (dispatch, _getState) => {
    return POST('authentication', null,
      { data: { email: email.replace(/\s+$/, ''), password: password.replace(/\s+$/, '') } })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          const userCompanies = (data.user && data.user.companies) || [];
          const companyWmViews = (userCompanies[0] && userCompanies[0].companyWmViews) || [];
          const companyLogo = (userCompanies[0] && userCompanies[0].profilePicture) || [];
          dispatch(setCurrentUser(data.user));
          dispatch(setIsEnterpriseUser(data.isEnterpriseAccount));
          dispatch(setCurrentCompanyPermissions(companyWmViews));
          dispatch(setCurrentCompanyLogo(companyLogo));
          dispatch(
            setCompanySetupCompleted(
              userCompanies.length > 0 &&
              userCompanies[0].setupCompleted
            ));
          dispatch(signIn(data.accessToken));
          dispatch(getConfigs());
          dispatch(getGeoCountry());
        }
        return Promise.resolve(response);
      }, (error) => {
        return Promise.reject(error);
      }).catch(err => {
        return Promise.reject(err);
      });

  };
}

export function getIsEnterpriseUser() {
  return (dispatch, _getState) => {
    const { accessToken } = _getState().auth;
    return GET('/account-type', accessToken).then(response => {
      if (response.status === 200) {
        dispatch(setIsEnterpriseUser(response.data.isEnterpriseAccount));
      }
      return Promise.resolve(response);
    }, error => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function signUp(user) {
  return (dispatch, _getState) => {
    // dispatch(setIsLoading(true))
    return POST('/users', null, { data: {
      email: user.email,
      password: user.password,
      firstName: user.firstName,
      lastName: user.lastName,
      companyName: user.companyName,
      companyTypeId: user.companyTypeId,
      phoneNumber: user.phoneNumber,
      countryCode: user.countryCode,
      countryPhoneCode: user.countryPhoneCode
    } }).then((response) => {
      if (response.status === 200) {
        const { data } = response;
        dispatch(setCurrentUser(data.user));
        dispatch(
          setCompanySetupCompleted(
            data.user.companies &&
            data.user.companies.length > 0 &&
            data.user.companies[0].setupCompleted
          ));
        dispatch(signIn(data.accessToken));
        dispatch(getGeoCountry());
      }
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    })
      .catch(error => {
        return Promise.reject(error);
      });
  };
}

export function forgotPassword(email) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    return POST('/forgot-password', accessToken, { data: { email } }).then(response => {
      return Promise.resolve(response);
    }, error => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function verifyPasswordResetToken(params) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;

    return GET('/forgot-password', accessToken, { params }).then(response => {
      return Promise.resolve(response);
    }, error => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

export function updatePassword(params, password) {
  return (dispatch, getState) => {
    const { accessToken } = getState().auth;
    const data = { params, data: { password } };

    return PATCH(`/forgot-password/${params.id}`, accessToken, data).then(response => {
      return Promise.resolve(response);
    }, (error) => {
      return Promise.reject(error.response);
    }).catch(error => {
      return Promise.reject(error);
    });
  };
}

// Selector
export function getIsEnterpriseUserSelector(state) {
  return state.auth.isEnterpriseUser;
}