import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";
import { submitEditEnterpriseAccount, enterpriseDetails } from "redux/company";
import { uploadCompanyProfilePicture } from "redux/uploadFile";

import { Grid, Typography, Button, Card } from "@material-ui/core";
import { EnterpriseForm } from "../components";
import { useTranslation } from "react-i18next";
import styles from "./edit.module.scss";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";

const FORM_NAME = "enterpriseForm";

const EditEnterpriseAccount = (props) => {
  const {
    history,
    match: { params },
  } = props;
  const { t } = useTranslation("Enterprise");
  const dispatch = useDispatch();
  const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({});
  const handleClickBack = () => history.goBack();

  useEffect(() => {
    if (!params.enterpriseId) return;
    (async () => {
      const response = await dispatch(
        enterpriseDetails(params.enterpriseId)
      ).catch((e) => ({ e }));
      if (response.e) return showErrorMessage(response.e);

      setCompanyDetails(response.data);
    })();
  }, [params.enterpriseId, dispatch]);

  const handleSubmit = async (values) => {
    const { enterpriseId } = params;
    let { avatar } = values;

    // submit to server to get url
    if (values.sourceFile) {
      const formData = new FormData();
      formData.append("file", values.sourceFile[0]);
      formData.append("type", "ENTERPRISE_ACCOUNT_PROFILE_PICTURE");
      formData.append("targetCompanyId", enterpriseId);

      const imageUploadResponse = await dispatch(
        uploadCompanyProfilePicture(formData)
      ).catch((e) => ({ e }));
      if (imageUploadResponse.e) return showErrorMessage(imageUploadResponse.e);
      avatar = imageUploadResponse.data && imageUploadResponse.data.url;
    }

    const companyData = {
      name: values.registeredName,
      tradingName: values.tradingName,
      address: values.address,
      addressLine2: values.address_line_2,
      city: values.city,
      state: values.state,
      zipCode: values.postalCode,
      countryCode: values.country,
      categoryIds: values.currentCategories.map((category) => category.id),
      profilePicture: avatar,
      active: values.accountStatus,
    };

    const result = await dispatch(
      submitEditEnterpriseAccount(enterpriseId, companyData)
    ).catch((e) => ({ e }));
    if (result.e) return showErrorMessage(result.e);
    history.goBack();
    showSuccessMessage(t("enterpriseEditSuccessfully"));
  };

  const handleRemoteSubmit = () => dispatch(submit(FORM_NAME));

  return (
    <Grid style={{ padding: 24 }}>
      {/* header */}
      <Grid container justify="space-between" direction="row">
        <Grid className={styles.headerWrapper} item>
          <Typography>{t("backTo")}</Typography>
          <Button onClick={handleClickBack} variant="text">
            {t("enterpriseAccounts")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={!isReadyToSubmit}
            variant="contained"
            className={
              isReadyToSubmit ? styles.btnSave : styles.btnSaveDisabled
            }
            onClick={handleRemoteSubmit}
          >
            {t("Common:save")}
          </Button>
        </Grid>
      </Grid>

      {/* body */}
      <Card className={styles.enterpriseInformation}>
        <Grid className={styles.titleWrapper}>
          <Typography className={styles.title}>
            {t("enterpriseInformation")}
          </Typography>
        </Grid>
        <Grid>
          <EnterpriseForm
            t={t}
            onSubmit={handleSubmit}
            setIsReadyToSubmit={setIsReadyToSubmit}
            companyDetails={companyDetails}
          />
        </Grid>
      </Card>
    </Grid>
  );
};

export default EditEnterpriseAccount;
