import React from 'react';
import { FormControlLabel, Checkbox, withStyles } from '@material-ui/core';
import classNames from 'classnames';

const styles = () => ({
  labelNoMargin: {
    margin: 0,
  },
  textColor: {
    color: '#3A4457 !important'
  },
  sh_color: {
    color: '#F9C825 !important'
  }
});

export default withStyles(styles)(props => {
  const { input: { onChange, value },
    classes, label, handleClick,
    labelClassName,
    labelPlacement='end',
    ...rest
  } = props;

  return (
    <FormControlLabel
      control={(
        <Checkbox
          classes={{ checked: classes.sh_color }}
          // eslint-disable-next-line no-unneeded-ternary
          checked={value ? true : false}
          onChange={onChange}
          onClick={handleClick}
          {...rest}
        />
      )}
      label={label}
      labelPlacement={labelPlacement}
      classes={{ labelPlacementStart: classNames(classes.labelNoMargin, labelClassName),
        label: classes.textColor }}
    />
  );
});