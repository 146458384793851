import React from 'react';
// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
// shared component
import { ReactSelect } from 'components/inputs';
// Material component
import { Grid, Button, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { TIME_FORMAT_12H_API, TIME_FORMAT_24H_API } from 'common/constant';

const radioButton = ({ input, styles, t,...rest }) => {
  return (
    <RadioGroup row {...input} {...rest} className={styles.radioGroup}>
      <FormControlLabel
        value={TIME_FORMAT_24H_API}
        control={<Radio className={`${styles.radio} ${styles['Mui-checked']}`} />}
        label={t('AccountSetting:time24h')}
      />
      <FormControlLabel
        value={TIME_FORMAT_12H_API}
        control={<Radio className={`${styles.radio} ${styles['Mui-checked']}`} />}
        label={t('AccountSetting:time12h')}
      />
    </RadioGroup>
  );
};
let LanguageForm = props => {
  const { translate, handleSubmit, styles } = props;
  const LANGUAGES = [
    { value: 'en-US', name: 'English' },
    { value: 'th-TH', name: 'ไทย' },
  ];
  return (
    <div className={styles.section}>
      <div className="language-form-wrap">
        <form className='form-body' onSubmit={handleSubmit}>
          <Grid item container spacing={2} direction='row' justify='space-between'>
            <Grid item xs={4} sm={4} md={4}>
              <div className={styles.sectionTitle}>
                {translate('AccountSetting:preference')}
              </div>
            </Grid>
            <Grid item xs={4} sm={4} md={4} />
            <Grid item xs={4} sm={4} md={4} className={styles.saveLanguageBtn}>
              <Button
                variant='outlined'
                type='submit'
              >
                {translate('AccountSetting:savePreferences')}
              </Button>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <div className={styles.fieldTitle}>{translate('AccountSetting:preferredLanguage')}</div>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Field
                name="language"
                component={ReactSelect}
                margin="none"
                options={LANGUAGES}
                className="selector"
                classNamePrefix="select"
                placeholder=""
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} />
            <Grid item xs={4} sm={4} md={4}>
              <div className={styles.fieldTitle}>{translate('AccountSetting:preferredTimeFormat')}</div>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Field
                name="timeFormat"
                component={radioButton}
                styles={styles}
                t={translate}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} />
          </Grid>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    language: ownProps && ownProps.accountInfo && ownProps.accountInfo.language,
    timeFormat: ownProps && ownProps.accountInfo && ownProps.accountInfo.timeFormat
  },
});

LanguageForm = reduxForm({
  form: 'LanguageForm',
  enableReinitialize: true
})(LanguageForm);


export default compose(
  connect(mapStateToProps, null),
)(LanguageForm);