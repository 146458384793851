export default {
  status: 'Status',
  'createdAt/By': 'Created at/by',
  id: 'ID',
  registeredName: 'Registered Name',
  enterpriseName: 'Enterprise Name',
  contact: 'Contact',
  tag: 'Category Tag',
  total: 'Numbers of Users',
  newAccount: 'New account',
  backTo: 'Back to:',
  enterpriseAccounts: 'Enterprise Accounts',
  enterpriseInformation: 'Enterprise information',
  upload: 'Upload',
  helpRecognize: 'A picture helps people recognize your company',
  acceptedFormat: 'Accepted format: .PNG, .JPG',
  registerName: 'Enterprise registered name*',
  tradingName: 'Enterprise name (Trading name)',
  registerAddress: 'Registered Address',
  address: 'Address line 1*',
  address_line_2: 'Address line 2',
  city: 'City*',
  state: 'State*',
  postalCode: 'Postal Code*',
  countryCode: 'Country*',
  accountStatus: 'Account status',
  active: 'Active',
  deactivated: 'Deactivate',
  deleteAccount: 'Delete account',
  deleteEnterpriseAccount: 'Delete enterprise account',
  enterpriseDescription:
    'Are you sure you want to delete this enterprise account? You can not undo this action.',
  enterpriseUsers: 'Enterprise users',
  newUser: 'Create new users',
  deleteEnterpriseUser: 'Delete this user?',
  createEnterpriseUser: 'Create new enterprise users',
  userInfo: 'User’s Info',
  makeContactPerson: 'Make this user a contact person.',
  replacePerson: 'This user will replace the current contact person.',
  bothEmailAndPhoneNumber:
    'Both email and phone number will be required for a contact person.',
  firstName: 'First Name*',
  lastName: 'Last Name*',
  email: 'Email*',
  phoneNumber: 'Phone Number*',
  title: 'Title',
  notes: 'Notes',
  editEnterpriseUser: 'Edit user\'s info',
  sendError: 'Reinvite error',
  sendSuccess:
    'Reinvited successfully, invitation will be expired on {{datetime}}',
  resendInvitation: 'Resend invitation via Mail',
  enterpriseCreatedSuccessfully: 'Enterprise account created successfully',
  enterpriseEditSuccessfully: 'Enterprise account edited successfully',
  userAlreadyArchived: '{{user}} already archived',
  createUserSuccessfully: '{{userName}} created successfully',
  countryPhoneCode: 'Country Code*',
  titlePlaceholder: 'COO, Head of CX, etc.',
  notesPlaceholder: 'Department, address title, etc.',
  companyArchived: 'Company {{companyName}} has been archived',
  addressRegister: 'Enterprise Registered Address',
  userId: 'User ID',
  dateJoined: 'Date joined',
  role: 'Role',
  makeUserOwner: 'Make this user the owner.',
  replaceCurrentOwner: 'This user will replace the current owner.',
  bothEmailPhoneRequired:
    'Both email and phone number will be required for the owner.',
  cantBeDeleted: 'This user can’t be deleted.',
  youAreChangingOwner: 'You are changing the owner',
  confirmChanging:
    'Are you sure you want to change the owner? You can not undo this action.',
  id_category: 'ID/Category',
  job_address: 'Address',
  schedule: 'Schedule',
  client: 'Client',
  currentVisit: 'Current/Latest visit',
  aboutThisJob: 'ABOUT THIS JOB',
  jobTitle: 'Job Title',
  jobId: 'Job ID',
  startOn: 'Starts On',
  endOn: 'Ends On',
  clientDetails: 'Client Details',
  propertyAddress: 'Property Address',
  viewAll: 'View all',
  numberSelected: '{{number}} selected',
  selectedFileFromGallery: 'Please select files from gallery to download',
  mediaFile: 'Media/File',
  serviceReport: 'Service Report',
  allFiles: 'All files',
  selectFileToDownload: 'Select file to download...',
  download: 'Download',
  filterByTag: 'FILTER BY TAG',
  visitOn: 'VISIT ON',
  filterByType: 'FILTER BY TYPE',
  allAddress: 'All addresses',
  jobs: 'Jobs',
  inputTag: 'Input Tag',
  Photo: 'Photo',
  Video: 'Video',
  Files: 'Files',
  type: 'Type',
  jobReport: 'Job Report',
  visitReport: 'Visit Report',
  downloadSR: 'Download',
  downloadFiles: 'Your files are being downloaded',
  downloadDescription:
    'This may takes time depends on the numbers of files you’ve selected.',
  createdAt: 'Created At',
  selectAll: 'Select all',
  deselectAll: 'Deselect all',
  service: 'Service',
  description: 'Description',
  unitPrice: 'Unit Price',
  tax: 'Tax',
  newService: 'New Serivce',
  deleteThisItem: 'Delete this item?',
  deleteThisItemDescription: 'Deleting this item will permanently remove it from WorkMagic',
  newProduct: 'New Product',
  model: 'Model',
  product: 'Product',
  visits: 'Visits ',
  startAt: 'Start at',
  endAt: 'End at',
  assignedTo: 'Assigned to',
};
