import React from 'react';
// Redux
import { formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
// Customised components
import {
  Grid,
  FormLabel,
  Button,
  withStyles,
  Typography,
} from '@material-ui/core';

import {
  AddOutlined as AddOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';

const styles = (theme) => ({
  groupLabel: {
    padding: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 12,
  },
  button: {
    borderRadius: 4,
  },
  selectedButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 600,
  },
  btnCategory: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: 12,
  },
  btn: {
    justifyContent: 'flex-start',
  },
});

const mapStateToProps = (state, props) => {
  const staffFilterForm = formValueSelector(props.formName);
  return {
  	currentCategories: staffFilterForm(state, 'categories') || [],
  };
};

const StaffCategory = (props) => {
  const { t, classes, handleClickOnCategory, currentCategories } = props;

  const renderBtnContent = () => {
    if (!Array.isArray(currentCategories) || !currentCategories.length)
      return (
        <Button fullWidth variant="outlined">
          {t('Common:selectCategory')}
          <AddOutlinedIcon />
        </Button>
      );

    return (
      <Button className={classes.btn} fullWidth variant="outlined">
        <Typography className={classes.btnCategory}>
          {currentCategories.map((category, index) => (
            <Typography
              component="span"
              style={{ color: '#043D8B' }}
              key={category.id}
            >
              {category.name}
              {index + 1 !== currentCategories.length && <> , </>}
            </Typography>
          ))}{' '}
        </Typography>
        <EditOutlinedIcon />
      </Button>
    );
  };

  return (
    <>
      <FormLabel className={classes.groupLabel}>
        {t('Common:category')}
      </FormLabel>
      <Grid container onClick={handleClickOnCategory}>
        {renderBtnContent()}
      </Grid>
    </>
  );
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, null)
)(StaffCategory);
