import React, { useState, useEffect } from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

// Redux
import { setStaffFilter } from 'redux/global';
import { useDispatch, useSelector } from 'react-redux';
// Material components
import { Button, Grid, withStyles, Typography } from '@material-ui/core';
import { CategoryModal } from 'pages/Dashboard/components';
// Component styles
import styles from './styles';
import {
  AddOutlined as AddOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from '@material-ui/icons';

function StaffToolbar(props) {
  const { classes, className, match, translate, tableRef } = props;
  const dispatch = useDispatch();
  const rootClassName = classNames(classes.root, className);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const currentCategories = useSelector(
    (state) => state.global.currentStaffFilter
  );

  useEffect(() => {
    // reset filter whenever this component being mounted
    dispatch(setStaffFilter([]));
  }, []);
  const handleClickOnCategory = () => setOpenCategoryModal(true);

  const handleCloseSelectCategoryModal = () => setOpenCategoryModal(false);

  const handleCategorySelection = (values) => {
    if (!Array.isArray(values)) return;
    const categoryIds = values.map((item) => item.id);
    tableRef.current.onQueryChange({ page: 0, categoryIds });
    dispatch(setStaffFilter(values.length ? values : []));
    handleCloseSelectCategoryModal();
  };

  const handleClickClear = () => {
    if (tableRef.current) {
      tableRef.current.onQueryChange({ page: 0, categoryIds: [] });
      dispatch(setStaffFilter([]));
    }
  };

  const renderBtnContent = () => {
    if (!Array.isArray(currentCategories) || !currentCategories.length)
      return (
        <>
          {translate('Common:selectCategory')}
          <AddOutlinedIcon />
        </>
      );

    return (
      <>
        <Typography>
          {currentCategories.map((category, index) => (
            <Typography component="span" key={category.id}>
              {category.name}
              {index + 1 !== currentCategories.length && <> , </>}
            </Typography>
          ))}
        </Typography>
        <EditOutlinedIcon />
      </>
    );
  };

  return (
    <>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={translate}
          categories={currentCategories}
          handleCategorySelection={handleCategorySelection}
        />
      )}
      <Grid className={rootClassName}>
        <Grid container direction="row">
          <Grid
            item
            container
            md={12}
            sm={12}
            justify="flex-end"
            alignItems="flex-end"
          >
            {Array.isArray(currentCategories) && !!currentCategories.length && (
              <Button
                onClick={handleClickClear}
                className={classes.clearFilter}
              >
                {translate('clearFilter')}
              </Button>
            )}

            <Button
              onClick={handleClickOnCategory}
              className={classes.btnCategory}
            >
              {renderBtnContent()}
            </Button>
            <Link to={`${match.url}/new`}>
              <Button color="primary" variant="outlined">
                {translate('addStaff')}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

StaffToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  selectedUsers: PropTypes.array,
};

StaffToolbar.defaultProps = {
  selectedUsers: [],
};

export default withStyles(styles)(StaffToolbar);
