import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, withStyles } from "@material-ui/core";

import MediaFileFilter from "./MediaFileFilter";
import MediaFileContent from "./MediaFileContent";
import { showErrorMessage, showSuccessMessage } from "lib/notifier";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { uploadJobFiles } from "redux/uploadFile";
import { getMediaFiles, getMediaSelect, mediaActions } from "redux/media";
import { MEDIA_TYPES } from "common/constant";
import { getTags } from "redux/tag";
import MultipleSelectSidebar from "./MultipleSelectSidebar";

const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
const ALLOW_FILE_TYPES = [
  "jpg",
  "jpeg",
  "png",
  "svg",
  "bmp",
  "mp4",
  "mov",
  "wmv",
  "avi",
  "mpeg",
  "webm",
  "avchd",
  "m4v",
  "doc",
  "csv",
  "xlsx",
  "xlsm",
  "xls",
  "xlxb",
  "xlts",
  "docx",
  "txt",
  "xml",
  "html",
  "pdf",
  "heic",
];

const validateFileType = (file = {}) => {
  const { name, type } = file;
  const fileExtArray = name.split(".");
  const fileExt = fileExtArray[fileExtArray.length - 1];
  const error = type || fileExt;
  return ALLOW_FILE_TYPES.includes(fileExt) ? null : error;
};

const styles = () => ({
  borderRight: {
    borderRight: "1px solid #F2F2F2 !important",
  },
});

const mapDispatchToProps = (dispatch) => ({
  uploadJobFilesAPI: (formData) => dispatch(uploadJobFiles(formData)),
  getMediaFilesAPI: (data) => dispatch(getMediaFiles(data)),
  getTagsAPI: (data) => dispatch(getTags(data)),
  mediaActionsDispatch: (data) => dispatch(mediaActions(data)),
});

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(
  ({
    // visits = [],
    translate,
    getMediaFilesAPI,
    jobId,
    job,
    uploadJobFilesAPI,
    classes,
    getTagsAPI,
    mediaActionsDispatch,
    afterUpdateMedia,
  }) => {
    const isSelectedMultiple = useSelector(getMediaSelect);
    const [visitOn, setVisitOn] = useState([]);
    const [tags, setTags] = useState([]);
    const [media, setMedia] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState({
      [MEDIA_TYPES.PHOTO]: true,
      [MEDIA_TYPES.VIDEO]: true,
      [MEDIA_TYPES.FILE]: true,
    });
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedPhrase, setSelectedPhrase] = useState(0);
    const mediaListLoading = useRef([]);

    const fetchVisits = useCallback(async () => {
      if (!jobId) return false;
      const payload = { jobId, action: GET_FILTER_OPTIONS };
      const result = await mediaActionsDispatch(payload).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      setVisitOn(result.data.visitOn);
      setSelectedIds(result.data.visitOn.map((visit) => visit.visitId));
    }, [jobId]);

    useEffect(() => {
      fetchVisits();
    }, [jobId, fetchVisits]);

    const fetchMedia = async () => {
      const requestQuery = {
        mediaTypes: Object.keys(selectedTypes).filter(
          (key) => selectedTypes[key]
        ),
        visitIds: selectedIds,
        tagIds: selectedTags.map((tag) => tag.id),
        mediaPhase: selectedPhrase,
        jobId,
      };
      const {
        data: { data },
      } = await getMediaFilesAPI(requestQuery);
      setMedia(data);
    };

    const fetchTags = async (searchText) => {
      let params = {};
      if (searchText) params = { ...params, $q: searchText };
      const {
        data: { data },
      } = await getTagsAPI(params);
      setTags(data);
    };

    useEffect(() => {
      fetchTags();
    }, []);

    useEffect(() => {
      fetchMedia();
    }, [selectedTags, selectedTypes, selectedIds, selectedPhrase]);

    const uploadJobFilesForm = (files) => {
      const uploadFilesApi = Object.keys(files).map((key) => {
        const getValidate = validateFileType(files[key]);
        if (getValidate) {
          throw new Error(getValidate);
        }
        const formData = new FormData();
        formData.append("file", files[key]);
        formData.append("jobId", jobId);
        formData.append("phase", selectedPhrase);
        mediaListLoading.current.push({ id: key, loading: true });
        return uploadJobFilesAPI(formData);
      });
      setMedia([...media, ...mediaListLoading.current]);
      return uploadFilesApi;
    };

    const onSubmit = async (files) => {
      const filesLength = files.length;
      if (!filesLength) return null;
      try {
        showSuccessMessage(translate("uploadSuccess"));
        const uploadPromises = uploadJobFilesForm(files).map(
          (promiseFile, index) => {
            return promiseFile.then((file) => {
              if (file.data) {
                mediaListLoading.current[index] = file.data;
              } else {
                mediaListLoading.current[index] = null;
              }
              setMedia([...media, ...mediaListLoading.current]);
            });
          }
        );
        await Promise.allSettled(uploadPromises);
        afterUpdateMedia();
        mediaListLoading.current = [];
      } catch (error) {
        showErrorMessage(error);
      }
    };

    return (
      <Grid container>
        <Grid item xs={3} className={classes.borderRight}>
          {isSelectedMultiple ? (
            <MultipleSelectSidebar
              fetchVisits={fetchVisits}
              job={job}
              currentMediaSelected={media}
              fetchMedia={fetchMedia}
              afterUpdateMedia={afterUpdateMedia}
            />
          ) : (
            <MediaFileFilter
              visitOn={visitOn}
              translate={translate}
              tags={tags}
              fetchTags={fetchTags}
              selectedTypes={selectedTypes}
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
              setSelectedTypes={setSelectedTypes}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          )}
        </Grid>
        <Grid item xs={9}>
          <MediaFileContent
            fetchVisits={fetchVisits}
            fetchMedia={fetchMedia}
            afterUpdateMedia={afterUpdateMedia}
            mediaInput={media}
            translate={translate}
            uploadMediaFiles={onSubmit}
            setSelectedPhrase={setSelectedPhrase}
            selectedPhrase={selectedPhrase}
          />
        </Grid>
      </Grid>
    );
  }
);
