import React from 'react';

// redux
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import styles from '../../details.module.scss';

const ConfirmChangingOwner = ({
  t,
  openConfirmModal,
  handleClose,
  userConfirmed,
}) => {
  return (
    <Dialog
      open={openConfirmModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Typography variant="h4">{t('youAreChangingOwner')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{t('confirmChanging')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          {t('Common:close')}
        </Button>
        <Button
          onClick={userConfirmed}
          className={styles.createNewEnterprise}
          autoFocus
        >
          {t('Common:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmChangingOwner;
