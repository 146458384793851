export default {
  // common && crumbs
  productsServices: 'Products & Services',
  productsServicesDesc:
    'Add and update your products & services to stay organized when creating quotes, jobs, and invoices.',
  addProduct: '+ New Product',
  addService: '+ New Service',
  // modalform
  services: 'Services',
  products: 'Products',
  updateService: 'Update Service',
  updateProduct: 'Update Product',
  addNewService: 'Add New Service',
  addNewProduct: 'Add New Product',
  addNewProductService: 'Add New Product/Service',
  name: 'Name',
  description: 'Description',
  unitCost: 'Unit cost',
  taxRate: 'Tax rate',
  create: 'Create',
  update: 'Update',
  noService: 'No services. Add a new service and it will show up here.',
  noProduct: 'No products. Add a new product and it will show up here.',
  permanentDelete:
    'Deleting <0><0></0></0> will permanently remove it from WorkMagic',
  noTax: 'No tax',
  nameLimitCharacters: 'Maximum 100 Characters.',
  save: 'Save',
  taxComponent: 'Tax Component',
  noTaxComponent: 'No Tax Component',
};
