import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Notifier from './services/Notifier';
import Routes from './routes';
import MomentUtils from '@date-io/moment';
import { moment, setAppTimezone } from 'lib/app-moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getGeoCountry } from 'redux/auth';
import Countries from 'lib/countryList';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const tagManagerArgs = {
  gtmId: 'GTM-P9TR5JX'
};

TagManager.initialize(tagManagerArgs);

const mapStateToProps = (state) => {
  if (
    state.auth.currentUser &&
    state.auth.currentUser.companies &&
    state.auth.currentUser.companies[0]
  ) {
    setAppTimezone(
      new Countries().getZone(state.auth.currentUser.companies[0].countryCode)
    );
  }
  return {
    location: state.router.location.pathname,
    accessToken: state.auth.accessToken,
    country: state.auth.geoCountry
  };
};

const mapDispatchToProps = {
  getGeoCountry
};

class AppMomentUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, 'DD/MM/YYYY');
  }
}

function App(props) {
  useEffect(() => {
    if (props.country && Object.keys(props.country).length === 0) {
      props.getGeoCountry();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.country]);

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={AppMomentUtils}>
      <Router>
        <Notifier />
        <div className="workmagic-webapp">
          <Routes />
        </div>
      </Router>
    </MuiPickersUtilsProvider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
