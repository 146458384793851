import React from 'react';

import {
  makeStyles,
  Grid,
  FormGroup,
  FormControlLabel,
  Box,
  Divider,
  Typography,
  Checkbox,
  TextField,
  InputAdornment,
  Chip,
} from '@material-ui/core';

import { LocalOfferOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';
import { DATE_FORMAT } from 'common/constant';

const LIST_FILE_TYPE = ['photo', 'video', 'files'];

const useStyles = makeStyles(() => ({
  visitContainer: {
    maxHeight: 200,
    overflowY: 'auto',
  },
  filterTitle: {
    textTransform: 'uppercase',
  },
  checkboxRoot: {
    marginBottom: 0,
  },
  checkboxLabel: {
    paddingTop: 6,
  },
  inputTagInputWrapper: {
    padding: 10,
    backgroundColor: '#F2F2F2',
    borderRadius: 4,
    '&.MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
  },
  inputTagInput: {
    '&::placeholder': {
      /* Chrome, Firefox, Opera, Safari 10.1+ */ color: '#666666',
      opacity: 1 /* Firefox */,
    },
    '&:-ms-input-placeholder': {
      /* Internet Explorer 10-11 */ color: '#666666',
    },
    '&::-ms-input-placeholder': {
      /* Microsoft Edge */ color: '#666666',
    },
  },
  checkBoxStyle: {
    paddingTop: 6,
    paddingBottom: 0,
  },
  chipStyle: {
    marginTop: 10,
    marginRight: 8,
    backgroundColor: '#000',
    opacity: 0.5,
  },
  checked_state: {
    color: '#F9C825 !important'
  }
}));

const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    return new Promise((rs) => {
      const later = async () => {
        clearTimeout(timeout);
        const resp = await func(...args).catch(() => rs([]));
        rs(resp);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
};

export default ({
  visitOn = [],
  translate,
  tags = [],
  fetchTags,
  selectedTypes,
  setSelectedTags,
  selectedTags,
  setSelectedTypes,
  selectedIds,
  setSelectedIds
}) => {
  const classes = useStyles();

  const handleOnChange = (event, value) => {
    const index = selectedTags.indexOf(value);
    if (index === -1) return setSelectedTags([...selectedTags, value]);
    const filterValues = [...selectedTags];
    filterValues.splice(index, 1);
    setSelectedTags(filterValues);
  };

  const handleDeleteTag = (tagId) => () => {
    const updatedTagsFilter = selectedTags.filter((tag) => tag.id !== tagId);
    setSelectedTags(updatedTagsFilter);
  };

  const promiseOptions = (event, value) => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        fetchTags(value);
        resolve();
      });
    });
  };

  const handleChangeTypeFilter = (index) => {
    const cloneValue = { ...selectedTypes };
    cloneValue[index] = !cloneValue[index];
    setSelectedTypes(cloneValue);
  };

  const handleChangeVisitOnFilter = (value) => {
    const index = selectedIds.indexOf(value);
    if (index === -1) return setSelectedIds([...selectedIds, value]);
    const filterValues = [...selectedIds];
    filterValues.splice(index, 1);
    setSelectedIds(filterValues);
  };

  const getDate=(date) => {
    if(date !== 'Unscheduled') {
      return moment(date, DATE_FORMAT).format('ll');
    }
    return date;
  };

  return (
    <>
      <FormGroup>
        <Box p={3}>
          <Grid item>
            <Typography className={classes.filterTitle}>
              {translate('visitOn')}
            </Typography>
          </Grid>
          <Grid item className={classes.visitContainer}>
            {Array.isArray(visitOn) &&
              visitOn.map((visit) => (
                <Grid item xs={12} key={visit.visitId}>
                  <FormControlLabel
                    key={visit.visitId}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(visit.visitId)}
                        onChange={() => handleChangeVisitOnFilter(visit.visitId)}
                        name={visit.visitId}
                        color="primary"
                        style={{ paddingTop: 6, paddingBottom: 0 }}
                        classes={{
                          checked: classes.checked_state,
                        }}
                      />
                    }
                    label={
                      <Box display="flex" flexDirection="column">
                        <Box color="#A2A2A2">{visit.publicId}</Box>
                        <Box>{getDate(visit.visitOn)}</Box>
                      </Box>
                    }
                    classes={{
                      root: classes.checkboxRoot,
                      label: classes.checkboxLabel,
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        <Divider />

        {/* filter by tag */}
        <Box p={3}>
          <Grid item>
            <Typography className={classes.filterTitle}>
              {translate('filterByTag')}
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: 10 }}>
            <Autocomplete
              onChange={handleOnChange}
              onInputChange={debounce(promiseOptions, 500)}
              options={tags}
              getOptionLabel={(option) => option.text}
              getOptionDisabled={(option) =>
                selectedTags.filter((tag) => tag.id === option.id).length !== 0}
              getOptionSelected={(option, value) => value.id === option.id}
              style={{ width: '100%' }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  placeholder={translate('inputTag')}
                  InputProps={{
                    ...params.InputProps,
                    inputProps: {
                      ...params.inputProps,
                      className: classes.inputTagInput,
                    },
                    className: classes.inputTagInputWrapper,
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalOfferOutlined style={{ color: 'black' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Grid>
              {selectedTags.map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.text}
                  onDelete={handleDeleteTag(tag.id)}
                  color="secondary"
                  className={classes.chipStyle}
                />
              ))}
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Box p={3}>
          <Grid item>
            <Typography className={classes.filterTitle}>
              {translate('filterByType')}
            </Typography>
          </Grid>
          {LIST_FILE_TYPE.map((type, index) => (
            <Grid item xs={12} key={index}>
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={selectedTypes[index]}
                    onChange={() => handleChangeTypeFilter(index)}
                    name={type}
                    color="primary"
                    className={classes.checkBoxStyle}
                    classes={{
                      checked: classes.checked_state,
                    }}
                  />
                }
                label={translate(type)}
                classes={{
                  root: classes.checkboxRoot,
                  label: classes.checkboxLabel,
                }}
              />
            </Grid>
          ))}
        </Box>
      </FormGroup>
    </>
  );
};
