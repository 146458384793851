import React, { useEffect, useState } from 'react';

// Externals
import { withRouter } from 'react-router-dom';

// Material components
import { Grid, Typography } from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';
import { SEND_TO_CLIENT } from 'common/constant';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  clearInvoice,
  downloadPdf as downloadInvoicePdf,
  getInvoice,
  setCurrentInvoice,
  sendInvoiceToClient,
  updateInvoiceDetails,
} from 'redux/invoice';
import {
  getPayment,
  clearPayment,
  addPayment,
  updatePayment,
  deletePayment,
  emailReceipt,
  downloadPdf as downloadReceipt,
} from 'redux/payment';

// Shared components
import {
  ConfirmDeleteModal,
  ConfirmEmailModal,
  ConfirmSmsModal,
  CreatePaymentModal,
  LoadingModal,
  Portlet,
  PortletContent,
  UpdatePaymentModal,
} from 'pages/Dashboard/components';
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from 'lib/notifier';
import { InvoiceClientDetailsCard } from '../components/cards';
import { InvoiceDetailsTable, InvoiceToolbar } from '../components/utils';

// Component styles
import styles from './styles';
import { Trans } from 'react-i18next';
import printJS from 'print-js';

const mapStateToProps = (state) => ({
  invoiceStatuses: state.config.configs.invoiceStatuses,
  payment: state.payment.payment,
  paymentMethods: state.config.configs.paymentMethods,
  paymentTypes: state.config.configs.paymentTypes,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  addPayment: (payment) => dispatch(addPayment(payment)),
  clearInvoice: () => dispatch(clearInvoice()),
  clearPayment: () => dispatch(clearPayment()),
  deletePayment: (paymentId) => dispatch(deletePayment(paymentId)),
  downloadInvoicePdf: (invoiceId) => dispatch(downloadInvoicePdf(invoiceId)),
  downloadReceipt: (paymentId) => dispatch(downloadReceipt(paymentId)),
  getInvoice: (invoiceId) => dispatch(getInvoice(invoiceId)),
  getPayment: (paymentId) => dispatch(getPayment(paymentId)),
  sendInvoiceToClientDispatch: (payload) =>
    dispatch(sendInvoiceToClient(payload)),
  emailReceipt: (paymentId, email) => dispatch(emailReceipt(paymentId, email)),
  setCurrentInvoice: (InvoiceDetails) =>
    dispatch(setCurrentInvoice(InvoiceDetails)),
  updateInvoiceDetails: (InvoiceDetails) =>
    dispatch(updateInvoiceDetails(InvoiceDetails)),
  updatePayment: (payment, paymentId) =>
    dispatch(updatePayment(payment, paymentId)),
});

const InvoiceDetails = (props) => {
  const {
    addPayment,
    classes,
    clearPayment,
    deletePayment,
    downloadInvoicePdf,
    downloadReceipt,
    emailReceipt,
    getInvoice,
    getPayment,
    invoiceId,
    invoiceStatuses,
    payment,
    paymentMethods,
    paymentTypes,
    sendInvoiceToClientDispatch,
    translate,
    updateInvoiceDetails,
    updatePayment,
    currentUser,
  } = props;

  let companyTag = (currentUser.companies[0] || {}).tag || '';
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();

  const isInDoD2UFranchisee =
    companyTag === 'disinfection2u' &&
    (currentUser.companies[0] || {}).countryCode === 'ID';

  const [isLoading, setIsLoading] = useState(false);
  const [updatePaymentModal, setUpdatePaymentModal] = useState(false);
  const [collectPaymentModal, setCollectPaymentModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [confirmEmailModal, setConfirmEmailModal] = useState(false);
  const [confirmSmsModal, setConfirmSmsModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);

  async function fetchInvoiceDetails() {
    try {
      const res = await getInvoice(invoiceId);
      if (res.status === 200) {
        setInvoiceDetails(res.data);
      }
    } catch (error) {
      showErrorMessage(error);
    }
  }

  useEffect(() => {
    fetchInvoiceDetails();
    return () => clearInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleArchiveInvoice = () => {
    updateInvoiceDetails({
      id: invoiceId,
      archived: !invoiceDetails.archived,
    }).then(
      () => {
        setInvoiceDetails({
          ...invoiceDetails,
          archived: !invoiceDetails.archived,
        });
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  };

  const updateInvoiceStatus = (event, statusId) => {
    updateInvoiceDetails({ id: invoiceId, statusId }).then(
      () => {
        setInvoiceDetails({ ...invoiceDetails, statusId });
      },
      (error) => {
        showErrorMessage(error);
      }
    );
  };

  const handleEmailInvoice = () => {
    setModalType('emailInvoice');
    setConfirmEmailModal(true);
  };

  const handleEmailReceipt = (paymentId) => {
    setModalType('emailReceipt');
    setConfirmEmailModal(true);
    setPaymentId(paymentId);
  };

  const handleSmsInvoice = () => setConfirmSmsModal(true);

  const handleDownloadInvoicePdf = async () => {
    setIsLoading(true);
    try {
      const response = await downloadInvoicePdf(invoiceId);
      setIsLoading(false);
      window.open(response.data.invoiceFile, '_blank');
    } catch (error) {
      setIsLoading(false);
      showErrorMessage(error);
    }
  };

  const handlePrintInvoicePdf = async () => {
    setIsLoading(true);
    const response = await downloadInvoicePdf(invoiceId);
    setIsLoading(false);
    printJS({
      printable: response.data.invoiceFile,
      type: 'pdf',
      showModal: true,
      modalMessage: translate('Invoice:preparePrinting'),
    });
  };

  const handleCloseLoading = () => setIsLoading(false);

  const handleCloseCollectPaymentModal = () => {
    setCollectPaymentModal(false);
    clearPayment();
  };

  const handleCloseUpdatePaymentModal = () => {
    setUpdatePaymentModal(false);
    clearPayment();
  };

  const handleOpenUpdatePaymentModal = async (payment) => {
    try {
      const res = await getPayment(payment.id);
      if (res.status === 200) {
        setPaymentDetails(res.data);
      }
      setUpdatePaymentModal(true);
    } catch (error) {
      showErrorMessage(error);
    }
  };

  const handleCollectPayment = () => setCollectPaymentModal(true);

  const handleAddPayment = async (values) => {
    setIsLoading(true);
    const submitValues = {
      amount: values.amount,
      notes: values.notes,
      paymentTypeId: values.paymentType,
      paymentMethodId: values.paymentMethod,
      paymentDate: values.paymentDate,
      reference: values.reference,
      chequeNumber: values.chequeNumber,
      clientId: invoiceDetails.clientId,
      invoiceId: invoiceDetails.id,
    };

    await addPayment(submitValues);
    setCollectPaymentModal(false);
    await fetchInvoiceDetails();
    setIsLoading(false);
  };

  const handleUpdatePayment = async (values) => {
    setIsLoading(true);
    const submitValues = {
      amount: values.amount,
      notes: values.notes,
      paymentMethodId: values.paymentMethod,
      paymentDate: values.paymentDate,
      reference: values.reference,
      chequeNumber: values.chequeNumber,
      clientId: invoiceDetails.clientId,
    };

    if (values.appliedTo === 'ACCOUNT') {
      submitValues.paymentTypeId = 'ACCOUNT';
      submitValues.invoiceId = null;
    }

    if (values.appliedTo !== 'ACCOUNT') {
      submitValues.paymentTypeId = 'INVOICE';
      submitValues.invoiceId = values.appliedTo;
    }

    await updatePayment(submitValues, paymentDetails.id);
    setUpdatePaymentModal(false);
    await fetchInvoiceDetails();
    setIsLoading(false);
  };

  const handleConfirmDelete = () => setConfirmDeleteModal(true);

  const handleDeletePayment = async () => {
    setIsLoading(true);
    await deletePayment(paymentDetails.id);
    setConfirmDeleteModal(false);
    setUpdatePaymentModal(false);
    await fetchInvoiceDetails();
    setIsLoading(false);
  };

  const handleCloseConfirmDeleteModal = () => setConfirmDeleteModal(false);

  const handleDownloadReceipt = async (paymentId) => {
    setIsLoading(true);
    const response = await downloadReceipt(paymentId);
    setIsLoading(false);
    window.open(response.data.receiptFile, '_blank');
  };

  const handleCloseConfirmEmailModal = () => {
    setModalType(null);
    setConfirmEmailModal(false);
  };

  const handleCloseConfirmSmsModal = () => setConfirmSmsModal(false);

  const handleSubmitSendInvoice = async (value) => {
    const isSendEmailAction = value.email || false;
    let payload = {
      invoiceId,
      action: SEND_TO_CLIENT,
    };

    if (value.email) payload = { ...payload, emails: [value.email] };
    if (value.countryPhoneCode && value.phoneNumber)
      payload = {
        ...payload,
        phoneNumbers: [value.countryPhoneCode + value.phoneNumber],
      };

    if (isSendEmailAction) {
      setConfirmEmailModal(false);
      showInfoMessage(translate('Invoice:sendingEmail'));
    } else {
      setConfirmSmsModal(false);
      showInfoMessage(translate('Invoice:sendingSMS'));
    }

    const result = await sendInvoiceToClientDispatch(payload).catch((e) => ({
      e,
    }));
    if (result.e) return showErrorMessage(result.e);
    showSuccessMessage(
      translate(isSendEmailAction ? 'Invoice:sentEmail' : 'Invoice:sentSMS')
    );
    await fetchInvoiceDetails();
  };

  const handleConfirmReceiptEmail = async (value) => {
    setConfirmEmailModal(false);
    showInfoMessage(translate('Invoice:sendingPaymentEmail'));
    try {
      await emailReceipt(paymentId, value.email);
      showSuccessMessage(translate('Invoice:sentPaymentEmail'));
    } catch (error) {
      showErrorMessage(error);
    }
  };

  // const clientInvoices = (((paymentDetails || {}).client || {}).invoices || []).map(invoice => {
  //   return { ...invoice, name: `Invoice ${invoice.id}`};
  // });

  // const appliedToOptions = paymentTypes.filter(a => a.id === "ACCOUNT").concat(clientInvoices);

  return (
    <div className={classes.root}>
      {invoiceDetails && (
        <Grid container justify="center">
          <Grid item lg={12} md={12} xl={10} xs={12}>
            <InvoiceToolbar
              detailPage
              handleClickStatus={updateInvoiceStatus}
              handleDownloadPdf={handleDownloadInvoicePdf}
              handleEmailInvoice={handleEmailInvoice}
              handlePrint={handlePrintInvoicePdf}
              handleSmsInvoice={handleSmsInvoice}
              invoice={invoiceDetails}
              fetchInvoiceDetails={fetchInvoiceDetails}
              invoiceStatuses={invoiceStatuses}
              translate={translate}
            />
          </Grid>
          <Grid item lg={12} md={12} xl={10} xs={12}>
            <Portlet invoiceLabel>
              <PortletContent className={classes.header}>
                <InvoiceClientDetailsCard
                  invoice={invoiceDetails}
                  invoiceStatuses={invoiceStatuses}
                  handleClickArchive={toggleArchiveInvoice}
                  handleClickStatus={updateInvoiceStatus}
                  handleCollectPayment={handleCollectPayment}
                  handleEmailInvoice={handleEmailInvoice}
                  translate={translate}
                />
              </PortletContent>
              <PortletContent>
                <InvoiceDetailsTable
                  invoiceDetails={invoiceDetails}
                  handleOpenPaymentModal={handleOpenUpdatePaymentModal}
                  translate={translate}
                  isInDoD2UFranchisee={isInDoD2UFranchisee}
                />
              </PortletContent>
              <Grid container justify="center" className={classes.quoteMessage}>
                <Typography>
                  {invoiceDetails && invoiceDetails.clientMessage
                    ? invoiceDetails.clientMessage
                    : translate('thankYou')}
                </Typography>
              </Grid>
              <PortletContent className={classes.spacingBottom}>
                <Grid container direction="column">
                  <Grid item>
                    <Typography variant="h4" className={classes.note}>
                      {translate('note')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {invoiceDetails.notes
                        ? invoiceDetails.notes
                        : translate('noNote')}
                    </Typography>
                  </Grid>
                </Grid>
              </PortletContent>
            </Portlet>
          </Grid>
        </Grid>
      )}
      {isLoading && (
        <LoadingModal open={isLoading} handleClose={handleCloseLoading} />
      )}
      {collectPaymentModal && (
        <CreatePaymentModal
          onSubmit={handleAddPayment}
          handleClose={handleCloseCollectPaymentModal}
          open={collectPaymentModal}
          payment={payment}
          paymentMethods={paymentMethods}
          paymentTypes={paymentTypes}
          translate={translate}
        />
      )}
      {updatePaymentModal && paymentDetails && (
        <UpdatePaymentModal
          onSubmit={handleUpdatePayment}
          handleClose={handleCloseUpdatePaymentModal}
          handleConfirmDelete={handleConfirmDelete}
          handleDownloadReceipt={handleDownloadReceipt}
          handleEmailReceipt={handleEmailReceipt}
          open={updatePaymentModal}
          paymentDetails={paymentDetails}
          paymentMethods={paymentMethods}
          paymentTypes={paymentTypes}
          translate={translate}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDeleteModal
          open={confirmDeleteModal}
          handleClose={handleCloseConfirmDeleteModal}
          handleConfirmDelete={handleDeletePayment}
          translate={translate}
          dialogTitle={
            <Trans i18nKey="Client:deletePayment">
              {paymentDetails && paymentDetails.id}
            </Trans>
          }
          dialogBodyText={
            <Trans i18nKey="Client:permanentDelete">
              {paymentDetails && paymentDetails.id}
            </Trans>
          }
        />
      )}
      {confirmEmailModal && modalType === 'emailInvoice' && (
        <ConfirmEmailModal
          onSubmit={handleSubmitSendInvoice}
          open
          handleClose={handleCloseConfirmEmailModal}
          translate={translate}
          title="sendInvoiceToEmail"
        />
      )}
      {confirmSmsModal && (
        <ConfirmSmsModal
          onSubmit={handleSubmitSendInvoice}
          open={confirmSmsModal}
          handleClose={handleCloseConfirmSmsModal}
          translate={translate}
        />
      )}
      {confirmEmailModal && modalType === 'emailReceipt' && (
        <ConfirmEmailModal
          onSubmit={handleConfirmReceiptEmail}
          open
          handleClose={handleCloseConfirmEmailModal}
          translate={translate}
          title="sendReceiptToEmail"
        />
      )}
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(InvoiceDetails);
