import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import queryString from 'query-string';

import SignUpForm from './components/forms/SignUpForm';
import LoginForm from './components/forms/LoginForm';
import D2uLoginForm from './components/forms/D2uLoginForm';
import ForgotPasswordForm from './components/forms/ForgotPasswordForm';
import ResetPasswordForm from './components/forms/ResetPasswordForm';
import { signInWithCredentials, signUp, forgotPassword,
  updatePassword, verifyPasswordResetToken } from 'redux/auth';
import { showErrorMessage, showInfoMessage } from 'lib/notifier';
import './index.scss';
import { LoadingModal } from 'pages/Dashboard/components';


const mapStateToProps = state => ({
  authForm: state.form.authForm && state.form.authForm.values,
  isSignIn: state.auth.isSignIn,
});

const mapDispatchToProps = dispatch => ({
  forgotPasswordFunction: (email) => dispatch(forgotPassword(email)),
  signInWithCredentialsFunction: (email, password) => dispatch(signInWithCredentials(email, password)),
  signUpFunction: (data) => dispatch(signUp(data)),
  updatePasswordFunction: (params, password) => dispatch(updatePassword(params, password)),
  verifyPasswordResetTokenFunction: (params) => dispatch(verifyPasswordResetToken(params)),
});

const AuthPage = props => {
  const {
    authForm,
    forgotPasswordFunction,
    history,
    location,
    match: {
      path
    },
    signInWithCredentialsFunction,
    signUpFunction,
    t,
    updatePasswordFunction,
    verifyPasswordResetTokenFunction
  } = props;

  const [tokenValidity, setTokenValidity] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const params = queryString.parse(location.search);
  const hasParams = params && Object.keys(params).includes('email') && Object.keys(params).includes('token');
  const handleCloseLoading = () => setIsLoading(false);

  async function handleForgot(value) {
    try {
      const response = await forgotPasswordFunction(value.email);
      if (response.status === 200) {
        history.push('/log-in');
        const message = t('resetEmailMsg');
        showInfoMessage(message);
      }
    } catch(error) {
      showErrorMessage(error);
    }
  }

  useEffect(() => {
    async function checkPwdResetToken() {
      if (hasParams) {
        try {
          const response = await verifyPasswordResetTokenFunction(params);
          if (response.status === 200) {
            const user = response.data;
            setUserId(user.id);
            setTokenValidity(true);
          }
        } catch (error) {
          showErrorMessage(error);
        }
      }
    }
    checkPwdResetToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasParams]);

  async function handleSignUp() {
    const { email, password, firstName, lastName, companyName,
      countryCode, countryPhoneCode, phoneNumber, companyTypeId } = authForm;
    const user = {
      email,
      password,
      firstName,
      lastName,
      companyName,
      countryCode,
      countryPhoneCode,
      phoneNumber,
      companyTypeId
    };
    try {
      setIsLoading(true);
      const response = await signUpFunction(user);
      if (response.status === 200) {
        setIsLoading(false);
        history.push('/company-details');
      } else {
        setIsLoading(false);
        showErrorMessage(response.data.message);
      }
    } catch(error) {
      setIsLoading(false);
      showErrorMessage(error);
    }
  }

  async function handleLogin() {
    const { email, password } = authForm;
    try {
      const response = await signInWithCredentialsFunction(email, password);
      if (response.status !== 200) {
        showErrorMessage(response.data.message);
      } else if (response.status === 200) {
        history.push('/dashboard');
      }
    } catch(error) {
      showErrorMessage(error);
    }
  }

  async function handleReset(value) {
    const resetParams = { ...params, id: userId };
    try {
      const response = await updatePasswordFunction(resetParams, value.password);
      if (response.status === 200) {
        history.push('/log-in');
        const message = t('passwordResetSuccess');
        showInfoMessage(message);
      }
    } catch(error) {
      showErrorMessage(error);
    }
  }

  let formComponent;
  if (path === '/log-in') {
    formComponent = <LoginForm onSubmit={handleLogin} translate={t} />;
  } else if (path === '/sign-up') {
    formComponent = <SignUpForm onSubmit={handleSignUp} translate={t} />;
  } else if (path === '/forgot' || path === '/forgot/:id') {
    if(hasParams && tokenValidity) {
      formComponent = <ResetPasswordForm onSubmit={handleReset} translate={t} />;
    } else {
      formComponent = <ForgotPasswordForm onSubmit={handleForgot} translate={t} />;
    }
  } else if (path === '/disinfection2u-log-in') {
    formComponent = <D2uLoginForm onSubmit={handleLogin} translate={t} />;
  } else {
    formComponent = <LoginForm onSubmit={handleLogin} translate={t} />;
  }

  return (
    <div className="body-container authentication-user">
      <Grid className="gridContainer" container direction='column' justify="space-between">
        <Grid className="content" item xs>
          { formComponent }
        </Grid>
      </Grid>
      { isLoading &&
        <LoadingModal open={isLoading} handleClose={handleCloseLoading} />
      }
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
