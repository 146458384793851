import React, { Component } from "react";

// Externals
import classNames from "classnames";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm, change, arrayPush, formValueSelector } from "redux-form";
import { clearClient } from "redux/client";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Button, Grid, FormLabel } from "@material-ui/core";
import { Link } from "react-router-dom";

// Customised components
import { ClientDetailsForm, PropertyAddressesForm } from "../formComponents";

// Shared components
import {
  Portlet,
  PortletContent,
  PortletFooter,
} from "pages/Dashboard/components";

// Component styles
import styles from "./styles";
import { grey } from "common/colors";
import { validateClientDetails } from "./validate";

import "./styles.scss";
import Countries from "lib/countryList";

const propertyAddressObject = {
  unitNumber: null,
  address: null,
  city: null,
  zipCode: null,
  state: null,
  countryCode: null,
  lat: null,
  lng: null,
  additionalDetails: null,
  placeId: null,
  isBillingAddress: false,
  name: null,
};

const billingAddressObject = {
  address: null,
  unitNumber: null,
  city: null,
  zipCode: null,
  state: null,
  countryCode: null,
};
const clientFormValues = formValueSelector("clientForm");

const mapStateToProps = (state, ownProps) => {
  const clientDetails = (ownProps && ownProps.clientDetails) || null;
  const clientBillingAddress =
    (clientDetails && clientDetails.billingAddress) || null;
  const clientTitle = (clientDetails && clientDetails.title) || null;

  // TODO: Will update it later by using current company. Waiting for WM-904
  const COUNTRY_PHONE_CODE =
    new Countries().getCountryPhoneCodeByCountryCode(
      (state.auth.currentUser.companies[0] || {}).countryCode
    ) ||
    (state.auth.currentUser.companies[0] || {}).countryPhoneCode ||
    state.auth.currentUser.countryPhoneCode ||
    null;

  return {
    initialValues: {
      id: (clientDetails && clientDetails.id) || null,
      title: clientTitle,
      firstName: (clientDetails && clientDetails.firstName) || null,
      lastName: (clientDetails && clientDetails.lastName) || null,
      companyName: (clientDetails && clientDetails.companyName) || null,
      useCompanyName: (clientDetails && clientDetails.useCompanyName) || false,
      emails: (clientDetails && clientDetails.contactEmails) || [
        {
          typeId: "MAIN",
          email: null,
        },
      ],
      phoneNumbers: (clientDetails && clientDetails.contactPhones) || [
        {
          typeId: "MAIN",
          phoneNumber: null,
          countryPhoneCode: COUNTRY_PHONE_CODE,
          canReceiveSms: false,
        },
      ],
      properties: (clientDetails && clientDetails.properties) || [
        {
          ...propertyAddressObject,
          ...{ isBillingAddress: true },
        },
      ],
      billingAddress: clientBillingAddress,
    },

    disablePrimaryNameCheckbox:
      (clientDetails && !clientDetails.companyName) || false,
    clientBillingAddress:
      clientBillingAddress || clientFormValues(state, "billingAddress"),
    clientTitle,
    emailTypes: state.config.configs.emailTypes,
    phoneTypes: state.config.configs.phoneTypes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearClient: () => dispatch(clearClient()),
  updateClientProperties: (index, property) => {
    dispatch(
      change("clientForm", "additionalDetails", property.additionalDetails)
    );
    dispatch(change("clientForm", "address", property.address));
    dispatch(change("clientForm", "city", property.city));
    dispatch(change("clientForm", "countryCode", property.countryCode));
    dispatch(
      change("clientForm", "isBillingAddress", property.isBillingAddress)
    );
    dispatch(change("clientForm", "lat", property.lat));
    dispatch(change("clientForm", "lng", property.lng));
    dispatch(change("clientForm", "name", property.name));
    dispatch(change("clientForm", "placeId", property.placeId));
    dispatch(change("clientForm", "state", property.state));
    dispatch(change("clientForm", "unitNumber", property.unitNumber));
    dispatch(change("clientForm", "zipCode", property.zipCode));
  },
  initPropertyAddress: () => {
    dispatch(arrayPush("clientForm", "properties", propertyAddressObject));
  },
  resetUsingCompanyName: () => {
    dispatch(change("clientForm", "useCompanyName", false));
  },
  initBillingAddress: () => {
    dispatch(change("clientForm", "billingAddress", billingAddressObject));
  },
  updateBillingAddress: (billingAddress) => {
    dispatch(change("clientForm", "billingAddress", billingAddress));
  },
  removeBillingAddress: () => {
    dispatch(change("clientForm", "billingAddress", null));
  },
});

class ClientForm extends Component {
  componentWillUnmount() {
    this.props.clearClient();
  }

  render() {
    const {
      classes,
      handleSubmit,
      className,
      initBillingAddress,
      resetUsingCompanyName,
      disablePrimaryNameCheckbox,
      handleCancelClientForm,
      handleClick,
      pristine,
      invalid,
      submitting,
      isModal = false,
      translate,
      updateClientProperties,
      initPropertyAddress,
      removeBillingAddress,
      clientBillingAddress,
      updateBillingAddress,
      clientTitle,
    } = this.props;
    const rootClassName = classNames(classes.root, className);

    const gridSize = isModal ? 6 : 6;

    return (
      <div className="form-wrapper">
        {!isModal && (
          <div className="head-details">
            <div className="direction-link">
              <span>{translate("Common:backTo")}</span>
              <Link to="/clients">
                <span className="wm-blue-color">{translate("clients")}</span>
              </Link>
            </div>
          </div>
        )}

        <FormLabel component="legend" className={classes.formLabel}>
          {translate("Common:compulsoryField")}
        </FormLabel>

        <form
          autoComplete="off"
          className={classes.formField}
          onSubmit={handleSubmit}
        >
          <Portlet className={rootClassName}>
            <PortletContent noPadding>
              <Grid container direction="row" spacing={2}>
                <Grid item xl={gridSize} lg={gridSize} md={12} sm={12} xs={12}>
                  <p className={classes.sectionTitle}>
                    {translate("Common:clientDetails")}
                  </p>
                  <ClientDetailsForm
                    classes={classes}
                    emailTypes={this.props.emailTypes}
                    phoneTypes={this.props.phoneTypes}
                    disablePrimaryNameCheckbox={disablePrimaryNameCheckbox}
                    resetUsingCompanyName={resetUsingCompanyName}
                    clientTitle={clientTitle}
                    translate={translate}
                  />
                </Grid>
                <Grid
                  item
                  xl={gridSize}
                  lg={gridSize}
                  md={12}
                  sm={12}
                  style={{ backgroundColor: grey[100] }}
                >
                  <p className={classes.sectionTitle}>
                    {translate("Common:propertyAddress")}
                  </p>
                  <PropertyAddressesForm
                    classes={classes}
                    initBillingAddress={initBillingAddress}
                    removeBillingAddress={removeBillingAddress}
                    updateClientProperties={updateClientProperties}
                    updateBillingAddress={updateBillingAddress}
                    initPropertyAddress={initPropertyAddress}
                    clientBillingAddress={clientBillingAddress}
                    translate={translate}
                  />
                </Grid>
              </Grid>
            </PortletContent>
            <PortletFooter className={classes.portletFooter}>
              <Grid container alignContent="flex-end" justify="flex-end">
                <Button color="secondary" onClick={handleCancelClientForm}>
                  {translate("Common:cancel")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type={isModal ? "button" : "submit"}
                  onClick={handleClick}
                  disabled={pristine || submitting || invalid}
                >
                  {translate("Client:saveClient")}
                </Button>
              </Grid>
            </PortletFooter>
          </Portlet>
        </form>
      </div>
    );
  }
}

ClientForm.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

ClientForm = reduxForm({
  form: "clientForm",
  validate: validateClientDetails,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ClientForm);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ClientForm);
