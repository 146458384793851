import React from 'react';
import { Button } from '@material-ui/core';

import styles from './media.module.scss';

const PhraseBtn = ({ onClick, label, isChosen }) => {
  return (
    <Button
      variant="outlined"
      className={isChosen ? styles.selected_btn : styles.unselect_btn}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default PhraseBtn;
