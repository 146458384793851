export default {
  dontHaveAccount:	'สนใจใช้ WorkMagic ไหม?',
  forFree:	'ลงทะเบียนที่นี่',
  continueAgree:	'เมื่อคุณเลือกดำเนินการต่อ คุณได้ยืนยันตามข้อตกลงใน',
  termsService:	'เงื่อนไขการให้บริการ',
  and:	'และ',
  privacyPolicy:	'นโยบายความเป็นส่วนตัว',
  startFreeTrial:	'เริ่มทดลองใช้ฟรี',
  featuresSupportNoCard:	'ให้ฟีเจอร์ครบทุกอย่างและความช่วยเหลืออย่างเต็มที่ ไม่จำเป็นต้องมีบัตรเครดิต',
  haveAccount:	'มีบัญชีอยู่แล้ว?',
  createAccount:	'สร้างบัญชีใหม่',
  forgotPassword:	'ลืมรหัสผ่าน?',
  resetEmailMsg: 'อีเมล์ถูกส่งไปหาคุณเพื่อรีเซ็ตรหัสผ่านใหม่เรียบร้อยแล้ว',
  resetEmail:	'ใส่อีเมลที่ลงทะเบียนไว้เพื่อรีเซ็ตรหัสผ่าน',
  resetPassword:	'รีเซ็ตรหัสผ่าน',
  passwordResetSuccess: 'รหัสผ่านของคุณได้ถูกรีเซ็ตแล้ว กรุณาล็อกอินเข้าใช้งานด้วยรหัสผ่านใหม่',
  goBack:	'< กลับ',
  backSignIn:	'< กลับไปที่ลงชื่อเข้าใช้',
  enterNewPassword:	'กรุณาใส่รหัสผ่านใหม่',
  savePassword:	'บันทึกรหัสผ่านใหม่',
  setupTitle:	'เยี่ยม! เริ่มสร้างบัญชีกันเถอะ',
  setupSubtitle:	'ยิ่งใส่ข้อมูลละเอียด เรายิ่งสามารถออกแบบระบบให้เหมาะสมกับบริษัทของคุณได้ดียิ่งขึ้น',
  businessStartYear:	'ปีที่เริ่มธุรกิจของคุณ',
  roleInCompany:	'ตำแหน่งของคุณในบริษัท',
  findOutABoutWM:	'คุณรู้จักเกี่ยวกับ WorkMagic ได้อย่างไร',
  companySize:	'ขนาดบริษัท',
  includeYourself:	'กรุณารวมตัวคุณเองด้วย',
  selectIndustry:	'เลือกอุตสาหกรรม',
  selectOneOrMoreIndustries:	'กรุณาเลือกอุตสาหกรรมอย่างน้อย 1 ข้อ',
  otherIndustry:	'อุตสาหกรรมอื่นๆ',
  inputIndustryIfNotListed:	'กรุณาใส่ชนิดของอุตสาหกรรมหากไม่ถูกกำหนดไว้',
  saveFinish:	'บันทึกและเสร็จสิ้น',
  hintPassword: 'รหัสผ่านจะต้องประกอบไปด้วย 8 ตัวอักษร',
};