export default {
  businessInformation: 'ข้อมูลบริษัท',
  businessDetails: 'รายละเอียดบริษัท',
  businessType: 'รูปแบบบริษัท',
  fullLegalName: 'ชื่อ (ตามหนังสือจดทะเบียนบริษัท)',
  registrationNumber: 'เลขทะเบียนนิติบุคคล (หากมี)',
  compulsoryField: '* คือข้อมูลที่จำเป็นต้องใส่',
  emailHelper: 'ลูกค้าของคุณจะตอบกลับที่อีเมลนี้',
  tradingName: 'ชื่อที่ใช้ในการค้า (หากมี)',
  website: 'เว็บไซต์',
  taxSettings: 'การตั้งค่าภาษี (หากมี)',
  taxID: 'ชื่อบริษัท (ตามหนังสือจดทะเบียนบริษัท)',
  taxNumber: 'เลขประจำตัวผู้เสียภาษีอากร',
  taxNumbers: 'Business Tax ID',
  addTaxID: '+ Add Tax ID',
  taxName: 'Tax Name',
  percentage: 'เปอร์เซ็นต์',
  addTaxNumber: '+ Add Tax Number',
  businessHours: 'เวลาทำการ',
  startTime: 'เวลาเริ่ม',
  endTime: 'เวลาจบ',
  registeredAddress: 'ที่อยู่ตามหนังสือจดทะเบียนบริษัท',
  fileUploadHelper: 'ขนาดไฟล์สูงสุด 99KB ต้องอยู่ในรูปแบบ PNG หรือ JPEG เท่านั้น และความสูงไม่เกิน 180 pixels',
  profilePicture: 'โลโก้บริษัท',
  saveBusinessForm: 'บันทึกข้อมูลบริษัท',
  companySettingTitle: 'เยี่ยมมาก! อีกนิดเดียวจะสร้างบัญชีเสร็จแล้ว',
  companySettingDescription: 'เราออกแบบระบบ WorkMagic ให้เข้ากับคุณ ข้อมูลที่ครบถ้วนจะทำให้เราแนะนำฟีเจอร์ต่างๆให้ได้อย่างเหมาะสมมากยิ่งขึ้น',
  startingYear: 'คุณเริ่มธุรกิจเมื่อปีใด',
  userRoleInCompany: 'ตำแหน่งของคุณในบริษัทคือ',
  leadSourceId: 'คุณรู้จัก WorkMagic ได้อย่างไร',
  companySizeId: 'กรุณานับรวมคุณด้วย',
  selectIndustry: 'เลือกอุตสาหกรรมของคุณ',
  selectionIndustryHelperText: 'กรุณาเลือกอย่างน้อยหนึ่งข้อ',
  otherIndustry: 'อุตสาหกรรมอื่นๆ',
  otherIndustryHelperText: 'กรุณาใส่อุตสาหกรรมของคุณหากไม่มีในลิสต์ข้างบน',
  saveAndFinish: 'บันทึกและสำเร็จ',
  companyUpdated: 'Company updated successfully',
  saveChange: 'Save Change',
  taxComponents: 'Tax Components',
  addTaxComponent: '+ Add Tax Component',
  taxComponentTitle: 'Tax Component Title',
  includedTaxRates: 'Included Tax Rates',
  taxApplicable: 'Tax Applicable',
};
