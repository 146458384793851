export default {
  id: "ID",
  address: "Address",
  archive: "Archive",
  backTo: "Back to: ",
  cancel: "Cancel",
  comingSoon: "Coming soon...",
  close: "Close",
  delete: "Delete",
  edit: "Edit",
  email: "Email",
  emailSms: "Email and SMS",
  login: "Login",
  logout: "Logout",
  markAs: "Mark as...",
  moreActions: "More actions",
  name: "Name",
  phone: "Phone",
  phoneNumber: "Phone Number",
  signup: "Sign Up",
  signin: "Sign In",
  sms: "SMS",
  unarchive: "Unarchive",
  WM: "WorkMagic",
  and: "and",
  or: "or",
  contactNo: "Contact Number",
  saveAnd: "Save and...",
  description: "Description",
  done: "Done",
  uppercaseDelete: "DELETE",
  save: "Save",
  submit: "Submit",
  of: "of",
  rows: "rows",
  firstPage: "First Page",
  previousPage: "Previous Page",
  nextPage: "Next Page",
  lastPage: "Last Page",
  search: "Search",
  actions: "Actions",
  ok: "Ok",
  active: "Active",
  archived: "Archived",
  contactUs: "Contact Us",
  confirm: "Confirm",
  approve: "Approve",
  clientDetails: "Client Details",
  clientDetailsUpper: "CLIENT DETAILS",
  poweredBy: "Powered by",
  // FORMS
  // titles
  mr: "Mr.",
  mrs: "Mrs.",
  ms: "Ms.",
  miss: "Miss",
  dr: "Dr.",

  // address
  billingAddress: "Billing Address",
  city: "City",
  country: "Country",
  countryCode: "Country Code",
  postalCode: "Postal Code",
  propertyAddress: "Property Address",
  billingAddressSamePropertyAddress:
    "Billing address is the same as this property address",
  street1: "Street 1",
  street2: "Street 2",
  state: "State",
  streetAddress: "Street Address",
  unitNumber: "Unit/House Number and Building Name",
  gpsCoordinates: "GPS Coordinates",
  longitude: "Longitude",
  latitude: "Latitude",
  additionalDetails: "Additional Details",

  // gender
  gender: "Gender",
  male: "Male",
  female: "Female",
  other: "Not Specified",

  // company settings
  businessManagement: "Business Management",
  businessSettings: "Business Settings",
  contactEmail: "Contact Email",
  contactPhoneNumber: "Contact Phone Number",
  companyAddress1: "Address Line 1",
  companyAddress2: "Address Line 2 (optional)",
  companyType: "Company Type",
  taxNumbers: "Tax Numbers",
  taxNumber: "Tax Number",
  addTaxNumber: "+ Add Tax Number",
  taxRates: "Tax Rates",
  percentage: "Percentage",
  addTaxRate: "+ Add Tax Rate",
  invoiceMessageTemplate: "Default Invoice Message",

  // others
  additionalNotes: "Additional Notes",
  addPhoneNumber: "Add Another Phone Number",
  addEmail: "Add Another Email",
  companyNamePrimary: "Use company name as the primary name",
  companyName: "Company name",
  companyNameOptional: "Company name is optional",
  confirmNewPassword: "Confirm new password",
  confirmPassword: "Confirm your password",
  firstName: "First Name",
  lastName: "Last Name",
  mobileNumber: "Your mobile number",
  newPassword: "New Password",
  organisationName: "Organisation Name",
  password: "Password",
  receiveSms: "This number can receive SMS.",
  yourEmail: "Your email address",
  yourPassword: "Your password",
  confirmYourPassword: "Confirm your password",
  yourCountry: "Your country",
  yourMobileNumber: "Your mobile number",
  title: "Title",
  type: "Type",
  products: "Products",
  services: "Services",
  companyOrganisationName: "Company or Organization Name",
  enterMessage: "Enter Your Message",
  yourName: "Your name",
  job: "Job",
  invoice: "Invoice",
  jobs: "Jobs",
  invoices: "Invoices",
  property: "Property",
  createNew: "Create new",
  new: "New",
  status: "Status",
  settings: "Settings",
  payment: "Payment",
  client: "Client",
  info: "Info",
  notes: "Notes",
  send: "Send",
  forDemoOnly: "For demonstration purposes only.",
  compulsoryField: "* these fields are required",
  approveAnd: "Approve and...",
  create: "Create",

  sendEmail: "Send Email",
  sendingEmail: "Sending Email..",
  sendSms: "Send SMS",
  sendingSms: "Sending SMS..",
  sendInvoiceToEmail: "Email Invoice to...",
  sendQuoteToEmail: "Email Quote to...",
  sendReceiptToEmail: "Email Receipt to...",
  smsInvoiceTo: "SMS Invoice to...",
  smsQuoteTo: "SMS Quote to...",
  paymentAppliedToInvoice: "Payment applied to Invoice",
  noAddressRecorded: "No address recorded.",
  noEmailRecorded: "No email recorded.",
  noPhoneRecorded: "No phone number recorded.",
  noEmailPhoneRecorded: "No email or phone number recorded.",
  sendNotification: "Send notification",
  assignJob: "Assign Job",
  message: "Message",
  convertToJob: "Convert to Job",
  all: "All",
  accept: "Accept",
  reject: "Reject",
  sunday: "Sunday",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  dropFileToUpload: "Drop file here or click to upload",
  workSettings: "Work Settings",
  accountSettingTitle: "Personal Information & Preference",
  accountSetting: "Account Setting",
  founder_ceo: "Founder/CEO",
  director: "Director",
  senior_manager: "Senior Manager",
  manager: "Manager",
  supervisor: "Supervisor",
  associate_or_xecutive: "Associate or Executive",
  other_employee: "Other Employee",
  // Language
  english: "English",
  thailand: "ThaiLand",

  // filter by date
  last30Days: "Last 30 days",
  thisMonth: "This month",
  lastMonth: "Last month",
  thisYear: "This year",
  custom: "Custom",
  issuedAt: "Issued at",
  number: "Number",
  team: "TEAM",
  jobInfo: "JOB INFO",
  recordPayment: "Record Payment",
  completed: "Action completed",
  date: "Date",

  // filter by worker, status
  back: "Back",
  backHubs: "Back: Hubs",
  filter: "Filter",
  apply: "Apply",
  clear: "Clear",
  assignedWorker: "Assigned Worker",
  on: "On",
  off: "Off",
  createdOn: "Create On",
  scheduledOn: "Scheduled On",
  cantFindWorker: "Can't find the worker you're looking for?",
  tryToSearch: "Try typing the worker's name to search.",
  allDates: "All dates",
  clientLogin: "Client Login",
  add: "Add",
  category: "Category Tag",
  selectCategory: "Select category tag",
  discard: "Discard",
  searchCategory: "Search category tag",
  deactivated: "Deactivate",
  role: "Role",

  propertyName: "Property Name",
  propertyPlaceholder: "Property Name (Home/Office/School...)",
  dateFormat: "MMM DD, YYYY",
  dateTimeFormat: "MMM DD, YYYY - hh:mm A",
  timeFormat: "hh:mm A",
  download: "Download",
  complete: "Complete",
  textCopied: "Copied",
  export: "Export",
  someThingWentWrong: "Something Went Wrong",
  "*": "*",
  completionTime: "Completion Time",
  model: "Model.",
  copy: "Copy",
  view: "View",
  generalInfo: "General Info",
  enterpriseUser: "Enterprise Users",
  warehouse: "Warehouse",
  service: "Service",
  product: "Product",
  verifiedJobsOnly: "Verified Jobs Only",
};
