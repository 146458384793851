// reducers.js

const SET_TODAY_ONLY = 'SET_TODAY_ONLY';

const initialState = {
    todayOnly: false, // Initial state
};

export function calendarReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TODAY_ONLY:
            return {
                ...state,
                todayOnly: action.payload,
            };
        default:
            return state;
    }
}

// You can export the action type as well if needed
export const setTodayOnly = (todayOnly) => ({
    type: SET_TODAY_ONLY,
    payload: todayOnly,
});
