import React from 'react';

// Redux
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

// Material components
import {
  Button,
  Grid,
  InputAdornment,
} from '@material-ui/core';

// Material helpers
import { withStyles } from '@material-ui/core';

// Customised components
import { TextInput } from 'components/inputs';
import ServiceProductInput from './ServiceProductInput';

// Component styles
import LineProductInputStyle from './styles/LineProductInputStyle';
import { normalizePrice, formatPriceInput } from 'lib/formatter';

const mapStateToProps = state => ({
  products: state.product.products || [],
  services: state.product.services || [],
  currentCompany: state.auth.currentUser.companies[0],
});


const LineProductInput = props => {
  const { classes, products, services, currentCompany,
    currencyCode, name, handleDelete, index, translate,
  } = props;

  let companyTag = currentCompany.tag || '';
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();
  const isInDoD2UFranchisee = companyTag === 'disinfection2u' && currentCompany.countryCode === 'ID';
  const servicesArray = Object.keys(services).map(service => {
    const item = services[service];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const productsArray = Object.keys(products).map(product => {
    const item = products[product];
    const data = {};
    data.label = item.name;
    data.value = item;
    return data;
  });

  const groupedOptions = [
    {
      title: 'Services',
      suggestions: servicesArray
    },
    {
      title: 'Products',
      suggestions: productsArray
    }
  ];

  const taxRateField = (field) => {
    return (
      <div className={classes.inputTaxRow}>
        <span>{field.input && field.input.value ? field.input.value : '0.00'}</span>
      </div>
    );
  };

  return (
    <Grid container direction='column' spacing={2}>
      { isInDoD2UFranchisee &&
        <Grid container direction='row' spacing={2}>
          <Grid item container direction='column' spacing={0} lg={4} md={4} sm={12}>
            <Grid item>
              <Field
                inputClass={classes.productName}
                name={`${name}.name`}
                placeholder={translate('Common:name')}
                component={ServiceProductInput}
                variant='outlined'
                margin='dense'
                options={groupedOptions}
                index={index}
                translate={translate}
              />
            </Grid>
            <Grid item>
              <Field
                className={classes.productDesc}
                name={`${name}.description`}
                placeholder={translate('Common:description')}
                component={TextInput}
                variant='outlined'
                margin='dense'
                multiline
                rows='2'
              />
            </Grid>
          </Grid>
          {/* QUANTITY */}
          <Grid item lg={2} md={2} sm={12}>
            <Field
              name={`${name}.quantity`}
              placeholder={translate('qty')}
              component={TextInput}
              variant='outlined'
              margin='dense'
              type='number'
              inputProps={{ min: 1 }}
            />
          </Grid>
          {/* UNIT COST */}
          <Grid item lg={3} md={3} sm={12}>
            <Field
              name={`${name}.unitPrice`}
              placeholder={translate('unitPrice')}
              component={TextInput}
              variant='outlined'
              type='text'
              margin='dense'
              InputProps={{
                startAdornment: <InputAdornment position='start'>{currencyCode}</InputAdornment>,
                inputProps: {
                  min: 0,
                  step: '.01'
                }
              }}
              normalize={normalizePrice(currencyCode)}
              format={formatPriceInput(currencyCode)}
            />
          </Grid>


          {/* TOTAL COST */}
          <Grid item container direction='column' justify='space-between' spacing={0} lg={3} md={3} sm={12}>
            <Grid item>
              <Field
                name={`${name}.totalIncludeTax`}
                placeholder={translate('totalPrice')}
                component={TextInput}
                variant='outlined'
                margin='dense'
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment>,
                  readOnly: true,
                }}
                normalize={normalizePrice(currencyCode)}
                format={formatPriceInput(currencyCode)}
              />
            </Grid>
            <Grid item container justify='flex-end' alignItems='flex-end'>
              <Button
                className={classes.deleteButton}
                variant='outlined'
                size='small'
                onClick={handleDelete}
              >
                {translate('Common:delete')}
              </Button>
            </Grid>
          </Grid>
        </Grid>}

      {/* For non-indonesia D2U */}
      { !isInDoD2UFranchisee &&
        <Grid container direction='row' spacing={2}>
          <Grid item container direction='column' spacing={0} lg={4} md={4} sm={12}>
            <Grid item>
              <Field
                inputClass={classes.productName}
                name={`${name}.name`}
                placeholder={translate('Common:name')}
                component={ServiceProductInput}
                variant='outlined'
                margin='dense'
                options={groupedOptions}
                index={index}
                translate={translate}
              />
            </Grid>
            <Grid item>
              <Field
                className={classes.productDesc}
                name={`${name}.description`}
                placeholder={translate('Common:description')}
                component={TextInput}
                variant='outlined'
                margin='dense'
                multiline
                rows='2'
              />
            </Grid>
          </Grid>
          {/* QUANTITY */}
          <Grid item lg={2} md={2} sm={12}>
            <Field
              name={`${name}.quantity`}
              placeholder={translate('qty')}
              component={TextInput}
              variant='outlined'
              margin='dense'
              type='number'
              inputProps={{ min: 1 }}
            />
          </Grid>
          {/* UNIT COST */}
          <Grid item lg={2} md={2} sm={12}>
            <Field
              name={`${name}.unitPrice`}
              placeholder={translate('unitPrice')}
              component={TextInput}
              variant='outlined'
              type='text'
              margin='dense'
              InputProps={{
                startAdornment: <InputAdornment position='start'>{currencyCode}</InputAdornment>,
                inputProps: {
                  min: 0,
                  step: '.01'
                }
              }}
              normalize={normalizePrice(currencyCode)}
              format={formatPriceInput(currencyCode)}
            />
          </Grid>

          {/* TAXT */}
          <Grid item lg={2} md={2} sm={12}>
            <Field
              name={`${name}.taxRate.percentage`}
              component={taxRateField}
              variant='outlined'
              margin='dense'
            />
          </Grid>
          {/* TOTAL COST */}
          <Grid item container direction='column' justify='space-between' spacing={0} lg={2} md={2} sm={12}>
            <Grid item>
              <Field
                name={`${name}.total`}
                placeholder={translate('totalPrice')}
                component={TextInput}
                variant='outlined'
                margin='dense'
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment>,
                  readOnly: true,
                }}
                normalize={normalizePrice(currencyCode)}
                format={formatPriceInput(currencyCode)}
              />
            </Grid>
            <Grid item container justify='flex-end' alignItems='flex-end'>
              <Button
                className={classes.deleteButton}
                variant='outlined'
                size='small'
                onClick={handleDelete}
              >
                {translate('Common:delete')}
              </Button>
            </Grid>
          </Grid>
        </Grid>}
    </Grid>
  );
};

export default compose(
  withStyles(LineProductInputStyle),
  connect(mapStateToProps, null),
)(LineProductInput);