import {
  Table,
  InputAdornment,
  Menu,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { showErrorMessage } from "lib/notifier";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  changeConfirmModal,
  mediaActions,
  setVisitSelected,
} from "redux/media";
import { getVisits } from "redux/visit";
import SearchIcon from "@material-ui/icons/Search";
import styles from "../styles.module.scss";
import { getDateTimeFormat } from "lib/formatter";

const VisitMenu = ({
  anchorEl,
  setAnchorEl,
  translate,
  mediaIds,
  dispatch,
  job,
  getMediaDetails,
  isShowConfirmModal,
  fetchVisits,
}) => {
  const [visits, setVisits] = useState([]);
  const [allVisits, setAllVisits] = useState([]);
  useEffect(() => {
    if (!job || !job.publicId) return null;

    (async () => {
      const result = await dispatch(
        getVisits({ jobId: job.publicId })
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      setVisits(result.data.data);
      setAllVisits(result.data.data);
    })();
  }, [job, dispatch]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showConfirmModal = () => {
    dispatch(changeConfirmModal(true));
  };

  const handleAssignVisit = async (item) => {
    if (isShowConfirmModal) {
      showConfirmModal();
      dispatch(setVisitSelected(item));
    } else {
      const result = await dispatch(
        mediaActions({
          visitId: item.id,
          action: "ASSIGN_OR_REMOVE_MEDIA_TO_VISIT",
          mediaIds,
        })
      ).catch((e) => ({ e }));
      if (result.e) return showErrorMessage(result.e);
      await fetchVisits();
      getMediaDetails && getMediaDetails();
    }
    handleClose();
  };

  const filterJob = (query) => {
    if (query) {
      const filteredList = allVisits.filter((visit) => {
        const fullTitle = visit.title.toLowerCase();
        const fullId = visit.publicId.toLowerCase();
        const searchResult =
          fullId.includes(query.toLowerCase()) ||
          fullTitle.includes(query.toLowerCase());
        return searchResult;
      });
      setVisits(filteredList);
    } else {
      setVisits(allVisits);
    }
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      keepMounted
      elevation={2}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      classes={{ paper: styles.visit_menu }}
    >
      <TextField
        classes={{ root: styles.searchBox }}
        placeholder="Search"
        variant="filled"
        onChange={(e) => filterJob(e.target.value)}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Table>
        <TableHead>
          <TableRow className={styles.table_header}>
            <TableCell className={styles.header_text}>
              {translate("start")}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate("visitId")}
            </TableCell>
            <TableCell className={styles.header_text}>
              {translate("title")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(visits) &&
            visits.map((visit) => (
              <TableRow
                key={visit.id}
                className={styles.hover}
                onClick={() => handleAssignVisit(visit)}
              >
                <TableCell className={styles.table_content}>
                  {moment(visit.start).format(getDateTimeFormat())}
                </TableCell>
                <TableCell className={styles.table_content}>
                  {visit.publicId}
                </TableCell>
                <TableCell className={styles.table_content}>
                  {visit.title}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Menu>
  );
};

export default VisitMenu;
