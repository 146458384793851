import React, { useState, useEffect } from "react";

// Material components
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Grow,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  withStyles,
  InputBase,
} from "@material-ui/core";
import { getJobs } from "redux/job";
import { useDispatch } from "react-redux";

// Material icons
import { Close as CloseIcon, Search as SearchIcon } from "@material-ui/icons";
// Component styles
import SelectJobModalStyle from "./styles";
import { showErrorMessage } from "lib/notifier";

const SEARCH_TIMEOUT = 800;
let timer = 0;

function SelectJobModal(props) {
  const {
    classes,
    open,
    handleCancelJobSelection,
    handleFinishJobSelection,
    translate,
    client,
  } = props;
  const [selectedJobIds, setSelectedJobs] = useState([]);
  const [jobList, setJobList] = useState([]);
  const dispatch = useDispatch();

  const fetchJobList = async (text) => {
    const params = {
      limit: 20,
      skip: 0,
      clientId: client.id,
      searchString: text,
    };
    const response = await dispatch(getJobs(params)).catch((e) => ({ e }));
    if (response.e) return showErrorMessage(response.e);
    setJobList(response.data.data);
  };

  useEffect(() => {
    fetchJobList();
  }, [fetchJobList]);

  function toggleSelectJobs(event, job) {
    const jobIds = [...selectedJobIds];
    const idPosition = jobIds.indexOf(job.publicId);
    if (idPosition === -1) return setSelectedJobs([...jobIds, job.publicId]);

    jobIds.splice(idPosition, 1);
    setSelectedJobs(jobIds);
  }

  function FinishedJobSelection() {
    handleFinishJobSelection(selectedJobIds);
  }

  const handleSearchText = (e) => {
    const text = e.target.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      fetchJobList(text);
    }, SEARCH_TIMEOUT);
  };

  return (
    <Dialog
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      open={open}
      transition={Grow}
      classes={{ paper: classes.root }}
    >
      <DialogTitle disableTypography className={classes.jobDialogTitle}>
        <Typography variant="h6" className={classes.jobDialogTitleText}>
          {translate("selectItems")}
        </Typography>
        {open ? (
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleCancelJobSelection}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent dividers className={classes.contentWrapper}>
        <Grid className={classes.inputSearchBox}>
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            autoFocus
            className={classes.inputSearch}
            placeholder={translate("Common:search")}
            inputProps={{ "aria-label": "search categories" }}
            onChange={(e) => handleSearchText(e)}
          />
        </Grid>
        <Grid item container className={classes.selectItemsWrapper}>
          {Array.isArray(jobList) && jobList.length ? (
            <Grid container direction="row">
              {jobList.map((job, index) => {
                return (
                  <Grid
                    item
                    md={12}
                    sm={12}
                    key={index}
                    className={classes.topSpacing}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          classes={{
                            checked: classes.checkboxState,
                            root: classes.noPadding,
                          }}
                          onChange={(event) => toggleSelectJobs(event, job)}
                          value={job.id}
                        />
                      }
                      label={
                        <>
                          <Typography className={classes.publicId}>
                            {job.publicId}
                          </Typography>
                          <Typography className={classes.title}>
                            {job.title}
                          </Typography>
                        </>
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography className={classes.noneAssignText}>
              {translate("noJobAvailable")}
            </Typography>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          {jobList && jobList.length > 0 ? (
            <>
              <Button size="small" onClick={handleCancelJobSelection}>
                {translate("Common:cancel")}
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={!selectedJobIds.length}
                className={classes.submitBtn}
                onClick={FinishedJobSelection}
              >
                {translate("Common:done")}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleCancelJobSelection}
            >
              {translate("Common:ok")}
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(SelectJobModalStyle)(SelectJobModal);
