export const COLORS = {
  white: '#FFFFFF',
  black: '#F7F7F7',
  orange: '#FFB822',
  blue: '#043D8B',
  pink: '#EB008B',

  lighterGray: 'rgba(0, 0, 0, 0.33)',
  lightGray: 'rgba(0, 0, 0, 0.36)',
  darkGray: 'rgba(112, 112, 112, 1)',
  darkerGray: 'rgba(0, 0, 0, 0.6)'
};

export const white = '#FFFFFF';

export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  620: '#707070',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};
export const gray = {
  50: '#E6E6E6',
  100: '#797979'
};
export const black = '#000000';

export const blue = '#0767DB';

export const green = '#45B880';

export const orange = '#FFB822';

export const red = '#ED4740';

export const primary = {
  main: blue,
  light: '#F6F9FD',
  dark: '#0B48A0'
};
