/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  withStyles,
  Drawer,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core";
import { CategoryModal } from "pages/Dashboard/components";
import { useTranslation } from "react-i18next";
import {
  reduxForm,
  Field,
  reset,
  submit,
  change,
  formValueSelector,
  FieldArray,
  getFormValues,
} from "redux-form";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { toggleFilterSidebar, setFilterParams } from "redux/global";
import { SIDE_BAR_FILTER_FORM_NAME } from "common/constant";
import StaffSearch from "../StaffSearch";
import HubSearch from "../HubSearch";
import DateRangePicker from "../DateRangePicker";
import StatusMultipleSelection from "../StatusMultipleSelection";
import { getCategories } from "redux/category";

import {
  Close as CloseIcon,
  AddOutlined as AddOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import { checkIsFiltering, useUpdateFilterSidebar } from "./utils";
import { useLocation } from "react-router";

const styles = () => ({
  textStyle: {
    fontWeight: 600,
    fontSize: 14,
  },
  backgroundBtn: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  shadowNone: {
    boxShadow: "none",
  },
  imgFilter: {
    marginRight: 4,
  },
  paper: {
    minWidth: 400,
    maxWidth: 400,
    overflowX: "hidden",
  },
  header: {
    padding: 20,
    marginBottom: 30,
    paddingBottom: 0,
  },
  closeIcon: {
    marginRight: 8,
  },
  filterContainer: {
    padding: "14px 20px",
  },
  dateContainer: {
    padding: "0px 20px",
  },
  applyBtn: {
    display: "flex",
    alignSelf: "flex-start",
    margin: "10px 20px",
    background: "#2D4E9D",
    borderRadius: 4,
    color: "white",
    "&:hover": {
      background: "#2D4E9D",
    },
  },
  dropdownSelect: {
    minWidth: 122,
  },
  upper_spacing: {
    marginTop: 12,
    width: "100%",
  },
  menuItemStyle: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  btnCategory: {
    maxWidth: 150,
    textAlign: "left",
  },
  checkboxRoot: {
    marginBottom: 0,
  },
  checkboxLabel: {
    paddingTop: 6,
  },
  checked_state: {
    color: "#F9C825 !important",
  },
});

let FilterSidebar = (props) => {
  // eslint-disable-next-line max-len
  const {
    classes,
    handleSubmit,
    pristine,
    isOpenFilterSidebar,
    valid,
    updateFilterStatus,
    submitDispatch,
    jobFiltering,
    isPressedResetBtn,
    setFilterParamsDispatch,
    currentCategories,
    isHideManageCategoryTag,
    enterpriseAccountId,
    formValues,
  } = props;
  const dispatch = useDispatch();
  const { t: translate } = useTranslation("Common");
  const [didMounted, setDidMounted] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const handleCloseSelectCategoryModal = () => setOpenCategoryModal(false);
  const [updateFilterSidebarFromParams] = useUpdateFilterSidebar();
  const location = useLocation();
  const handleCategorySelection = (data) => {
    dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categories", data));
    // convert to ids before submit
    if (Array.isArray(data)) {
      const categoryIds = data.map((category) => category.id);
      dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categoryIds", categoryIds));
    }
    handleCloseSelectCategoryModal(false);
  };

  const handleClickOnCategory = () => setOpenCategoryModal(true);

  const handleResetForm = () => {
    dispatch(reset(SIDE_BAR_FILTER_FORM_NAME));
    updateFilterStatus(false);
    dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "isPressedReset", true));
    dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categoryIds", []));
    dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "hubs", []));
    dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "isVerified", 0));
    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "serviceReport", [
        {
          label: translate("Job:available"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:none"),
          checked: true,
          value: 0,
        },
      ])
    );
    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "lateVisit", [
        {
          label: translate("Job:hasLateVisit"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:hasNoVisit"),
          checked: true,
          value: 0,
        },
      ])
    );

    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "isCollectedItems", [
        {
          label: translate("Job:collected"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:notCollected"),
          checked: true,
          value: 0,
        },
      ])
    );
    // have to set this, to keep query when user change calendar type
    setFilterParamsDispatch({});
  };

  async function getCompanyCategories(options = {}) {
    const result = await dispatch(getCategories(options));
    if (result.status === 200) {
      // setCategories(result.data.data);
      const params = new URLSearchParams(location.search);
      let newList = {};
      const mapTreeCategories = (categoryList) => {
        categoryList.forEach((category) => {
          newList = {
            ...newList,
            [category.id]: {
              ...category,
              checked: true,
            },
          };
          if (
            !Array.isArray(category.childrenCategories) ||
            !category.childrenCategories.length
          )
            return null;
          mapTreeCategories(category.childrenCategories);
        });
      };
      mapTreeCategories(result.data.data);
      const listParams = params.getAll("jobCategoryIds[]");
      let newListParams = [];
      Object.values(newList).forEach((val) => {
        if (listParams.findIndex((elm) => parseInt(elm) === val.id) > -1) {
          newListParams = [
            ...newListParams,
            {
              id: val?.id,
              name: val?.name,
              parentCategoryId: val?.parentCategoryId
                ? val?.parentCategoryId
                : null,
            },
          ];
        }
      });
      if (newListParams.length > 0) {
        const categoryIds = newListParams.map((val) => val.id);
        dispatch(
          change(SIDE_BAR_FILTER_FORM_NAME, "categories", newListParams)
        );
        dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categoryIds", categoryIds));
      } else {
        dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categories", []));
        dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "categoryIds", []));
      }
    }
  }

  useEffect(() => {
    // TODO: will implement load more in next release
    getCompanyCategories({ limit: 100, enterpriseAccountId });
  }, []);

  useEffect(() => {
    if (formValues) {
      updateFilterStatus(checkIsFiltering(formValues, jobFiltering));
    }
  }, [formValues, jobFiltering]);

  const handleCloseFilterSidebar = () => {
    if (valid && isPressedResetBtn) {
      dispatch(change(SIDE_BAR_FILTER_FORM_NAME, "isPressedReset", false));
      submitDispatch(SIDE_BAR_FILTER_FORM_NAME);
    }
    dispatch(toggleFilterSidebar(false));
  };

  const dispatchLateVisit = () => {
    // if (!showLateVisitFilter) return;

    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "lateVisit", [
        {
          label: translate("Job:hasLateVisit"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:hasNoVisit"),
          checked: true,
          value: 0,
        },
      ])
    );
  };

  useEffect(() => {
    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "serviceReport", [
        {
          label: translate("Job:available"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:none"),
          checked: true,
          value: 0,
        },
      ])
    );

    dispatchLateVisit();

    dispatch(
      change(SIDE_BAR_FILTER_FORM_NAME, "isCollectedItems", [
        {
          label: translate("Job:collected"),
          checked: true,
          value: 1,
        },
        {
          label: translate("Job:notCollected"),
          checked: true,
          value: 0,
        },
      ])
    );

    updateFilterSidebarFromParams();
    setDidMounted(true);
  }, [translate, dispatch, dispatchLateVisit, updateFilterSidebarFromParams]);

  if (!didMounted) return null;
  const prepareData = (val) => {
    // close drawer then submit values
    dispatch(toggleFilterSidebar(false));
    const isFiltering = checkIsFiltering(val, jobFiltering);

    updateFilterStatus(isFiltering);

    // have to set this, to keep query when user change calendar type
    if (isFiltering) setFilterParamsDispatch(val);

    return handleSubmit();
  };

  return (
    <>
      {openCategoryModal && (
        <CategoryModal
          open={openCategoryModal}
          handleCloseSelectCategoryModal={handleCloseSelectCategoryModal}
          translate={translate}
          categories={currentCategories}
          handleCategorySelection={handleCategorySelection}
          isHideManageCategoryTag={isHideManageCategoryTag}
          enterpriseAccountId={enterpriseAccountId}
        />
      )}
      <Drawer
        anchor="right"
        open={isOpenFilterSidebar}
        onClose={handleCloseFilterSidebar}
        classes={{ paper: classes.paper }}
      >
        <form onSubmit={handleSubmit(prepareData)}>
          <Header {...props} translate={translate} />
          <Filter
            {...props}
            translate={translate}
            handleClickOnCategory={handleClickOnCategory}
          />
          <Grid container>
            <Button
              disabled={pristine}
              className={classes.applyBtn}
              size="medium"
              variant="contained"
              type="submit"
            >
              {translate("apply")}
            </Button>
            <Button onClick={handleResetForm}>{translate("clear")}</Button>
          </Grid>
        </form>
      </Drawer>
    </>
  );
};

const Header = ({ classes, translate }) => {
  const dispatch = useDispatch();
  return (
    <Grid container className={classes.header}>
      <Grid item container alignItems="center">
        <IconButton
          onClick={() => dispatch(toggleFilterSidebar(false))}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h4">{translate("filter")}</Typography>
      </Grid>
    </Grid>
  );
};

const Filter = ({
  handleClickOnCategory,
  jobFiltering,
  classes,
  translate,
  statues = [],
  currentCategories,
  isHideWorker = false,
  isHideSR = false,
  isHideHub = false,
  showLateVisitFilter = false,
  showFilterCompleteTime = false,
  showFilterCollectedItems = false,
  isEnterprise,
  enterpriseAccountId,
}) => {
  const [newCurrentCategories, setCurrentCategories] = useState(
    currentCategories
  );
  // const [categories, setCategories] = useState([]);
  // const location = useLocation();
  const dispatch = useDispatch();

  const renderBtnContent = () => {
    if (!Array.isArray(currentCategories) || !currentCategories.length)
      return (
        <>
          {translate("Common:selectCategory")}
          <AddOutlinedIcon />
        </>
      );

    return (
      <>
        <Typography className={classes.btnCategory}>
          {newCurrentCategories.map((category, index) => (
            <Typography component="span" key={category.id}>
              {category.name}
              {index + 1 !== newCurrentCategories.length && <> , </>}
            </Typography>
          ))}{" "}
        </Typography>
        <EditOutlinedIcon />
      </>
    );
  };

  const renderLabel = ({ input }) => {
    return <Typography>{input.value}</Typography>;
  };

  const renderCheckBox = ({ input }) => {
    return (
      <Checkbox
        checked={input.value}
        onChange={() => input.onChange(!input.value)}
        classes={{
          checked: classes.checked_state,
        }}
      />
    );
  };

  const renderFilterOption = ({ fields, description }) => (
    <Box flexDirection="column" display="flex" p={2}>
      {description && <Typography>{translate(`${description}`)}</Typography>}
      {fields.map((field) => {
        return (
          <FormControlLabel
            key={field}
            control={
              <Field name={`${field}.checked`} component={renderCheckBox} />
            }
            label={<Field name={`${field}.label`} component={renderLabel} />}
            classes={{
              root: classes.checkboxRoot,
              label: classes.checkboxLabel,
            }}
          />
        );
      })}
    </Box>
  );

  // async function getCompanyCategories(options = {}) {
  //   const result = await dispatch(getCategories(options));
  //   if (result.status === 200) {
  //     setCategories(result.data.data);
  //   }
  // }

  useEffect(() => {
    setCurrentCategories(currentCategories);
  }, [currentCategories]);

  // useEffect(() => {
  //   // TODO: will implement load more in next release
  //   getCompanyCategories({ limit: 100, enterpriseAccountId });
  // }, []);

  return (
    <Grid container>
      <Grid
        item
        container
        justify="space-between"
        alignItems="center"
        className={classes.filterContainer}
      >
        <Grid item>
          <Typography variant="h5">{translate("status")}</Typography>
        </Grid>
        <Grid item className={`${classes.upper_spacing}`}>
          <Field
            name="status"
            component={StatusMultipleSelection}
            variant="outlined"
            margin="dense"
            options={statues}
            isVisitStatus
            isEnterprise={isEnterprise}
            menuItemStyle={classes.menuItemStyle}
          />
        </Grid>
        {jobFiltering && (
          <>
            <Grid
              item
              container
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 8 }}
            >
              <Grid item>
                <Typography variant="h5">{translate("createdOn")}</Typography>
              </Grid>
              <Grid item>
                <Field name="createdOn" component={DateRangePicker} />
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Grid item>
                <Typography variant="h5">{translate("scheduledOn")}</Typography>
              </Grid>
              <Grid item>
                <Field name="scheduledOn" component={DateRangePicker} />
              </Grid>
            </Grid>
          </>
        )}

        {showFilterCompleteTime && (
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            style={{ marginTop: 8 }}
          >
            <Grid item>
              <Typography variant="h5">
                {translate("completionTime")}
              </Typography>
            </Grid>
            <Grid item>
              <Field name="completionTime" component={DateRangePicker} />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          style={{ marginTop: 8 }}
        >
          <Grid item>
            <Typography variant="h5">
              {translate("verifiedJobsOnly")}
            </Typography>
          </Grid>
          <Grid item>
            <Field
              name="isVerified"
              component={({ input }) => {
                const changeValue = (value) => {
                  const temp = 1 - value;
                  input.onChange(temp);
                };
                return (
                  <Checkbox
                    checked={Number(input.value)}
                    onChange={() => changeValue(input.value)}
                    classes={{
                      checked: classes.checked_state,
                    }}
                  />
                );
              }}
            />
          </Grid>
        </Grid>

        {!isHideWorker && (
          <Grid item container direction="column" style={{ marginTop: 14 }}>
            <Grid item>
              <Typography variant="h5">
                {translate("assignedWorker")}
              </Typography>
            </Grid>
            <Grid item className={`${classes.upper_spacing}`}>
              <Field name="staffs" component={StaffSearch} />
            </Grid>
          </Grid>
        )}

        <Grid
          item
          container
          justify="space-between"
          alignItems="center"
          style={{ marginTop: 14 }}
        >
          <Grid item>
            <Typography variant="h5">{translate("category")}</Typography>
          </Grid>
          <Grid item onClick={handleClickOnCategory}>
            <Button variant="outlined">{renderBtnContent()}</Button>
          </Grid>
        </Grid>
{/* 
        {!isHideHub && (
          <Grid item container direction="column" style={{ marginTop: 14 }}>
            <Grid item>
              <Typography variant="h5">{translate("Hub")}</Typography>
            </Grid>
            <Grid item className={`${classes.upper_spacing}`}>
              <Field name="hubs" component={HubSearch} isMulti />
            </Grid>
          </Grid>
        )}

        {!isHideSR && (
          <Grid item container style={{ marginTop: 16 }}>
            <Grid item container>
              <Typography variant="h5">
                {translate("Job:serviceReport")}
              </Typography>
            </Grid>
            <Grid item container>
              <FieldArray
                name="serviceReport"
                component={renderFilterOption}
                translate={translate}
                description="Job:serviceReportFilter"
              />
            </Grid>
          </Grid>
        )}

        {showLateVisitFilter && (
          <Grid item container style={{ marginTop: 16 }}>
            <Grid item container>
              <Typography variant="h5">{translate("Job:lateVisit")}</Typography>
            </Grid>
            <Grid item container>
              <FieldArray
                name="lateVisit"
                component={renderFilterOption}
                translate={translate}
              />
            </Grid>
          </Grid>
        )} */}

        {/* {showFilterCollectedItems && (
          <Grid item container style={{ marginTop: 16 }}>
            <Grid item container>
              <Typography variant="h5">
                {translate("Job:collectedItems")}
              </Typography>
            </Grid>
            <Grid item container>
              <FieldArray
                name="isCollectedItems"
                component={renderFilterOption}
                translate={translate}
              />
            </Grid>
          </Grid>
        )} */}
      </Grid>
    </Grid>
  );
};

const sideBarFilterForm = formValueSelector(SIDE_BAR_FILTER_FORM_NAME);

const mapStateToProps = (state, ownProps) => {
  // const { defaultFilterScheduledOn } = ownProps;
  return {
    initialValues: {
      staffs: "",
      status: "ALL",
      createdOn: "",
      scheduledOn: "",
      serviceReport: "",
      lateVisit: "",
      completionTime: "",
      isVerified: 0,
    },
    formValues: getFormValues(SIDE_BAR_FILTER_FORM_NAME)(state),
    isOpenFilterSidebar: state.global.isOpenFilterSidebar || false,
    isPressedResetBtn: sideBarFilterForm(state, "isPressedReset") || false,
    currentCategories: sideBarFilterForm(state, "categories") || [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  submitDispatch: (formName) => dispatch(submit(formName)),
  setFilterParamsDispatch: (params) => dispatch(setFilterParams(params)),
});

FilterSidebar = reduxForm({
  form: SIDE_BAR_FILTER_FORM_NAME,
  destroyOnUnmount: false,
})(FilterSidebar);

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(FilterSidebar);
