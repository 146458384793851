import React, { useState, useEffect } from "react";

// Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  reduxForm,
  Field,
  change,
  formValueSelector,
  submit,
  arraySplice,
} from "redux-form";

// Material components
import { Grid, Button, Typography, withStyles } from "@material-ui/core";

// Component
import { InvoiceDetailsFormStyle } from "./styles";
import {
  ServiceProductCard,
  InternalNotesCard,
  ServiceProductCardForIndo,
} from "../cards";
import {
  ContactDetails,
  AddressDetails,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletFooter,
  PortletContent,
  ConfirmEmailModal,
  ConfirmSmsModal,
} from "pages/Dashboard/components";

import { OnlyDecimal } from "components/converters";

// shared component
import { TextInput, DatePicker } from "components/inputs";

import SplitButton from "components/buttons/SplitButton";

const getDueByByPaymentDue = (paymentDue, currentDate = null) => {
  if (paymentDue === null || paymentDue === undefined || paymentDue === -1) {
    return null;
  }
  const date = currentDate ? new Date(currentDate) : new Date();

  if (paymentDue === 0) {
    return date;
  }

  date.setDate(date.getDate() + paymentDue);
  return date;
};

const invoiceForm = formValueSelector("invoiceForm");

const mapStateToProps = (state) => {
  const { invoice } = state.invoice;
  const paymentDue = invoice && parseInt(invoice.paymentDue || 0);
  let initalInvoiceItems = (invoice && invoice.items) || [];
  initalInvoiceItems = initalInvoiceItems.map((item) => {
    let itemTaxPercentage = 0;
    if (item.taxRate) {
      itemTaxPercentage = item.taxRate.percentage || 0;
    }

    if (item.taxComponent) {
      itemTaxPercentage = (item.taxComponent.taxRates || []).reduce(
        (sum, taxRate) => {
          return sum + parseFloat(taxRate.percentage || 0);
        },
        0
      );
    }
    const taxAmount = (parseFloat(item.total) * itemTaxPercentage) / 100;
    const totalIncludeTax = parseFloat(item.total) + taxAmount;
    item.totalIncludeTax = totalIncludeTax;
    return item;
  });
  const invoiceSubtotal = (invoice && invoice.subtotal) || 0;
  const invoiceTax = (invoice && invoice.tax) || 0;
  const invoiceSubTotalIncludeTax =
    parseFloat(invoiceSubtotal) + parseFloat(invoiceTax);
  const withholdingTax23 = (0.02 * parseFloat(invoiceSubTotalIncludeTax)) / 1.1;
  const invoiceDiscount = (invoice && invoice.discount) || 0;
  const totalAfterTax23 =
    invoiceSubTotalIncludeTax - invoiceDiscount - withholdingTax23;

  return {
    initialValues: {
      id: invoice.id,
      clientId: invoice && invoice.client && invoice.client.id,
      items: initalInvoiceItems,
      subject: (invoice && invoice.subject) || null,
      clientMessage: invoice && invoice.clientMessage,
      notes: (invoice && invoice.notes) || null,
      discount: invoiceDiscount,
      discountValue: (invoice && invoice.discountValue) || 0,
      discountType: (invoice && invoice.discountType) || "%",
      tax: (invoice && invoice.tax) || 0,
      total: (invoice && invoice.total) || 0,
      subtotal: (invoice && invoice.subtotal) || 0,
      jobIds: (invoice && invoice.jobIds) || [],
      issuedAt: (invoice && invoice.issuedAt) || new Date(),
      dueBy: state.invoice && invoice.dueBy,
      withholdingTax23,
      subTotalIncludeTax: invoiceSubTotalIncludeTax,
      paymentDue,
      totalAfterTax23,
    },
    client: (invoice && invoice.client) || null,
    invoice: invoice || null,
    issuedAt: invoiceForm(state, "issuedAt"),
    paymentDue: invoiceForm(state, "paymentDue"),
    dueBy: invoiceForm(state, "dueBy"),
    paymentDueOptions: state.config.configs.paymentDues,
    currentCompany:
      (state.auth.currentUser &&
        state.auth.currentUser.companies &&
        state.auth.currentUser.companies[0]) ||
      {},
    discountType: invoiceForm(state, "discountType"),
    discountValue: invoiceForm(state, "discountValue"),
    invoiceItems: invoiceForm(state, "items"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateInvoiceForm: (attribute, value) => {
    dispatch(change("invoiceForm", attribute, value));
  },
  submitForm: () => dispatch(submit("invoiceForm")),
  updateInvoiceItem: (index, newItem) => {
    dispatch(arraySplice("invoiceForm", "items", index, 1, newItem));
  },
});

const isDate = (input) => {
  if (input && input.toString() === "Invalid Date") return false;
  if (Object.prototype.toString.call(input) === "[object Date]") return true;
  return false;
};

let InvoiceDetailsForm = (props) => {
  const {
    invoice,
    client,
    handleSubmit,
    classes,
    handleCancelInvoiceForm,
    updateInvoiceForm,
    issuedAt,
    paymentDue,
    setSubmitAction,
    setSubmitActionValue,
    setConfirmStatus,
    submitForm,
    translate,
    paymentDueOptions,
    currentCompany,
    discountValue,
    invoiceItems,
    discountType,
    updateInvoiceItem,
  } = props;
  const [showDueBy, setShowDueBy] = useState(false);
  const [confirmEmailModal, setConfirmEmailModal] = useState(false);
  const [confirmSmsModal, setConfirmSmsModal] = useState(false);
  const [modalType, setModalType] = useState(null);
  const isCustomDueBy = paymentDue && paymentDue === -1;

  let companyTag = currentCompany.tag || "";
  companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, "").trim();

  const isInDoD2UFranchisee =
    companyTag === "disinfection2u" && currentCompany.countryCode === "ID";
  const clientEmail =
    (invoice && invoice.client && invoice.client.email) ||
    (client &&
      client.contactEmails &&
      client.contactEmails.length > 0 &&
      (client.contactEmails.find((email) => email.typeId === "MAIN") || {})
        .email);

  const clientPhone =
    (invoice && invoice.client && invoice.client.phoneNumber) ||
    (client &&
      client.phones &&
      client.phones.length > 0 &&
      (client.phones.find((phone) => phone.typeId === "MAIN") || {})
        .phoneNumber);

  const { displayName } = client || {};
  const clientName = displayName;

  function handleChangePaymentDue(event) {
    const { value } = event.target;
    switch (parseInt(value)) {
      case -1:
        updateInvoiceForm("paymentDue", -1);
        updateInvoiceForm("dueBy", invoice.dueBy);
        setShowDueBy(true);
        break;

      case 0:
        updateInvoiceForm("paymentDue", 0);
        updateInvoiceForm("dueBy", getDueByByPaymentDue(0, issuedAt));
        setShowDueBy(false);
        break;

      case 7:
        updateInvoiceForm("paymentDue", 7);
        updateInvoiceForm("dueBy", getDueByByPaymentDue(7, issuedAt));
        setShowDueBy(false);
        break;

      case 14:
        updateInvoiceForm("paymentDue", 14);
        updateInvoiceForm("dueBy", getDueByByPaymentDue(14, issuedAt));
        setShowDueBy(false);
        break;

      case 30:
        updateInvoiceForm("paymentDue", 30);
        updateInvoiceForm("dueBy", getDueByByPaymentDue(30, issuedAt));
        setShowDueBy(false);
        break;

      default:
        updateInvoiceForm("dueBy", null);
        updateInvoiceForm("paymentDue", null);
        setShowDueBy(false);
    }
  }

  // Tax is applied after discounts.
  // For indo, it's discount before tax23
  function updateInvoiceWhenItemChange() {
    let invoiceTaxAmount = 0;
    let discountAmount = 0;
    let totalAmount = 0;
    let discountPercentage = 0;

    const subtotalBeforeDiscount = (invoiceItems || []).reduce(
      (sum, currentItem) => {
        return (
          sum +
          parseFloat(currentItem.unitPrice) * parseFloat(currentItem.quantity)
        );
      },
      0
    );

    if (!discountValue || parseFloat(discountValue) <= 0) {
      discountAmount = 0;
      discountPercentage = 0;
    } else if (discountType === "%") {
      discountPercentage = parseFloat(discountValue) / 100;
      discountAmount =
        (parseFloat(discountValue) * subtotalBeforeDiscount) / 100;
    } else {
      discountAmount = parseFloat(discountValue);
      discountPercentage = parseFloat(discountValue) / subtotalBeforeDiscount;
    }
    const subtotalAfterDiscount =
      parseFloat(subtotalBeforeDiscount) - parseFloat(discountAmount);

    (invoiceItems || []).forEach((item, index) => {
      let sumTaxPercentage = 0;
      if (item.taxRate) {
        sumTaxPercentage += parseFloat(item.taxRate.percentage || 0);
      }

      if (item.taxComponent) {
        sumTaxPercentage += (item.taxComponent.taxRates || []).reduce(
          (sum, taxRate) => {
            return sum + parseFloat(taxRate.percentage || 0);
          },
          0
        );
      }

      const itemInitialTotal =
        parseFloat(item.quantity) * parseFloat(item.unitPrice);
      const itemDiscount = parseFloat(itemInitialTotal) * discountPercentage;
      const itemTotalAfterDiscount =
        parseFloat(itemInitialTotal) - itemDiscount;

      const itemTaxAmount =
        (itemTotalAfterDiscount * parseFloat(sumTaxPercentage)) / 100;

      const newItem = {
        ...item,
        total: OnlyDecimal(itemTotalAfterDiscount),
        tax: itemTaxAmount,
        totalIncludeTax: OnlyDecimal(itemTotalAfterDiscount + itemTaxAmount),
      };

      updateInvoiceItem(index, newItem);
      invoiceTaxAmount += itemTaxAmount;
    });

    const subTotalIncludeTax = subtotalAfterDiscount + invoiceTaxAmount;

    totalAmount =
      parseFloat(subtotalAfterDiscount) + parseFloat(invoiceTaxAmount);

    const withholdingTax23 = (0.02 * parseFloat(subTotalIncludeTax)) / 1.1;
    const totalAfterTax23 = totalAmount - withholdingTax23;

    updateInvoiceForm("subtotal", OnlyDecimal(subtotalAfterDiscount));
    updateInvoiceForm("discount", OnlyDecimal(discountAmount));
    updateInvoiceForm("tax", OnlyDecimal(invoiceTaxAmount));
    updateInvoiceForm("total", OnlyDecimal(totalAmount));
    updateInvoiceForm("subTotalIncludeTax", OnlyDecimal(subTotalIncludeTax));
    updateInvoiceForm("withholdingTax23", OnlyDecimal(withholdingTax23));
    updateInvoiceForm("totalAfterTax23", OnlyDecimal(totalAfterTax23));
  }

  useEffect(() => {
    updateInvoiceWhenItemChange();
  }, [discountValue, invoiceItems, discountType, updateInvoiceWhenItemChange]);

  function issuedAtChange(date) {
    if (isDate(date) && parseInt(paymentDue) >= 0) {
      updateInvoiceForm(
        "dueBy",
        getDueByByPaymentDue(parseInt(paymentDue), date)
      );
    }
  }

  function handleEmailInvoice() {
    setModalType("emailInvoice");
    setConfirmStatus(true);
    setConfirmEmailModal(true);
  }

  function handleSmsInvoice() {
    setConfirmStatus(true);
    setConfirmSmsModal(true);
  }

  const handleConfirmInvoiceEmail = (value) => {
    setConfirmEmailModal(false);
    setSubmitAction("email");
    setSubmitActionValue(value);
    submitForm();
  };

  const handleConfirmSmsPhone = (value) => {
    setConfirmSmsModal(false);
    setSubmitAction("sms");
    setSubmitActionValue(value);
    submitForm();
  };

  const handleCloseConfirmEmailModal = () => {
    setModalType(null);
    setConfirmEmailModal(false);
  };

  const handleApproveForm = () => {
    setConfirmStatus(true);
    submitForm();
  };

  const handleCloseConfirmSmsModal = () => setConfirmSmsModal(false);

  const saveButtonOptions = {
    title: translate("Common:approve"),
    menuId: "save-options",
    action: handleApproveForm,
    menu: [
      {
        title: translate("Common:approveAnd"),
        menuItems: [
          {
            label: translate("Common:email"),
            action: handleEmailInvoice,
          },
          {
            label: translate("Common:sms"),
            action: handleSmsInvoice,
          },
        ],
      },
    ],
  };

  return (
    <form
      autoComplete="off"
      className={classes.formField}
      onSubmit={handleSubmit}
    >
      <Portlet>
        <PortletHeader noDivider className={classes.cardHeader}>
          <PortletLabel
            title={client && `${translate("invoiceFor")} ${clientName}`}
            variant="h3"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <Grid container direction="row">
            <Grid
              container
              item
              xl={8}
              lg={8}
              md={8}
              xs={12}
              className={classes.leftContent}
            >
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                className={classes.invoiceInfo}
              >
                <Typography variant="h5" className={classes.title}>
                  {translate("invoiceSubject")}
                </Typography>

                <Field
                  name="subject"
                  component={TextInput}
                  placeholder={translate("inputInvoiceSubject")}
                  variant="outlined"
                  margin="dense"
                  className={classes.subtitle}
                />
              </Grid>

              <Grid item container direction="row" className={classes.contact}>
                <Grid item container direction="column" md={8} sm={12}>
                  <AddressDetails
                    address={
                      (invoice && invoice.billingAddress) ||
                      (client && client.billingAddress)
                    }
                    translate={translate}
                    title={translate("billingAddress")}
                  />
                </Grid>
                <Grid item container direction="column" md={4} sm={12}>
                  <ContactDetails
                    contactEmail={clientEmail || null}
                    contactPhoneNumber={clientPhone || null}
                    translate={translate}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              xs={12}
              className={classes.rightContent}
            >
              <Grid
                item
                container
                direction="row"
                spacing={1}
                md={12}
                sm={12}
                className={classes.title}
              >
                <h5>{translate("invoiceDetails")}</h5>
              </Grid>
              <Grid item container direction="row" spacing={1} md={12} sm={12}>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="type-name">{translate("issuedAt")}</div>
                </Grid>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="value">
                    <Field
                      name="issuedAt"
                      component={DatePicker}
                      minDate={null}
                      onChange={(value) => issuedAtChange(value)}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item container direction="row" spacing={1} md={12} sm={12}>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="type-name">{translate("paymentDue")}</div>
                </Grid>
                <Grid item md={6} sm={12} className={classes.dateItem}>
                  <div className="value">
                    <Field
                      name="paymentDue"
                      component={TextInput}
                      select
                      label=""
                      margin="dense"
                      required
                      variant="outlined"
                      SelectProps={{ native: true }}
                      onChange={handleChangePaymentDue}
                    >
                      {paymentDueOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </Field>
                  </div>
                </Grid>
              </Grid>

              {(showDueBy || !!isCustomDueBy) && (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  md={12}
                  sm={12}
                >
                  <Grid item md={6} sm={12} className={classes.dateItem}>
                    <div className="type-name">{translate("dueDate")}</div>
                  </Grid>
                  <Grid item md={6} sm={12} className={classes.dateItem}>
                    <div className="value">
                      <Field
                        name="dueBy"
                        component={DatePicker}
                        minDate={issuedAt && issuedAt}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item md={12} sm={12}>
            {isInDoD2UFranchisee ? (
              <ServiceProductCardForIndo translate={translate} />
            ) : (
              <ServiceProductCard translate={translate} />
            )}
          </Grid>

          <Grid item md={12} sm={12}>
            <InternalNotesCard translate={translate} />
          </Grid>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Grid container direction="row" justify="flex-end" spacing={1}>
            <Grid item>
              <Button color="secondary" onClick={handleCancelInvoiceForm}>
                {translate("Common:cancel")}
              </Button>
            </Grid>
            <Grid item>
              <Button color="primary" type="submit">
                {translate("saveInvoice")}
              </Button>
            </Grid>
            <Grid item>
              <SplitButton {...saveButtonOptions} translate={translate} />
            </Grid>
          </Grid>
        </PortletFooter>
      </Portlet>

      {confirmEmailModal && modalType === "emailInvoice" && (
        <ConfirmEmailModal
          onSubmit={handleConfirmInvoiceEmail}
          open={confirmEmailModal && modalType === "emailInvoice"}
          handleClose={handleCloseConfirmEmailModal}
          translate={translate}
          title="sendInvoiceToEmail"
          clientEmail={clientEmail || null}
        />
      )}
      {confirmSmsModal && (
        <ConfirmSmsModal
          onSubmit={handleConfirmSmsPhone}
          open={confirmSmsModal}
          handleClose={handleCloseConfirmSmsModal}
          translate={translate}
        />
      )}
    </form>
  );
};

InvoiceDetailsForm = reduxForm({
  form: "invoiceForm",
})(InvoiceDetailsForm);

export default compose(
  withRouter,
  withStyles(InvoiceDetailsFormStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(InvoiceDetailsForm);
