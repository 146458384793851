export default {
  // common && crumbs
  addClient:	'+ Add client',
  clientDetails:	'Client Details',
  editClient:	'Edit Client',
  clients: 'Clients',
  importClients: 'Import Clients',
  exportClients: 'Export Clients',
  clientList: 'Client List ',

  // clients table
  status:	'Status',
  type:	'Type',
  name:	'Name',
  email:	'Email',
  phone:	'Phone',
  actions:	'Actions',
  search:	'Search',
  showArchived:	'Show Archived',
  rows:	'rows',
  of:	'of',
  viewClient:	'View Client',
  location: 'Location?',

  // details
  properties:	'Properties',
  overview:	'Overview',
  schedule:	'Schedule',
  contactInfo:	'Contact Info',
  billingHistory:	'Billing History',
  noBillingDetails:	'No Billing Details',
  currentBalance:	'Current Balance',
  paymentForInvoice:	'Payment for invoice',
  paymentForAccount:	'Payment for account',
  internalNotes:	'Internal Note and attachments',
  saveClient:	'Save Client',
  job: 'Job',
  invoice: 'Invoice',
  property: 'Property',
  payment: 'Payment',
  addPropertyAddress: 'Add Property Address',
  addBillingAddress: 'Add Billing Address',

  // selectjobmodal
  selectItems: 'Select item(s)',
  noJobAvailable: 'There is no job available for this client.',

  // createpropertymodal
  createNewProperty: 'Create New Property',
  createProperty: 'Create Property',

  // updatepaymentmodal
  appliedTo: 'Applied to',
  paymentDate: 'Transaction Date',
  paymentMethod: 'Method',
  emailReceipt: 'Email Receipt',
  downloadPdf: 'Download PDF',
  amount: 'Amount',
  chequeNumber: 'Cheque Number',
  reference: 'Reference',
  details: 'Details',

  // confirmdeletemodal
  deleteProduct: 'DELETE PRODUCT?',
  deleteService: 'DELETE SERVICE',
  permanentDelete: 'Deleting Payment <0><0></0></0> will permanently remove it from WorkMagic',
  deletePayment: 'DELETE PAYMENT <0><0></0></0>',

  // notifications
  archivedSuccess:	'{{clientName}} has been archived',
  unarchivedSuccess:	'{{clientName}} has been unarchived',
  askConfirmationBeforeArchive:	'Do you want to archive this client?',
  askConfirmationBeforeUnarchive:	'Do you want to unarchive this client?',
  updatedSuccess:	'{{clientName}} has been updated',
  // Property
  searchAddress:	'Search Address',
  clientHasNoName: 'Client has no name.',
  noJobToCreateInvoice: 'This client have no job to create invoice.',
  noInvoice: 'There is no invoice for this client.',
  createInvoiceNow: 'Create a new invoice now!',
  noJob: 'There is no job for this client.',
};