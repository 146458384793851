import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
// Actions
import { signOutUser } from 'api/authenticationApi';

// Material helpers and components
import {
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  ClickAwayListener,
  Paper,
  MenuItem,
  MenuList,
  Avatar,
  withStyles,
} from '@material-ui/core';

// Material icons
import {
  SettingsOutlined as SettingsIcon,
} from '@material-ui/icons';

// Component styles
import styles from './styles';
import './index.scss';
import { ChangeLanguageMenu } from 'components/popups';

import StaffDefaultAvatar from 'assets/images/staff_default_avatar.svg';

const Topbar = (props) => {
  const {
    classes,
    className,
    history,
    title,
    childTitle,
    currentUser,
    translate,
  } = props;
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);

  const rootClassName = classNames(classes.root, className);

  const handleSignOut = () => {
    signOutUser();

    let companyTag = (currentUser &&
      currentUser.companies &&
      currentUser.companies[0] &&
      currentUser.companies[0].tag) || '';
    companyTag = companyTag.replace(/(\r\n|\n|\r)/gm, '').trim();

    if (companyTag === 'disinfection2u') {
      return history.push('/disinfection2u-log-in');
    }
    history.push('/log-in');
  };

  const handleClick = () => {
    setOpenDropdownMenu(!openDropdownMenu);
  };

  const handleClickAway = () => {
    setOpenDropdownMenu(false);
  };

  return (
    <>
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row" alignItems="center" sm={6}>
              <Grid item>
                <Typography
                  className={classes.title}
                  variant="h4"
                >
                  {title}
                </Typography>
              </Grid>
              {childTitle && (
                <Typography className={classes.childTitle} variant="h5">
                  {`/ ${childTitle}`}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              sm={6}
            >
              <Grid item>
                <ChangeLanguageMenu selectedLanguage={i18n.language} />
              </Grid>
              <Grid item>
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className={classNames(classes.downdownMenu)}>
                    <Tooltip title={translate('Common:settings')}>
                      <IconButton
                        className={classes.signOutButton}
                        onClick={handleClick}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                    {openDropdownMenu ? (
                      <Paper className={classes.paper}>
                        <div className="dropdown-header">
                          <div className={classes.menuForUserInfo}>
                            <div className={classes.userAvatar}>
                              <Avatar
                                className={classes.avatar}
                                src={(currentUser || {}).avatar || StaffDefaultAvatar}
                              />
                            </div>
                            <div className={classes.userInfo}>
                              <span className="username">
                                {currentUser.fullName}
                              </span>
                              <span className="email">{currentUser.email}</span>
                            </div>
                          </div>
                        </div>
                        <MenuList className={classes.menuList}>
                          {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                          {/* <Divider /> */}
                          <MenuItem onClick={handleSignOut} className={classes.menuItem}>
                            {translate('Common:logout')}
                          </MenuItem>
                        </MenuList>
                      </Paper>
                    ) : null}
                  </div>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </div>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string,
};

Topbar.defaultProps = {
  onToggleSidebar: () => {},
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  withStyles(styles)
)(Topbar);
