import React, { useState, useEffect } from 'react';
import { Trans } from 'react-i18next';
import styles from './styles.module.css';
import { Card, Grid, Button, Checkbox as CheckboxMUI, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TextInput, Checkbox } from 'components/inputs';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues, change } from 'redux-form';
import { formatPrice as fp } from 'lib/formatter';
import { DEFAULT_PLAN } from 'common/constant';
import { Link } from 'react-router-dom';
import PricingPlan from 'components/publicComponents/PricingPlan';
import moment from 'moment';

const SubscriptionForm = (props) => {
  const { translate, formValues, handleSubmit, plans, changeField, submitting,
    geoCountry, paymentMethod } = props;
  const { planCode, billingInterval, useTeamTracking, useTimesheet } = formValues;
  const [agree, setAgree] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const hasBillingMethod = paymentMethod && Object.keys(paymentMethod).length > 0;

  useEffect(() => {
    if (hasBillingMethod) {
      setAgree(true);
    }
  }, [hasBillingMethod]);

  function handleChangePlan() {
    // reset add-ons values
    changeField('useTimesheet', false);
    changeField('useTeamTracking', false);
    // changeField('useMarketing', false);
  }

  function handleChangeAgree(e) {
    setAgree(e.target.checked);
  }

  function handleOpenDialog() {
    setShowDialog(true);
  }

  function handleCloseDialog() {
    setShowDialog(false);
  }

  const pricingPlans = (plans && plans.pricingPlans.base['ALL']) || [];
  const basePlans = pricingPlans.reduce((r, plan) => {
    r[plan.metadata.planCode] = r[plan.metadata.planCode] || [];
    if (plan.billingInterval === 'month') {
      r[plan.metadata.planCode].monthly = plan;
    } else {
      r[plan.metadata.planCode].annual = plan;
    }
    return r;
  }, []);

  let selectedPlan;
  if (plans && planCode) {
    selectedPlan = basePlans[planCode];
  } else if (plans) {
    selectedPlan = basePlans[DEFAULT_PLAN];
  }
  let currency = selectedPlan ? selectedPlan.monthly.currency.toUpperCase() : '';
  // TODO: fix hard-code
  if (currency === 'SGD') {
    currency = 'S$';
  } else if (currency === 'MYR') {
    currency = 'RM';
  } else if (currency === 'THB') {
    currency = '฿';
  }

  const baseAdditionalUserPrice = plans ? plans.pricingPlans.extraUser.ALL[0].amountDecimal : 0;
  const usersIncluded = selectedPlan ? selectedPlan.monthly.metadata.usersIncluded : 0;
  const activeUser = plans ? plans.subscription.activeUserCount : 0;
  const additionalUser = Math.max(activeUser - usersIncluded, 0);
  const additionalUserPrice = additionalUser * baseAdditionalUserPrice;

  // eslint-disable-next-line no-nested-ternary
  const basePlanPrice = selectedPlan ?
    (billingInterval === 'year' ? selectedPlan.annual.amountDecimal : selectedPlan.monthly.amountDecimal) : 0;

  const teamTrackingIncluded = selectedPlan && selectedPlan.monthly.metadata.teamTrackingIncluded === 'true';
  const baseTeamTracking = plans ? plans.pricingPlans.teamTracking.ALL[0].amountDecimal : 0;
  const teamTrackingPrice = activeUser * baseTeamTracking;
  const useTeamTrackingPrice = useTeamTracking && !teamTrackingIncluded;

  const timesheetIncluded = selectedPlan && selectedPlan.monthly.metadata.timesheetIncluded === 'true';
  const baseTimesheet = plans ? plans.pricingPlans.timesheet.ALL[0].amountDecimal : 0;
  const timesheetPrice = activeUser * baseTimesheet;
  const useTimeSheetPrice = useTimesheet && !timesheetIncluded;

  const creditCardRate = ((plans && planCode && plans.pricingPlans.creditCardRate.ALL
    .find(rate => rate.metadata.planCode === planCode)) || {}).percentage;

  const addOnsPrice = Number(useTeamTrackingPrice ? teamTrackingPrice : 0)
    + Number(useTimeSheetPrice ? timesheetPrice : 0)
    + Number(additionalUserPrice);
  const subTotalPrice = Number(basePlanPrice) + addOnsPrice;
  const creditCardPrice = creditCardRate ? subTotalPrice * Number(creditCardRate / 100) : 0;
  const totalPrice = subTotalPrice + creditCardPrice;

  const submitText = hasBillingMethod ? 'updateSubscription' : 'proceedToCheckout';

  const showTrialEnd = plans && new Date(plans.subscription.trialEnd) < new Date()
    && (!hasBillingMethod || !plans.subscription.planCode);

  /* If this subscription was over grace period days (default is 14) days from billing due,
  we will have newBillingDue is for new subscription. */

  let subscriptionStartDate;
  if( plans && plans.subscription) {
    const { payFromNow, billingDue } = plans.subscription;
    subscriptionStartDate = payFromNow ? translate('now') : moment(billingDue).format('MMM DD, YYYY');
  }

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={handleSubmit}
    >
      {plans &&
      <div className={styles.container}>
        {showTrialEnd && <Card className={styles.card} style={{ textAlign: 'center' }}>
          {translate('trialExpiredMessage')}
        </Card>}
        <div className={styles.title}>{translate('manageSubscription')}</div>
        <Grid container>
          <Grid className={styles.gridLeft} md={7} xs={12} item>
            <Card className={styles.card}>
              <Grid container>
                <Grid item xs={6}>
                  <Field
                    name='planCode'
                    component={TextInput}
                    label={translate('selectPlan')}
                    select
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChangePlan}
                  >
                    { Object.entries(basePlans).map(option => (
                      <option
                        key={option[0]}
                        value={option[0]}
                      >
                        {option[0]}
                      </option>
                    )) }
                  </Field>
                </Grid>
                <Grid item xs={6}>
                  {plans && selectedPlan &&
                    <div className={styles.planPrice}>
                      <div>{`${fp(selectedPlan.monthly.amountDecimal / 100, currency)} / ${translate('month')}`}</div>
                      <div>{`${fp(selectedPlan.annual.amountDecimal / 100, currency)} / ${translate('year')}`}</div>
                    </div>}
                </Grid>
              </Grid>
              <div className={styles.planIncludes}>
                <div>
                  <div className={styles.textBoldSmall}>{translate('planIncludes')}:</div>
                  {timesheetIncluded ? <div>{translate('timesheet')}</div> : null}
                  {teamTrackingIncluded ? <div>{translate('teamGPSTracking')}</div> : null}
                  {usersIncluded ? <div>{translate('users')}: {usersIncluded}</div> : null}
                </div>
                <div><span className={styles.textBtn} onClick={handleOpenDialog}>{translate('learnMore')}</span></div>
              </div>
            </Card>

            {!(timesheetIncluded && teamTrackingIncluded) &&
              <>
                <div className={styles.textBold}>{translate('manageAddOns')}</div>
                <Card className={styles.card}>
                  <div className={styles.textBoldSmall}>{translate('teamManagement')}</div>
                  <div style={{ marginBottom: 8 }}>{translate('planFeature')}</div>
                  {!timesheetIncluded &&
                  <div className={styles.spaceBetween}>
                    <div>
                      <div>{translate('timesheet')}</div>
                      <div className={styles.textGray}>
                        {translate('addOnPricePerMonth', { price: fp(baseTimesheet / 100, currency) })}
                      </div>
                    </div>
                    <div>
                      <Field
                        name='useTimesheet'
                        component={Checkbox}
                      />
                      <span>{`${fp(timesheetPrice / 100, currency)} / ${translate('month')}`}</span>
                    </div>
                  </div>}

                  {!teamTrackingIncluded &&
                  <div className={styles.spaceBetween}>
                    <div>
                      <div>{translate('teamGPSTracking')}</div>
                      <div className={styles.textGray}>
                        {translate('addOnPricePerMonth', { price: fp(baseTeamTracking / 100, currency) })}
                      </div>
                    </div>
                    <div className={styles.check}>
                      <Field
                        name='useTeamTracking'
                        component={Checkbox}
                        disabled={teamTrackingIncluded}
                      />
                      <span>
                        {`${fp(teamTrackingPrice / 100, currency)} / ${translate('month')}`}
                      </span>
                    </div>
                  </div> }

                </Card>
              </>}
          </Grid>

          <Grid className={styles.gridRight} md={5} xs={12} item>
            <Card className={styles.card}>
              <div className={styles.subtitle}>{translate('summary')}</div>
              <div className={styles.spaceBetweenMiddle}>
                <div>{translate('billingCycle')}</div>
                <Field
                  className={styles.intervalInput}
                  name='billingInterval'
                  component={TextInput}
                  select
                  SelectProps={{ native: true }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="month">{translate('monthly')}</option>
                  <option value="year">{translate('annual')}</option>
                </Field>
              </div>
              <hr />

              <div className={styles.spaceBetween}>
                <div className={styles.textBoldSmall}>{translate(planCode)}</div>
                <div>{fp(basePlanPrice / 100, currency)}</div>
              </div>

              {addOnsPrice > 0 &&
              <>
                <div className={styles.textBold}>{translate('addOns')}</div>

                {additionalUser > 0 &&
                <div className={styles.spaceBetween}>
                  <div>
                    <div>{translate('additionalUsers')}</div>
                    <div className={styles.textGray}>{translate('users')}: {additionalUser}</div>
                  </div>
                  <div>{fp(additionalUserPrice / 100, currency)}</div>
                </div> }

                {(useTimeSheetPrice || useTeamTrackingPrice) &&
                <div className={styles.textBoldSmall} style={{ marginBottom: 8 }}>{translate('teamManagement')}</div>}

                {useTimeSheetPrice &&
                <div className={styles.spaceBetween}>
                  <div>
                    <div>{translate('timesheet')}</div>
                    <div className={styles.textGray}>{translate('users')}: {activeUser}</div>
                  </div>
                  <div>{fp(timesheetPrice / 100, currency)}</div>
                </div> }
                {useTeamTrackingPrice &&
                <div className={styles.spaceBetween}>
                  <div>
                    <div>{translate('teamGPSTracking')}</div>
                    <div className={styles.textGray}>{translate('users')}: {activeUser}</div>
                  </div>
                  <div>{fp(teamTrackingPrice / 100, currency)}</div>
                </div> }
              </> }

              <hr />

              <div className={styles.spaceBetween}>
                <div>
                  <div className={styles.textBoldSmall}>{translate('subtotal')}</div>
                </div>
                <div>{fp(subTotalPrice / 100, currency)}</div>
              </div>
              {creditCardRate > 0 && (
                <div className={styles.spaceBetween}>
                  <div>
                    <div>{translate('creditCardRate')}</div>
                    <div className={styles.textGray}>{`${creditCardRate} %`}</div>
                  </div>
                  <div>{fp(creditCardPrice / 100, currency)}</div>
                </div>
              )}
              <hr />

              <div className={styles.spaceBetween}>
                <div>
                  <div className={styles.total}>{translate('total')}</div>
                  <div>{translate('taxInclusive')}</div>
                </div>
                <div>{fp(totalPrice / 100, currency)}</div>
              </div>
              { ((plans && !plans.subscription.planCode) || !hasBillingMethod) && (
                <div className={styles.center}>
                  <CheckboxMUI checked={agree} onChange={handleChangeAgree} />
                  <span>
                    <Trans i18nKey="Account:checkoutAgreementMessage">
                      <Link to='/subscription-agreement' target="_blank" />
                      <Link to='/privacy-policy' target="_blank" />
                      { subscriptionStartDate }
                    </Trans>
                  </span>
                </div>
              )}
              <div className={styles.submit}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || !agree}
                >
                  {submitting ? translate('submitting') : translate(submitText)}
                </Button>
              </div>
            </Card>
            <div className={styles.more}>
              <div className={styles.textBold}>{translate('needMoreTime')}</div>
              <div><Link to="/contact" target="_blank">{translate('contactCustomer')}</Link></div>
              <div>
                <a
                  href="https://servishero.zendesk.com/hc/en-us/categories/360002523291-WorkMagic"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate('FAQ')}
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </div> }

      <Dialog onClose={handleCloseDialog} open={showDialog} fullWidth maxWidth="xl">
        <div className={styles.dialog}>
          <CloseIcon className={styles.dialogClose} fontSize="large" onClick={handleCloseDialog} />
          <PricingPlan country={geoCountry} translate={translate} homepage={false} />
        </div>
      </Dialog>
    </form>
  );
};

const mapDispatch = (dispatch) => ({
  changeField: (field, value) => dispatch(change('subscriptionForm', field, value))
});

const mapState = (state) => ({
  formValues: getFormValues('subscriptionForm')(state) || {},
  geoCountry: state.auth.geoCountry,
});

export default compose(
  connect(mapState, mapDispatch),
  reduxForm({ form: 'subscriptionForm' }),
)(SubscriptionForm);
