import LocalizedStrings from 'react-localization';

export const locales = new LocalizedStrings({
  en: {
    common: {
      ok: 'Ok',
      cancel: 'Cancel',
      edit: 'Edit',
      backTo: 'Back to',
      clients: 'Clients',
    },
    landingPage: {
      marketingMessage1:
        'The Best Software to Run <br/> Your Home Service Business',
      marketingMessage2:
        'Our technology lets you manage your staffs, <br/> customer relationships and operations, likes magic',
      businessOptions:
        'Cleaning | Plumbing | Electrical | Air conditioning <br/> Movers & Lorry | Landscaping | Handyman <br/> and more',
    },
    errorMessage: {
      requiredEmail: 'Email required',
      required: 'Required',
      chooseUserRole: 'Please select a role for the user.',
      inputJobTitle: 'Please input job title',
      noClientSelected: 'No client selected',
      noPropertySelected: 'No property selected',
      clientNotFound: 'Client was not found',
      propertyNotFound: 'Property was not found',
      setStartTime: 'Please set start time.',
      setEndTime: 'Please set end time.',
      setStartDate: 'Please set start date.',
      setEndDate: 'Please set end date.',
      invalid: 'Invalid value',
      invalidStartDate: 'Invalid start date',
      invalidEndDate: 'Invalid end date',
      invalidStartTime: 'Invalid start time',
      invalidEndTime: 'Invalid end time',
      invalidEmail: 'Invalid Email',
      invalidDiscount: 'Discount value cannot be larger than 100%',
      endTimeAfterStartTime: 'End time must be after start time',
      endDateAfterStartDate: 'End date must be after start date',
      invoiceSubject: 'Please enter the subject for the invoice.',
      setItemName: 'Set item name',
      positiveQuantity: 'Quantity must be larger than zero',
      positiveUnitCost: 'Unit cost must be larger than zero',
      positiveTotal: 'Total must be larger than zero',
      productName: 'Set product name',
      firstNameRequired: 'First name required',
      lastNameRequired: 'Last name required',
      emailRequired: 'Email required',
      passwordRequired: 'Password required',
      confirmPasswordRequired: 'Password confirmation required',
      countryPhoneCodeRequired: 'Country code required',
      mobileNumberRequired: 'Mobile number required',
      phoneNumberRequired: 'Mobile number required',
      addressRequired: 'Address required',
      cityRequired: 'City required',
      roleRequired: 'Role required',
      countryCodeRequired: 'Country required',
      zipCodeRequired: 'Postal code required',
      hintPassword: `Password must be at least 8 characters long,
          contain both lowercase & uppercase letters, at least one number,
          one special character (!@#%^*) and no space.`,
      organisationNameRequired: "Organisation's name required.",
      organisationEmailRequired: "Organisation's contact email required",
      organisationMobileNumberRequired:
        "Organisation's contact mobile number required",
      passwordConfirmationNotMatch:
        'The password confirmation was not match with password',
      visitTitleRequired: 'Please input the visit title.',
      taxNameRequired: 'Tax name required',
      taxNumberRequired: 'Tax number required',
    },
    client: {
      archivedSuccess: '{clientName} has been archived',
      unarchivedSuccess: '{clientName} has been unarchived',
      askConfirmationBeforeArchive: 'Do you want to archive this client?',
      askConfirmationBeforeUnarchive: 'Do you want to unarchive this client?',
      addClient: '+ Add Client',
      importClient: 'Import Client',
    },
  },
  th: {
    landingPage: {
      marketingMessage1: 'content for ThaiLand here.',
      marketingMessage2: 'Service for your home services business',
    },
  },
});
