import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAnnouncements } from 'redux/dashboard';
import moment from 'moment';
import { showErrorMessage } from 'lib/notifier';


const Announcement = ({ t, isParentLoading, latestUpdate }) => {
  const [currentTime, setCurrentTime] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState({});
  const [isOpenModalDetails, setIsOpenModalDetails] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isParentLoading) return;
    (async () => {
      setIsLoading(true);
      const response = await dispatch(getAnnouncements());
      setIsLoading(false);
      if (response.e) return showErrorMessage(response.e);
      setAnnouncements(response.data);
    })();
    console.log(announcements)
  }, [isParentLoading, latestUpdate]);

  const handleClickAnnouncements = (announcementDetails) => {
    setIsOpenModalDetails(true);
    setSelectedAnnouncement(announcementDetails);
  };

  const handleClose = () => setIsOpenModalDetails(false);

  return (
    <div>
      <h1>Announcement Page</h1>

    </div>
  );
};

export default Announcement;
